import { BooleanInput, coerceBooleanProperty } from '@angular/cdk/coercion';
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  HostBinding,
  Input,
  Output,
  ViewChild,
  ViewEncapsulation,
  inject,
} from '@angular/core';

import { OverlayModule } from '@angular/cdk/overlay';
import { CommonModule } from '@angular/common';

import { MentionData, MentionDirective } from '../mention.directive';
import { UserComponent } from '../user.component';
const WAIT_FOR_CONTENT_REFRESH = 300;
@Component({
  selector: 'design-mention',
  templateUrl: './mention.component.html',
  styleUrls: ['./mention.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  imports: [CommonModule, OverlayModule, MentionDirective, UserComponent],
})
export class MentionComponent implements AfterViewInit {
  private readonly _cdr = inject(ChangeDetectorRef);
  private readonly _el = inject(ElementRef);
  @HostBinding('class.design-mention') class = true;
  @ViewChild(MentionDirective) private mention!: MentionDirective;
  @Input() mentionData: MentionData[] = [];
  private _value = '';
  @Input() set value(value: string) {
    this._value = value;
  }
  get value() {
    return this._value;
  }
  @Input() externalTagName!: string;
  private _readonly = false;

  @Input() set readonly(isReadonly: BooleanInput) {
    this._readonly = coerceBooleanProperty(isReadonly);
  }
  get readonly() {
    return this._readonly;
  }
  private _rows?: number = undefined;
  @Input() set rows(rows: number | undefined) {
    this._rows = rows;
    this._cdr.markForCheck();
  }
  get rows(): number | undefined {
    return this._rows;
  }
  @Input() set placeholder(value: string) {
    this._placeholder = value;
  }
  get placeholder() {
    return this._placeholder ?? '';
  }
  protected _placeholder?: string;
  @Output() textContent: EventEmitter<string> = new EventEmitter<string>();
  @Output() htmlContent: EventEmitter<string> = new EventEmitter<string>();
  @Output() enterPressed: EventEmitter<void> = new EventEmitter<void>();
  // eslint-disable-next-line @angular-eslint/no-output-native

  ngAfterViewInit(): void {
    this.mention.contentElement.setAttribute('placeholder', this.placeholder);
  }
  resetValue(): void {
    this.contentHtmlValue;
    this.mention.value = '';
  }
  changeValue(value: string): void {
    this.mention.value = value;
  }
  loseFocus(): void {
    this.mention.contentElement.blur();
  }
  private _htmlToText(htmlText: string): string {
    const copyText = htmlText;
    const textWithReplacedUserTags = this._changeUserTag(copyText);
    return this._removeUnexpectedTags(textWithReplacedUserTags);
  }
  private _emitText(htmlText: string) {
    this.textContent.emit(this._htmlToText(htmlText));
  }

  private _removeUnexpectedTags(textWithoutUserTags: string): string {
    const htmlRegexG = /<(?:"[^"]*"['"]*|'[^']*'['"]*|[^'">])+>/g;
    const tagArray = [...textWithoutUserTags.matchAll(htmlRegexG)];
    tagArray.forEach((matchTag) => {
      const tagName = this._tagName(matchTag[0]).toUpperCase();

      switch (tagName) {
        case 'BR': {
          const br = new RegExp('<br>', 'g');
          textWithoutUserTags = textWithoutUserTags.replace(br, '\n');
          break;
        }
        case 'DIV': {
          const div = new RegExp('<div>|</div>', 'g');
          textWithoutUserTags = textWithoutUserTags.replace(div, '');
          break;
        }
        default: {
          break;
        }
      }
    });
    textWithoutUserTags = textWithoutUserTags.replace(/&nbsp;/gm, ' ');
    return textWithoutUserTags;
  }
  private _tagName(matchTag: string): string {
    const tagRegexG = /<\/?([a-zA-Z\/-]*)/g;
    const tagName = tagRegexG.exec(matchTag)?.[1] || '';
    return tagName;
  }

  private _changeUserTag(htmlText: string): string {
    const userRegexG =
      /(?:<user-link *?(value="(.[^"]*)?|ng-reflect-value="(.[^"]*)?).*?>(.*?)<\/user-link>)/gm;
    const tagArray = [...htmlText.matchAll(userRegexG)];
    tagArray.forEach((matchTag) => {
      const userName = matchTag[4].slice(1, matchTag[4].length);
      const userId = matchTag[2] || matchTag[3];
      const backendElement = `<user-link data-user-info="${userId}" data-user-name="${userName}"></user-link>`;

      htmlText = htmlText.replace(matchTag[0], backendElement);
    });
    return htmlText;
  }

  contentChange($event: string): void {
    this.htmlContent.emit($event);
    this._emitText($event);
  }

  focus(): void {
    setTimeout(() => {
      this.mention.convertUrlToString();
      this._el.nativeElement.firstChild.focus();
    }, 0);
  }
  get contentTextValue(): Promise<string> {
    return new Promise((resolve) => {
      setTimeout(() => {
        const text = this._htmlToText(this.mention.contentValue);
        resolve(this._htmlToText(this.mention.contentValue));
        this.mention.contentElement.innerHTML =
          this.mention.convertUrlToTag(text);
      }, WAIT_FOR_CONTENT_REFRESH);
    });
  }
  get contentHtmlValue(): string {
    return this.mention.contentValue;
  }
}
