import { inject, Pipe, PipeTransform } from '@angular/core';
import { attachmentIcon } from '@simlab/design/attachment-icon';
import { DesignSymbol } from '@simlab/design/internal';
import {
  DocumentStates,
  FileCardStates,
  FolderStates,
  RowStates
} from '@simlab/documents/models';

@Pipe({
  name: 'getDocumentIcon',
  standalone: true
})
export class DocumentIconGetterPipe<
  TData extends DocumentStates | FileCardStates | FolderStates
> implements PipeTransform
{
  private readonly _attachmentIcon = inject(attachmentIcon);

  transform(data: TData['data']): DesignSymbol {
    if (this._isNotDirectory(data)) {
      return this._attachmentIcon.getAttachmentIconByFilenameOrType(
        data.name,
        data.documentType
      );
    }

    return DesignSymbol.FolderFill;
  }

  private _isNotDirectory(
    data: TData['data']
  ): data is FileCardStates['data'] | RowStates['data'] {
    return !data.isDirectory;
  }
}
