import {
  CommentToken,
  DesignCommentComponent,
} from './components/comment/comment.component';
import { CommentOptions } from './models/comment-options.model';
import { CommentAttachment, CommentWithFiles } from './models/comment.models';

export {
  CommentAttachment,
  CommentOptions,
  CommentToken,
  CommentWithFiles,
  DesignCommentComponent,
};
