/* eslint-disable @angular-eslint/no-input-rename */
/* eslint-disable @angular-eslint/no-inputs-metadata-property */
import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  ViewEncapsulation,
  inject,
} from '@angular/core';
import { CanColor, mixinColor } from '@simlab/design/internal';
import { DesignProgressSpinnerComponent } from '@simlab/design/progress-spinner';

const HEIGHT_SCALE_MULTIPLIER = 0.6;
const STROKE_SCALE_MULTIPLIER = 0.08;

const _ButtonLoaderBase = mixinColor(
  class {
    public _elementRef = inject(ElementRef);
  },
);

export abstract class ButtonLoaderBase extends _ButtonLoaderBase {
  abstract setHeight(value: number): void;
}

@Component({
    selector: 'design-button-loader',
    template: `<design-progress-spinner
    [diameter]="diameter"
    [strokeWidth]="stroke"
    design
  ></design-progress-spinner>`,
    styleUrls: ['./button-loader.component.scss'],
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
    imports: [DesignProgressSpinnerComponent],
    providers: [
        { provide: ButtonLoaderBase, useExisting: ButtonLoaderComponent },
    ],
    // eslint-disable-next-line @angular-eslint/no-host-metadata-property
    host: {
        class: 'design-button-loader',
    },
    inputs: ['color: designColor']
})
export class ButtonLoaderComponent
  extends ButtonLoaderBase
  implements CanColor
{
  diameter = 0;
  stroke = 0;

  setHeight(value: number): void {
    this.diameter = value * HEIGHT_SCALE_MULTIPLIER;
    this.stroke = value * STROKE_SCALE_MULTIPLIER;
  }
}
