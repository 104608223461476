import { inject } from '@angular/core';
import { tapResponse } from '@ngrx/operators';
import { rxMethod } from '@ngrx/signals/rxjs-interop';
import { AbstractConstructor, Constructor } from '@simlab/design/internal';
import { ToastService } from '@simlab/design/toast';
import { ApiProcoreService } from '@simlab/procore/data-access';
import { PunchItemCommentPayload } from '@simlab/procore/models';
import { exhaustMap, pipe } from 'rxjs';
import { RxMethod } from './_punch-item-store.service';
import { PunchItemState } from './_punch-item.state';

export type PunchItemAddComment = {
  addComment: RxMethod<PunchItemCommentPayload>;
};

type TPunchItemComment = Constructor<PunchItemAddComment> &
  AbstractConstructor<PunchItemAddComment>;
export function mixinPunchItemAddComment<T extends AbstractConstructor<any>>(
  base: T
): TPunchItemComment & T;

export function mixinPunchItemAddComment<T extends Constructor<any>>(base: T) {
  return class extends base implements PunchItemAddComment {
    private readonly _state = inject(PunchItemState);
    private readonly _api = inject(ApiProcoreService);
    private readonly _toastService = inject(ToastService);

    readonly addComment = rxMethod<PunchItemCommentPayload>(
      pipe(
        exhaustMap((payload) => {
          this._state.patchItemState({
            addingCommentStatus: 'PENDING'
          });
          return this._api.createPunchItemComment(payload).pipe(
            tapResponse({
              next: () => {
                this._state.patchItemState({
                  addingCommentStatus: 'SUCCESS'
                });
                this._state.fetchActivities(payload.procorePunchItemId);
              },
              error: (error) => {
                this._toastService.open(
                  $localize`:@@COMMENT_ERROR:An error occured while adding the comment.`,
                  'Error'
                );
                this._state.patchItemState({
                  addingCommentStatus: 'FAIL'
                });
                console.error(error);
              },
              finalize: () => {
                queueMicrotask(() => {
                  this._state.patchItemState({
                    addingCommentStatus: 'IDLE'
                  });
                });
              }
            })
          );
        })
      )
    );
  };
}
