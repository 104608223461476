import {
  AfterContentInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ContentChildren,
  DestroyRef,
  EventEmitter,
  Input,
  Output,
  QueryList,
  inject,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { defer, merge, startWith, switchMap } from 'rxjs';
import { DesignButtonToggleComponent } from '../design-button-toggle/design-button-toggle.component';

let uniqueBtnGroupId = 0;
@Component({
    selector: 'design-button-group',
    imports: [],
    template: ` <ng-content select="design-button-toggle"></ng-content> `,
    styleUrls: ['./design-button-group.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    // eslint-disable-next-line @angular-eslint/no-host-metadata-property
    host: {
        '[class.disabled]': 'disabled',
    }
})
export class DesignButtonGroupComponent implements AfterContentInit {
  private readonly _destroyerRef = inject(DestroyRef);
  private readonly _cdr = inject(ChangeDetectorRef);
  private _disabled = false;
  private _id = `design-button-group-${uniqueBtnGroupId++}`;
  public get id() {
    return this._id;
  }
  @Input() public set id(value) {
    this._id = value;
  }
  private _value: string | number | undefined;
  public get value(): string | number | undefined {
    return this._value;
  }

  @Input() public set value(value: string | number | undefined) {
    this._value = value;
    this._setSelectedValue();
  }

  public get disabled() {
    return this._disabled;
  }
  @Input() public set disabled(value) {
    this._disabled = value;
    this._disableChildren();
  }
  @Output() selectionChange = new EventEmitter<string | number | undefined>();
  @ContentChildren(DesignButtonToggleComponent)
  buttons!: QueryList<DesignButtonToggleComponent>;

  private readonly _selectionChange$ = defer(() =>
    this.buttons.changes.pipe(
      startWith(this.buttons),
      switchMap(() => {
        return merge(
          ...this.buttons.map((buttonToggle) => buttonToggle.selectionChange),
        );
      }),
    ),
  );

  ngAfterContentInit(): void {
    this._selectionChange$
      .pipe(takeUntilDestroyed(this._destroyerRef))
      .subscribe((value) => {
        this.buttons.forEach((buttonToggle) => {
          if (value !== buttonToggle.value) {
            buttonToggle.deselect();
          }
        });
        this.selectionChange.emit(value);
      });
    this._disableChildren();
    this._setSelectedValue();
  }
  private _setSelectedValue() {
    this.buttons &&
      this.buttons.map((buttonToggle) => {
        buttonToggle.selected = buttonToggle.value === this.value;
      }) &&
      this._cdr.markForCheck();
  }

  private _disableChildren() {
    this.buttons &&
      this.buttons.map((buttonToggle) => {
        buttonToggle.parentDisabled = this._disabled;
      }) &&
      this._cdr.markForCheck();
  }
}
