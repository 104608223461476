import { FocusableOption, FocusOrigin } from '@angular/cdk/a11y';
import { CommonModule } from '@angular/common';
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Host,
  inject,
  Input,
  OnDestroy,
  Output,
  ViewEncapsulation
} from '@angular/core';
import { DesignIconButton } from '@simlab/design/button';
import { DesignIcon } from '@simlab/design/icon';
import { CanColor } from '@simlab/design/internal';
import { tap } from 'rxjs';
import { ExpansionPanelHeader } from '../expansion-panel-header.directive';
import { DesignRadioExpansionPanel } from '../radio-expansion-panel/radio-expansion-panel.component';

@Component({
    selector: 'design-expansion-panel-header',
    imports: [CommonModule, DesignIcon, DesignIconButton],
    templateUrl: './expansion-panel-header.component.html',
    styleUrls: ['./expansion-panel-header.component.scss'],
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
    host: {
        class: 'design-expansion-panel-header design-focus-indicator',
        role: 'button',
        '[attr.aria-expanded]': '_isExpanded()',
        '[class.design-expansion-panel-header--expanded]': '_isExpanded()',
        '[class.design-color--primary]': 'color === "primary"',
        '[class.design-color--secondary]': 'color === "secondary"',
        '[class.design-color--error]': 'color === "error"',
    },
    inputs: ['color'],
    providers: [
        {
            provide: ExpansionPanelHeader,
            useExisting: ExpansionPanelHeaderComponent,
        },
    ]
})
export class ExpansionPanelHeaderComponent
  extends ExpansionPanelHeader
  implements FocusableOption, AfterViewInit, OnDestroy, CanColor
{
  private changeDetectorRef = inject(ChangeDetectorRef);

  constructor(
    @Host()
    public panel: DesignRadioExpansionPanel
  ) {
    super(panel);
    // Avoids focus being lost if the panel contained the focused element and was closed.
    // panel.closed
    // .pipe(filter(() => panel._containsFocus()))
    // .subscribe(() => _focusMonitor.focusVia(_elementRef, 'program'));
    this.expanded = this.panel.expanded;
  }

  @Input() showArrow = true;
  @Output() emitClick = new EventEmitter<void>();
  expanded = false;

  override _toggle(): void {
    if (!this.disabled) {
      if (this.panel.expanded) {
        this.panel.close();
      } else {
        this.panel.open();
      }
    }
    this.expanded = this.panel.expanded;
    this.changeDetectorRef.detectChanges();
  }

  openCloseHeader(): void {
    this._toggle();
  }

  ngAfterViewInit(): void {
    this._focusMonitor
      .monitor(this._elementRef)
      .pipe(
        tap((origin: FocusOrigin) => {
          if (origin && this.panel.accordion) {
            this.panel.accordion._handleHeaderFocus(this);
          }
        })
      )
      .subscribe();
  }

  ngOnDestroy(): void {
    this._parentChangeSubscription.unsubscribe();
    this._focusMonitor.stopMonitoring(this._elementRef);
  }
}
