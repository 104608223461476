import { HttpErrorResponse } from '@angular/common/http';
import { inject } from '@angular/core';
import { AbstractConstructor, Constructor } from '@simlab/design/internal';
import { ToastService } from '@simlab/design/toast';
import { ApiProcoreService } from '@simlab/procore/data-access';
import { procoreBaseInfoPayload } from '@simlab/procore/models';
import { catchError, EMPTY, exhaustMap, forkJoin, map, Observable, of } from 'rxjs';

export type PunchItemUploadFilesAndReturnIds = {
  uploadFilesAndReturnIds$(files: File[]): Observable<string[]>;
};

type TPunchItemUploadFiles = Constructor<PunchItemUploadFilesAndReturnIds> &
  AbstractConstructor<PunchItemUploadFilesAndReturnIds>;
export function mixinPunchItemUploadFiles<T extends AbstractConstructor<any>>(
  base: T
): TPunchItemUploadFiles & T;

export function mixinPunchItemUploadFiles<T extends Constructor<any>>(base: T) {
  return class extends base implements PunchItemUploadFilesAndReturnIds {
    private readonly _api = inject(ApiProcoreService);
    private readonly _toastService = inject(ToastService);
    private readonly _baseInfoProcore = inject(procoreBaseInfoPayload);

    uploadFilesAndReturnIds$(files: File[]) {
      const baseInfoProcore = this._baseInfoProcore();
      if (!files || !files.length || !baseInfoProcore) {
        return of([]);
      }

      const { procoreCompanyId, procoreProjectId } = baseInfoProcore;
      const requests: Observable<string>[] = files.map((file) => {
        const filename = file.name;
        return this._api
          .createUpload({
            procoreCompanyId,
            procoreProjectId,
            filename
          })
          .pipe(
            exhaustMap((createUploadResponse) =>
              this._api
                .uploadFileToUrl(file, createUploadResponse)
                .pipe(map(() => createUploadResponse.uuid))
            ),
            catchError((error: HttpErrorResponse) => {
              this._toastService.open(
                $localize`:@@SEND_TO_REVIEW_ERROR:An error occured while setting the punch item status as Ready to Review.`,
                'Error'
              );
              console.error(error);
              return of();
            })
          );
      });

      return forkJoin(requests);
    }
  };
}


