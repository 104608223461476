import { Component, OnInit, inject } from '@angular/core';
import { EventType, Router, RouterModule } from '@angular/router';
import { SeverityLevel } from '@microsoft/applicationinsights-web';
import { RouterFacadeService } from '@simlab/util-shared';
import { InsightsService } from '@simlab/util/core';
import {
  EventTypes,
  OidcSecurityService,
  PublicEventsService
} from 'angular-auth-oidc-client';
import { catchError, filter, of, switchMap, take } from 'rxjs';
import { CheckForUpdateService } from './check-for-update.service';

@Component({
    selector: 'simlab-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
    imports: [RouterModule]
})
export class AppComponent implements OnInit {
  private readonly _oidcSecurityService = inject(OidcSecurityService);
  private readonly _routerFacadeService = inject(RouterFacadeService);
  private readonly _router = inject(Router);
  private readonly _checkForUpdate = inject(CheckForUpdateService);
  private readonly _eventService = inject(PublicEventsService);
  private readonly _insightsService = inject(InsightsService);

  constructor() {
    this._router.events
      .pipe(filter((event) => event.type === EventType.NavigationError))
      .subscribe((e) => console.log(e));
  }

  ngOnInit(): void {
    this._eventService
      .registerForEvents()
      .pipe(
        filter(
          (notification) =>
            notification.type === EventTypes.CheckingAuthFinishedWithError
        )
      )
      .subscribe(() => {
        this._insightsService.trackException({
          error: new Error('maxIdTokenIatOffsetAllowedInSeconds'),
          id: 'maxIdTokenIatOffsetAllowedInSeconds',
          severityLevel: SeverityLevel.Error
        });
        this._router.navigate(['/error']);
      });

    this._routerFacadeService.getRoute$
      .pipe(
        filter((e) => !!e && !e.includes('callbackMatterport')),
        take(1),
        switchMap(() =>
          this._oidcSecurityService.checkAuth().pipe(
            catchError((e) => {
              console.log(e);

              return of(e);
            })
          )
        ),
        catchError((error) => {
          if (
            error.indexOf &&
            error.indexOf('could not find matching config for state') > -1
          ) {
            this._router.navigate(['/']);
          }

          return of(error);
        })
      )
      .subscribe();
    this._checkForUpdate.checkForUpdate();
  }
}
