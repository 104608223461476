/* eslint-disable @typescript-eslint/no-empty-interface */
import { forwardRef, InjectionToken } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { _DesignRadioButtonBase } from './design-radio-button-base.directive';
import { DesignRadioGroup } from './design-radio-group';
import { _DesignRadioGroupBase } from './design-radio-group-base.directive';

/**
 * Provider Expression that allows mat-radio-group to register as a ControlValueAccessor. This
 * allows it to support [(ngModel)] and ngControl.
 * @docs-private
 */
export const DESIGN_RADIO_GROUP_CONTROL_VALUE_ACCESSOR: any = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => DesignRadioGroup),
  multi: true,
};

/**
 * Injection token that can be used to inject instances of `MatRadioGroup`. It serves as
 * alternative token to the actual `MatRadioGroup` class which could cause unnecessary
 * retention of the class and its component metadata.
 */
export const DESIGN_RADIO_GROUP = new InjectionToken<
  _DesignRadioGroupBase<_DesignRadioButtonBase>
>('DesignRadioGroup');

export interface DesignRadioDefaultOptions {
  //   color: ThemePalette;
}

export const DESIGN_RADIO_DEFAULT_OPTIONS =
  new InjectionToken<DesignRadioDefaultOptions>(
    'design-radio-default-options',
    {
      providedIn: 'root',
      factory: DESIGN_RADIO_DEFAULT_OPTIONS_FACTORY,
    }
  );

export function DESIGN_RADIO_DEFAULT_OPTIONS_FACTORY(): DesignRadioDefaultOptions {
  return {
    color: 'accent',
  };
}
