/* eslint-disable @angular-eslint/no-host-metadata-property */
/* eslint-disable @angular-eslint/component-class-suffix */
import { CdkAccordionItem } from '@angular/cdk/accordion';
import { PortalModule } from '@angular/cdk/portal';
import { CommonModule } from '@angular/common';
import {
  AfterContentInit,
  ChangeDetectionStrategy,
  Component,
  contentChild,
  contentChildren,
  ViewEncapsulation,
} from '@angular/core';
import { designRadioExpansionPanelAnimations } from '../../animations/radio-expansion-panel.animation';
import { designRadioAccordionBaseToken } from '../../tokens/radio-accordion.token';
import { designRadioExpansionPanelBaseToken } from '../../tokens/radio-expansion-panel.token';
import { DesignExpansionPanel } from '../expansion-panel.component';
import { ExpansionPanelHeader } from '../expansion-panel-header.directive';

export interface DesignRadioExpansionPanelBase extends CdkAccordionItem {
  /** Whether the expansion indicator should be hidden. */
  hideToggle: boolean;
}

@Component({
    selector: 'design-radio-expansion-panel',
    exportAs: 'designRadioExpansionPanel',
    imports: [CommonModule, PortalModule],
    templateUrl: './radio-expansion-panel.component.html',
    styleUrls: ['./radio-expansion-panel.component.scss'],
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
    animations: [designRadioExpansionPanelAnimations.bodyExpansion],
    providers: [
        //NOTE: (dawid) Provide DesignRadioAccordion as undefined to prevent nested expansion panels from registering to the same accordion.
        {
            provide: designRadioAccordionBaseToken,
            useExisting: DesignRadioExpansionPanel,
        },
        {
            provide: designRadioExpansionPanelBaseToken,
            useExisting: DesignRadioExpansionPanel,
        },
    ],
    host: {
        class: 'design-radio-expansion-panel',
        '[attr.aria-expanded]': 'expanded',
        '[class.design-radio-expansion-panel--expanded]': 'expanded',
        '[class.design-radio-expansion-panel--spacing]': 'hasSpacing',
    }
})
export class DesignRadioExpansionPanel
  extends DesignExpansionPanel
  implements AfterContentInit
{
  /** Optionally defined accordion the expansion panel belongs to. */
  // override accordion!: DesignRadioAccordion;

  override toggle(): void {
    if (!this.disabled && !this.expanded) {
      this.expanded = true;

      if (this.accordion._onChange) {
        this.accordion._onChange(this.value);
      }
    }
  }

  override close(): void {
    if (!this.disabled) {
      this.expanded = false;

      if (this.accordion?._onChange) {
        this.accordion._onChange(this.value);
      }
    }
  }

  override open(): void {
    if (!this.disabled) {
      this.expanded = true;

      if (this.accordion?._onChange) {
        this.accordion._onChange(this.value);
      }
    }
  }
}
