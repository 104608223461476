import { computed, inject, Injector } from '@angular/core';
import { takeUntilDestroyed, toObservable } from '@angular/core/rxjs-interop';
import {
  ActivatedRoute,
  ActivatedRouteSnapshot,
  Params
} from '@angular/router';
import { tapResponse } from '@ngrx/operators';
import {
  patchState,
  signalStore,
  withComputed,
  withHooks,
  withMethods,
  withState,
  WritableStateSource
} from '@ngrx/signals';
import { rxMethod } from '@ngrx/signals/rxjs-interop';
import { GlobalFiltersStore } from '@simlab/annotations-filters/services';
import { Page, ProjectPaging, PunchItemWithMarker } from '@simlab/data-access';
import { StagesFacade, UserPreferenceFacade } from '@simlab/data-store';
import { DesignDateValidators } from '@simlab/design/datepicker';
import { EmptyObject, SortModel } from '@simlab/design/sort-switch';
import { NoteFiltersStore } from '@simlab/feature-stages/services';
import { StagesRootService } from '@simlab/feature/stages';
import {
  EMPTY_PUNCH_ITEM,
  TEMP_PUNCH_ITEM_ID
} from '@simlab/procore/annotation-panel/data-access';
import {
  ApiProcoreService,
  canRenderProcoreEntity
} from '@simlab/procore/data-access';
import {
  Assignment,
  DeletePunchItemPayload,
  procoreBaseInfoPayload,
  PunchItem,
  PunchItemList,
  PunchItemsGetPayloadFilters
} from '@simlab/procore/models';
import { RouterFacadeService } from '@simlab/util-shared';
import {
  catchError,
  combineLatest,
  distinctUntilChanged,
  distinctUntilKeyChanged,
  EMPTY,
  exhaustMap,
  filter,
  map,
  of,
  pipe,
  switchMap,
  tap
} from 'rxjs';
import { AnnotationsProcorePermissionsService } from './annotations-procore-permissions/annotations-procore-permissions.service';
import { ProcoreFiltersStore } from './procore-filters.store';
import { filtersChangesObserver$ } from './procore-list-store-shared-logic';

const updateElementOnList = (
  list: PunchItemList[],
  punchItem: PunchItem
): PunchItemList[] => {
  return list.map((item) => {
    if (item.procoreId !== punchItem.procoreId) return item;

    return {
      ...item,
      name: punchItem.name,
      position: punchItem.position,
      dueDate: punchItem.dueDate,
      workflowStatus: punchItem.workflowStatus,
      updatedAt: punchItem.updatedAt,
      marker: punchItem.marker
    };
  });
};

const isProjectConnectedWithProcore$ = (
  activatedRouteSnapshot: ActivatedRouteSnapshot,
  permissions: AnnotationsProcorePermissionsService
) =>
  switchMap(() =>
    combineLatest([
      canRenderProcoreEntity(activatedRouteSnapshot),
      permissions.hasUserPunchItemPermissions$.pipe(
        filter((value): value is boolean => value !== undefined)
      )
    ]).pipe(
      map(
        ([isProjectConnectedWithProcore, hasUserTemplatePermission]) =>
          isProjectConnectedWithProcore && hasUserTemplatePermission
      )
    )
  );

const resetStateStore = (store: WritableStateSource<State>) => {
  const initStateWithoutSeveralVariables = initState.omitProperty(
    'projectId',
    'pageStageId',
    'page'
  );

  // NOTE (Łukasz): recreate page state, because we wanna trigger change reference procedure.
  patchState(store, {
    ...{ ...initStateWithoutSeveralVariables, page: { ...initState.page } }
  });
};

const cantRenderPunchItemsData = (
  globalFiltersStore: InstanceType<typeof GlobalFiltersStore>,
  procoreFiltersStore: InstanceType<typeof ProcoreFiltersStore> | null,
  noteFiltersStore: InstanceType<typeof NoteFiltersStore>
) => {
  const areActivePunchItemFilters =
    procoreFiltersStore?.areActivePunchItemFilters();
  const areActiveRFIFilters = !!procoreFiltersStore?.areActiveRFIFilters();
  const areActiveNoteFilters = noteFiltersStore.areFiltersActive();

  const hasOnlySelectedUsersFromStages =
    globalFiltersStore.hasOnlySelectedAuthorFromStages() ||
    globalFiltersStore.hasOnlySelectedStakeHoldersFromStages();

  return (
    (hasOnlySelectedUsersFromStages ||
      areActiveRFIFilters ||
      areActiveNoteFilters) &&
    !areActivePunchItemFilters
  );
};

const isStagesFilterTruth = (
  stages: State['filters']['stages']
): stages is Exclude<State['filters']['stages'], null | undefined> =>
  Array.isArray(stages) && !!stages.length;

export const INIT_PAGING_STATE = { pageSize: 20, pageNumber: 1 } as const;

type State = {
  projectId: string;
  pageStageId: string;
  items: PunchItemList[];
  isListLoading: boolean;
  isLoading: boolean;
  isItemLoading: boolean;
  metaData: Page | null;
  searchInput: string;
  sort: SortModel | EmptyObject;
  page: ProjectPaging;
  filters: Partial<PunchItemsGetPayloadFilters>;
  itemSelected: PunchItem;
  activateMarkers: PunchItemWithMarker[];
  isManuallyTriggeredFilterMode: boolean;
};

const initState: State = {
  projectId: '',
  pageStageId: '',
  items: [],
  isListLoading: true,
  isLoading: true,
  isItemLoading: true,
  metaData: null,
  searchInput: '',
  sort: {},
  page: INIT_PAGING_STATE,
  itemSelected: EMPTY_PUNCH_ITEM,
  filters: {},
  activateMarkers: [],
  isManuallyTriggeredFilterMode: false
};

export const PunchItemsListStore = signalStore(
  withState(initState),
  withComputed((store) => ({
    fetchQuery: computed(() => {
      const filterStages = store.filters().stages;
      const pageStageId = store.pageStageId();

      const stages = isStagesFilterTruth(filterStages)
        ? [...filterStages]
        : [pageStageId];
      return {
        projectId: store.projectId(),
        filter: {
          ...store.filters(),
          search: store.searchInput(),
          stages
        },
        page: {
          pageSize: store.page().pageSize,
          pageNumber: store.page().pageNumber
        },
        sortColumn: store.sort().sortColumn ?? null,
        isAscending: store.sort().isAscending ?? null
      };
    }),
    isFilterMode: computed(() => {
      const filters = { ...store.filters(), search: store.searchInput() };

      if (store.isManuallyTriggeredFilterMode()) return true;

      return Object.values(filters).some(
        (value) =>
          (Array.isArray(value) && value.length > 0) ||
          (!Array.isArray(value) && !!value)
      );
    })
  })),
  withMethods(
    (
      store,
      api = inject(ApiProcoreService),
      globalFiltersStore = inject(GlobalFiltersStore),
      procoreFiltersStore = inject(ProcoreFiltersStore, { optional: true }),
      noteFiltersStore = inject(NoteFiltersStore),
      userPreferences = inject(UserPreferenceFacade),
      stageFacade = inject(StagesFacade),
      activatedRoute = inject(ActivatedRoute),
      baseInfoProcore = inject(procoreBaseInfoPayload),
      injector = inject(Injector),
      routerFacade = inject(RouterFacadeService),
      stagesRootService = inject(StagesRootService),
      permissions = inject(AnnotationsProcorePermissionsService)
    ) => ({
      updateProjectId: (projectId: State['projectId']) => {
        patchState(store, { projectId });
      },
      updatePageStageId: (pageStageId: State['pageStageId']) => {
        patchState(store, { pageStageId, items: [], isListLoading: true });
      },
      updateSearch: (searchInput: State['searchInput']) => {
        patchState(store, {
          searchInput,
          items: [],
          isListLoading: true,
          page: INIT_PAGING_STATE,
          sort: {}
        });
      },
      updateSort: (sort: State['sort']) => {
        patchState(store, { sort, items: [], isLoading: true });
      },
      updatePage: (page: State['page']) => {
        patchState(store, { page, items: [], isListLoading: true, sort: {} });
      },
      // setSelectedItemId: (itemSelected: State['itemSelected']) => {
      //   patchState(store, { itemSelected });
      // },
      setSelectedEmptyItem: () => {
        patchState(store, { itemSelected: EMPTY_PUNCH_ITEM });
      },
      resetState: () => {
        globalFiltersStore.resetFilters();
        procoreFiltersStore?.resetFilters();
        noteFiltersStore.resetFilters();

        resetStateStore(store);
      },
      resetListStates: () => {
        patchState(store, { items: [], activateMarkers: [] });
      },
      deleteItem: rxMethod<DeletePunchItemPayload>(
        pipe(
          exhaustMap((payload) =>
            api.deletePunchItem(payload).pipe(
              tapResponse({
                next: () => {
                  stageFacade.decrementPunchItemCount(
                    store.pageStageId(),
                    payload.withActionNumber
                  );
                  patchState(store, ({ items, activateMarkers }) => {
                    return {
                      itemSelected: undefined,
                      items: items.filter(
                        (item) => item.procoreId !== payload.procoreId
                      ),
                      activateMarkers: activateMarkers.filter(
                        (item) => item.procoreId !== payload.procoreId
                      )
                    };
                  });
                },
                error: console.error,
                finalize: () => {
                  patchState(store, { isListLoading: false });
                }
              })
            )
          )
        )
      ),
      deleteItemFromStore: (itemId: State['items'][number]['id']) => {
        const items = store.items().filter((item) => item.id !== itemId);
        patchState(store, { items });
      },
      deleteItemMarkerFromStore: (itemId: State['items'][number]['id']) => {
        const activateMarkers = store
          .activateMarkers()
          .filter((item) => item.id !== itemId);
        patchState(store, { activateMarkers });
      },
      autoMarkersFetch: rxMethod<void>(
        pipe(
          isProjectConnectedWithProcore$(activatedRoute.snapshot, permissions),
          switchMap((isProjectConnectedWithProcore) =>
            toObservable(store.fetchQuery, { injector })
              .pipe(
                filter(() => isProjectConnectedWithProcore),
                filter(
                  ({ projectId, filter: { stages } }) =>
                    projectId !== '' && !!stages[0]
                )
              )
              .pipe(
                switchMap((fetchQuery) => {
                  const cantRender = cantRenderPunchItemsData(
                    globalFiltersStore,
                    procoreFiltersStore,
                    noteFiltersStore
                  );

                  if (cantRender) {
                    patchState(store, {
                      activateMarkers: []
                    });
                    return EMPTY;
                  }

                  return api.getPunchItemsWithMarkersForStages(fetchQuery).pipe(
                    tap(({ items }) => {
                      const activateMarkers = items.filter(
                        ({ marker }) => marker !== undefined
                      );
                      patchState(store, { activateMarkers });
                    }),
                    catchError(() => of([]))
                  );
                })
              )
          )
        )
      ),
      setFilters: rxMethod<void>(() => {
        return filtersChangesObserver$(
          globalFiltersStore,
          procoreFiltersStore,
          noteFiltersStore,
          injector
        ).pipe(
          tap(({ globalFilters, procoreFilters }) => {
            const formattedDueDate = procoreFilters.punchItemDueDate
              ? DesignDateValidators.transformStringDateToDate(
                  procoreFilters.punchItemDueDate as string,
                  userPreferences.getDateFormat()
                ).toISOString()
              : undefined;
            patchState(store, {
              filters: {
                ...store.filters(),
                authorIds: globalFilters.authors
                  .map(({ procoreUserId }) => procoreUserId)
                  .filter((id): id is number => id !== undefined),
                assigneeIds: globalFilters.stakeholders
                  .map(({ procoreUserId }) => procoreUserId)
                  .filter((id): id is number => id !== undefined),
                createdAtFrom: globalFilters.dateCreation.from || undefined,
                createdAtTo: globalFilters.dateCreation.to || undefined,
                modifiedAtFrom:
                  globalFilters.dateModification.from || undefined,
                modifiedAtTo: globalFilters.dateModification.to || undefined,
                workflowStatuses: procoreFilters.punchItemStatuses,
                ballInCourtIds: procoreFilters.punchItemBallInCourt,
                dueDate: formattedDueDate ?? undefined,
                stages: globalFilters.stagesRange
              },
              isListLoading: true,
              isLoading: true,
              isManuallyTriggeredFilterMode: false,
              items: [],
              page: INIT_PAGING_STATE,
              sort: {},
              searchInput: ''
            });
          })
        );
      }),
      setSelected: (itemSelected: State['itemSelected']) => {
        patchState(store, { itemSelected });
      },
      clearSelected: () => {
        patchState(store, {
          itemSelected: EMPTY_PUNCH_ITEM
        });
      },
      setSelectedById: rxMethod<State['items'][number]['procoreId']>(
        pipe(
          filter((procoreId) => !!procoreId),
          tap(() => patchState(store, { isItemLoading: true })),
          exhaustMap((id: State['items'][number]['procoreId']) =>
            api
              .getPunchItem({ procoreId: id, projectId: store.projectId() })
              .pipe(
                catchError((err) => {
                  console.error(err);
                  return of(undefined);
                }),
                tap({
                  next: (data) => {
                    patchState(store, {
                      itemSelected: data
                    });
                  },
                  finalize: () => {
                    patchState(store, { isItemLoading: false });
                  }
                })
              )
          )
        )
      ),
      updateSelectedItemMarker: (marker: State['items'][number]['marker']) => {
        const selectedItem = store.itemSelected();
        if (selectedItem === undefined) {
          console.error('Selected item is undefined');
          return;
        }

        patchState(store, (actualData) => {
          const actualActiveMarkers = actualData.activateMarkers;

          const activeMarkerToUpdate = actualActiveMarkers.find(
            (activeMarker) => activeMarker.id === selectedItem.id
          );
          if (activeMarkerToUpdate !== undefined) {
            activeMarkerToUpdate.marker = marker;
          } else {
            if (marker !== undefined)
              actualActiveMarkers.push({
                ...selectedItem,
                marker
              } satisfies PunchItemWithMarker);
          }

          return {
            itemSelected: { ...selectedItem, marker },
            activateMarkers: [...actualActiveMarkers]
          };
        });
      },
      autoFetch: rxMethod<void>(
        pipe(
          isProjectConnectedWithProcore$(activatedRoute.snapshot, permissions),
          switchMap((isProjectConnectedWithProcore) =>
            toObservable(store.fetchQuery, { injector }).pipe(
              filter(
                ({ projectId, filter: { stages } }) =>
                  projectId !== '' && !!stages[0]
              ),
              filter(() => {
                if (!isProjectConnectedWithProcore) {
                  patchState(store, { isLoading: false });
                }

                return isProjectConnectedWithProcore;
              }),
              switchMap((dataQuery) => {
                const cantRender = cantRenderPunchItemsData(
                  globalFiltersStore,
                  procoreFiltersStore,
                  noteFiltersStore
                );

                if (cantRender) {
                  patchState(store, {
                    items: [],
                    isLoading: false,
                    isManuallyTriggeredFilterMode: true
                  });
                  return EMPTY;
                }

                return api.getPunchItemsList(dataQuery).pipe(
                  tapResponse({
                    next: (data) => {
                      const { items, ...metaData } = data;

                      patchState(store, (actualData) => ({
                        ...actualData,
                        items: [...actualData.items, ...items],
                        metaData,
                        isListLoading: false,
                        isLoading: false,
                        isManuallyTriggeredFilterMode: false
                      }));
                    },
                    error: console.error,
                    finalize: () => {
                      patchState(store, {
                        isListLoading: false,
                        isLoading: false,
                        isManuallyTriggeredFilterMode: false
                      });
                    }
                  })
                );
              })
            )
          )
        )
      ),
      fetchPunchItem: rxMethod<number>(
        pipe(
          tap(() => {
            patchState(store, { isItemLoading: true });
          }),
          switchMap((procoreId) =>
            api
              .getPunchItem({
                procoreId,
                projectId: baseInfoProcore()?.projectId ?? ''
              })
              .pipe(
                tapResponse({
                  next: (itemSelected) =>
                    patchState(store, {
                      itemSelected,
                      items: updateElementOnList(store.items(), itemSelected)
                    }),
                  error: console.error,
                  finalize: () => {
                    patchState(store, { isItemLoading: false });
                  }
                })
              )
          )
        )
      ),
      getItemSelected$: () =>
        toObservable(store.itemSelected).pipe(
          filter(
            (itemSelected): itemSelected is PunchItem =>
              itemSelected !== undefined
          )
        ),

      getPunchItem$: () =>
        toObservable(store.itemSelected).pipe(
          filter(
            (punchItem): punchItem is PunchItem =>
              punchItem !== undefined &&
              !!punchItem.id &&
              punchItem.id !== TEMP_PUNCH_ITEM_ID
          )
        ),

      patchAssignment: (updatedAssignment: Assignment) =>
        patchState(store, {
          itemSelected: {
            ...store.itemSelected(),
            assignments: store
              .itemSelected()
              .assignments.map((assignment) =>
                assignment.id === updatedAssignment.id
                  ? updatedAssignment
                  : assignment
              )
          }
        }),
      patchPunchItem: (itemSelected: PunchItem) =>
        patchState(store, {
          itemSelected,
          items: updateElementOnList(store.items(), itemSelected)
        }),
      openPunchItemRightPanel: (procoreId: number) => {
        const queryParams: Params = {
          punchItemId: procoreId,
          sidenavContent: 'punch-item'
        };
        routerFacade.setQueryParams(undefined, queryParams, 'merge');
        stagesRootService.rightPanelState = 'open';
      }
    })
  ),
  withHooks({
    onInit(store, activatedRoute = inject(ActivatedRoute)) {
      activatedRoute.params
        .pipe(
          map((params) => params['projectId'] as string),
          distinctUntilChanged(),
          takeUntilDestroyed()
        )
        .subscribe((id) => {
          if (!id) return;
          store.updateProjectId(id);
        });

      activatedRoute.queryParams
        .pipe(
          map((params) => params['stageId'] as string),
          distinctUntilChanged(),
          takeUntilDestroyed()
        )
        .subscribe((id) => {
          if (!id) return;

          store.updatePageStageId(id);
        });

      activatedRoute.queryParams
        .pipe(
          map((params) => ({
            sidenavContent: params['sidenavContent'],
            punchItemId: params['punchItemId']
          })),

          distinctUntilKeyChanged('punchItemId'),
          takeUntilDestroyed()
        )
        .subscribe(({ punchItemId }) =>
          !punchItemId || punchItemId === TEMP_PUNCH_ITEM_ID
            ? store.setSelected(EMPTY_PUNCH_ITEM)
            : store.setSelectedById(punchItemId)
        );

      store.autoFetch();
      store.setFilters();
      store.autoMarkersFetch();
    }
  })
);
