import { OrganizationLevel, SupportedLanguage } from '@simlab/data-access';
import { ProjectObjectCounts } from './project-object-counts';
import { SasToken } from './sas-token';
import { ICreationInfo } from './stages-base.interace';

export interface Project extends ICreationInfo {
  id: string;
  name: string;
  description: string;
  creatorId: string;
  lastEditorId: string;
  isOwner: boolean;
  markedAsFavourite: boolean; //NOTE: (david) misspelled
  projectThumbnailUrl: string;
  projectThumbnailUrlWithSas: SasToken;
  projectObjectsCounts: ProjectObjectCounts;
  organizationName: string;
  place?: ProjectAddress;
  isActive: boolean;
  procoreConnectionStatus: ProcoreConnectionStatusEnum;
  procoreInfo?: ProcoreProjectInfo;
}

export interface ProjectAddress {
  address: string;
  latitude: number;
  longitude: number;
}

export type GenerateProjectReportPayloadBase = {
  title: string | undefined;
  lang: SupportedLanguage;
  outputFormat: ProjectReportOutputFormat;
  separator: string | undefined;
};

export enum ProjectReportOutputFormat {
  PDF,
  CSV
}

export type GenerateProjectReportPayload = {
  projectId: string;
  lang: string;
  outputFormat: ProjectReportOutputFormat;
  filter: {
    stagesIds?: Array<string>;
    rootNoteAuthors?: Array<string>;
    rootNoteStakeholders?: Array<string>;
    rootNoteCreatedFrom?: string | undefined;
    rootNoteCreatedTo?: string | undefined;
    rootNoteModifiedFrom?: string | undefined;
    rootNoteModifiedTo?: string | undefined;
    rootNoteTypes?: Array<string>;
    rootNoteStatuses?: Array<string>;
    separator?: string;
  };
};

export type ProjectReporResponse = {
  documentId: string;
  documentName: string;
  documentUrl: string;
};
export interface ProjectPermissions {
  permissions: number;
  hasAdminRoleInProject: boolean;
  organizationPermissionsLevel: OrganizationLevel;
}

export type ProcoreConnectionStatusEnum =
  (typeof ProcoreConnectionStatusEnum)[keyof typeof ProcoreConnectionStatusEnum];
export const ProcoreConnectionStatusEnum = {
  NotConnected: 1,
  Connected: 2,
  Disconnected: 3,
  Disconnecting: 4, // local status (doesn't exists on BE)
  Reconnecting: 5 // local status (doesn't exists on BE)
} as const;

export interface ProcoreProjectInfo {
  companyId: number;
  projectId: number;
  companyName: string;
  projectName: string;
}

export interface ProcoreProjectInfo {
  companyId: number;
  projectId: number;
  companyName: string;
  projectName: string;
}
