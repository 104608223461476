<section class="button-container">
  <label class="section-header" i18n="@@MEDIA_HEADER">MEDIA</label>
  <button
    type="button"
    class="button-container__btn"
    [disabled]="disabled"
    design-stroked-button
    [designMenuTriggerFor]="menu"
    [clickAble$]="checkViewOnly$"
  >
    <i designIcon="add" designSize="20px"></i>
    <div i18n="@@ADD_ATTACHMENTS">Add Attachments</div>
  </button>

  <design-menu-panel #menu customPanelClass="note-media-menu-panel">
    <button
      design-menu-item
      type="button"
      class="note-media-menu-panel__btn"
      (click)="fileElement.click()"
    >
      <i designIcon="laptop" designSize="20px"></i>
      @if (isComputerDevice()) {
        <span i18n="@@FROM_MY_COMPUTER">From My Computer</span>
      } @else {
        <span i18n="@@FROM_MY_DEVICE">From My Device</span>
      }
    </button>

    <button
      design-menu-item
      type="button"
      class="note-media-menu-panel__btn"
      (click)="openDocumentsModal()"
    >
      <i designIcon="cloud" designSize="20px"></i>
      <span i18n="@@FROM_STAGES_DOCUMENTS"> From Project Documents </span>
    </button>

    <ui-divider />

    <button
      design-menu-item
      type="button"
      class="note-media-menu-panel__btn"
      (click)="takeScreenshotDialog()"
    >
      <i designIcon="prtscr" designSize="20px"></i>
      <span i18n="@@TAKE_A_SCREENSHOT"> Take a Screenshot </span>
    </button>

    <button
      design-menu-item
      type="button"
      class="note-media-menu-panel__btn"
      (click)="showAudioRecordDialog()"
    >
      <i designIcon="audio" designSize="20px"></i>
      <span i18n="@@RECORD_VOICE_MESSAGE"> Record Voice Message </span>
    </button>
  </design-menu-panel>
</section>

<design-tab-group spaceAroundTab class="note-media-tab-group">
  <design-tab [count]="(noteMediaCounts$ | async)!.photo">
    <ng-template designTabLabel>
      <i designIcon="image_file" designSize="20px"></i>
      <div i18n="@@PHOTO">Photo</div>
    </ng-template>
    <section>
      <div #photoWrapper>
        <feature-stages-note-media-photo
          (imageLoadedEmitter)="changingImagesUrl($event)"
        />
      </div>
      <ng-container *ngIf="(changeNote$ | async) === true">
        <ng-container
          *ngIf="!changingImages && (noteMediaCounts$ | async)!.photo > 0"
        >
          <ui-progress-spinner
            class="spinner"
            [strokeWidth]="8"
            [diameter]="70"
            uiColor="secondary"
          ></ui-progress-spinner>
        </ng-container>
      </ng-container>
    </section>
  </design-tab>
  <design-tab [count]="(noteMediaCounts$ | async)!.video">
    <ng-template designTabLabel>
      <i designIcon="video_file" designSize="20px"></i>
      <div i18n="@@VIDEO">Video</div>
    </ng-template>
    <feature-stages-note-media-video />
  </design-tab>
  <design-tab [count]="(noteMediaCounts$ | async)!.audio">
    <ng-template designTabLabel>
      <i designIcon="audio" designSize="20px"></i>
      <div i18n="@@AUDIO">Audio</div>
    </ng-template>
    <feature-stages-note-media-audio />
  </design-tab>
  <design-tab [count]="(noteMediaCounts$ | async)!.doc">
    <ng-template designTabLabel>
      <i designIcon="document" designSize="20px"></i>
      <div i18n="@@DOCS">Docs</div>
    </ng-template>
    <feature-stages-note-media-document />
  </design-tab>
</design-tab-group>

<ng-container>
  <input
    #fileElement
    (change)="uploadFile(fileElement)"
    type="file"
    accept="{{ acceptedFileFormats }}"
    style="display: none"
    multiple
  />
</ng-container>
