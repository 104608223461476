import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  Input,
  OnInit,
  Renderer2
} from '@angular/core';

@Component({
    selector: 'ui-progress-bar',
    imports: [CommonModule],
    templateUrl: './ui-progress-bar.component.html',
    styleUrls: ['./ui-progress-bar.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class UiProgressBarComponent implements OnInit {
  @Input() progressHeight = 8;
  @Input() color = '#3075EC';

  @Input() set value(value: number) {
    const element: HTMLElement = (
      this.elementRef.nativeElement as HTMLElement
    ).querySelector('#progress-bar-value') as HTMLElement;
    this.renderer.setStyle(element, 'transform', `scaleX(${value * 0.01})`);
    this.cdr.markForCheck();
  }

  constructor(
    private readonly cdr: ChangeDetectorRef,
    private readonly renderer: Renderer2,
    private readonly elementRef: ElementRef
  ) {}

  ngOnInit(): void {
    this.renderer.setStyle(
      document.documentElement,
      `--progress-bar-height`,
      `${this.progressHeight}px`,
      2
    );

    this.renderer.setStyle(
      document.documentElement,
      `--bar-color`,
      `${this.color}`,
      2
    );
    this.cdr.markForCheck();
  }
}
