<div class="send-item__form" [formGroup]="form">
  @if (radioOptions().length) {
    <design-radio-group formControlName="action">
      @for (option of radioOptions(); track option.actionType) {
        <design-radio-button [value]="option">
          <label designLabel>{{ option.label }}</label>
        </design-radio-button>

        @if (option.actionType === 'sendToAssigneesAsWorkRequired') {
          <div class="send-item__assignee-select-wrapper">
            <ng-container *ngTemplateOutlet="assigneeSelect"></ng-container>
          </div>
        }
      }
    </design-radio-group>
  }

  @if (isPersonSelectVisible() && !radioOptions().length) {
    <ng-container *ngTemplateOutlet="assigneeSelect"></ng-container>
  }

  <design-comment formControlName="commentWithFiles" />
</div>

<div class="send-item__submit-wrapper">
  <button
    design-flat-button
    designButtonColor="accent"
    [designFireOnClick]="submit$"
  >
    {{ actionButton().label }}

    <design-button-loader designPrefix designColor="accent" />
  </button>
</div>

<ng-template #assigneeSelect>
  <ui-form-field [formGroup]="form">
    <label i18n="@@SELECT_A_PERSON">SELECT A PERSON</label>
    <ui-select
      name="assignee"
      id="assignee"
      formControlName="assigneeIds"
      [multiple]="true"
    >
      @for (assignee of assigneeOptions(); track assignee.id) {
        <ui-option [value]="assignee.id">
          {{ assignee.name }}
        </ui-option>
      }
    </ui-select>
  </ui-form-field>
</ng-template>
