import { FormControl, FormGroup } from '@angular/forms';
import { TForm } from '@simlab/data-access';
import { DocumentTypeEnumValues } from '@simlab/design/attachment-icon';
import { DocumentSourceEnumValues } from '@simlab/documents/models';

export type StagesDocumentsFilters = {
  documentTypes: DocumentTypeEnumValues[] | null;
  creatorIds: string[] | null;
  documentSources: DocumentSourceEnumValues[] | null;
};

export type StagesDocumentsFiltersForm = TForm<StagesDocumentsFilters>;

export const StagesDocumentsFiltersFormBuilder = {
  buildForm: (updateOn: 'blur' | 'submit') =>
    new FormGroup<StagesDocumentsFiltersForm>(
      {
        documentTypes: new FormControl([]),
        creatorIds: new FormControl([]),
        documentSources: new FormControl([])
      },
      { updateOn }
    )
};
