import { AsyncPipe, NgIf } from '@angular/common';
import { Component, OnInit, forwardRef } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { StageComponent } from '@simlab/data-access';
import { ComponentsFacade, StagesFacade } from '@simlab/data-store';
import {
  MATTERPORT_TOKEN,
  MatterportBaseService,
  MatterportOauthService,
  MatterportSlitViewComponent,
  MatterportSplitViewControl,
  injectMatterport,
  matterportSplitViewControlToken
} from '@simlab/feature/matterport';
import { MatterportManagerService } from '@simlab/matterport';
import { Camera } from '@simlab/matterport/assets/mpSdk/sdk';
import { PositionCameraService } from '@simlab/util-shared';
import { Observable, filter, map, mergeMap, of, take, tap } from 'rxjs';

export interface SynchronizedComponentV3 extends StageComponent {
  stageDate: string;
  stageName: string;
}

@Component({
    templateUrl: './split-view.component.html',
    styleUrls: ['./split-view.component.scss'],
    imports: [MatterportSlitViewComponent, NgIf, AsyncPipe],
    providers: [
        PositionCameraService,
        MatterportBaseService,
        MatterportManagerService,
        MatterportOauthService,
        {
            provide: MATTERPORT_TOKEN,
            useFactory: injectMatterport,
            deps: [MatterportManagerService]
        },
        {
            provide: matterportSplitViewControlToken,
            useExisting: forwardRef(() => SplitViewComponent)
        }
    ]
})
export class SplitViewComponent implements MatterportSplitViewControl, OnInit {
  readonly projectId$: Observable<string | undefined> =
    this.activatedRoute.params.pipe(
      filter((params: Params) => params !== undefined),
      map((params: Params) => params['projectId']),
      take(1)
    );

  readonly selectedModel$: Observable<StageComponent | undefined> =
    this.componentsFacade.selectedComponent$.pipe(
      tap((components: StageComponent | undefined) => {
        if (components === undefined) {
          this.router.navigate(['../../stages'], {
            relativeTo: this.activatedRoute
          });
        }
      }),
      filter(
        (components: StageComponent | undefined) => components !== undefined
      ),

      mergeMap((components: StageComponent | undefined) => {
        if (!components) {
          return of(undefined);
        }
        if (components.isSynchronizingAccepted) {
          return of(components);
        }
        return of(undefined);
      })
    );

  readonly initialPosition =
    this.router.getCurrentNavigation()?.extras.state?.['position'];

  readonly initialFloor =
    this.router.getCurrentNavigation()?.extras.state?.['floor'];

  constructor(
    private readonly positionCameraService: PositionCameraService,
    private readonly router: Router,
    private readonly stagesFacade: StagesFacade,
    private readonly activatedRoute: ActivatedRoute,
    private readonly componentsFacade: ComponentsFacade
  ) {}

  ngOnInit(): void {
    this.positionCameraService.cameraPose = undefined;
  }

  exitCompareView(
    stageId?: string,
    position?: Camera.Pose,
    floor?: number
  ): void {
    if (stageId) {
      this.stagesFacade.setSelectedStageId(stageId);
    }
    this.positionCameraService.cameraPose = undefined;
    this.router.navigate(['../matterport'], {
      relativeTo: this.activatedRoute,
      queryParams: {
        stageId: stageId
      },
      state: {
        position,
        floor
      }
    });
  }
}
