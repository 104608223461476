import { ChangeDetectionStrategy, Component, input } from '@angular/core';
import {
  CanComponentDeactivate,
  ComponentListComponent,
  StageInfoComponent,
  StagesRootService
} from '@simlab/feature/stages';
import { UiDividerModule } from '@simlab/ui/divider';
import {
  combineLatest,
  map,
  Observable,
  Subject,
  take,
  takeUntil,
  tap
} from 'rxjs';

@Component({
    templateUrl: './info.component.html',
    styleUrls: ['./info.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    imports: [UiDividerModule, StageInfoComponent, ComponentListComponent]
})
export class InfoComponent implements CanComponentDeactivate {
  readonly projectId = input.required<string>();
  private _smallScreen!: boolean;
  private readonly _destroySource: Subject<void> = new Subject<void>();

  constructor(private readonly stagesRootService: StagesRootService) {
    this.stagesRootService.mobileScreenSize$
      .pipe(takeUntil(this._destroySource))
      .subscribe((isMobile: boolean) => {
        this._smallScreen = isMobile;
      });
  }

  canDeactivate(): Observable<boolean> {
    return combineLatest([
      this.stagesRootService.leftPanelState$,
      this.stagesRootService.rightPanelState$
    ]).pipe(
      map(([left, right]) => {
        return true;
      }),
      take(1),
      tap(() => {
        this.stagesRootService.leftPanelState = 'close';
        this.stagesRootService.rightPanelState = 'close';
      })
    );
  }
}
