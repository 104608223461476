import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  NgZone,
  OnDestroy,
  QueryList,
  ViewChildren, inject } from '@angular/core';
import { DesignOption } from '@simlab/design/common';
import {
  defer,
  distinctUntilChanged,
  firstValueFrom,
  map,
  Observable,
  shareReplay,
  Subject,
  take,
  takeUntil,
  tap,
} from 'rxjs';
import {
  MentionData,
  MENTION_DATA,
  MENTION_DATA_FILTER,
} from './mention.directive';

@Component({
    template: `
    <div class="options-container">
      <ng-container *ngIf="filteredData$ | async as filteredData">
        <ng-container *ngIf="filteredData.length > 0; else noData">
          <ng-container
            *ngFor="
              let item of filteredData;
              trackBy: trackByValue;
              let idx = index
            "
          >
            <span
              #list
              designOption
              [value]="item.value"
              [id]="item.value.toString()"
              [attr.idx]="idx"
              (selectionChange)="close$.emit(item.value)"
              >{{ item.displayName }}</span
            >
          </ng-container>
        </ng-container>
        <ng-template #noData>
          <span
            #list
            designOption
            class="disable-events"
            [attr.idx]="-1"
            (selectionChange)="close$.emit(-1)"
            >No identities found</span
          >
        </ng-template>
      </ng-container>
    </div>
  `,
    styleUrls: ['./mention-overlay.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class MentionOverlayComponent implements OnDestroy {
      private readonly data = inject<MentionData[]>(MENTION_DATA);
      private readonly filterValue = inject<Observable<string>>(MENTION_DATA_FILTER);
      private readonly ngZone = inject(NgZone);
      private readonly cdr = inject(ChangeDetectorRef);
  @ViewChildren('list', { read: DesignOption }) list!: QueryList<DesignOption>;
  readonly close$: EventEmitter<string | number | boolean> = new EventEmitter<
    string | number | boolean
  >();
  readonly filteredData$: Observable<MentionData[]> = defer(() =>
    this.filterValue.pipe(
      distinctUntilChanged(),
      map((filter: string | undefined) => {
        if (!filter) return this.data;
        const deleteAtFromFilter = filter.length
          ? filter.substring(1, filter.length)
          : filter;
        return this.data.filter((mentionRow: MentionData, idx: number) =>
          mentionRow.displayName
            ?.toLowerCase()
            .includes(deleteAtFromFilter.toLowerCase())
        );
      }),
      tap((data: MentionData[]) => {
        //need wait for list change
        this._destroySource.next();
        this.ngZone.onStable
          .pipe(
            tap(() => this._selectOption(data[0]?.value || -1)),
            takeUntil(this._destroySource),
            take(1)
          )
          .subscribe();
      })
    )
  ).pipe(shareReplay(1));
  private readonly _destroySource: Subject<void> = new Subject<void>();

  ngOnDestroy(): void {
    this._destroySource.next();
    this._destroySource.complete();
  }
  trackByValue(index: number, item: MentionData) {
    return item.value;
  }

  emitSelected() {
    this.close$.emit(this.selected);
  }

  private get selected(): string {
    return this.list.toArray().find((option) => option.active)?.value;
  }
  async selectNext() {
    const previousSelection = this.selected;
    const filteredData = await firstValueFrom(this.filteredData$);
    const previousSelectionIdx = filteredData.findIndex(
      (value: MentionData) => value.value === previousSelection
    );
    if (previousSelectionIdx < filteredData.length - 1) {
      this._selectOption(filteredData[previousSelectionIdx + 1].value);
    }
    this.cdr.markForCheck();
  }
  async selectPrevious() {
    const previousSelection = this.selected;
    const filteredData = await firstValueFrom(this.filteredData$);
    const previousSelectionIdx = filteredData.findIndex(
      (value: MentionData) => value.value === previousSelection
    );
    if (previousSelectionIdx > 0) {
      this._selectOption(filteredData[previousSelectionIdx - 1].value);
    }
    this.cdr.markForCheck();
  }

  private _selectOption(value: string | number | boolean) {
    this.list.toArray().forEach((option: DesignOption) => {
      if (option.value === value) {
        option.setActiveStyles();
      } else {
        option.setInactiveStyles();
      }
    });
  }
}
