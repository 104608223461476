/* eslint-disable @angular-eslint/component-class-suffix */
/* eslint-disable @angular-eslint/no-inputs-metadata-property */
/* eslint-disable @angular-eslint/no-host-metadata-property */
import { FocusMonitor } from '@angular/cdk/a11y';
import { UniqueSelectionDispatcher } from '@angular/cdk/collections';
import {
  ANIMATION_MODULE_TYPE,
  Attribute,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  Inject,
  Optional,
  ViewEncapsulation,
} from '@angular/core';
import { _DesignRadioButtonBase } from './design-radio-button-base.directive';
import {
  DESIGN_RADIO_DEFAULT_OPTIONS,
  DesignRadioDefaultOptions,
} from './token';

@Component({
  selector: 'design-radio-button',
  templateUrl: 'radio.html',
  styleUrls: ['radio.scss'],
  standalone: true,
  host: {
    class: 'design-radio-button',
    '[attr.id]': 'id',
    '[class.design-primary]': 'color === "primary"',
    '[class.design-radio-button--disabled]': 'disabled',
    '[class.design-accent]': 'color === "accent"',
    '[class.design-warn]': 'color === "warn"',
    '[class.design-radio-button--checked]': 'checked',
    '[class._design-animation-noopable]': '_noopAnimations',
    // Needs to be removed since it causes some a11y issues (see #21266).
    '[attr.tabindex]': 'null',
    '[attr.aria-label]': 'null',
    '[attr.aria-labelledby]': 'null',
    '[attr.aria-describedby]': 'null',
    // Note: under normal conditions focus shouldn't land on this element, however it may be
    // programmatically set, for example inside of a focus trap, in this case we want to forward
    // the focus to the native element.
    '(focus)': '_inputElement.nativeElement.focus()',
    '(click)': '_onInputInteraction($event)',
    '[required]': 'required',
    '[disabled]': 'disabled',
  },
  inputs: ['disableRipple', 'tabIndex'],
  exportAs: 'designRadioButton',
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DesignRadioButton extends _DesignRadioButtonBase {
  constructor(
    elementRef: ElementRef,
    _changeDetector: ChangeDetectorRef,
    _focusMonitor: FocusMonitor,
    _radioDispatcher: UniqueSelectionDispatcher,
    @Optional() @Inject(ANIMATION_MODULE_TYPE) animationMode?: string,
    @Optional()
    @Inject(DESIGN_RADIO_DEFAULT_OPTIONS)
    _providerOverride?: DesignRadioDefaultOptions,
    @Attribute('tabindex') tabIndex?: string
  ) {
    super(
      elementRef,
      _changeDetector,
      _focusMonitor,
      _radioDispatcher,
      animationMode,
      _providerOverride,
      tabIndex
    );
  }
}
