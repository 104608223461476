<div class="title">{{ type() }}</div>

<div class="request-container">
  <div class="request-container__top">
    <label>{{ titleLabel() | uppercase }}</label>
    <div>{{ title() }}</div>
  </div>
  <div class="request-container__down">
    <label>{{ descriptionLabel() | uppercase }}</label>
    <div [innerHTML]="description()"></div>
  </div>

  @if (anyAttachments()) {
  <div class="punch-item-activity__content__attachments">
    @for (attachment of attachments(); track attachment.id) {
    <ui-chip>
      <div
        class="punch-item-activity__content__attachments__chip-content"
      >
        <i
          class="color"
          [designIcon]="
                attachment.filename | getAttachmentIcon
              "
          designSize="1rem"
        ></i>
        <span class="filename">{{ attachment.filename }}</span>
      </div>
    </ui-chip>
    }
  </div>
  }
</div>
