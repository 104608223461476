export const DocumentSourceEnum = {
  Unknown: 0,
  Local: 1,
  Procore: 2
} as const;

export const ExternalTransferStatusEnum = {
  None: 0,
  Processing: 1,
  Success: 2,
  Failure: 3
} as const;

export const DirectorySearchLevelEnum = {
  All: 0,
  Directory: 1,
  DirectoryAndSubdirectories: 2
} as const;

export const DocumentReferenceTypeEnum = {
  RootNote: 1,
  Component: 2
} as const;

export const DocumentsSortColumn = {
  CreatedAt: 1,
  Name: 2,
  Size: 3,
  Attached: 4
} as const;

export const DocumentReferenceTypePath: Record<
  DocumentReferenceTypeEnumValues,
  string
> = {
  [DocumentReferenceTypeEnum.Component]: 'info',
  [DocumentReferenceTypeEnum.RootNote]: 'notes'
};

export const DocumentReferenceTypeName: Record<
  DocumentReferenceTypeEnumValues,
  string
> = {
  [DocumentReferenceTypeEnum.Component]: 'Component',
  [DocumentReferenceTypeEnum.RootNote]: 'Note'
};

export type DocumentReferenceTypeEnumValues =
  (typeof DocumentReferenceTypeEnum)[keyof typeof DocumentReferenceTypeEnum];

export type DocumentSourceEnumValues =
  (typeof DocumentSourceEnum)[keyof typeof DocumentSourceEnum];

export type ExternalTransferStatusEnumValues =
  (typeof ExternalTransferStatusEnum)[keyof typeof ExternalTransferStatusEnum];

export type DirectorySearchLevelEnumValues =
  (typeof DirectorySearchLevelEnum)[keyof typeof DirectorySearchLevelEnum];

export type DocumentsSortColumnValues =
  (typeof DocumentsSortColumn)[keyof typeof DocumentsSortColumn];
