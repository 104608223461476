import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  input,
  output
} from '@angular/core';
import { DesignRadioAccordionModule } from '@simlab/design/accordion';
import { DesignIconButton } from '@simlab/design/button';
import { DesignIcon } from '@simlab/design/icon';
import { UiTooltip } from '@simlab/design/tooltip';
import { UiHelperModule } from '@simlab/ui/helper';
import { UiMenuModule } from '@simlab/ui/menu';
import { UiMenuPanelModule } from '@simlab/design/menu-panel';
import {
  IAreaMeasurementComponent,
  _IAreaMeasurementsGroupAction
} from '../../../models/src/area-measurement.interface';
import {
  TMeasurement,
  TMeasurementExtended,
  TMeasurementGroup
} from '../../../models/src/common-models';
import { TRemoveMeasurementFromGroup } from '../../../models/src/distance.type';
import { MeasurementPermission } from '../../../services/measurement.privileges';
import { MeasurementItemComponent } from '../measurement-item/measurement-item.component';

export type TMeasurementGroupExtended = {
  summaryDisplayValue: number;
  measurements: TMeasurementExtended[];
} & Omit<TMeasurementGroup, 'measurements'>;
@Component({
    selector: 'feature-stages-measurement-group-item',
    imports: [
        CommonModule,
        DesignIconButton,
        UiMenuModule,
        UiMenuPanelModule,
        DesignRadioAccordionModule,
        MeasurementItemComponent,
        DesignIcon,
        UiTooltip,
        UiHelperModule
    ],
    templateUrl: './measurement-group-item.component.html',
    styleUrls: ['./measurement-group-item.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class MeasurementGroupItemComponent
  extends MeasurementPermission
  implements
    Pick<
      IAreaMeasurementComponent<_IAreaMeasurementsGroupAction>,
      | 'deleteAreaMeasurementGroup'
      | 'changeName'
      | 'addMeasurementsToGroup'
      | 'removeMeasurementFromGroup'
      | 'goToMeasurementList'
    >
{
  readonly unit = input.required<string>();
  readonly group = input.required<TMeasurementGroupExtended>();

  readonly changeName = output<void>();
  readonly goToMeasurementList = output<string>();
  readonly addMeasurementsToGroup = output<void>();
  readonly deleteAreaMeasurementGroup = output<void>();
  readonly removeMeasurementFromGroup = output<TRemoveMeasurementFromGroup>();

  measurementsId = (_: number, item: TMeasurement) => item.id;
}
