/* eslint-disable @nx/enforce-module-boundaries */
import { ElementRef } from '@angular/core';
import { mixinDisableRipple, mixinTabIndex } from '@simlab/design/internal';

abstract class DesignRadioButtonBase {
  abstract disabled: boolean;
  constructor(public _elementRef: ElementRef) {}
}

export const _DesignRadioButtonMixinBase = mixinDisableRipple(
  mixinTabIndex(DesignRadioButtonBase)
);
