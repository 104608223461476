import { ChangeDetectionStrategy, Component, computed } from '@angular/core';
import { FormatDateSignalPipe } from '@simlab/design/format-date';
import {
  InfoItemContainerComponent,
  PunchItemPanelBaseDirective
} from '@simlab/procore/annotation-panel/ui';

@Component({
  selector: 'procore-punch-item-side-panel-workflow',
  standalone: true,
  imports: [InfoItemContainerComponent, FormatDateSignalPipe],
  templateUrl: './punch-item-side-panel-workflow.component.html',
  styleUrl: './punch-item-side-panel-workflow.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PunchItemSidePanelWorkflowComponent extends PunchItemPanelBaseDirective {
  protected readonly createdAt = computed(() => {
    return this.punchItem().createdAt;
  });

  protected readonly dueDate = computed(() => {
    const dueDate = this.punchItem().dueDate;
    return dueDate || this.noValueStr;
  });

  protected readonly creator = computed(() => {
    const creator = this.punchItem().createdBy;
    return creator.name;
  });

  protected readonly punchItemManager = computed(() => {
    const punchItemManager = this.punchItem().punchItemManager;
    return punchItemManager.name;
  });

  protected readonly finalApprover = computed(() => {
    const finalApprover = this.punchItem().finalApprover;
    return finalApprover?.name || this.noValueStr;
  });

  protected readonly ballInCourt = computed(() => {
    const ballInCourt = this.punchItem().ballInCourt;
    return ballInCourt?.length
      ? ballInCourt.map((ball) => ball.name).join(', ')
      : this.noValueStr;
  });

  protected readonly distributionMembers = computed(() => {
    const distributionMembers = this.punchItem().distributionMembers;
    return distributionMembers?.length
      ? distributionMembers.map((member) => member.name).join(', ')
      : this.noValueStr;
  });
}
