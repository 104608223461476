/* eslint-disable @typescript-eslint/member-ordering */
import {
  Directive,
  ElementRef,
  Input,
  OnDestroy,
  OnInit,
  ViewContainerRef,
  inject,
} from '@angular/core';
import { NgControl } from '@angular/forms';
import { DatePickerComponent, IDatePickerDirectiveConfig } from 'ng-datepicker';
import { Observable, Subject, takeUntil, tap } from 'rxjs';
import {
  DATA_PICKER_FORMAT_CONFIG,
  DatepickerConfig,
  FormatDateDataPicker,
} from '../models/datepicker-config.interface';

declare const ngDevMode: boolean;

const _DatepickerBase = class {};

@Directive()
export abstract class DatepickerBase
  extends _DatepickerBase
  implements OnInit, OnDestroy
{
  private readonly _viewContainerRef = inject(ViewContainerRef);
  private readonly _elementRef = inject(ElementRef);
  readonly formControl = inject(NgControl, { optional: true });
  private readonly _destroySource: Subject<void> = new Subject<void>();
  private readonly _dateFormat$: Observable<FormatDateDataPicker> = inject(
    DATA_PICKER_FORMAT_CONFIG,
  );

  protected readonly _datePicker: DatePickerComponent =
    this._createDatePicker();

  @Input()
  get config(): IDatePickerDirectiveConfig {
    return this._config;
  }
  set config(value: DatepickerConfig) {
    //NOTE: date format https://day.js.org/docs/en/display/format
    this._config = <IDatePickerDirectiveConfig>{
      ...value,
      hideInputContainer: true,
      inputElementContainer: this._elementRef,
      monthFormat: 'MMMM YYYY',
      enableMonthSelector: false,
      weekDayFormat: 'dd',
      showGoToCurrent: false,
      closeOnSelect: false,
      closeOnSelectDelay: 50,
      format: value.format?.toUpperCase() || 'DD/MM/YYYY',
    };

    this._updateDatepickerConfig();
    this._datePicker.cd.markForCheck();
  }

  private _config!: IDatePickerDirectiveConfig;

  constructor() {
    super();
  }

  ngOnDestroy(): void {
    this._destroySource.next();
    this._destroySource.complete();
  }

  ngOnInit(): void {
    this.config = {};

    this._dateFormatObserver();
    this._attachFormToDatePicker();
  }

  private _dateFormatObserver() {
    this._dateFormat$
      .pipe(
        tap((data: FormatDateDataPicker) => {
          this.config = { ...this._config, format: data } as DatepickerConfig;
          this._updateDatepickerConfig();
          this._datePicker.cd.markForCheck();
          this._datePicker.selected = this._datePicker._selected;
        }),
        takeUntil(this._destroySource),
      )
      .subscribe();
  }

  open(): void {
    this._datePicker.onClick();
  }

  private _createDatePicker(): DatePickerComponent {
    return this._viewContainerRef.createComponent(DatePickerComponent).instance;
  }

  private _updateDatepickerConfig() {
    if (!this._datePicker) {
      if (typeof ngDevMode === 'undefined' || ngDevMode) {
        throw new Error('Datepicker does not exist!');
      }

      return;
    }

    this._datePicker.theme = 'design-datepicker-wrapper';
    this._datePicker.config = this.config;

    this._datePicker.init();
  }

  private _attachFormToDatePicker() {
    if (!this.formControl) {
      if (typeof ngDevMode === 'undefined' || ngDevMode) {
        throw new Error('Datepicker requires FormControl!');
      }

      return;
    }

    this._datePicker.onViewDateChange(this.formControl.value);

    let init = true;
    this._datePicker.registerOnChange((value: any, changedByInput: any) => {
      if (changedByInput || value === undefined) {
        return;
      }

      this.formControl?.control?.setValue(this._datePicker.inputElementValue);

      if (!init) {
        this.formControl?.control?.markAsDirty({
          onlySelf: true,
        });
      } else {
        init = false;
      }
    });
  }

  protected _onInput(event: InputEvent): void {
    const inputElement = <HTMLInputElement>event.target;
    if (this._datePicker.inputElementValue !== inputElement.value) {
      this._datePicker.onViewDateChange(inputElement.value);
    }
  }
}
