import { ChangeDetectionStrategy, Component, input } from '@angular/core';
import {
  PunchItemInformationAdditionalComponent,
  PunchItemInformationBasicComponent,
  PunchItemPanelBaseDirective
} from '@simlab/procore/annotation-panel/ui';

@Component({
  selector: 'procore-punch-item-side-panel-information',
  standalone: true,
  imports: [
    PunchItemInformationBasicComponent,
    PunchItemInformationAdditionalComponent
  ],
  templateUrl: './punch-item-side-panel-information.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  styles: [
    `
      :host {
        display: flex;
        flex-direction: column;
        gap: 24px;
      }
    `
  ]
})
export class PunchItemSidePanelInformationComponent extends PunchItemPanelBaseDirective {
  readonly currencySymbol = input<string>();
}
