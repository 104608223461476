import { OverlayModule } from '@angular/cdk/overlay';
import { CommonModule, DatePipe, formatDate } from '@angular/common';
import { Component, Inject, inject } from '@angular/core';
import {
  FormControl,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
  Validators
} from '@angular/forms';
import { FormatDate, TForm } from '@simlab/data-access';
import { StagesFacade, UserPreferenceFacade } from '@simlab/data-store';
import { DesignAutocompleteModule } from '@simlab/design/autocomplete';
import {
  DesignFlatButton,
  DesignIconButton,
  DesignStrokedButton
} from '@simlab/design/button';
import { DesignCommonModule, DesignLabel } from '@simlab/design/common';
import {
  DATA_PICKER_FORMAT_CONFIG,
  DesignDateValidators,
  DesignDatepickerInput
} from '@simlab/design/datepicker';
import { DesignDialogWrapperModule } from '@simlab/design/dialog';
import {
  DesignFormFieldModule,
  FormFieldError,
  designErrorToken
} from '@simlab/design/form-field';
import { DesignIcon } from '@simlab/design/icon';
import { DesignInput } from '@simlab/design/input';
import { UiButtonModule } from '@simlab/ui/button';
import { UiFormFieldModule } from '@simlab/ui/form-field';
import { UiHintModule } from '@simlab/ui/hint';
import { UiIconModule } from '@simlab/ui/icon';
import { UiInputModule } from '@simlab/ui/input';
import { ConfirmationModalRef, MODAL_DATA } from '@simlab/ui/modal';
import { UiSelectModule } from '@simlab/ui/select';
import { Observable, firstValueFrom, map, tap } from 'rxjs';
import { StageForm } from '../../models/stage-form';
import { TStageFormDetail, TStageFormResponse } from './stage-form-details';

type StageFormDialogForm = TForm<StageForm>;

@Component({
    selector: 'feature-stages-stage-form-dialog',
    templateUrl: './stage-form-dialog.component.html',
    styleUrls: ['./stage-form-dialog.component.scss'],
    imports: [
        DesignIcon,
        DesignLabel,
        DesignInput,
        DesignIconButton,
        DesignCommonModule,
        DesignFormFieldModule,
        DesignDatepickerInput,
        DesignAutocompleteModule,
        DesignDialogWrapperModule,
        DesignFlatButton,
        DesignStrokedButton,
        FormsModule,
        CommonModule,
        OverlayModule,
        ReactiveFormsModule,
        UiHintModule,
        UiIconModule,
        UiInputModule,
        UiButtonModule,
        UiSelectModule,
        UiFormFieldModule
    ],
    providers: [
        DatePipe,
        {
            provide: designErrorToken,
            useValue: <FormFieldError>{
                datePatternMismatch: () => `Invalid date format.`
            }
        },
        {
            provide: DATA_PICKER_FORMAT_CONFIG,
            deps: [UserPreferenceFacade],
            useFactory: (userPreferenceFacade: UserPreferenceFacade) => {
                return userPreferenceFacade.getDateFormat$.pipe(map((data: string) => {
                    if (data === 'MM/dd/yyyy') {
                        return 'MM/DD/YYYY';
                    }
                    return 'DD/MM/YYYY';
                }));
            }
        }
    ]
})
export class StageFormDialogComponent {
  private _currentUserFormat: FormatDate = 'MM/dd/yyyy';
  readonly stageForm: FormGroup<StageFormDialogForm> =
    new FormGroup<StageFormDialogForm>({
      name: new FormControl<string>('', {
        validators: [
          Validators.required,
          Validators.minLength(3),
          Validators.maxLength(65)
        ],
        nonNullable: true
      }),
      stageDate: new FormControl(
        formatDate(
          new Date().toISOString().slice(0, 10),
          'dd/MM/yyyy',
          'en-US'
        ),
        {
          validators: [
            Validators.required,
            DesignDateValidators.correctDate('dd/MM/yyyy', {
              datePatternMismatch: true
            })
          ],
          nonNullable: true
        }
      )
    });
  readonly userPreferenceFacade = inject(UserPreferenceFacade);
  readonly loaded$: Observable<boolean> = this.stageFacade.stagesLoaded$;
  constructor(
    private readonly stageFacade: StagesFacade,
    private readonly modalRef: ConfirmationModalRef<TStageFormResponse>,
    @Inject(MODAL_DATA)
    readonly data: TStageFormDetail
  ) {
    firstValueFrom(
      this.userPreferenceFacade.getDateFormat$.pipe(
        tap((userFormatDate: FormatDate) => {
          this._currentUserFormat = userFormatDate;
          this.stageForm.controls.stageDate.setValidators([
            Validators.required,
            DesignDateValidators.correctDate(userFormatDate, {
              datePatternMismatch: true
            })
          ]);

          this.stageForm.controls.stageDate.patchValue(
            formatDate(
              new Date().toISOString().slice(0, 10),
              userFormatDate,
              'en-US'
            )
          );

          if (data.form?.stageDate) {
            this.stageForm.controls.stageDate.patchValue(
              formatDate(data.form.stageDate, userFormatDate, 'en-US')
            );
          }
        })
      )
    );

    if (data.form?.name) {
      this.stageForm.controls.name.patchValue(data.form.name);
    }
  }

  get title(): string {
    return this.data.title ?? 'Add/Edit stage';
  }

  submitForm(): void {
    let data = '';
    if (this._currentUserFormat === 'dd/MM/yyyy') {
      const date = (this.stageForm.value.stageDate as string).split('/');
      data = new Date(
        date[1] + '/' + date[0] + '/' + date[2] + ' UTC'
      ).toISOString();
    } else {
      data = new Date(
        (this.stageForm.value.stageDate as string) + ' UTC'
      ).toISOString();
    }

    if (this.stageForm.valid) {
      this.modalRef.emit({
        state: true,
        result: {
          name: this.stageForm.value.name || '',
          stageDate: data || ''
        }
      });
      this.modalRef.close();
    }
  }
  cancelForm(): void {
    this.modalRef.emit({ state: false });
    this.modalRef.close();
  }
}
