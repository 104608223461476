<procore-punch-item-information-basic
  [punchItem]="punchItem()"
  [displayElements]="displayElements()"
  [isPunchItemLoading]="isPunchItemLoading()"
/>
<procore-punch-item-information-additional
  [currencySymbol]="currencySymbol()"
  [punchItem]="punchItem()"
  [displayElements]="displayElements()"
  [isPunchItemLoading]="isPunchItemLoading()"
/>
