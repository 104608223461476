import { DIALOG_DATA, DialogRef } from '@angular/cdk/dialog';
import {
  ChangeDetectionStrategy,
  Component,
  computed,
  inject,
  OnInit,
  signal,
  viewChild,
  ViewEncapsulation
} from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { ActivatedRoute, Router } from '@angular/router';
import { DesignFlatButton, DesignStrokedButton } from '@simlab/design/button';
import { DesignDialogWrapperModule } from '@simlab/design/dialog';
import {
  DocumentsInstanceData,
  DocumentsInstanceDataToken
} from '@simlab/documents/data-access';
import { FileModel } from '@simlab/documents/models';
import { UiDividerModule } from '@simlab/ui/divider';

import { ProcoreDocumentsWrapperInModalComponent } from './procore-documents-in-modal/procore-documents-wrapper-in-modal.component';

import { UiTabsModule } from '@simlab/design/tabs';
import { canRenderProcoreEntity } from '@simlab/procore/data-access';
import { StagesDocumentsInModalComponent } from './stages-documents-in-modal/stages-documents-in-modal.component';

export type DocumentsModalComponentData = {
  projectId: string;
  acceptedFormat: string[];
};

export type SelectedDocumentsForNotes = {
  documents: FileModel[];
  from:
    | 'stagesDocuments'
    | 'procoreDocuments'
    | 'procoreDrawings'
    | 'procorePictures'
    | undefined;
};

const selectedInstanceDictionary = {
  0: 'stagesDocuments',
  1: 'procoreDocuments'
} as const;

type SelectedInstance =
  (typeof selectedInstanceDictionary)[keyof typeof selectedInstanceDictionary];

@Component({
  imports: [
    DesignDialogWrapperModule,
    DesignStrokedButton,
    DesignFlatButton,
    UiTabsModule,
    UiDividerModule,
    StagesDocumentsInModalComponent,
    ProcoreDocumentsWrapperInModalComponent
  ],
  templateUrl: './documents-modal.component.html',
  styleUrl: './documents-modal.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  host: {
    class: 'feature-projects-documents-modal'
  },
  providers: [
    {
      provide: DocumentsInstanceDataToken,
      deps: [DIALOG_DATA],
      useFactory: (dialogData: DocumentsModalComponentData) => {
        return <DocumentsInstanceData>{
          projectId: signal(dialogData.projectId),
          hasInfoSidenav: false
        };
      }
    }
  ]
})
export class DocumentsModalComponent implements OnInit {
  private readonly _dialogRef = inject(DialogRef);
  private readonly _documents = viewChild(StagesDocumentsInModalComponent);
  private readonly _procoreDocuments = viewChild(
    ProcoreDocumentsWrapperInModalComponent
  );
  private readonly _router = inject(Router);
  private readonly _activatedRoute = inject(ActivatedRoute);

  protected readonly selectedInstance =
    signal<SelectedInstance>('stagesDocuments');
  protected readonly data = inject<DocumentsModalComponentData>(DIALOG_DATA);
  protected readonly selectedDocuments = computed(() => {
    const documentsSelectedItems = this._documents()?.selectedItems() ?? [];
    const procoreDocumentsSelectedItems =
      this._procoreDocuments()?.selectedDocuments() ?? {
        documents: [],
        from: undefined
      };

    return (<SelectedDocumentsForNotes>{
      documents: [
        ...documentsSelectedItems,
        ...procoreDocumentsSelectedItems.documents
      ]
        .map((item) => item.data().data)
        .filter((data) => !data.isDirectory)
        .filter((data) => {
          const name = data.name as string;
          // NOTE (Łukasz) Drawings can be without extension
          if (
            this._isSelectedItemsFromProcoreDrawings(
              procoreDocumentsSelectedItems
            )
          )
            return true;

          return this.data.acceptedFormat.includes(
            name.slice(name.lastIndexOf('.')).toLocaleLowerCase()
          );
        }),
      from: documentsSelectedItems.length
        ? 'stagesDocuments'
        : procoreDocumentsSelectedItems.documents.length
          ? procoreDocumentsSelectedItems.from
          : undefined
    }) satisfies SelectedDocumentsForNotes;
  });

  readonly projectConnectedWithProcore = toSignal(
    canRenderProcoreEntity(inject(ActivatedRoute).snapshot)
  );

  ngOnInit(): void {
    this.resetFolderIdFromUrl();
  }

  protected documentsInstanceChange(selectedIndex: number | null): void {
    const selectedDictionaryIndex =
      selectedIndex as keyof typeof selectedInstanceDictionary;
    this.selectedInstance.set(
      selectedInstanceDictionary[selectedDictionaryIndex ?? 0]
    );

    this.resetFolderIdFromUrl();
    this._documents()?.resetSelectedItems();
    this._procoreDocuments()?.resetSelectedItems();
  }

  protected resetFolderIdFromUrl() {
    this._router.navigate([], {
      queryParams: { folderId: null },
      queryParamsHandling: 'merge',
      relativeTo: this._activatedRoute
    });
  }

  protected cancel(): void {
    this._dialogRef.close();
  }

  protected add(): void {
    this._dialogRef.close(this.selectedDocuments());
  }

  private _isSelectedItemsFromProcoreDrawings({
    from,
    documents
  }: {
    documents: unknown[];
    from: SelectedDocumentsForNotes['from'];
  }) {
    return from === 'procoreDrawings' && documents.length > 0;
  }
}
