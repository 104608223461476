@if (loading()) {
  <design-progress-spinner designColor="accent" class="progress-spinner" />
}

<div class="annotation" [ngClass]="{ 'annotation--with-menu': isMenu() }">
  @if (isMenu()) {
    <lib-annotation-panel-menu
      [panelMenuItems]="panelMenuItems()"
      [selectedItemKey]="selectedKeyOrDefault()"
      (onItemSelect)="onItemSelect($event)"
    />
  }
  <div class="annotation__wrapper">
    <div class="annotation-header">
      <lib-annotation-panel-header
        class="annotation-header__instance"
        [name]="name()"
        [subName]="subName()"
        [iconType]="iconType()"
      >
        @if (nameTemplate() !== undefined) {
          <ng-template #nameContent>
            <ng-container [ngTemplateOutlet]="nameTemplate() || null" />
          </ng-template>
        }
      </lib-annotation-panel-header>

      <div class="annotation-header__right-side">
        <ng-content select="[headerContent]" />
        <lib-annotation-panel-header-btn
          [isMenuTriggerVisible]="isMenuTriggerVisible()"
        />
      </div>
    </div>
    <div class="annotation-content">
      <ng-content />
    </div>
  </div>
</div>
