<ui-form-field class="procore-location-select">
  <label designLabel i18n="@@MORE_DETAILS_LOCATIONS">Locations</label>

  <ui-select
    class="procore-location-select__select"
    i18n-placeholder="@@SELECT_A_LOCATION"
    placeholder="Select a Location"
    [multiple]="false"
    [formControl]="control()"
    [compareWith]="compareLocations"
    [fixedOverlayWidth]="true"
    (closed)="onPanelClose()"
    (opened)="onPanelOpen()"
  >
    <design-form-field class="procore-location-select__search">
      <i
        class="suffix"
        designSuffix
        designIcon="search"
        designSize="1.3rem"
      ></i>
      <input
        designInput
        i18n-placeholder="@@SEARCH_EXISTING_LOCATION"
        placeholder="Search existing Location"
        [formControl]="searchControl"
      />
    </design-form-field>

    @if (ancestorNodes().length && !searchValue()) {
      <design-breadcrumb
        [path]="breadcrumbs()"
        [numberOfRenderedElementsOnScreen]="10"
        (customNavigate)="selectNode($event.location)"
      />
    }

    @if (!optionsLoading() && !beforeInitialFetch()) {
      @if (searchValue()) {
        @for (location of searchOptions(); track location.id) {
          <ui-option
            [value]="location"
            [transformTrigger]="transformTrigger"
            (click)="selectNode(location)"
          >
            {{ location.name | locationName }}
          </ui-option>
        }

        @if (!searchOptions().length) {
          <p
            class="procore-location-select__no-locations-message"
            i18n="@@NO_LOCATIONS_FOUND"
          >
            No locations found.
          </p>
        }
      } @else {
        @for (location of childNodeOptions(); track location.id) {
          <ui-option
            class="opt"
            [value]="location"
            [transformTrigger]="transformTrigger"
          >
            <div class="procore-location-select__childnode-wrapper">
              {{ location.nodeName }}
              <button
                designIconButton
                class="procore-location-select__select-node"
                (click)="selectAndShowNested($event, location)"
              >
                <i designIcon="arrow_right" desingSize="1.3rem"></i>
              </button>
            </div>
          </ui-option>
        }

        @if (!childNodeOptions()?.length) {
          <p
            class="procore-location-select__no-locations-message"
            i18n="@@NO_LOCATIONS"
          >
            No locations.
          </p>
        }
      }
    } @else {
      <div class="procore-location-select__spinner-wrapper">
        <design-progress-spinner
          [diameter]="25"
          [strokeWidth]="5"
          designColor="accent"
          class="progress-spinner"
        />
      </div>
    }

    <ui-option
      [value]="control().value"
      style="display: none"
      [transformTrigger]="transformTrigger"
    >
      {{ control().value?.name | locationName }}
    </ui-option>
  </ui-select>
</ui-form-field>
