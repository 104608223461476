import { computed, inject, Injectable, Provider } from '@angular/core';
import { rxMethod } from '@ngrx/signals/rxjs-interop';
import { PunchItemState } from './_punch-item.state';
import { mixinPunchItemAddComment } from './punch-item-add-comment.base';
import { mixinPunchItemSendItem } from './punch-item-send-item.base';
import { mixinPunchItemUpdateAssignment } from './punch-item-update-assignment.base';
import { mixinPunchItemUpdateWorkflowAndComment } from './punch-item-update-workflow-and-conditionally-comment.base';
import { mixinPunchItemUploadFiles } from './punch-item-upload-files.base';

export type RxMethod<TInput> = ReturnType<typeof rxMethod<TInput>>;

const _PunchItemStateBase = mixinPunchItemUploadFiles(
  mixinPunchItemAddComment(
    mixinPunchItemUpdateAssignment(
      mixinPunchItemUpdateWorkflowAndComment(mixinPunchItemSendItem(class {}))
    )
  )
);

@Injectable()
export class PunchItemStateService extends _PunchItemStateBase {
  private readonly _state = inject(PunchItemState);

  readonly isLoading = this._state.isLoading;
  readonly punchItem = this._state.punchItem;
  readonly procoreId = computed(() => {
    const punchItem = this.punchItem();
    return punchItem.procoreId;
  });
  readonly punchItemStageId = computed(() => {
    const punchItem = this.punchItem();
    return punchItem.stageId;
  });
  readonly displayStatus = this._state.displayStatus;
  readonly activities = this._state.activities;
  readonly isActivitiesLoading = this._state.isActivitiesLoading;
  readonly addingCommentStatus = this._state.addingCommentStatus;
  readonly assignmentUpdateStatus = this._state.assignmentUpdateStatus;
  readonly sendItemUpdateStatus = this._state.sendItemUpdateStatus;

  readonly deletePunchItem = this._state.deletePunchItem;
}

export const PUNCH_ITEM_STATE_PROVIDERS: Provider[] = [
  PunchItemState,
  PunchItemStateService
] as const;
