import {
  ChangeDetectionStrategy,
  Component,
  computed,
  inject,
  viewChild
} from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { NgForm, ReactiveFormsModule } from '@angular/forms';
import {
  documentsApiToken,
  DocumentsInstanceDataToken,
  filtersToken,
  SelectedDocumentFilterItem
} from '@simlab/documents/data-access';
import { UiFormFieldModule } from '@simlab/ui/form-field';
import { UiSelectModule } from '@simlab/ui/select';
import { catchError, map, of } from 'rxjs';

import { Params } from '@angular/router';
import { DocumentsFilters, FiltersParams } from '@simlab/documents/ui';
import {
  documentSourcesList,
  documentTypeOptions
} from '../../pages/documents-page/stages-documents/data-access/stages-documents.data';
import { StageDocumentsApi } from '../../pages/documents-page/stages-documents/models/stages-documents-api.modal';
import {
  StagesDocumentsFilters,
  StagesDocumentsFiltersFormBuilder
} from './StagesDocumentsFiltersFormBuilder';
import { StagesDocumentsFiltersUrlParamsMapper } from './stages-documents-filters-url-params.mapper';
import {
  ExtractTypeFromArray,
  StagesDocumentsSelectedFiltersMapperClass
} from './stages-documents-selected-filters.mapper';

export type StagesFiltersParams = FiltersParams<StagesDocumentsFilters>;

@Component({
    selector: 'feature-projects-documents-stages-documents-filters',
    imports: [UiSelectModule, ReactiveFormsModule, UiFormFieldModule],
    templateUrl: './stages-documents-filters.component.html',
    styleUrl: './stages-documents-filters.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush,
    host: {
        '[class.is-render-in-modal]': 'isRenderInModal()'
    },
    providers: [
        {
            provide: filtersToken,
            useExisting: StagesDocumentsFiltersComponent
        },
        StagesDocumentsSelectedFiltersMapperClass
    ]
})
export class StagesDocumentsFiltersComponent extends DocumentsFilters<StagesDocumentsFilters> {
  private readonly _api = inject<StageDocumentsApi>(documentsApiToken);
  private readonly _instanceData = inject(DocumentsInstanceDataToken);
  private readonly _selectedFiltersMapper = inject(
    StagesDocumentsSelectedFiltersMapperClass
  );

  protected readonly documentTypeOptions = documentTypeOptions;
  protected readonly documentSourcesList = documentSourcesList;
  protected readonly authorsList = toSignal(
    this._api.getProjectDocumentsAuthors$(this._instanceData.projectId()).pipe(
      catchError((err) => {
        console.error(err);

        return of([]);
      })
    )
  );

  readonly formView = viewChild.required<NgForm>('form');
  readonly formGroup = computed(() =>
    StagesDocumentsFiltersFormBuilder.buildForm(
      this.isRenderInModal() ? 'submit' : 'blur'
    )
  );

  readonly selectedFilters$ = this.formGroup().valueChanges.pipe(
    map((data) =>
      Object.entries(data).reduce((chips, [key, value]) => {
        const specKey = key as keyof StagesDocumentsFilters;
        if (value === null) return chips;

        if (Array.isArray(value)) {
          value.forEach((item) => {
            const mappedValue = this._getMappedFilter(specKey, item);
            if (mappedValue === undefined) return;

            chips.push({
              key: key,
              id: item,
              value: mappedValue
            });
          });

          return chips;
        }

        const mappedValue = this._getMappedFilter(specKey, value);
        if (mappedValue === undefined) return chips;

        chips.push({
          key: key,
          id: value,
          value: mappedValue
        });

        return chips;
      }, [] as SelectedDocumentFilterItem[])
    )
  );
  readonly selectedFilters = toSignal(this.selectedFilters$);

  private _getMappedFilter<TKey extends keyof StagesDocumentsFilters>(
    key: TKey,
    value: NonNullable<ExtractTypeFromArray<StagesDocumentsFilters[TKey]>>
  ) {
    if (value === null) return undefined;

    return this._selectedFiltersMapper.mapper()[key](value);
  }

  constructor() {
    super(StagesDocumentsFiltersUrlParamsMapper.getMappedFilters);
  }

  override hasDomainFields(queryParams: Params): boolean {
    return (
      this._hasDomainFieldCorrectValue(queryParams['documentSources']) ||
      this._hasDomainFieldCorrectValue(queryParams['documentTypes']) ||
      this._hasDomainFieldCorrectValue(queryParams['creatorIds'])
    );
  }

  private _hasDomainFieldCorrectValue(domainField: unknown): boolean {
    return (
      (Array.isArray(domainField) && domainField.length > 0) ||
      (!Array.isArray(domainField) && !!domainField)
    );
  }
}
