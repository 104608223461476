/* eslint-disable @angular-eslint/component-class-suffix */
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  computed,
  contentChildren,
  input,
  signal,
} from '@angular/core';
import { DesignIcon } from '@simlab/design/icon';
import { DesignSymbolInput } from '@simlab/design/internal';
import { DesignFabButton } from '../fab-button/fab-button.component';
import { animation } from './fab-button-menu.animations';

export type DesignFabDirection = 'top' | 'down' | 'left' | 'right';

const ANIMATION: Record<DesignFabDirection, string> = {
  top: 'translate(0,calc(100% * var(--index)))',
  down: 'translate(0, calc(-100% * var(--index)))',
  left: 'translate(calc(100% * var(--index)),0)',
  right: 'translate(calc(-100% * var(--index)),0)',
};

const DURATION_ANIMATION = 1000;

@Component({
    selector: 'design-fab-button-menu',
    imports: [DesignFabButton, DesignIcon],
    templateUrl: './fab-button-menu.component.html',
    styleUrl: './fab-button-menu.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush,
    host: {
        class: 'fab-button-menu',
    },
    animations: [animation]
})
export class DesignFabButtonMenu implements AfterViewInit {
  readonly children = contentChildren<DesignFabButton>(DesignFabButton, {
    descendants: true,
  });

  readonly disabled = input<boolean>();
  readonly direction = input<DesignFabDirection>('down');
  readonly designIconName = input.required<DesignSymbolInput>();

  readonly isActive = signal(false);
  readonly animationDirection = computed(() => {
    const direction = this.direction();
    return ANIMATION[direction];
  });

  ngAfterViewInit() {
    this.children().forEach((el: DesignFabButton, index: number) => {
      const animationTime = DURATION_ANIMATION - index * 100;
      el._elementRef.nativeElement.style.animationDuration = `${animationTime}ms`;
      el._elementRef.nativeElement.style.setProperty(
        '--index',
        (index + 1).toString(),
      );

      el._elementRef.nativeElement.style.setProperty(
        'z-index',
        (20 - index + 1).toString(),
      );
    });
  }

  toggle() {
    this.isActive.set(false);
  }
}
