import {
  ChangeDetectionStrategy,
  Component,
  computed,
  inject
} from '@angular/core';
import { toObservable } from '@angular/core/rxjs-interop';
import { FormControl, FormGroup, ReactiveFormsModule } from '@angular/forms';
import {
  DesignFlatButtonModule,
  DesignStrokedButton
} from '@simlab/design/button';
import {
  CommentWithFiles,
  DesignCommentComponent
} from '@simlab/design/comment';
import { DesignDialogWrapperModule } from '@simlab/design/dialog';
import { DesignRadioButton, DesignRadioGroup } from '@simlab/design/radio';
import { ToastService } from '@simlab/design/toast';
import {
  PunchItemStateService,
  PunchItemUpdateStatus
} from '@simlab/procore/annotation-panel/services';
import {
  Assignment,
  PunchItemAssignmentStatus,
  UpdatePunchItemAssignment
} from '@simlab/procore/models';
import { PunchItemAssignmentStatusLabelGetterPipe } from '@simlab/procore/pipes';
import { filter, tap } from 'rxjs';
import { DIALOG_DATA, DialogRef } from '@angular/cdk/dialog';

@Component({
  selector: 'procore-punch-item-side-panel-assignee-response-modal',
  standalone: true,
  imports: [
    DesignDialogWrapperModule,
    DesignStrokedButton,
    DesignRadioButton,
    DesignRadioGroup,
    PunchItemAssignmentStatusLabelGetterPipe,
    ReactiveFormsModule,
    DesignCommentComponent,
    DesignFlatButtonModule
  ],
  templateUrl: './punch-item-side-panel-assignee-response-modal.component.html',
  styleUrl: './punch-item-side-panel-assignee-response-modal.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PunchItemSidePanelAssigneeResponseModalComponent {
  private readonly _toastService = inject(ToastService);
  private readonly _modalRef = inject(DialogRef);
  private readonly _data = inject<{
    assignment: Assignment;
    procorePunchItemId: number;
    state: PunchItemStateService;
  }>(DIALOG_DATA);
  private readonly _state = this._data.state;
  private readonly _assignment = this._data.assignment;
  private readonly _assignmentUpdateStatus = this._state.assignmentUpdateStatus;
  protected readonly save$ = toObservable(this._assignmentUpdateStatus).pipe(
    tap(() => this._save()),
    filter((status) => status !== 'IDLE' && status !== 'PENDING'),
    tap((status) => this._handleUpdateResult(status))
  );
  protected readonly name = this._data.assignment.loginInformation.name;
  protected readonly formGroup = new FormGroup({
    status: new FormControl<PunchItemAssignmentStatus>(
      PunchItemAssignmentStatus.Unresolved,
      { nonNullable: true }
    ),
    comment: new FormControl<CommentWithFiles>(
      {
        comment: '',
        files: []
      },
      { nonNullable: true }
    )
  });
  protected readonly radioButtons = [
    PunchItemAssignmentStatus.Unresolved,
    PunchItemAssignmentStatus.WorkNotAccepted,
    PunchItemAssignmentStatus.ReadyForReview,
    PunchItemAssignmentStatus.Resolved
  ] as const;
  protected readonly isPending = computed(() => {
    const status = this._assignmentUpdateStatus();
    return status === 'PENDING';
  });

  protected get comment() {
    return this.formGroup.controls.comment.value.comment;
  }

  protected get files() {
    return this.formGroup.controls.comment.value.files || [];
  }

  protected get status() {
    return this.formGroup.controls.status.value;
  }

  cancelDialog() {
    this._modalRef.close();
  }

  private _save() {
    const updateAssignment: UpdatePunchItemAssignment = {
      procorePunchItemId: this._data.procorePunchItemId,
      punchItemAssignmentId: this._assignment.id,
      loginInformationId: this._assignment.loginInformation.id,
      approved: this._assignment.approved,
      comment: this.comment,
      files: this.files,
      status: this.status
    };

    return this._state.updateAssignment(updateAssignment);
  }

  private _handleUpdateResult(status: PunchItemUpdateStatus) {
    switch (status) {
      case 'SUCCESS':
        this._toastService.open(
          $localize`:@@UPDATE_ASSIGNMENT_SUCCESS:The punch item assignment has been updated correctly.`,
          'Success'
        );
        this._modalRef.close();
        break;

      case 'FAIL':
        this._toastService.open(
          $localize`:@@UPDATE_ASSIGNMENT_FAIL:Errors occured during update of punch item assignment.`,
          'Error'
        );
        break;
    }
  }
}
