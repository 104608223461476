import { HttpErrorResponse } from '@angular/common/http';
import { computed, inject, Injectable } from '@angular/core';
import { tapResponse } from '@ngrx/operators';
import { patchState, signalState } from '@ngrx/signals';
import { rxMethod } from '@ngrx/signals/rxjs-interop';
import { Marker } from '@simlab/data-access';
import {
  CURRENT_PROJECT_ID,
  CURRENT_STAGE_ID,
  UserPreferenceFacade
} from '@simlab/data-store';
import { DesignDateValidators } from '@simlab/design/datepicker';
import { ToastService } from '@simlab/design/toast';
import { TEMP_PUNCH_ITEM_ID } from '@simlab/procore/annotation-panel/data-access';
import { ApiProcoreService } from '@simlab/procore/data-access';
import {
  CreatePunchItemPayload,
  procoreBaseInfoPayload,
  ProcoreProjectInfo,
  PunchItem,
  PunchItemDisplayElements,
  PunchItemFormBase,
  PunchItemFormValue,
  PunchItemSaveBase,
  UpdatePunchItemPayload
} from '@simlab/procore/models';
import {
  INIT_PAGING_STATE,
  PunchItemsListStore
} from '@simlab/procore/services';
import { prepareFieldsetConfig } from '@simlab/procore/shared/data-access';
import {
  ProcoreAnnotationFieldsetType,
  PunchItemConfigurableFieldset,
  PunchItemtFieldsConfiguration
} from '@simlab/procore/shared/models';
import { catchError, EMPTY, exhaustMap, iif, map, mergeMap, of } from 'rxjs';
import { mixinPunchItemUploadFiles } from './punch-item-upload-files.base';

const TRANSLATIONS = (title?: string) =>
  ({
    createPunchItemSuccess: $localize`:@@PUNCH_ITEM_CREATE_SUCCESS:Punch Item ${title} created successfully.`,
    createPunchItemError: $localize`:@@PUNCH_ITEM_CREATE_ERROR:An error occured while creating the Punch Item.`,
    updatePunchItemSuccess: $localize`:@@PUNCH_ITEM_UPDATE_SUCCESS:Punch Item ${title} updated successfully.`,
    updatePunchItemError: $localize`:@@PUNCH_ITEM_UPDATE_ERROR:An error occured while updating the Punch Item.`,
    placeMarkerError: $localize`:@@ERR_PLACING_MARKER_TO_PUNCH_ITEM:An error occured while placing Marker to Punch Item`,
    updateAssigneesError: $localize`:@@ERR_UPDATING_ASSIGNEES:An error occured while updating assignee(s) of Punch Item`,
    fetchFieldConfigurationError: $localize`:@@ERR_FETCHING_PUNCH_ITEM_FIELD_CONFIGURATION:An error occured while fetching Punch Item field configuration.`
  }) as const;

const INITIAL_PUNCH_ITEM_FORM: PunchItemFormValue = {
  id: null,
  private: false,
  name: '',
  position: null,
  due: undefined,
  punchItemManagerId: null,
  finalApproverId: null,
  procoreId: 0,
  description: {
    comment: '',
    files: []
  },
  loginInformationIds: []
};

type TPunchItemAddEditState = {
  displayStatus: PunchItemDisplayElements | undefined;
  punchItemForm: PunchItemFormValue;
  isSavingInProgress: boolean;
  fieldsConfigurationLoading: boolean;
  fieldsConfiguration: PunchItemtFieldsConfiguration | undefined;
};

const initialState: TPunchItemAddEditState = {
  displayStatus: undefined,
  punchItemForm: INITIAL_PUNCH_ITEM_FORM,
  isSavingInProgress: false,
  fieldsConfigurationLoading: true,
  fieldsConfiguration: undefined
};

const _PunchItemFormStateBase = mixinPunchItemUploadFiles(class {});

@Injectable()
export class PunchItemFormState extends _PunchItemFormStateBase {
  private readonly _listStore = inject(PunchItemsListStore);
  private readonly _api = inject(ApiProcoreService);
  private readonly _toastService = inject(ToastService);
  private readonly _baseInfoProcore = inject(procoreBaseInfoPayload);
  private readonly _userPreferences = inject(UserPreferenceFacade);
  private readonly _projectId = inject(CURRENT_PROJECT_ID);
  private readonly _stageId = inject(CURRENT_STAGE_ID);

  private readonly _state = signalState({
    ...initialState
  });

  readonly punchItem = this._listStore.itemSelected;
  readonly punchItemFormValues = this._state.punchItemForm;
  readonly marker = computed(() => {
    const selectedItem = this.punchItem();
    return selectedItem.marker;
  });

  readonly isLoading = computed(() => {
    const fieldsConfigurationLoading = this._state.fieldsConfigurationLoading();

    if (this.punchItem().id === TEMP_PUNCH_ITEM_ID) {
      return fieldsConfigurationLoading;
    }
    return this._listStore.isItemLoading() || fieldsConfigurationLoading;
  });
  readonly isSavingInProgress = this._state.isSavingInProgress;
  readonly defaultFieldsConfig = this._state.fieldsConfiguration;

  readonly save = rxMethod<PunchItemFormValue>(
    exhaustMap((punchItemForm) => {
      patchState(this._state, { isSavingInProgress: true });
      return iif(
        () => this._isUpdateExisting(),
        this._updatePunchItem$(punchItemForm),
        this._createNewPunchItem$(punchItemForm)
      );
    })
  );

  readonly getConfigurableFieldset = rxMethod<ProcoreProjectInfo>(
    mergeMap((projectInfo) => {
      const { procoreProjectId, procoreCompanyId } = projectInfo;
      return this._api
        .getProjectConfigurableFieldset({
          procoreCompanyId,
          procoreProjectId,
          type: ProcoreAnnotationFieldsetType.PunchItem
        })
        .pipe(
          tapResponse({
            next: (configurableFieldset) =>
              this._prepareFieldsetConfig(configurableFieldset),
            error: (err) => {
              this._toastService.open(
                TRANSLATIONS().fetchFieldConfigurationError,
                'Error'
              );
              console.error(err);
            },
            finalize: () =>
              patchState(this._state, { fieldsConfigurationLoading: false })
          })
        );
    })
  );

  constructor() {
    super();
    const baseInfoProcore = this._baseInfoProcore();

    if (baseInfoProcore) {
      this.getConfigurableFieldset(baseInfoProcore);
    }
  }

  savePunchItem() {
    this.save(this.punchItemFormValues());
  }

  updateFormValues(punchItemForm: Partial<PunchItemFormBase>) {
    patchState(this._state, {
      punchItemForm: { ...this._state().punchItemForm, ...punchItemForm }
    });
  }

  clearState(): void {
    patchState(this._state, initialState);
  }

  private _isUpdateExisting() {
    return !!this._state.punchItemForm().procoreId;
  }

  private _createNewPunchItem$(punchItemForm: PunchItemFormBase) {
    const files = punchItemForm.description?.files || [];
    const assignees = punchItemForm.loginInformationIds;

    return this.uploadFilesAndReturnIds$(files).pipe(
      mergeMap((fileIds) => {
        const createPunchItemPayload: CreatePunchItemPayload = {
          ...this._createPunchItemSavePayloadBase(
            this._state.punchItemForm(),
            fileIds
          ),
          projectId: this._projectId()!,
          stageId: this._stageId()!
        };

        return this._api.createPunchItem(createPunchItemPayload).pipe(
          mergeMap((response) =>
            this._conditionallyPlaceMarkerOnNewPunchItem(response.punchItem)
          ),
          mergeMap((punchItem) => {
            return assignees.length
              ? this._updateAssignees(
                  punchItemForm.loginInformationIds,
                  punchItem.procoreId
                ).pipe(map(() => punchItem))
              : of(punchItem);
          }),
          tapResponse({
            next: (punchItem) =>
              this._handleCreateNewPunchItemSuccess(punchItem),
            error: (error: HttpErrorResponse) => {
              this._toastService.open(
                TRANSLATIONS().createPunchItemError,
                'Error'
              );
              console.error(error);
            },
            finalize: () => {
              patchState(this._state, { isSavingInProgress: false });
            }
          })
        );
      })
    );
  }

  private _updatePunchItem$(punchItemForm: PunchItemFormValue) {
    const baseInfoProcore = this._baseInfoProcore();
    if (baseInfoProcore === undefined) {
      console.log('baseInfoProcore is undefined');
      return EMPTY;
    }

    const { procoreProjectId, procoreCompanyId } = baseInfoProcore;
    const files = punchItemForm.description?.files || [];

    return this.uploadFilesAndReturnIds$(files).pipe(
      mergeMap((uploadIds) => {
        const payload: UpdatePunchItemPayload = {
          ...this._createPunchItemSavePayloadBase(punchItemForm),
          procoreProjectId,
          procoreCompanyId,
          procoreId: punchItemForm.procoreId,
          uploadIds
        };

        return this._api.updatePunchItem(payload).pipe(
          mergeMap((response) => {
            if (this._isAssigneesChanged()) {
              return this._updateAssignees(
                this.punchItemFormValues.loginInformationIds(),
                response.procoreId
              ).pipe(
                map(
                  (punchItemWithUpdatedAssignees) =>
                    punchItemWithUpdatedAssignees ?? response
                )
              );
            } else {
              return of(response);
            }
          }),
          tapResponse({
            next: (punchItem) =>
              this._handleUpdatePunchItemSuccess(punchItem, this.marker()),
            error: (error: HttpErrorResponse) => {
              this._toastService.open(
                TRANSLATIONS().updatePunchItemError,
                'Error'
              );
              console.error(error);
            },
            finalize: () => {
              patchState(this._state, { isSavingInProgress: false });
            }
          })
        );
      })
    );
  }

  private _conditionallyPlaceMarkerOnNewPunchItem(punchItem: PunchItem) {
    const marker = this.marker();
    if (marker) {
      return this._api
        .placeMarkerToPunchItem({
          id: punchItem.id,
          ...marker
        })
        .pipe(
          catchError((err) => {
            this._toastService.open(TRANSLATIONS().placeMarkerError, 'Error');
            return of(err);
          }),
          map(() => punchItem)
        );
    } else {
      return of(punchItem);
    }
  }

  private _updateAssignees(procoreUserIds: number[], procoreId: number) {
    const baseInfoProcore = this._baseInfoProcore();
    if (baseInfoProcore === undefined) {
      console.error('baseInfoProcore is undefined');
      return EMPTY;
    }

    const { procoreCompanyId, procoreProjectId } = baseInfoProcore;
    return this._api
      .updatePunchItemAssignees({
        procoreCompanyId,
        procoreProjectId,
        procoreId,
        procoreUserIds
      })
      .pipe(
        catchError((err) => {
          console.error(err);
          this._toastService.open(TRANSLATIONS().updateAssigneesError, 'Error');
          return of(null);
        })
      );
  }

  private _isAssigneesChanged() {
    const punchItem = this.punchItem();
    const punchItemAssigneeIds = punchItem.assignments.map(
      (assignment) => assignment.loginInformation.id
    );
    const formAssigneeIds = this.punchItemFormValues.loginInformationIds();

    if (punchItemAssigneeIds.length !== formAssigneeIds.length) return true;

    const sortAndJoin = (arr: number[]) => arr.sort().join();
    return sortAndJoin(punchItemAssigneeIds) !== sortAndJoin(formAssigneeIds);
  }

  private _handleCreateNewPunchItemSuccess(punchItem: PunchItem) {
    this._toastService.open(
      TRANSLATIONS(punchItem.name).createPunchItemSuccess,
      'Success'
    );
    // Refresh the pagination and fetch the first page to display the newly added Punch Item in the list
    this._listStore.updatePage(INIT_PAGING_STATE);
    this._listStore.openPunchItemRightPanel(punchItem.procoreId);
  }

  private _handleUpdatePunchItemSuccess(
    punchItem: PunchItem,
    marker: Marker | undefined
  ) {
    this._toastService.open(
      TRANSLATIONS(punchItem.name).updatePunchItemSuccess,
      'Success'
    );
    this._listStore.patchPunchItem(punchItem);
    this._listStore.updateSelectedItemMarker(marker);
    this._listStore.openPunchItemRightPanel(punchItem.procoreId);
  }

  private _createPunchItemSavePayloadBase(
    formValue: PunchItemFormValue,
    filesIds: string[] = []
  ): PunchItemSaveBase {
    return {
      description: formValue.description?.comment,
      dueDate: formValue.due
        ? DesignDateValidators.transformStringDateToDate(
            formValue.due as string,
            this._userPreferences.getDateFormat()
          ).toISOString()
        : null,
      name: formValue.name,
      position: formValue.position!,
      priority: formValue.priority,
      private: formValue.private,
      scheduleImpact: formValue.scheduleImpact?.scheduleImpact,
      scheduleImpactDays: formValue.scheduleImpact?.scheduleImpactDays,
      costCodeId: formValue.costCodeId,
      costImpact: formValue.costImpact?.costImpact,
      costImpactAmount: formValue.costImpact?.costImpactAmount,
      punchItemTypeId: formValue.punchItemTypeId,
      loginInformationIds: formValue.loginInformationIds,
      distributionMemberIds: formValue.distributionMemberIds,
      punchItemManagerId: formValue.punchItemManagerId,
      finalApproverId: formValue.finalApproverId,
      uploadIds: filesIds,
      locationId: formValue.locationId?.id || null,
      tradeId: formValue.tradeId,
      customFields: null
    };
  }

  private _prepareFieldsetConfig(
    configurableFieldset: PunchItemConfigurableFieldset
  ) {
    const fieldsConfiguration = prepareFieldsetConfig(configurableFieldset);

    patchState(this._state, {
      fieldsConfiguration
    });
  }
}
