import {
  ChangeDetectionStrategy,
  Component,
  DestroyRef,
  inject,
  InjectionToken,
  NgZone,
  OnDestroy,
  Signal
} from '@angular/core';
import { takeUntilDestroyed, toSignal } from '@angular/core/rxjs-interop';
import { Router } from '@angular/router';
import { MatterportAnnotationControlService } from '@simlab/annotation/data-access';
import { IVector3, Marker } from '@simlab/data-access';
import {
  DesignFlatButtonModule,
  DesignIconButton
} from '@simlab/design/button';
import { DesignIcon } from '@simlab/design/icon';
import {
  MATTERPORT_TOKEN,
  MatterportBaseService
} from '@simlab/feature/matterport';
import { StagesRootService } from '@simlab/feature/stages';
import {
  ICONS,
  MatterportComponent,
  MatterportService,
  StartPlacingConfig,
  TagNote,
  TagNoteTypes
} from '@simlab/matterport';
import { SpriteConfiguration } from '@simlab/simlab-facility-management/scene-object';
import {
  BehaviorSubject,
  catchError,
  combineLatest,
  forkJoin,
  mergeMap,
  Observable,
  of,
  race,
  Subject,
  switchMap,
  take,
  tap,
  throwError
} from 'rxjs';
import { Vector3 } from 'three';
import { NotesTagPlacementService } from './data-access/notes-tag-placement.service';
import { PunchItemTagPlacementService } from './data-access/punch-item-tag-placement.service';
import { RFITagPlacementService } from './data-access/rfi-tag-placement.service';

export type AnnotationMarker = {
  id: string | number | any;
  marker: Marker | undefined;
  type: TagNoteTypes;
};

export type PlaceMarkerInAnnotationParams = {
  annotationId: string;
  convertedPosition: IVector3;
};

export interface AnnotationTagPlacement {
  readonly hasMarker: Signal<boolean | undefined>;
  readonly selectedAnnotationId$: Observable<string>;
  placeMarkerInAnnotation$: (
    params: PlaceMarkerInAnnotationParams
  ) => Observable<AnnotationMarker['id']>;
  removePosition$: () => Observable<AnnotationMarker>;
  initMarkerSet$: () => Observable<AnnotationMarker>;
}

export const ANNOTATION_TAG_PLACEMENT =
  new InjectionToken<AnnotationTagPlacement>('Annotation Tag Placement');

@Component({
    selector: 'annotation-tag-placement',
    templateUrl: './tag-placement.component.html',
    styleUrls: ['./tag-placement.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    imports: [DesignIconButton, DesignIcon, DesignFlatButtonModule],
    providers: [
        {
            provide: ANNOTATION_TAG_PLACEMENT,
            useFactory: () => {
                const url = inject(Router).url;
                const annotationTagPlacementServices = {
                    noteId: NotesTagPlacementService,
                    punchItemId: PunchItemTagPlacementService,
                    rfiId: RFITagPlacementService
                };
                for (const prop in annotationTagPlacementServices) {
                    if (url.includes(prop))
                        return new annotationTagPlacementServices[prop as keyof typeof annotationTagPlacementServices]();
                }
                inject(MatterportAnnotationControlService).offAnnotationMarkAddMode();
                throw new Error("Can't find instance for annotation tag placement");
            }
        }
    ]
})
export class TagPlacementComponent implements OnDestroy {
  private readonly _matterportAnnotationControl = inject(
    MatterportAnnotationControlService
  );
  private readonly _destroyRef = inject(DestroyRef);
  private readonly _ngZone = inject(NgZone);
  private readonly _matterportManagerService =
    inject<MatterportService>(MATTERPORT_TOKEN);
  private readonly _stagesRootService = inject(StagesRootService);
  private readonly _matterportBaseService = inject(MatterportBaseService);
  private readonly _annotationTagPlacement = inject(ANNOTATION_TAG_PLACEMENT);

  private readonly _abandonPlacing$: Subject<void> = new Subject<void>();
  private readonly _placing$: BehaviorSubject<boolean> =
    new BehaviorSubject<boolean>(false);

  private _lastPreviousKnownTagPosition:
    | {
        id: string;
        matterportOffset: string | undefined;
        markerPosition: Vector3;
        markerType: string;
      }
    | undefined = undefined;
  private _smallScreen = false;

  protected readonly placing = toSignal(this._placing$.asObservable());
  protected readonly noteHasMarker = this._annotationTagPlacement.hasMarker;

  constructor() {
    this._stagesRootService.mobileScreenSize$
      .pipe(takeUntilDestroyed(this._destroyRef))
      .subscribe((isMobile: boolean) => {
        this._smallScreen = isMobile;
      });
  }

  ngOnDestroy(): void {
    this._matterportAnnotationControl.offAnnotationMarkAddMode();
  }

  protected closeLocalizationEdition() {
    this._abandonPlacing$.next();
    this._placing$.next(false);
    this._matterportAnnotationControl.offAnnotationMarkAddMode();
    this._stagesRootService.rightPanelState = 'open';
  }

  protected removePosition() {
    this._annotationTagPlacement
      .removePosition$()
      .pipe(tap(({ id }) => this._matterportManagerService.deleteNote(id)))
      .subscribe(() => {
        this._matterportAnnotationControl.offAnnotationMarkAddMode();
      });
  }

  protected setComponentPosition() {
    this._placing$.next(true);

    this._annotationTagPlacement
      .initMarkerSet$()
      .pipe(
        mergeMap((annotation: AnnotationMarker) =>
          this._setMarkerProcedure$(annotation)
        ),
        switchMap(() => this._markerPlacementDetector()),
        take(1),
        mergeMap(
          (
            value: void | {
              tagNote: TagNote;
              comp: MatterportComponent | undefined;
            }
          ) => {
            if (value === undefined) {
              return throwError(() => new Error('Abandon tag placing'));
            }
            return combineLatest([
              of(value.tagNote) as Observable<TagNote>,
              this._annotationTagPlacement.selectedAnnotationId$
            ]);
          }
        ),
        mergeMap(([tagNote, rootNoteId]: [TagNote, string]) => {
          const convertedPosition = this._matterportManagerService
            .transformConverter()
            .to3dPosition(
              new Vector3(
                tagNote.position.x,
                tagNote.position.y,
                tagNote.position.z
              )
            );

          return this._annotationTagPlacement.placeMarkerInAnnotation$({
            annotationId: rootNoteId,
            convertedPosition
          });
        }),
        tap((annotationId) => {
          this._matterportManagerService.selectedNote = annotationId;
        }),
        catchError((e) => {
          this._matterportManagerService.abandonPlacingComponent();
          const joined: Observable<any>[] = [of(e)];
          if (this._lastPreviousKnownTagPosition) {
            const { x, y, z } =
              this._lastPreviousKnownTagPosition.markerPosition;

            joined.push(
              this._matterportManagerService.addComponentWithOffset$({
                id: this._lastPreviousKnownTagPosition.id,
                position: new Vector3(x, y, z),
                normal: new Vector3(0, 0, 0),
                stemHeight: 0,
                scale: new Vector3(0.12, 0.12, 0.12),
                objects: [
                  new SpriteConfiguration({
                    icon: ICONS[
                      this._lastPreviousKnownTagPosition
                        .markerType as TagNoteTypes
                    ]
                  })
                ]
              })
            );
          }
          return forkJoin(joined);
        })
      )
      .subscribe(() => {
        if (!this._smallScreen) {
          this._stagesRootService.rightPanelState = 'open';
        }

        //NOTE: (olek) szpachla
        this._ngZone.run(() => {
          this._matterportAnnotationControl.offAnnotationMarkAddMode();
        });

        this._placing$.next(false);
      });
  }

  private _setMarkerProcedure$({ marker, id, type }: AnnotationMarker) {
    this._lastPreviousKnownTagPosition = marker?.position
      ? {
          id: id,
          matterportOffset: this._stagesRootService._lastKnownMatterportOffset,
          markerPosition: new Vector3(
            marker.position.x,
            marker.position.y,
            marker.position.z
          ),
          markerType: type
        }
      : undefined;

    if (this._smallScreen && this._touchScreen()) {
      this._matterportManagerService.setHint(
        '<p>Hold down to place a marker</p>'
      );
    }

    const config: StartPlacingConfig = {
      note: {
        id: id,
        noteType: type,
        position: new Vector3(
          marker?.position.x || 0,
          marker?.position.y || 0,
          marker?.position.z || 0
        )
      },
      autoFinishByClick: true,
      mobile: this._smallScreen && this._touchScreen()
    };
    if (marker) {
      this._matterportManagerService.deleteNote(id);
    }
    return this._matterportManagerService.startPlacingComponent$(config);
  }

  private _markerPlacementDetector() {
    return race(
      this._matterportManagerService.tagPlacementAccepted$.pipe(
        tap(
          (
            value: void | {
              tagNote: TagNote;
              comp: MatterportComponent | undefined;
            }
          ) => {
            if (value && value.comp)
              this._matterportBaseService.addMatterportComponent = value.comp;
          }
        )
      ),
      this._abandonPlacing$.asObservable()
    );
  }

  private _touchScreen = () =>
    ('ontouchstart' in window || navigator.maxTouchPoints > 0) &&
    !window.matchMedia('(pointer:fine)').matches;
}
