import {
  ChangeDetectionStrategy,
  Component,
  computed,
  inject,
  input,
  output
} from '@angular/core';
import { RFIState } from '@simlab/procore/annotation-panel/services';

import { toSignal } from '@angular/core/rxjs-interop';
import { Params } from '@angular/router';
import { MatterportAnnotationControlService } from '@simlab/annotation/data-access';
import { ProjectPrivilegesFacade } from '@simlab/data-store';
import { StagesRootService } from '@simlab/feature/stages';
import { RFI } from '@simlab/procore/models';
import {
  RFIStatusIconGetterPipe,
  RFIStatusLabelGetterPipe,
  RFIStatusTextGetterPipe,
  RFIStatusTypeGetterPipe
} from '@simlab/procore/pipes';
import { RFIIconTypeGetterPipe } from '@simlab/procore/rfi/ui';
import { UiImageInfoModule } from '@simlab/ui/image-info';
import { OpenNewTabService, RouterFacadeService } from '@simlab/util-shared';
import { annotationsActionsToken } from 'common/annotation-panel/models';
import {
  AnnotationPanelLocation3DComponent,
  AnnotationPanelRootComponent
} from 'common/annotation-panel/ui';
import { map } from 'rxjs';
import { NotificationsComponent } from '../notifications/notifications.component';
import { RequestContainerComponent } from '../request-container/request-container.component';
import { BaseRFISidePanel } from './base-rfi-side-panel';
import { RFISidePanelAction } from './rfi-side-panel-actions';

export type OnTransferRFIElementModel = {
  id: RFI['id'];
  status: RFI['status'];
};

interface RFIAction {
  editRFI: () => void;
}

@Component({
  selector: 'procore-rfi-side-panel',
  imports: [
    AnnotationPanelRootComponent,
    AnnotationPanelLocation3DComponent,
    NotificationsComponent,
    UiImageInfoModule,
    RequestContainerComponent,
    RFIStatusLabelGetterPipe,
    RFIIconTypeGetterPipe,
    RFIStatusTextGetterPipe,
    RFIStatusIconGetterPipe,
    RFIStatusTypeGetterPipe
  ],
  templateUrl: './rfi-side-panel.component.html',
  styleUrl: './rfi-side-panel.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    RFIState,
    OpenNewTabService,
    {
      provide: annotationsActionsToken,
      useClass: RFISidePanelAction
    }
  ]
})
export class RfiSidePanelComponent
  extends BaseRFISidePanel
  implements RFIAction
{
  private readonly _routerFacade = inject(RouterFacadeService);
  private readonly _stagesRootService = inject(StagesRootService);
  private readonly _matterportAnnotationControl = inject(
    MatterportAnnotationControlService
  );
  private readonly _privileges = inject(ProjectPrivilegesFacade);

  private readonly _hasEditPrivilegesInProject = toSignal(
    this._privileges.noEditPrivilegesInProject$.pipe(
      map((noEditPrivilegesInProject) => !noEditPrivilegesInProject)
    )
  );

  readonly stageName = input.required<string>();
  readonly onClosePanel = output<void>();
  readonly onTransferElement = output<OnTransferRFIElementModel>();

  readonly title = computed(() => {
    const { title } = this.rfi();
    return title;
  });

  readonly name = computed(() => {
    const { title, number } = this.rfi();

    return `#${number ?? ''}: ${title}`;
  });

  readonly itemUrl = computed(() => {
    const { itemUrl } = this.rfi();
    return itemUrl;
  });

  readonly subject = computed(() => {
    const { subject } = this.rfi();
    return subject;
  });

  readonly status = computed(() => {
    const { status } = this.rfi();
    return status;
  });

  readonly questions = computed(() => {
    const { questions } = this.rfi();
    return questions[0]?.richTextBody;
  });

  readonly hasLocation = computed(() => {
    const { marker } = this.rfi();

    return marker !== undefined;
  });

  readonly canEdit = computed(() => {
    return (
      !!this.allowedActions()?.showEdit && this._hasEditPrivilegesInProject()
    );
  });

  get button() {
    return {
      title: $localize`:@@OPEN_IN_PROCORE:Open in Procore`,
      action$: this.openNewTab.openUrlInNewTabWithSafariSupport(this.itemUrl())
    };
  }

  protected setLocation() {
    this._matterportAnnotationControl.triggerAnnotationMarkerAddMode(
      'RFI',
      true
    );
  }

  protected reassignLocation() {
    this._matterportAnnotationControl.triggerAnnotationMarkerChangeMode('RFI');
  }

  protected goTo() {
    this._matterportAnnotationControl.moveToAnnotation('RFI');
  }

  editRFI() {
    this.onClosePanel.emit();

    const queryParams: Params = {
      sidenavContent: 'form-rfi'
    };

    this._routerFacade.setQueryParams(undefined, queryParams, 'merge');
    this._stagesRootService.rightPanelState = 'open';
  }
}
