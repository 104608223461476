/* eslint-disable @angular-eslint/no-host-metadata-property */
/* eslint-disable @angular-eslint/directive-selector */
/* eslint-disable @angular-eslint/directive-class-suffix */
import { ContentChildren, Directive, QueryList } from '@angular/core';
import { _DesignRadioGroupBase } from './design-radio-group-base.directive';
import { DesignRadioButton } from './radio-button';
import {
  DESIGN_RADIO_GROUP,
  DESIGN_RADIO_GROUP_CONTROL_VALUE_ACCESSOR,
} from './token';

@Directive({
  selector: 'design-radio-group',
  exportAs: 'designRadioGroup',
  providers: [
    DESIGN_RADIO_GROUP_CONTROL_VALUE_ACCESSOR,
    {
      provide: DESIGN_RADIO_GROUP,
      useExisting: DesignRadioGroup,
    },
  ],
  standalone: true,
  host: {
    role: 'radiogroup',
    class: 'design-radio-group',
  },
})
export class DesignRadioGroup extends _DesignRadioGroupBase<DesignRadioButton> {
  /** Child radio buttons. */
  @ContentChildren(DesignRadioButton, { descendants: true })
  _radios!: QueryList<DesignRadioButton>;
}
