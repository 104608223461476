import {
  ChangeDetectionStrategy,
  Component,
  computed,
  output
} from '@angular/core';
import { DesignStrokedButton } from '@simlab/design/button';
import { PunchItemPanelBaseDirective } from '@simlab/procore/annotation-panel/ui';
import { PunchItemSidePanelAssigneeItemComponent } from '../punch-item-side-panel-assignee-item/punch-item-side-panel-assignee-item.component';

@Component({
  selector: 'procore-punch-item-side-panel-assignees',
  standalone: true,
  imports: [PunchItemSidePanelAssigneeItemComponent, DesignStrokedButton],
  styleUrl: './punch-item-side-panel-assignees.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  template: `
    <div class="punch-item-assignees__all-work-complete">
      <button
        design-stroked-button
        i18n="@@ALL_WORK_COMPLETE"
        (click)="allWorkComplete.emit()"
      >
        All Work Complete
      </button>
    </div>
    <div class="punch-item-assignees__items">
      @for (assignment of assignments(); track assignment.id) {
        <procore-punch-item-side-panel-assignee-item
          [assignment]="assignment"
        />
      }
    </div>
  `
})
export class PunchItemSidePanelAssigneesComponent extends PunchItemPanelBaseDirective {
  protected readonly assignments = computed(() => {
    const { assignments } = this.punchItem();
    return assignments || [];
  });

  readonly allWorkComplete = output();
}
