import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { DesignFlatButton } from '@simlab/design/button';
import { DesignCommonModule } from '@simlab/design/common';
import { DesignDialogWrapperModule } from '@simlab/design/dialog';
import { UiIconModule } from '../../../ui-icon/public-api';
import { ConfirmationModalRef, MODAL_DATA } from '../../public-api';

@Component({
    selector: 'ui-confirm-with-icon-modal',
    templateUrl: './confirm-with-icon-modal.component.html',
    styleUrls: ['./confirm-with-icon-modal.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    imports: [
        CommonModule,
        DesignCommonModule,
        DesignDialogWrapperModule,
        DesignFlatButton,
        UiIconModule,
    ]
})
export class ConfirmWithIconModalComponent {
  constructor(
    @Inject(MODAL_DATA)
    readonly data: { title: string; context: string },
    private readonly modalRef: ConfirmationModalRef<unknown>
  ) {}

  confirm(): void {
    this.modalRef.emit({ state: true });
    this.modalRef.close();
  }

  cancel(): void {
    this.modalRef.emit({ state: false });
    this.modalRef.close();
  }
}
