import { CommonModule, UpperCasePipe } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  input,
  ViewEncapsulation
} from '@angular/core';

@Component({
  selector: 'procore-info-item-container',
  standalone: true,
  imports: [UpperCasePipe],
  templateUrl: './info-item-container.component.html',
  styleUrl: './info-item-container.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  host: {
    class: 'procore-info-item-container',
    '[class.procore-info-item-container--bottom-border]': 'withBottomBorder()'
  }
})
export class InfoItemContainerComponent {
  readonly label = input<string>();
  readonly withBottomBorder = input<boolean>(true);
}
