import { ChangeDetectionStrategy, Component } from '@angular/core';
import { ImageInfoModule } from 'libs/ui/src/lib/ui-image-info/components/image-info/image-info.component';

const noPermissionTranslation = $localize`:@@NO_PERMISSIONS_DESCRIPTION:Your procore account permissions do not allow you to access this data`;

@Component({
  selector: 'feature-projects-procore-no-permissions',
  standalone: true,
  imports: [ImageInfoModule],
  templateUrl: './procore-no-permissions.component.html',
  styleUrl: './procore-no-permissions.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ProcoreNoPermissionsComponent {
  protected readonly noPermissionTranslation = noPermissionTranslation;
}
