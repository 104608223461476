import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  NgZone,
  OnDestroy,
  OnInit,
  Output,
  inject
} from '@angular/core';
import { DesignFlatButton, DesignIconButton } from '@simlab/design/button';
import { DesignIcon } from '@simlab/design/icon';
import { MatterportManagerService } from '@simlab/matterport';
import { Vector3 } from 'three';

import {
  TAreaMesh,
  TMeasurementListenersAction
} from '@simlab/simlab-facility-management/sub-features/measurement';
import { UiDividerModule } from '@simlab/ui/divider';
import {
  Subject,
  defer,
  distinctUntilChanged,
  filter,
  first,
  firstValueFrom,
  merge,
  of,
  startWith,
  switchMap,
  takeUntil,
  tap
} from 'rxjs';
const SHORTCUTS = ['CTRL+Z', 'CTRL+Y', 'DELETE', 'INSERT'] as const;
export type Shortcuts = (typeof SHORTCUTS)[number];
const ADD_POINT_HINT = $localize`:@@ADD_POINT_HINT:Tap and hold to insert point`;
const DELETE_POINT_HINT = $localize`:@@DELETE_POINT_HINT:Tap and hold to delete point`;
@Component({
    selector: 'simlab-area-measurement-tool',
    imports: [
        CommonModule,
        DesignFlatButton,
        DesignIcon,
        DesignIconButton,
        UiDividerModule
    ],
    templateUrl: './area-measurement-tool.component.html',
    styleUrls: ['./area-measurement-tool.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class MeasurementToolComponent implements OnInit, OnDestroy {
  private readonly matterportManager = inject(MatterportManagerService);
  private readonly cdr = inject(ChangeDetectorRef);
  private readonly ngZone = inject(NgZone);
  private readonly _destroySource: Subject<void> = new Subject<void>();
  public onPointAdd = false;
  public onPointDelete = false;

  @Input() addPointDisabled = false;
  @Input() removePointDisabled = false;
  @Input() undoDisabled = false;
  @Input() redoDisabled = false;
  @Input() saveDisabled = true;

  @Output() readonly cancelEmit: EventEmitter<void> = new EventEmitter<void>();
  @Output() readonly saveEmit: EventEmitter<Required<TAreaMesh>> =
    new EventEmitter<Required<TAreaMesh>>();

  get areaMode() {
    return this.matterportManager.areaMesh.selectedMeasurementMode;
  }
  get canUndo() {
    return this.matterportManager.areaMesh.canUndo;
  }
  get canRedo() {
    return this.matterportManager.areaMesh.canRedo;
  }

  readonly keyup = defer(() => {
    return this.matterportManager.events.fromMatterportEvent<KeyboardEvent>(
      'keyup'
    );
  });
  readonly keydown = defer(() => {
    return this.matterportManager.events.fromMatterportEvent<KeyboardEvent>(
      'keydown'
    );
  });

  readonly insertShortcut = defer(() => {
    return merge(
      this.keydown.pipe(
        filter((event: KeyboardEvent) => {
          return this.shortcut(event) === 'INSERT';
        })
      ),
      this.keyup
    ).pipe(
      startWith(undefined),
      distinctUntilChanged((prev, curr) => prev?.type === curr?.type),

      switchMap((e: KeyboardEvent | undefined) => {
        if (e?.type === 'keydown') {
          this.ngZone.run(() => {
            this.onPointAdd = true;
            this.cdr.markForCheck();
          });
          return this.addPoint$();
        } else {
          return of(this.cancelListener());
        }
      })
    );
  });
  readonly deleteShortcut = defer(() => {
    return merge(
      this.keydown.pipe(
        filter((event: KeyboardEvent) => {
          return this.shortcut(event) === 'DELETE';
        })
      ),
      this.keyup
    ).pipe(
      startWith(undefined),
      distinctUntilChanged((prev, curr) => prev?.type === curr?.type),

      switchMap((e: KeyboardEvent | undefined) => {
        if (e?.type === 'keydown') {
          this.ngZone.run(() => {
            this.onPointDelete = true;
            this.cdr.markForCheck();
          });
          return this.deletePoint$();
        } else {
          return of(this.cancelListener());
        }
      })
    );
  });
  readonly undoShortcut = defer(() => {
    return this.keydown.pipe(
      filter((event: KeyboardEvent) => {
        return this.shortcut(event) === 'CTRL+Z';
      })
    );
  });
  readonly redoShortcut = defer(() => {
    return this.keydown.pipe(
      filter((event: KeyboardEvent) => {
        return this.shortcut(event) === 'CTRL+Y';
      })
    );
  });

  ngOnInit(): void {
    this._keyListeners();
    this._areaChangeObserver();
  }
  private _areaChangeObserver() {
    this.ngZone.run(() => {
      this.matterportManager.areaMesh.selectedMeasurementChange$
        .pipe(takeUntil(this._destroySource))
        .subscribe((changes) => {
          this.saveDisabled =
            !changes ||
            (changes.changes.vertices && changes.changes.vertices.length < 3);

          if (changes?.source === 'created')
            this.saveEmit.emit(
              this._transformData(changes.changes) as Required<TAreaMesh>
            );
          this.cdr.markForCheck();
        });
    });
    this.ngZone.run(() => {
      this.matterportManager.areaMesh.selectedMeasurementMode$
        .pipe(takeUntil(this._destroySource))
        .subscribe((changes) => {
          this.cdr.markForCheck();
        });
    });
  }
  private _keyListeners() {
    return merge(
      this.insertShortcut,
      this.deleteShortcut,
      this.redoShortcut,
      this.undoShortcut
    )
      .pipe(takeUntil(this._destroySource))
      .subscribe();
  }
  ngOnDestroy(): void {
    this.matterportManager.areaMesh.cancelMeasurementCreation();
    this.matterportManager.areaMesh.removeListener();

    this._destroySource.next();
  }
  undo() {
    this.matterportManager.areaMesh.undo();
  }
  redo() {
    this.matterportManager.areaMesh.redo();
  }
  cancel() {
    this.matterportManager.areaMesh.cancelMeasurementCreation();
    this.matterportManager.areaMesh.removeListener();
    this.cancelEmit.emit();
  }
  save() {
    firstValueFrom(
      this.matterportManager.areaMesh.selectedMeasurementChange$.pipe(
        filter((changes) => !!changes && changes.source === 'created'),
        tap((changes) => {
          if (!changes) return;

          changes &&
            this.saveEmit.emit(
              this._transformData(changes.changes) as Required<TAreaMesh>
            );
        })
      )
    );
    this.matterportManager.areaMesh.finish();
  }
  private _transformData(changes: TAreaMesh) {
    const transform = this.matterportManager.transformConverter;
    const transformedPositions = changes.vertices.map(({ x, y, z, index }) => {
      const calcPosition = transform.to3dPosition(new Vector3(x, y, z));
      return {
        x: calcPosition.x,
        y: calcPosition.y,
        z: calcPosition.z,
        index
      };
    });
    return {
      ...changes,
      vertices: transformedPositions
    } as TAreaMesh;
  }
  addPoint() {
    this.onPointAdd = true;
    this.matterportManager.areaMesh.isTouchDevice() &&
      this.matterportManager.setHint(
        ADD_POINT_HINT,
        undefined,
        'var(--ui-theme-surface-primary)',
        'var(--ui-theme-text-primary)'
      ),
      setTimeout(
        () => this.matterportManager.setHint(undefined, undefined),
        2000
      );
    this.addPoint$().subscribe();
  }

  deletePoint() {
    this.onPointDelete = true;
    this.matterportManager.areaMesh.isTouchDevice() &&
      this.matterportManager.setHint(
        DELETE_POINT_HINT,
        undefined,
        'var(--ui-theme-surface-primary)',
        'var(--ui-theme-text-primary)'
      ),
      setTimeout(
        () => this.matterportManager.setHint(undefined, undefined),
        2000
      );
    this.deletePoint$().subscribe();
  }
  cancelListener() {
    this.matterportManager.areaMesh.removeListener();

    this.ngZone.run(() => {
      this.onPointAdd = false;
      this.onPointDelete = false;
      this.cdr.markForCheck();
    });
  }
  addPoint$ = () => {
    const listenerActionType: TMeasurementListenersAction =
      this.matterportManager.areaMesh.selectedMeasurementMode === 'create'
        ? 'CREATION_POINT'
        : 'ADD_POINT';
    return this.matterportManager.areaMesh.addListener(listenerActionType).pipe(
      first(),
      takeUntil(this.keyup),
      tap(() => {
        this.matterportManager.areaMesh.removeListener();
        this.ngZone.run(() => {
          this.onPointAdd = false;
          this.cdr.markForCheck();
        });
      })
    );
  };
  deletePoint$ = () => {
    return this.matterportManager.areaMesh.addListener('REMOVE_POINT').pipe(
      first(),
      takeUntil(this.keyup),
      tap(() => {
        this.matterportManager.areaMesh.removeListener();
        this.ngZone.run(() => {
          this.onPointDelete = false;
          this.cdr.markForCheck();
        });
      })
    );
  };
  readonly shortcut = (event: KeyboardEvent): Shortcuts =>
    `${event.ctrlKey ? 'CTRL+' : ''}${
      event.altKey ? 'ALT+' : ''
    }${event.key.toUpperCase()}` as Shortcuts;
}
