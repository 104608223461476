import { ChangeDetectionStrategy, Component, input } from '@angular/core';
import { FormGroup, ReactiveFormsModule } from '@angular/forms';
import { DesignLabel } from '@simlab/design/common';
import {
  DesignFormField,
  DesignFormFieldError
} from '@simlab/design/form-field';
import { DesignInput } from '@simlab/design/input';
import { CostImpactForm } from '@simlab/procore/shared/models';
import { ProcoreImpactLabelGetterPipe } from '@simlab/procore/pipes';
import { UiFormFieldModule } from '@simlab/ui/form-field';
import { UiSelectModule } from '@simlab/ui/select';
import { _ImpactFormBase } from '../_impact-form-base/impact-form.base';

@Component({
  selector: 'procore-cost-impact',
  standalone: true,
  imports: [
    ReactiveFormsModule,
    DesignInput,
    UiFormFieldModule,
    UiSelectModule,
    DesignFormField,
    ProcoreImpactLabelGetterPipe,
    DesignFormFieldError,
    DesignLabel
  ],
  templateUrl: './cost-impact.component.html',
  styleUrl: './cost-impact.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CostImpactComponent extends _ImpactFormBase {
  form = input.required<FormGroup<CostImpactForm>>();

  get impactControl() {
    return this.form().controls.costImpact;
  }

  get impactValueControl() {
    return this.form().controls.costImpactAmount;
  }
}
