import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';
import { Metadata } from '../../models/metadata';

@Component({
    selector: 'ui-paginator',
    exportAs: '[uiPaginator]',
    templateUrl: './paginator.component.html',
    styleUrls: ['./paginator.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class PaginatorComponent {
  @Output() pagingUpdated = new EventEmitter<{
    pageSize: number;
    pageNumber: number;
  }>();

  @Input() loading!: boolean;
  @Input() metadata!: Metadata;

  // @Input() hasNext!: boolean;
  // @Input() hasPrevious!: boolean;
  // @Input() currentPage!: number;
  // @Input() totalPages!: number;
  // @Input() pageSize!: number;
}
