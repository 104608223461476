import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { Media } from '@simlab/data-access';
import { NotesFacade } from '@simlab/data-store';
import { FormatDatePipe } from '@simlab/design/format-date';
import { UiMenuPanelModule } from '@simlab/design/menu-panel';
import { AudioComponent } from '@simlab/ui/audio';
import { Observable, defer, firstValueFrom, switchMap, tap } from 'rxjs';
import { BaseMedia } from '../../../directives/base-media.directive';

@Component({
    selector: 'feature-stages-note-media-audio-item',
    changeDetection: ChangeDetectionStrategy.OnPush,
    imports: [CommonModule, AudioComponent, UiMenuPanelModule, FormatDatePipe],
    templateUrl: './note-media-audio-item.component.html',
    styleUrls: ['./note-media-audio-item.component.scss']
})
export class NoteMediaAudioItemComponent extends BaseMedia {
  private readonly _notesFacade = inject(NotesFacade);
  readonly audio$: Observable<Media> = defer(() =>
    this.getSingleBlobSecureUrl(this.media)
  );

  deleteAudioNote(item: Media): void {
    firstValueFrom(
      this._notesFacade.selectedNoteId$.pipe(
        switchMap((selectedNoteId: string) => {
          return this.deleteMedia$(item, selectedNoteId);
        }),
        tap(() => this._notesFacade.removeDigitalNote(item.id, 'audio'))
      )
    );
  }
}
