import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  HostBinding,
  Renderer2,
  ViewEncapsulation,
} from '@angular/core';

@Component({
    selector: 'ui-tree',
    templateUrl: './tree.component.html',
    styleUrls: ['./tree.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    encapsulation: ViewEncapsulation.None,
    standalone: false
})
export class TreeComponent {
  @HostBinding('class.ui-tree') class = true;
  constructor(
    private readonly renderer: Renderer2,
    private readonly elementRef: ElementRef
  ) {}
}
