import { NgTemplateOutlet } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  computed,
  effect,
  inject,
  input
} from '@angular/core';
import { toObservable } from '@angular/core/rxjs-interop';
import { FormControl, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { DesignFlatButtonModule } from '@simlab/design/button';
import {
  CommentWithFiles,
  DesignCommentComponent
} from '@simlab/design/comment';
import { DesignFormFieldModule } from '@simlab/design/form-field';
import { DesignRadioButton, DesignRadioGroup } from '@simlab/design/radio';
import { PunchItemStateService } from '@simlab/procore/annotation-panel/services';
import {
  ProcoreUser,
  PunchItem,
  PunchItemAssignee,
  PunchItemAssignmentStatus,
  PunchItemDisplayElements,
  PunchListWorkflowStatus
} from '@simlab/procore/models';
import { UiFormFieldModule } from '@simlab/ui/form-field';
import { UiSelectModule } from '@simlab/ui/select';
import { tapOnce } from '@simlab/util-shared';
import { filter } from 'rxjs';
import {
  PunchItemAction,
  PunchItemActions
} from './punch-item-send-item.types';

type ActionTranslation = {
  [key in PunchItemActions]: string;
};

const ACTION_TRANSLATIONS: (personName?: string) => ActionTranslation = (
  personName
) => ({
  sendToAssignees: $localize`:@@SEND_TO_ASSIGNEES:Send to Assignee(s)`,
  sendToAssigneesAsWorkRequired: $localize`:@@SEND_TO_ASSIGNEES_AS_WORK_REQUIRED:Send to Assignee(s) as Work Required`,
  changeToInDispute: $localize`:@@RETURN_TO_AS_IN_DISPUTE:Return to ${personName} as In Dispute`,
  sendToPunchItemManager: $localize`:@@SEND_TO_PUNCH_ITEM_MANAGER:Send to Punch Item Manager`,
  changeToReadyForReview: $localize`:@@READY_TO_REVIEW:Ready To Review`,
  returnToManagerAsInitiated: $localize`:@@RETURN_TO_MANAGER_AS_INITIATED:Return to ${personName} as Initiated`,
  returnToAssigneesAsWorkNotAccepted: $localize`:@@RETURN_TO_ASSIGNEES_AS_WORK_NOT_ACCEPTED:Return to assignee(s) as Work Not Accepted`,
  returnToApproverAsReadyToClose: $localize`:@@RETURN_TO_APPROVER_AS_READY_TO_CLOSE:Return to ${personName} as Ready To Close`,
  setWorkNotAccepted: $localize`:@@WORK_NOT_ACCEPTED:Work Not Accepted`,
  setAllWorkComplete: $localize`:@@ALL_WORK_COMPLETE:All Work Complete`,
  returnToManagerAsNotAccepted: $localize`:@@RETURN_TO_MANAGER_AS_NOT_ACCEPTED:Return to ${personName} as Not Accepted`,
  closePunchItem: $localize`:@@CLOSE_PUNCH_ITEM:Close Punch Item`,
  submit: $localize`:@@SUBMIT:Submit`
});

@Component({
  selector: 'procore-punch-item-send-item',
  standalone: true,
  imports: [
    DesignCommentComponent,
    ReactiveFormsModule,
    DesignFormFieldModule,
    UiSelectModule,
    UiFormFieldModule,
    DesignRadioButton,
    DesignRadioGroup,
    NgTemplateOutlet,
    DesignFlatButtonModule
  ],
  templateUrl: './punch-item-send-item.component.html',
  styleUrl: './punch-item-send-item.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PunchItemSendItemComponent {
  private readonly _state = inject(PunchItemStateService);
  private readonly _sendItemUpdateStatus = this._state.sendItemUpdateStatus;
  readonly punchItem = input.required<PunchItem>();
  readonly displayElements = input.required<PunchItemDisplayElements>();
  readonly assigneeOptions = input<PunchItemAssignee[]>();

  protected readonly workflowStatus = computed(() => {
    const { workflowStatus } = this.punchItem();
    return workflowStatus;
  });

  protected readonly isPersonSelectVisible = computed(() => {
    const { showChooseAssignees } = this.displayElements();
    return showChooseAssignees;
  });

  protected readonly actionButton = computed(() => {
    const workflowStatus = this.workflowStatus();
    const displayElements = this.displayElements();
    return this._setActionButton(workflowStatus, displayElements);
  });

  protected readonly radioOptions = computed(() => {
    const workflowStatus = this.workflowStatus();
    const displayElements = this.displayElements();
    const { punchItemManager, finalApprover } = this.punchItem();

    return this._setRadioButtonOptions(
      workflowStatus,
      displayElements,
      punchItemManager,
      finalApprover
    );
  });

  protected readonly submit$ = toObservable(this._sendItemUpdateStatus).pipe(
    tapOnce(() => {
      this.submit();
    }),
    filter((status) => status !== 'IDLE' && status !== 'PENDING')
  );

  protected readonly form = new FormGroup({
    commentWithFiles: new FormControl<CommentWithFiles>(
      {
        comment: '',
        files: []
      },
      { nonNullable: true }
    ),
    assigneeIds: new FormControl<number[]>([], { nonNullable: true }),
    action: new FormControl<PunchItemAction | null>(null)
  });

  get action() {
    return this.form.controls.action.value;
  }

  get assigneeIds() {
    return this.form.controls.assigneeIds.value;
  }

  get comment() {
    return this.form.controls.commentWithFiles.value.comment;
  }

  get files() {
    return this.form.controls.commentWithFiles.value.files || [];
  }

  constructor() {
    effect(() => {
      const radioOptions = this.radioOptions();
      const firstOption = radioOptions[0];
      if (firstOption) {
        this.form.controls.action.setValue(firstOption);
      }
    });
  }

  protected submit() {
    const punchItemAction = this.action || this.actionButton();

    switch (punchItemAction.actionType) {
      case 'sendToAssignees':
      case 'sendToAssigneesAsWorkRequired':
        this._sendToAssignees(punchItemAction.setWorkflowStatusTo);
        break;

      case 'closePunchItem':
      case 'sendToPunchItemManager':
      case 'returnToManagerAsNotAccepted':
      case 'changeToInDispute':
      case 'returnToManagerAsInitiated':
      case 'returnToApproverAsReadyToClose':
        this._updateWorkflowStatus(punchItemAction.setWorkflowStatusTo);
        break;

      case 'setAllWorkComplete':
      case 'setWorkNotAccepted':
      case 'returnToAssigneesAsWorkNotAccepted':
        this._updateAllAssignments(punchItemAction.assignmentStatus);
        break;

      case 'changeToReadyForReview':
        this._setAsReadyToReview(punchItemAction.assignmentStatus);
        break;
    }
  }

  private _setActionButton(
    workflowStatus: PunchListWorkflowStatus,
    displayElements: PunchItemDisplayElements
  ): PunchItemAction {
    const buttonWithoutAssignedAction: PunchItemAction = {
      label: ACTION_TRANSLATIONS().submit,
      actionType: 'submit'
    };

    switch (workflowStatus) {
      case PunchListWorkflowStatus.Initiated:
        // Send to Assignee(s) or Submit
        return displayElements.showSendToAssignees
          ? {
              label: ACTION_TRANSLATIONS().sendToAssignees,
              actionType: 'sendToAssignees',
              setWorkflowStatusTo: PunchListWorkflowStatus.WorkRequired
            }
          : buttonWithoutAssignedAction;

      case PunchListWorkflowStatus.Draft:
        return {
          label: ACTION_TRANSLATIONS().sendToPunchItemManager,
          actionType: 'sendToPunchItemManager',
          setWorkflowStatusTo: PunchListWorkflowStatus.Initiated
        };

      case PunchListWorkflowStatus.WorkRequired:
      case PunchListWorkflowStatus.WorkNotAccepted:
        return {
          label: ACTION_TRANSLATIONS().changeToReadyForReview,
          actionType: 'changeToReadyForReview',
          assignmentStatus: PunchListWorkflowStatus.Initiated
        };

      case PunchListWorkflowStatus.ReadyToClose:
        // Close Punch Item or Submit
        return displayElements.showReturnToManagerAsNotAccepted
          ? buttonWithoutAssignedAction
          : {
              label: ACTION_TRANSLATIONS().closePunchItem,
              actionType: 'closePunchItem',
              setWorkflowStatusTo: PunchListWorkflowStatus.Closed
            };

      default:
        // PunchListWorkflowStatus.InDispute:
        // PunchListWorkflowStatus.ReadyForReview:
        // PunchListWorkflowStatus.NotAcceptedByCreator:
        return buttonWithoutAssignedAction;
    }
  }

  private _setRadioButtonOptions(
    workflowStatus: PunchListWorkflowStatus,
    displayElements: PunchItemDisplayElements,
    punchItemManager: ProcoreUser,
    finalApprover: ProcoreUser
  ): PunchItemAction[] {
    switch (workflowStatus) {
      case PunchListWorkflowStatus.Initiated:
        return displayElements.showChangeToInDispute
          ? [
              {
                label: ACTION_TRANSLATIONS().sendToAssigneesAsWorkRequired,
                actionType: 'sendToAssigneesAsWorkRequired',
                setWorkflowStatusTo: PunchListWorkflowStatus.WorkRequired
              },
              {
                label: ACTION_TRANSLATIONS(punchItemManager.name)
                  .changeToInDispute,
                actionType: 'changeToInDispute',
                setWorkflowStatusTo: PunchListWorkflowStatus.InDispute
              }
            ]
          : [];

      case PunchListWorkflowStatus.InDispute:
        return [
          {
            label: ACTION_TRANSLATIONS(punchItemManager.name)
              .returnToManagerAsInitiated,
            actionType: 'returnToManagerAsInitiated',
            setWorkflowStatusTo: PunchListWorkflowStatus.Initiated
          },
          {
            label: ACTION_TRANSLATIONS().closePunchItem,
            actionType: 'closePunchItem',
            setWorkflowStatusTo: PunchListWorkflowStatus.Closed
          }
        ];

      case PunchListWorkflowStatus.NotAcceptedByCreator:
        return [
          {
            label: ACTION_TRANSLATIONS().returnToAssigneesAsWorkNotAccepted,
            actionType: 'returnToAssigneesAsWorkNotAccepted',
            assignmentStatus: PunchItemAssignmentStatus.WorkNotAccepted
          },
          {
            label: ACTION_TRANSLATIONS(finalApprover.name)
              .returnToApproverAsReadyToClose,
            actionType: 'returnToApproverAsReadyToClose',
            setWorkflowStatusTo: PunchListWorkflowStatus.ReadyToClose
          }
        ];

      case PunchListWorkflowStatus.ReadyForReview:
        return [
          {
            label: ACTION_TRANSLATIONS().setAllWorkComplete,
            actionType: 'setAllWorkComplete',
            assignmentStatus: PunchItemAssignmentStatus.Resolved
          },
          {
            label: ACTION_TRANSLATIONS().setWorkNotAccepted,
            actionType: 'setWorkNotAccepted',
            assignmentStatus: PunchItemAssignmentStatus.WorkNotAccepted
          }
        ];

      case PunchListWorkflowStatus.ReadyToClose:
        return displayElements.showReturnToManagerAsNotAccepted
          ? [
              {
                label: ACTION_TRANSLATIONS().closePunchItem,
                actionType: 'closePunchItem',
                setWorkflowStatusTo: PunchListWorkflowStatus.Closed
              },
              {
                label: ACTION_TRANSLATIONS(punchItemManager.name)
                  .returnToManagerAsNotAccepted,
                actionType: 'returnToManagerAsNotAccepted',
                setWorkflowStatusTo:
                  PunchListWorkflowStatus.NotAcceptedByCreator
              }
            ]
          : [];

      default:
        return [];
    }
  }

  private _getTranslationForAction = (
    action: PunchItemActions,
    personName?: string
  ) => {
    switch (action) {
      case 'sendToAssignees':
        return $localize`:@@SEND_TO_ASSIGNEES:Send to Assignee(s)`;

      case 'sendToAssigneesAsWorkRequired':
        return $localize`:@@SEND_TO_ASSIGNEES_AS_WORK_REQUIRED:Send to Assignee(s) as Work Required`;

      case 'changeToInDispute':
        return $localize`:@@RETURN_TO_AS_IN_DISPUTE:Return to ${personName} as In Dispute`;

      case 'sendToPunchItemManager':
        return $localize`:@@SEND_TO_PUNCH_ITEM_MANAGER:Send to Punch Item Manager`;

      case 'changeToReadyForReview':
        return $localize`:@@READY_TO_REVIEW:Ready To Review`;

      case 'returnToManagerAsInitiated':
        return $localize`:@@RETURN_TO_MANAGER_AS_INITIATED:Return to ${personName} as Initiated`;

      case 'returnToAssigneesAsWorkNotAccepted':
        return $localize`:@@RETURN_TO_ASSIGNEES_AS_WORK_NOT_ACCEPTED:Return to assignee(s) as Work Not Accepted`;

      case 'returnToApproverAsReadyToClose':
        return $localize`:@@RETURN_TO_APPROVER_AS_READY_TO_CLOSE:Return to ${personName} as Ready To Close`;

      case 'setWorkNotAccepted':
        return $localize`:@@WORK_NOT_ACCEPTED:Work Not Accepted`;

      case 'setAllWorkComplete':
        return $localize`:@@ALL_WORK_COMPLETE:All Work Complete`;

      case 'returnToManagerAsNotAccepted':
        return $localize`:@@RETURN_TO_MANAGER_AS_NOT_ACCEPTED:Return to ${personName} as Not Accepted`;

      case 'closePunchItem':
        return $localize`:@@CLOSE_PUNCH_ITEM:Close Punch Item`;

      case 'submit':
        return $localize`:@@SUBMIT:Submit`;
    }
  };

  private _sendToAssignees(workflowStatus: PunchListWorkflowStatus) {
    this._state.sendToAssignees({
      workflowStatus,
      formFiles: this.files,
      comment: this.comment,
      procoreUserIds: this.assigneeIds
    });
  }

  private _updateWorkflowStatus(workflowStatus: PunchListWorkflowStatus) {
    this._state.updatePunchItemWorkflowStatus({
      workflowStatus,
      formFiles: this.files,
      comment: this.comment
    });
  }

  private _updateAllAssignments(assignmentStatus: PunchItemAssignmentStatus) {
    const punchItemAssignmentIds = this.punchItem().assignments.map(
      (assignment) => assignment.id
    );
    this._state.updatePunchItemAssignmentsStatus({
      status: assignmentStatus,
      punchItemAssignmentIds,
      comment: this.comment,
      formFiles: this.files
    });
  }

  private _setAsReadyToReview(assignmentStatus: PunchItemAssignmentStatus) {
    this._state.setAsReadyForReview({
      status: assignmentStatus,
      comment: this.comment,
      files: this.files,
      approved: false
    });
  }
}
