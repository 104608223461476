import { NgClass } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  inject,
  input,
  output,
  TemplateRef,
  viewChild,
  ViewEncapsulation
} from '@angular/core';
import { rxMethod } from '@ngrx/signals/rxjs-interop';
import {
  ProcoreConnectionStatusEnum,
  ProcoreProjectInfo
} from '@simlab/data-access';
import {
  DesignFlatButtonModule,
  DesignIconButton
} from '@simlab/design/button';
import { DesignCheckbox } from '@simlab/design/checkbox';
import { DesignIcon } from '@simlab/design/icon';
import { UiMenuPanelModule } from '@simlab/design/menu-panel';
import { StatusBadgeComponent } from '@simlab/design/status-badge';
import { UiEventElementWithoutProgressComponent } from '@simlab/event-queue';
import { UiIconModule } from '@simlab/ui/icon';
import { of, pipe, tap } from 'rxjs';
import {
  ConnectProjectInfoRootService,
  connectProjectInfoTranslations,
  DisconnectProcoreProjectDataParams,
  ReconnectProcoreProjectParams,
  RedistributeProcoreProjectDataParams
} from './connect-project-info-root/connect-project-info-root.service';

const statusLabel: Record<
  Exclude<
    ProcoreConnectionStatusEnum,
    typeof ProcoreConnectionStatusEnum.NotConnected
  >,
  string
> = {
  [ProcoreConnectionStatusEnum.Connected]: $localize`:@@CONNECTED:Connected`,
  [ProcoreConnectionStatusEnum.Disconnected]: $localize`:@@DISCONNECTED:Disconnected`,
  [ProcoreConnectionStatusEnum.Disconnecting]: `${$localize`:@@DISCONNECTING:Disconnecting`}...`,
  [ProcoreConnectionStatusEnum.Reconnecting]: `${$localize`:@@RECONNECTING:Reconnecting`}...`
};

@Component({
  selector: 'procore-connect-project-info',
  imports: [
    NgClass,
    UiIconModule,
    DesignFlatButtonModule,
    DesignIconButton,
    DesignIcon,
    UiMenuPanelModule,
    DesignCheckbox,
    UiEventElementWithoutProgressComponent,
    StatusBadgeComponent
  ],
  template: `
    @if (connectedStatus(); as connectedStatus) {
      @if (connectedStatus !== connectionStatusEnum.NotConnected) {
        <div class="icon-wrapper">
          <i
            class="icon-wrapper__full-logo"
            ui-icon
            name="icon_procore_logo"
            size="img"
            defaultColor
          ></i>
          <i
            class="icon-wrapper__icon-logo"
            ui-icon
            name="icon_procore"
            size="img"
            defaultColor
          ></i>
        </div>
        <div class="connected-section">
          <design-status-badge
            class="connected-section__status"
            [ngClass]="{
              'connected-section__status--connected':
                connectedStatus === connectionStatusEnum.Connected,
              'connected-section__status--disconnected':
                connectedStatus === connectionStatusEnum.Disconnected,
              'connected-section__status--processing':
                connectedStatus === connectionStatusEnum.Disconnecting ||
                connectedStatus === connectionStatusEnum.Reconnecting
            }"
          >
            {{ statusLabel[connectedStatus] }}
          </design-status-badge>
          <button
            class="connected-section__actions"
            design-icon-button
            data-cy="stage-options-button"
            type="button"
            [disabled]="
              connectedStatus === connectionStatusEnum.Disconnecting ||
              connectedStatus === connectionStatusEnum.Reconnecting
            "
            [designMenuTriggerFor]="
              connectedStatus === connectionStatusEnum.Connected
                ? menuForConnectedStatus
                : menuForDisconnectedStatus
            "
          >
            <i designIcon="more_second" designSize="1.5rem"></i>
          </button>
        </div>

        <design-menu-panel #menuForConnectedStatus>
          <button
            design-menu-item
            type="button"
            (click)="onDetailsProject.emit()"
          >
            <div i18n="@@DETAILS">Details</div>
          </button>
          <button
            design-menu-item
            [disabled]="!permissionToManage()"
            type="button"
            (click)="redistributeData()"
            i18n="@@ARRANGE_DATA_BY_DATE"
          >
            Arrange data by date
          </button>
          <button
            design-menu-item
            type="button"
            [disabled]="!hasAdminInStageProject()"
            (click)="onUnassignProject()"
          >
            <ng-container i18n="@@DISCONNECT">Disconnect</ng-container>
          </button>
        </design-menu-panel>

        <design-menu-panel #menuForDisconnectedStatus>
          <button
            design-menu-item
            type="button"
            (click)="reconnect()"
            i18n="@@RECONNECT"
          >
            Reconnect
          </button>
          <button
            design-menu-item
            type="button"
            i18n="@@CONNECT_TO_ANOTHER_PROJECT"
            (click)="connectToAnotherProject()"
          >
            Connect to another project
          </button>
        </design-menu-panel>
      } @else {
        @if (permissionToManage()) {
          <div class="icon-wrapper-connect">
            <i ui-icon name="icon_procore_logo" size="img" defaultColor></i>
          </div>
          <button
            class="connect-btn"
            design-flat-button
            designButtonColor="accent"
            type="button"
            (click)="onConnectClick.emit()"
          >
            <div class="button" i18n="@@CONNECT_PROJECTS">Connect projects</div>
          </button>
        }
      }
    }

    <ng-template #redistributeDataForm>
      <design-checkbox
        #checkboxRef
        (change)="includeManuallyAssigned = checkboxRef.checked"
      >
        <p
          class="checkbox-label"
          i18n="@@IGNORE_ALREADY_DATA_TRANSFERRED_MANUALLY"
        >
          Ignore already data transferred manually
        </p>
      </design-checkbox>
    </ng-template>

    <ng-template #redistributeTask let-context="context" let-last="last">
      <event-ui-event-element-without-progress
        [title]="context.title"
        [status]="context.status.workStatus"
        [last]="last"
        (cancel)="context.cancel?.()"
      />
    </ng-template>

    <ng-template #disconnectProjectModalContent>
      <div class="disconnect-message">
        <p class="disconnect-message__text">
          {{ connectProjectInfoTranslations['disconnectProjectMessage'] }}
        </p>
        <p class="disconnect-message__text">
          {{ connectProjectInfoTranslations['disconnectProjectMessage2'] }}
        </p>
      </div>
    </ng-template>
  `,
  styleUrl: './connect-project-info.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  providers: [ConnectProjectInfoRootService],
  host: {
    class: 'procore-connect-project-info'
  }
})
export class ConnectProjectInfoComponent {
  private readonly _root = inject(ConnectProjectInfoRootService);
  private readonly _redistributeDataForm = viewChild.required<TemplateRef<any>>(
    'redistributeDataForm'
  );
  private readonly _redistributeTask =
    viewChild.required<TemplateRef<any>>('redistributeTask');
  private readonly _disconnectProjectModalContent = viewChild.required<
    TemplateRef<any>
  >('disconnectProjectModalContent');

  protected readonly statusLabel = statusLabel;
  protected readonly connectProjectInfoTranslations =
    connectProjectInfoTranslations;
  protected readonly connectionStatusEnum = ProcoreConnectionStatusEnum;
  protected includeManuallyAssigned = false;

  readonly connectionInfo = input<ProcoreProjectInfo | undefined>();
  readonly connectedStatus = input<ProcoreConnectionStatusEnum>();
  readonly permissionToManage = input.required<boolean>();
  readonly hasAdminInStageProject = input.required<boolean>();
  readonly projectId = input.required<string>();

  readonly onConnectClick = output<void>();
  readonly onDetailsProject = output<void>();
  readonly onRedistributeData = output<void>();

  protected readonly connectToAnotherProject = rxMethod<void>(
    pipe(
      this._root.connectToAnotherProjectAction$,
      tap(() => this.onConnectClick.emit())
    )
  );

  protected reconnect(): void {
    const connectionInfo = this.connectionInfo();
    if (connectionInfo === undefined) return;

    const params: ReconnectProcoreProjectParams = {
      projectId: this.projectId(),
      procoreCompanyId: connectionInfo.companyId,
      procoreProjectId: connectionInfo.projectId
    };

    of(params).pipe(this._root.reconnectProjectAction$).subscribe();
  }

  protected redistributeData(): void {
    const params: RedistributeProcoreProjectDataParams = {
      projectId: this.projectId(),
      redistributeDataForm: this._redistributeDataForm(),
      includeManuallyAssignedFn: () => this.includeManuallyAssigned,
      redistributeTaskTemplate: this._redistributeTask(),
      proceduresAfterSuccessfulRedistribute: () =>
        this.onRedistributeData.emit()
    };

    of(params).pipe(this._root.redistributeDataAction$).subscribe();
  }

  protected onUnassignProject(): void {
    const params: DisconnectProcoreProjectDataParams = {
      projectId: this.projectId(),
      contentTemplate: this._disconnectProjectModalContent()
    };

    of(params).pipe(this._root.unassignProjectAction$).subscribe();
  }
}
