import { coerceArray, coerceCssPixelValue } from '@angular/cdk/coercion';
import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  input,
  Input,
  NgModule,
  Renderer2,
  ViewEncapsulation
} from '@angular/core';
import { DesignFlatButtonModule } from '@simlab/design/button';
import { DesignIcon } from '@simlab/design/icon';
import { DesignSymbolInput } from '@simlab/design/internal';
import { Observable } from 'rxjs';
import { ICON_TYPE } from '../../../ui-icon/models/icon-type';
import { UiIconModule } from '../../../ui-icon/ui-icon.module';

export type InfoButton = {
  title: string;
  icon?: DesignSymbolInput;
  action$: Observable<unknown>;
};
@Component({
    selector: 'ui-image-info',
    templateUrl: './image-info.component.html',
    styleUrls: ['./image-info.component.scss'],
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
    imports: [CommonModule, UiIconModule, DesignFlatButtonModule, DesignIcon],
    // eslint-disable-next-line @angular-eslint/no-host-metadata-property
    host: {
        class: 'ui-image-info'
    }
})
export class ImageInfoComponent {
  readonly button = input<InfoButton>();
  @Input() imageSrc!: ICON_TYPE;
  @Input() header!: string;
  @Input() //description!: string;
  set descriptions(value: Array<string>) {
    this._descriptions = coerceArray(value);
  }
  get descriptions(): string[] {
    return this._descriptions;
  }
  private _descriptions!: string[];

  @Input() set maxWidth(value: number | string) {
    this.renderer.setStyle(
      this.elementRef.nativeElement,
      '--imageWidth',
      coerceCssPixelValue(value),
      2
    );
  }

  constructor(
    private readonly elementRef: ElementRef,
    private readonly renderer: Renderer2
  ) {}
}

@NgModule({
  imports: [ImageInfoComponent],
  exports: [ImageInfoComponent]
})
export class ImageInfoModule {}
