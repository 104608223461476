import { AsyncPipe } from '@angular/common';
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Inject,
  NgZone,
  OnDestroy,
  Signal,
  ViewChild,
  computed,
  inject
} from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import {
  ActivatedRoute,
  ActivatedRouteSnapshot,
  Router
} from '@angular/router';
import { SystemOfMeasurement } from '@simlab/data-access';
import {
  ComponentsFacade,
  NotesFacade,
  StagesFacade,
  UserPreferenceFacade
} from '@simlab/data-store';

import {
  MatterportAnnotationControlService,
  MatterportNoteControlService
} from '@simlab/annotation/data-access';
import { TagPlacementComponent } from '@simlab/annotation/ui';
import { breakpointsObserver } from '@simlab/design/layout';
import {
  IVector3,
  TAreaMeasurement,
  TDistance,
  TypeMeasurement
} from '@simlab/feature-measurement/models';
import { AreaFacade, DistanceFacade } from '@simlab/feature-measurement/state';
import {
  AreaComponent,
  DistanceComponent
} from '@simlab/feature-measurement/ui';
import {
  DistanceMeasurementToolComponent,
  MATTERPORT_TOKEN,
  MatterportAreaMeasurementDirective,
  MatterportBaseService,
  MatterportComponent,
  MatterportLineMeasurementDirective,
  MatterportOauthService,
  MeasurementToolComponent,
  injectMatterport,
  matterport3DWalkControlToken
} from '@simlab/feature/matterport';
import {
  CanComponentDeactivate,
  PanelRightService,
  ScreenshotDialogComponent,
  StageMatterportService,
  StagesRootService
} from '@simlab/feature/stages';
import {
  MatterportManagerService,
  MatterportSdkBundleModule,
  MatterportService
} from '@simlab/matterport';
import { Units } from '@simlab/matterport/api';
import { TMeasurementMesh } from '@simlab/simlab-facility-management/sub-features/line-measurement';
import { TAreaMesh } from '@simlab/simlab-facility-management/sub-features/measurement';
import { ICON_TYPE } from '@simlab/ui/icon';
import { InfoButton, UiImageInfoModule } from '@simlab/ui/image-info';
import { UiMatterportLoadingModule } from '@simlab/ui/matterport-loading';
import { ModalService } from '@simlab/ui/modal';
import { UiProgressSpinnerComponent } from '@simlab/ui/progress-spinner';
import { RouterFacadeService, UNITY_PLATFORM_URL } from '@simlab/util-shared';
import { derivedAsync } from 'ngxtension/derived-async';
import {
  Observable,
  Subject,
  catchError,
  combineLatest,
  defer,
  distinctUntilChanged,
  filter,
  first,
  firstValueFrom,
  iif,
  map,
  merge,
  mergeMap,
  of,
  switchMap,
  take,
  takeUntil,
  tap
} from 'rxjs';
import { Vector3 } from 'three';
import {
  StagesRootComponent,
  TSidenavContent
} from '../stages-root/stages-root.component';

const DESCRIPTION_IMAGE_INFO: string[] = [
  $localize`:@@NO_STAGES_IN_PROJECT:There aren't any stages in this project yet.`,
  $localize`:@@ADD_STAGE_TO_START:Add stage to start.`
];

const DESCRIPTION_IMAGE_INFO_NO_STAGES = [
  $localize`:@@NO_STAGES_IN_PROJECT:There aren't any stages in this project yet.`,
  $localize`:@@ADD_STAGE_TO_START:Add stage to start.`
];

const DESCRIPTION_IMAGE_NO_COMPONENTS: string[] = [
  $localize`:@@THERE_ARE_NO_COMPONENTS:There are no components yet.`,
  $localize`:@@TAP_TO_ADD_COMPONENT:Tap +Add component to add one.`
];

const DESCRIPTION_IMAGE_INFO_MATTERPORT_NOT_SYNCHRO = [
  $localize`:@@PROJ_DASH_MATTER_NOT_SYNCHRO_1:The component of the selected stage is not synchronized.`,
  $localize`:@@PROJ_DASH_MATTER_NOT_SYNCHRO_2:To display the component you must first synchronize it.`
];

@Component({
  templateUrl: './matterport.component.html',
  styleUrls: ['./matterport.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    AsyncPipe,
    UiImageInfoModule,
    TagPlacementComponent,
    MatterportComponent,
    MatterportAreaMeasurementDirective,
    MatterportLineMeasurementDirective,
    MeasurementToolComponent,
    DistanceMeasurementToolComponent,
    MatterportSdkBundleModule,
    UiMatterportLoadingModule,
    UiProgressSpinnerComponent
  ],
  providers: [
    StageMatterportService,
    MatterportBaseService,
    MatterportManagerService,
    MatterportOauthService,
    {
      provide: MATTERPORT_TOKEN,
      useFactory: injectMatterport,
      deps: [MatterportManagerService]
    }
  ]
})
export class MatterportPageComponent
  implements CanComponentDeactivate, AfterViewInit, OnDestroy
{
  private readonly _matterportAnnotationControl = inject(
    MatterportAnnotationControlService
  );
  private readonly _unityPlatform = inject(UNITY_PLATFORM_URL);
  readonly descriptionImageInfo = DESCRIPTION_IMAGE_INFO;
  readonly router = inject(Router);
  readonly cdr = inject(ChangeDetectorRef);
  readonly ngZone = inject(NgZone);

  readonly userPreferences = inject(UserPreferenceFacade);
  readonly areaMeasurementComponent$ = (
    inject(matterport3DWalkControlToken) as StagesRootComponent
  )
    .rightPanelContent$<AreaComponent>()
    .pipe(filter((component) => component instanceof AreaComponent));

  readonly distanceMeasurementComponent$ = (
    inject(matterport3DWalkControlToken) as StagesRootComponent
  )
    .rightPanelContent$<DistanceComponent>()
    .pipe(filter((component) => component instanceof DistanceComponent));

  private readonly _areaMeasurementFacade = inject(AreaFacade);
  private readonly _routerFacade = inject(RouterFacadeService);

  private readonly _breakpointsObserverService = inject(breakpointsObserver);

  readonly mobile = this._breakpointsObserverService.phone;
  private readonly _distanceMeasurementFacade = inject(DistanceFacade);

  private readonly _selectedStages = toSignal(
    this._stagesFacade.selectedStages$
  );
  private readonly _componentsLength = derivedAsync(
    () => {
      const selectedStages = this._selectedStages();
      if (selectedStages) {
        return this._componentsFacade
          .matterportComponentsForStage$(selectedStages.id)
          .pipe(map((components) => components.length));
      }
      return 0;
    },
    { requireSync: true }
  );
  private readonly _currentComponentSynchronized = toSignal(
    this._componentsFacade.selectedComponent$.pipe(
      map((e) => e?.isSynchronizingAccepted)
    )
  );

  readonly sidenavContent = toSignal(
    this._routerFacade
      .getNthRouterParam$(0)
      .pipe(
        map(
          (route: ActivatedRouteSnapshot | null) =>
            route?.queryParams['sidenavContent'] as TSidenavContent
        )
      )
  );

  private readonly _destroySource: Subject<void> = new Subject<void>();
  private _smallScreen = false;

  readonly areaMeasurement: Signal<TAreaMeasurement[]> = toSignal<
    TAreaMeasurement[]
  >(this._areaMeasurementFacade.allMeasurement$) as Signal<TAreaMeasurement[]>;

  readonly distanceMeasurement: Signal<TDistance[]> = toSignal<TDistance[]>(
    this._distanceMeasurementFacade.allMeasurement$
  ) as Signal<TDistance[]>;

  @ViewChild('simlabMatterportAreaMeasurement', {
    read: MatterportAreaMeasurementDirective
  })
  matterportAreaMeasurement!: MatterportAreaMeasurementDirective;
  @ViewChild('simlabMatterportLineMeasurement', {
    read: MatterportLineMeasurementDirective
  })
  matterportLineMeasurement!: MatterportLineMeasurementDirective;

  @ViewChild('simlabMatterport', { read: MatterportComponent, static: true })
  private _matterport!: MatterportComponent;

  readonly userPreferencesUnit$: Observable<Units> = defer(() =>
    this.userPreferences.getUserPreference$.pipe(
      map((userPreferences) => {
        if (!userPreferences) return Units.Metric;
        const unit = SystemOfMeasurement[
          userPreferences.systemOfMeasurement
        ] as keyof typeof Units;
        return Units[unit];
      })
    )
  );
  readonly segmentsVisibility$ =
    this._areaMeasurementFacade.showMeasurementsSegments$;

  readonly segmentsLineVisibility$ =
    this._distanceMeasurementFacade.showMeasurementsSegments$;

  readonly matterportIsOpen$ = defer(() =>
    this._matterport.matterportIsOpen$.pipe(filter((e) => e))
  );

  readonly localizationPanelOpen$: Observable<boolean> =
    this._matterportAnnotationControl.isAnnotationMarkerInActiveMode$;

  readonly screenshotMode$: Observable<boolean> =
    this.stagesRootService.screenshot$;

  readonly elementLoaded = toSignal(
    this._stagesFacade.stagesLoaded$.pipe(
      switchMap((stagesLoaded) =>
        this._componentsFacade.loaded$.pipe(
          map((componentLoaded) => {
            return stagesLoaded && componentLoaded;
          })
        )
      )
    )
  );

  readonly stageWarningInfo: Signal<
    | {
        img: ICON_TYPE;
        header: string;
        descriptions: string[];
        maxWidth?: number;
        phoneWidth?: number;
        button?: InfoButton;
      }
    | undefined
  > = computed(() => {
    const stages = this._selectedStages();
    const componentsMatterportLength = this._componentsLength();
    const currentComponentSynchronized = this._currentComponentSynchronized();
    const elementLoaded = this.elementLoaded();

    if (!elementLoaded) return undefined;

    if (!stages)
      return {
        img: 'icon_no_stages',
        phoneWidth: 125,
        header: $localize`:@@NO_STAGES:No Stages`,
        descriptions: DESCRIPTION_IMAGE_INFO_NO_STAGES
      };

    if (!stages.hasComponents) {
      return {
        img: 'icon_add_component',
        header: $localize`:@@ADD_COMPONENT:Add component`,
        descriptions: DESCRIPTION_IMAGE_NO_COMPONENTS,
        phoneWidth: 150,
        button: {
          title: $localize`:@@ADD_COMPONENT:Add component`,
          icon: 'Add',
          action$: of(true).pipe(
            tap(() => {
              this.router.navigate(['../stages/info'], {
                queryParamsHandling: 'preserve',
                relativeTo: this._activatedRoute
              });
            })
          )
        } as InfoButton
      };
    }

    if (stages.hasComponents && componentsMatterportLength === 0) {
      return {
        img: 'icon_desktop_app',
        header: $localize`:@@FULL_EXPERIENCE_WITH_UNITY:Get the Full Experience\n with the Desktop App`,
        descriptions: [
          $localize`:@@TO_VIEW_3D_MODELS_UNITY:To view 3D models, BIM, and point clouds, and access more powerful tools, download the Stages desktop app.`
        ],
        maxWidth: 600,
        phoneWidth: 300,
        button: this._unityPlatform
          ? {
              title: $localize`:@@DOWNLOAD_DESKTOP_APP:Download desktop app`,
              icon: 'Download',
              action$: of(true).pipe(
                tap(() => {
                  if (this._unityPlatform)
                    window.location.href = this._unityPlatform;
                })
              )
            }
          : undefined
      };
    }

    if (!currentComponentSynchronized) {
      return {
        img: 'icon_matterport_not_found',
        header: $localize`:@@PROJ_DASH_MATTER_NOT_SYNCHRO:Matterport not synchronized`,
        descriptions: DESCRIPTION_IMAGE_INFO_MATTERPORT_NOT_SYNCHRO
      };
    }

    return undefined;
  });

  readonly initialPosition = this.stagesRootService.lastKnownCameraPose;
  readonly initialFloor = this.stagesRootService.lastKnownFloor;
  constructor(
    @Inject(MATTERPORT_TOKEN)
    private readonly matterportService: MatterportService,

    private readonly panelService: PanelRightService,
    private readonly stagesRootService: StagesRootService,
    private readonly stageMatterportService: StageMatterportService,
    private readonly _stagesFacade: StagesFacade,
    private readonly _activatedRoute: ActivatedRoute,
    private readonly _componentsFacade: ComponentsFacade,
    private readonly modalService: ModalService,
    private readonly matterportNoteControlService: MatterportNoteControlService,
    private readonly notesFacade: NotesFacade
  ) {
    this.initMatterport();
  }
  ngAfterViewInit(): void {
    firstValueFrom(
      this._routerFacade.getRouteNestedParams$.pipe(
        tap((route) => {
          if (route.queryParams['noteId'] !== undefined) {
            this.matterportNoteControlService.moveToNote = {
              annotationType: 'notes',
              flag: true
            };
            this.stagesRootService.rightPanelState = 'open';
          }

          if (route.queryParams['punchItemId'] !== undefined) {
            this.stagesRootService.rightPanelState = 'open';
          }
        })
      )
    );
    this.matterportIsOpen$
      .pipe(
        filter((e) => e),
        switchMap(() =>
          merge(
            this.areaMeasurementComponent$.pipe(
              switchMap((measurementComponent) =>
                merge(
                  measurementComponent.addAction.pipe(
                    tap((type) => {
                      this._matterport.screenshotMode = true;
                      const id =
                        this.matterportAreaMeasurement.createMeasurement();
                      this.matterportAreaMeasurement.hideMeasurements([id]);
                      this.matterportLineMeasurement.hideMeasurements();
                      this.stageMatterportService.hideAllNotes(true);
                      this.matterportAreaMeasurement.selectedMeasurement = id;
                      this.cdr.markForCheck();
                    })
                  ),
                  measurementComponent.goTo.pipe(
                    switchMap((position: IVector3) => {
                      return this.matterportService.moveTo$(
                        new Vector3(position.x, position.y, position.z)
                      );
                    })
                  ),
                  measurementComponent.closeAction.pipe(
                    tap(() => (this._matterport.screenshotMode = false)),
                    switchMap(() =>
                      this._areaMeasurementFacade.allMeasurementsHidden$.pipe(
                        first(),
                        tap(
                          (hidden) =>
                            hidden &&
                            this.matterportAreaMeasurement.hideMeasurements()
                        )
                      )
                    )
                  ),
                  measurementComponent.editAction.pipe(
                    tap(() => {
                      this._matterport.screenshotMode = true;
                      const selected =
                        this.matterportAreaMeasurement.selectedMeasurement;
                      this.cdr.detectChanges();
                      const omit = [];
                      selected && omit.push(selected);
                      this.matterportAreaMeasurement.hideMeasurements(omit);
                      this.matterportAreaMeasurement.editSelectedMeasurement();
                    })
                  ),

                  this._areaMeasurementFacade.addSuccess$.pipe(
                    tap(({ id, data, color }) =>
                      this.matterportAreaMeasurement.addMeasurement({
                        id,
                        vertices: data.vertices,
                        triangles: data.triangles,
                        surfaceSize: data.surfaceSize,
                        color
                      })
                    )
                  ),
                  this._areaMeasurementFacade.deleteSuccess$.pipe(
                    tap((id) =>
                      this.matterportAreaMeasurement.deleteMeasurement(id)
                    )
                  ),
                  this._areaMeasurementFacade.updateColorSuccess$.pipe(
                    tap(({ id, color }) =>
                      this.matterportAreaMeasurement.updateMeasurementColor(
                        id,
                        color
                      )
                    )
                  ),
                  this._areaMeasurementFacade.allMeasurementsHidden$.pipe(
                    tap((hidden) => {
                      this._hideAllMeasurement(hidden, 'measurement-area');
                    })
                  ),
                  this._areaMeasurementFacade.selectedMeasurementId$.pipe(
                    distinctUntilChanged(),
                    tap((id: string | undefined) => {
                      {
                        this.matterportAreaMeasurement.selectedMeasurement = id;
                        this.matterportLineMeasurement.selectedMeasurement =
                          undefined;
                      }
                    }),
                    switchMap((id: string | undefined) =>
                      this._areaMeasurementFacade.allMeasurementsHidden$.pipe(
                        first(),
                        tap((hidden) => {
                          if (hidden) {
                            const omit = id ? [id] : [];
                            this.matterportAreaMeasurement.hideMeasurements(
                              omit
                            );
                          }
                        })
                      )
                    )
                  )
                )
              ),
              takeUntil(this._destroySource)
            ),
            this.distanceMeasurementComponent$.pipe(
              switchMap((measurementComponent) =>
                merge(
                  measurementComponent.addAction.pipe(
                    tap((type) => {
                      this._matterport.screenshotMode = true;
                      this.matterportLineMeasurement.selectedMeasurement =
                        undefined;
                      const id =
                        this.matterportLineMeasurement.createMeasurement();
                      this.matterportAreaMeasurement.hideMeasurements();
                      this.matterportLineMeasurement.hideMeasurements();
                      this.stageMatterportService.hideAllNotes(true);
                      this.matterportLineMeasurement.selectedMeasurement = id;
                      this.cdr.markForCheck();
                    })
                  ),
                  measurementComponent.goTo.pipe(
                    switchMap((position: IVector3) => {
                      return this.matterportService.moveTo$(
                        new Vector3(position.x, position.y, position.z)
                      );
                    })
                  ),
                  measurementComponent.closeAction.pipe(
                    tap(() => (this._matterport.screenshotMode = false)),
                    switchMap(() =>
                      this._distanceMeasurementFacade.allMeasurementsHidden$.pipe(
                        first(),
                        tap(
                          (hidden) =>
                            hidden &&
                            this.matterportLineMeasurement.hideMeasurements()
                        )
                      )
                    )
                  ),
                  measurementComponent.editAction.pipe(
                    tap(() => {
                      this._matterport.screenshotMode = true;
                      const selected =
                        this.matterportLineMeasurement.selectedMeasurement;
                      this.cdr.detectChanges();
                      const omit = [];
                      selected && omit.push(selected);
                      this.matterportLineMeasurement.hideMeasurements(omit);
                      this.matterportLineMeasurement.editSelectedMeasurement();
                    })
                  ),

                  this._distanceMeasurementFacade.allMeasurementsHidden$.pipe(
                    tap((hidden) => {
                      this._hideAllMeasurement(hidden, 'measurement-distance');
                    })
                  ),

                  this._distanceMeasurementFacade.addSuccess$.pipe(
                    tap(({ id, data, color }) =>
                      this.matterportLineMeasurement.addMeasurement({
                        id,
                        points: data,
                        color
                      })
                    )
                  ),
                  this._distanceMeasurementFacade.deleteSuccess$.pipe(
                    tap((id) =>
                      this.matterportLineMeasurement.deleteMeasurement(id)
                    )
                  ),
                  this._distanceMeasurementFacade.updateColorSuccess$.pipe(
                    tap(({ id, color }) =>
                      this.matterportLineMeasurement.updateMeasurementColor(
                        id,
                        color
                      )
                    )
                  ),
                  this._distanceMeasurementFacade.selectedMeasurementId$.pipe(
                    distinctUntilChanged(),
                    tap((id: string | undefined) => {
                      this.matterportLineMeasurement.selectedMeasurement = id;
                      this.matterportAreaMeasurement.selectedMeasurement =
                        undefined;
                    }),
                    switchMap((id: string | undefined) =>
                      this._distanceMeasurementFacade.allMeasurementsHidden$.pipe(
                        first(),
                        tap((hidden) => {
                          if (hidden) {
                            const omit = id ? [id] : [];
                            this.matterportLineMeasurement.hideMeasurements(
                              omit
                            );
                          }
                        })
                      )
                    ),
                    catchError((e) => {
                      console.warn(e);
                      return of(e);
                    })
                  )
                )
              ),
              takeUntil(this._destroySource)
            )
          )
        )
      )

      .subscribe();
  }
  showMeasurementsSegments() {
    this.ngZone.run(() => {
      this._areaMeasurementFacade.toggleMeasurementSegmentsVisibility();
    });
  }

  showDistanceMeasurementsSegments() {
    this.ngZone.run(() => {
      this._distanceMeasurementFacade.toggleMeasurementSegmentsVisibility();
    });
  }

  private _hideAllMeasurement(hidden: boolean, type: TypeMeasurement) {
    if (!hidden) {
      this.matterportAreaMeasurement.showMeasurements();
      this.matterportLineMeasurement.showMeasurements();
    } else {
      let selected: string | undefined = '';
      if (type === 'measurement-area')
        selected = this.matterportAreaMeasurement.selectedMeasurement;
      else selected = this.matterportLineMeasurement.selectedMeasurement;
      const omit = [];
      selected && omit.push(selected);
      this.matterportAreaMeasurement.hideMeasurements(omit);
      this.matterportLineMeasurement.hideMeasurements(omit);
    }
  }

  successDrawMeasurement() {
    firstValueFrom(
      combineLatest([
        this.areaMeasurementComponent$.pipe(
          tap((area) => {
            if (area.openEditor()) {
              this._hideAllMeasurement(true, 'measurement-area');
            }
          })
        ),
        this.distanceMeasurementComponent$.pipe(
          tap((distance) => {
            if (distance.openEditor()) {
              this._hideAllMeasurement(true, 'measurement-distance');
            }
          })
        )
      ])
    );
  }

  selectedAreaIdChange($event: string | undefined) {
    this._areaMeasurementFacade.selectedMeasurement($event);
  }

  async saveMeasurementsData($event: Required<TAreaMesh>) {
    this.stageMatterportService.hideAllNotes(false);
    const measurementComponent = await firstValueFrom(
      this.areaMeasurementComponent$
    );
    this._matterport.screenshotMode = false;

    this._areaMeasurementFacade.allMeasurementsHidden$
      .pipe(first())
      .subscribe((hidden) => {
        !hidden && this._showAllMeasurement();
      });
    measurementComponent.editData.next($event);
  }

  async saveMeasurementsDistanceData($event: Required<TMeasurementMesh>) {
    this.stageMatterportService.hideAllNotes(false);
    const measurementComponent = await firstValueFrom(
      this.distanceMeasurementComponent$
    );
    this._matterport.screenshotMode = false;

    this._distanceMeasurementFacade.allMeasurementsHidden$
      .pipe(first())
      .subscribe((hidden) => {
        !hidden && this._showAllMeasurement();
      });
    measurementComponent.editData.next($event);
  }

  async cancelMeasurementsData($event: void) {
    this.stageMatterportService.hideAllNotes(false);
    const measurementComponent = await firstValueFrom(
      this.areaMeasurementComponent$
    );
    this._matterport.screenshotMode = false;
    this._areaMeasurementFacade.allMeasurementsHidden$
      .pipe(first())
      .subscribe((hidden) => {
        !hidden && this._showAllMeasurement();
      });
    measurementComponent.cancel.next();
  }

  private _showAllMeasurement() {
    this.matterportLineMeasurement.showMeasurements();
    this.matterportAreaMeasurement.showMeasurements();
  }

  async cancelMeasurementsDistanceData($event: void) {
    this.stageMatterportService.hideAllNotes(false);
    const measurementComponent = await firstValueFrom(
      this.distanceMeasurementComponent$
    );
    this._matterport.screenshotMode = false;
    this._distanceMeasurementFacade.allMeasurementsHidden$
      .pipe(first())
      .subscribe((hidden) => {
        !hidden && this._showAllMeasurement();
      });
    measurementComponent.cancel.next();
  }
  private initMatterport() {
    merge(
      this.matterportNoteControlService.moveToNote$.pipe(
        tap((data) => {
          this._matterport.moveTo(data);
        })
      ),
      this.notesFacade.deleteNoteSuccess$.pipe(
        tap((noteId: string) => this._matterport.deleteNote(noteId))
      ),
      this.matterportNoteControlService.takeScreenshot$.pipe(
        tap(() => this.screenShot())
      )
    )
      .pipe(takeUntil(this._destroySource))
      .subscribe();
  }

  ngOnDestroy(): void {
    this._destroySource.next();
    this._destroySource.complete();
  }

  canDeactivate(): Observable<boolean> {
    return combineLatest([
      this.stagesRootService.leftPanelState$,
      this.stagesRootService.rightPanelState$
    ]).pipe(
      map(([left, right]) => {
        return true;
      }),

      take(1),
      tap(() => {
        this.stagesRootService.leftPanelState = 'close';
        this.stagesRootService.rightPanelState = 'close';
      })
    );
  }
  openPanel() {
    this.stagesRootService.leftPanelState = 'open';
  }

  screenShot() {
    this.stagesRootService.screenshot = true;
    this.stagesRootService.leftPanelState = 'close';
    this.stagesRootService.rightPanelState = 'close';
    this.matterportService.hasMatterport$
      .pipe(
        take(1),
        mergeMap((hasMatterport: boolean) =>
          iif(
            () => hasMatterport === true,
            of(true),
            this.matterportService.scanLoaded$
          )
        ),
        switchMap(() =>
          this.modalService
            .createModalWithProviders(
              ScreenshotDialogComponent,
              {
                centered: true,
                offset: {
                  x: 0,
                  y: 0
                },
                minWidth: '100%',
                hasBackdrop: false,
                panelClass: 'screenshot-pointer'
              },
              [
                {
                  provide: MATTERPORT_TOKEN,
                  useValue: this.matterportService
                }
              ]
            )
            .events$.pipe(
              tap(() => {
                this.stagesRootService.screenshot = false;
                this.stagesRootService.rightPanelState = 'open';
              }),
              take(1)
            )
        ),
        takeUntil(this._destroySource)
      )
      .subscribe();
  }
}
