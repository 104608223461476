import {
  ChangeDetectionStrategy,
  Component,
  computed,
  input
} from '@angular/core';
import { InfoItemContainerComponent } from '../info-item-container/info-item-container.component';
import { PunchItemPanelBaseDirective } from '../punch-item-panel-base/punch-item-panel.base';

@Component({
  selector: 'procore-punch-item-information-additional',
  standalone: true,
  imports: [InfoItemContainerComponent],
  templateUrl: './punch-item-information-additional.component.html',
  styleUrl: './punch-item-information-additional.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PunchItemInformationAdditionalComponent extends PunchItemPanelBaseDirective {
  readonly currencySymbol = input<string>();

  protected readonly scheduleImpact = computed(() => {
    const days = this.punchItem().scheduleImpactDays;
    if (days === null || days === undefined) {
      return this.noValueStr;
    }

    switch (days) {
      case 0:
        return $localize`:@@PUNCH_ITEM_INFORMATION_SCHEDULE_IMPACT_0:${days} days`;
      case 1:
        return $localize`:@@PUNCH_ITEM_INFORMATION_SCHEDULE_IMPACT_1:${days} days`;
      default:
        return $localize`:@@PUNCH_ITEM_INFORMATION_SCHEDULE_IMPACT_MORE:${days} days`;
    }
  });

  protected readonly costImpact = computed(() => {
    const impact = this.punchItem().costImpactAmount;
    const currencySymbol = this.currencySymbol();

    return impact
      ? $localize`:@@PUNCH_ITEM_INFORMATION_IMPACT_VALUE:${impact} ${currencySymbol}`
      : this.noValueStr;
  });

  protected readonly linkedDrawings = computed(() => {
    const linkedDrawings = this.punchItem().drawingRevisions;
    if (linkedDrawings?.length) {
      return linkedDrawings.map((drawing) => drawing.title).join(', ');
    } else {
      return this.noValueStr;
    }
  });

  protected readonly codeCode = computed(() => {
    const costCode = this.punchItem().costCode;
    return costCode?.name || this.noValueStr;
  });

  protected readonly reference = computed(() => {
    const reference = this.punchItem().reference;
    return reference || this.noValueStr;
  });

  protected readonly stagesLink = computed(() => {
    const link = this.punchItem().link;
    return link || this.noValueStr;
  });
}
