import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { DesignFlatButton, DesignStrokedButton } from '@simlab/design/button';
import { DesignCommonModule } from '@simlab/design/common';
import { DesignDialogWrapperModule } from '@simlab/design/dialog';
import { UiButtonModule } from '../../../ui-button/ui-button.module';
import { UiIconModule } from '../../../ui-icon/ui-icon.module';
import { ConfirmationModalRef } from '../../models/confirmation-modal-ref';
import { MODAL_DATA } from '../../tokens/modal-data.token';

const CONFIRM = $localize`:@@CONFIRM:Confirm`;
const WARNING = $localize`:@@WARNING:Warning`;

@Component({
    templateUrl: './delete-confirmation-dialog.component.html',
    styleUrls: ['./delete-confirmation-dialog.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    imports: [
        CommonModule,
        DesignCommonModule,
        DesignDialogWrapperModule,
        UiButtonModule,
        UiIconModule,
        DesignFlatButton,
        DesignStrokedButton,
    ]
})
export class DeleteConfirmationDialogComponent {
  set confirmButton(value: string) {
    this._confirmButton = value;
  }
  get confirmButton(): string {
    return this._confirmButton;
  }
  private _confirmButton = CONFIRM;

  set title(value: string) {
    this._titleModal = value;
  }
  get title(): string {
    return this._titleModal;
  }
  private _titleModal = WARNING;

  constructor(
    @Inject(MODAL_DATA)
    public readonly modalData: {
      text: string;
      title?: string;
      confirmButton?: string;
    },
    private readonly modalRef: ConfirmationModalRef<unknown>
  ) {
    this.confirmButton = modalData.confirmButton
      ? modalData.confirmButton
      : CONFIRM;
    this.title = modalData.title ? modalData.title : WARNING;
  }

  confirm(): void {
    this.modalRef.emit({ state: true });
    this.modalRef.close();
  }

  cancel(): void {
    this.modalRef.emit({ state: false });
    this.modalRef.close();
  }
}
