import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  InjectionToken,
  Input,
  Output,
  ViewEncapsulation,
  inject
} from '@angular/core';
import { FormControl, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SystemOfMeasurement, UserPreferences } from '@simlab/data-access';
import { DesignRadioAccordionModule } from '@simlab/design/accordion';
import { DesignCommonModule } from '@simlab/design/common';
import { Observable, map } from 'rxjs';
import { PersonalSettingBase } from '../../personal-settings.component';

export const UserPreferencesToken = new InjectionToken<UserPreferences>(
  'User preferences'
);

@Component({
    template: `
    <label i18n="@@UNITS">Units</label>
    <design-radio-accordion hasSpacing [formControl]="unitsFormControl">
      <design-radio-expansion-panel
        id="units-metric"
        [value]="units.Metric"
        class="units-design-accordion"
      >
        <design-radio-expansion-panel-header color="secondary">
          <label designLabel i18n="@@METRIC_SYSTEM">
            Metric system (meters)
          </label>
        </design-radio-expansion-panel-header>
      </design-radio-expansion-panel>

      <design-radio-expansion-panel
        class="units-design-accordion"
        id="units-imperial"
        [value]="units.Imperial"
      >
        <design-radio-expansion-panel-header color="secondary">
          <label designLabel i18n="@@IMPERIAL_SYSTEM">
            Imperial system (inches)
          </label>
        </design-radio-expansion-panel-header>
      </design-radio-expansion-panel>
    </design-radio-accordion>
  `,
    styleUrls: ['./units.component.scss'],
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
    imports: [
        CommonModule,
        DesignRadioAccordionModule,
        DesignCommonModule,
        FormsModule,
        ReactiveFormsModule
    ]
})
export class UnitsComponent implements PersonalSettingBase {
  readonly unitsFormControl = new FormControl<SystemOfMeasurement>(
    SystemOfMeasurement.Metric
  );
  private _userPreferences: UserPreferences = inject(UserPreferencesToken);

  @Input() set userPreferences(userPreferences: UserPreferences) {
    this._userPreferences = userPreferences;
  }
  get userPreferences() {
    return this._userPreferences;
  }
  readonly units = SystemOfMeasurement;
  constructor() {
    this.unitsFormControl.patchValue(this._userPreferences.systemOfMeasurement);
  }
  @Output() readonly userPreferencesChange: Observable<UserPreferences> =
    this.unitsFormControl.valueChanges.pipe(
      map((value) => {
        if (!value) return this.userPreferences;
        return {
          ...this.userPreferences,
          systemOfMeasurement: value
        };
      })
    );
}
