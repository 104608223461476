import { InjectionToken } from '@angular/core';

const ACCEPTED_IMG_TAG_EXTENSION = [
  'apng',
  'bmp',
  'gif',
  'jpeg',
  'jpg',
  'png',
  'webp',
  'svg',
] as const;

export const ACCEPTED_IMG_TAG_EXTENSION_TOKEN = new InjectionToken<
  typeof ACCEPTED_IMG_TAG_EXTENSION
>('Accepted img tag extension token', {
  providedIn: 'root',
  factory: () => ACCEPTED_IMG_TAG_EXTENSION,
});

export type ACCEPTED_IMG_TAG_EXTENSION_TYPE = typeof ACCEPTED_IMG_TAG_EXTENSION;

const ACCEPTED_VIDEO_TAG_EXTENSION: string[] = [
  'mp4',
  'webm',
  'ogv',
  'mov',
] as const;

export type ACCEPTED_VIDEO_TAG_EXTENSION_TYPE =
  typeof ACCEPTED_VIDEO_TAG_EXTENSION;

export const ACCEPTED_VIDEO_TAG_EXTENSION_TOKEN = new InjectionToken<
  typeof ACCEPTED_VIDEO_TAG_EXTENSION
>('Accepted video tag extension token', {
  providedIn: 'root',
  factory: () => ACCEPTED_VIDEO_TAG_EXTENSION,
});

const ACCEPTED_AUDIO_TAG_EXTENSION: string[] = ['mp3', 'wav', 'ogg'] as const;

export type ACCEPTED_AUDIO_TAG_EXTENSION_TYPE =
  typeof ACCEPTED_AUDIO_TAG_EXTENSION;

export const ACCEPTED_AUDIO_TAG_EXTENSION_TOKEN = new InjectionToken<
  typeof ACCEPTED_AUDIO_TAG_EXTENSION
>('Accepted audio tag extension tokne', {
  providedIn: 'root',
  factory: () => ACCEPTED_AUDIO_TAG_EXTENSION,
});
