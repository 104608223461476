/* eslint-disable @angular-eslint/no-host-metadata-property */
/* eslint-disable @angular-eslint/component-class-suffix */
import { NgIf, NgSwitch, NgSwitchCase } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  Input,
  ViewEncapsulation,
} from '@angular/core';
import { designFormFieldToken } from '../tokens/form-field.token';
import { FormFieldBase } from './form-field-base.directive';

@Component({
    selector: 'design-form-field',
    imports: [NgIf, NgSwitch, NgSwitchCase],
    templateUrl: './form-field.component.html',
    styleUrls: ['./form-field.component.scss'],
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
    host: {
        class: 'design-form-field',
        '[class.design-form-field--margin-top]': 'hasLabel === false',
        '[class.design-form-field--invalid]': 'control.errorState',
        '[class.design-focused]': 'control.focused',
    },
    providers: [{ provide: designFormFieldToken, useExisting: DesignFormField }]
})
export class DesignFormField extends FormFieldBase {

  @Input() disablePadding = false;
  @Input() disableHover = false;

  get hasIconPrefix(): boolean {
    return this._hasIconPrefix;
  }

  get hasIconSuffix(): boolean {
    return this._hasIconSuffix;
  }

  get hasTextPrefix(): boolean {
    return this._hasTextPrefix;
  }

  get hasTextSuffix(): boolean {
    return this._hasTextSuffix;
  }

  get hasLabel(): boolean {
    return !!this._labelChildNonStatic || !!this._labelChildStatic;
  }
}
