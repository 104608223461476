import {
  Component,
  HostBinding,
  Input,
  TemplateRef,
  ViewEncapsulation
} from '@angular/core';
import { MenuDirection } from '../../models/menu-direction';

@Component({
    selector: 'ui-menu',
    templateUrl: './menu.component.html',
    styleUrls: ['./menu.component.scss'],
    encapsulation: ViewEncapsulation.None,
    standalone: false
})
export class MenuComponent {
  @HostBinding('class') get directionClass(): string {
    return this.direction;
  }
  @HostBinding('class.menu') mainClass = true;
  @Input() menu!: TemplateRef<any>;

  @Input() set direction(value: MenuDirection) {
    this._direction = value;
  }
  get direction(): MenuDirection {
    return this._direction;
  }
  private _direction: MenuDirection = 'horizontal';

  @Input() gap!: number; //TODO: handle gap -> --menu-gap
}
