import {
  ConnectedPosition,
  Overlay,
  ScrollStrategy
} from '@angular/cdk/overlay';
import {
  ChangeDetectionStrategy,
  Component,
  ContentChildren,
  InjectionToken,
  input,
  Input,
  OnInit,
  QueryList,
  ViewEncapsulation
} from '@angular/core';
import { take, takeUntil } from 'rxjs/operators';
import {
  FormFieldControl
} from '../../../ui-form-field/directives/form-field-control.directive';
import { ICON_FONT_TYPE } from '../../../ui-icon/models/sim-font.types';
import { selectAnimations } from '../../animations/select.animations';
import { _SelectBase } from '../../directives/select-base.directive';
import { MAT_OPTION_PARENT_COMPONENT } from '../../models/options-parent';
import { OptionComponent } from '../option/option.component';
// import { FormFieldControl } from '../../form-field/ui-form-field-control.directive';
// import { selectAnimations } from '../animations/ui-select.animations';
// import { _SelectBase } from '../directives/select-base.directive';
// import { MAT_OPTION_PARENT_COMPONENT } from '../interfaces/options-parent';
// import { OptionComponent } from './option/option.component';

export const SELECT_ITEM_HEIGHT_EM = 3;
export const SELECT_PANEL_MAX_HEIGHT = 256;
/** The panel's padding on the x-axis. */
export const SELECT_PANEL_PADDING_X = 16;
/** The panel's x axis padding if it is indented (e.g. there is an option group). */
export const SELECT_PANEL_INDENT_PADDING_X = SELECT_PANEL_PADDING_X * 2;
export const SELECT_PANEL_VIEWPORT_PADDING = 8;
/** Injection token that determines the scroll handling while a select is open. */
export const MAT_SELECT_SCROLL_STRATEGY = new InjectionToken<
  () => ScrollStrategy
>('mat-select-scroll-strategy');

/** @docs-private */
export function MAT_SELECT_SCROLL_STRATEGY_PROVIDER_FACTORY(
  overlay: Overlay
): () => ScrollStrategy {
  return () => overlay.scrollStrategies.block();
}
export const MAT_SELECT_SCROLL_STRATEGY_PROVIDER = {
  provide: MAT_SELECT_SCROLL_STRATEGY,
  deps: [Overlay],
  useFactory: MAT_SELECT_SCROLL_STRATEGY_PROVIDER_FACTORY
};
@Component({
  selector: 'ui-select',
  templateUrl: './select.component.html',
  styleUrls: ['./select.component.scss'],
  // eslint-disable-next-line @angular-eslint/no-host-metadata-property
  host: {
    role: 'combobox',
    'aria-autocomplete': 'none',
    // TODO(crisbeto): the value for aria-haspopup should be `listbox`, but currently it's difficult
    // to sync into Google, because of an outdated automated a11y check which flags it as an invalid
    // value. At some point we should try to switch it back to being `listbox`.
    'aria-haspopup': 'true',
    class: 'ui-select',
    '[class.select-disabled]': 'disabled',
    '[class.select-trigger--open]': 'panelOpen',
    '[class.select-invalid]': 'errorState',
    '[class.select-required]': 'required',
    '[class.select-empty]': 'empty',
    '[class.select-multiple]': 'multiple',
    '(keydown)': '_handleKeydown($event)',
    '(focus)': '_onFocus()',
    '(blur)': '_onBlur()'
  },
  animations: [
    selectAnimations.transformPanelWrap,
    selectAnimations.transformPanel
  ],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    { provide: MAT_OPTION_PARENT_COMPONENT, useExisting: SelectComponent },
    { provide: FormFieldControl, useExisting: SelectComponent },
  ],
  standalone: false
})
export class SelectComponent
  extends _SelectBase<SelectChange>
  implements OnInit, FormFieldControl<any>
{
  @ContentChildren(OptionComponent, { descendants: true })
  options!: QueryList<OptionComponent>;
  _isOpen = false;
  customTrigger = null;

  // @Input() icon?: ICON_TYPE;
  @Input() iconName: ICON_FONT_TYPE | undefined | null;
  readonly fixedOverlayWidth = input(false);

  _triggerRect: DOMRect | undefined;
  _triggerFontSize = 0;
  _transformOrigin = 'top';
  _overlayPanelClass: string | string[] = '';
  _positions: ConnectedPosition[] = [
    {
      originX: 'start',
      originY: 'top',
      overlayX: 'start',
      overlayY: 'top'
    },
    {
      originX: 'start',
      originY: 'bottom',
      overlayX: 'start',
      overlayY: 'bottom'
    }
  ];
  private _scrollTop = 0;
  _offsetY = 0;
  override open(): void {
    if (super._canOpen()) {
      super.open();
      this._triggerRect = this.trigger.nativeElement.getBoundingClientRect();
      // Note: The computed font-size will be a string pixel value (e.g. "16px").
      // `parseInt` ignores the trailing 'px' and converts this to a number.
      this._triggerFontSize = parseInt(
        getComputedStyle(this.trigger.nativeElement).fontSize || '0'
      );
      this._calculateOverlayPosition();

      // Set the font size on the panel element once it exists.
      this._ngZone.onStable.pipe(take(1)).subscribe(() => {
        if (
          this._triggerFontSize &&
          this._overlayDir.overlayRef &&
          this._overlayDir.overlayRef.overlayElement
        ) {
          this._overlayDir.overlayRef.overlayElement.style.fontSize = `${this._triggerFontSize}px`;
        }
      });
    }
  }
  override ngOnInit() {
    super.ngOnInit();
    this._viewportRuler
      .change()
      .pipe(takeUntil(this._destroy))
      .subscribe(() => {
        if (this.panelOpen) {
          this._triggerRect =
            this.trigger.nativeElement.getBoundingClientRect();
          this._changeDetectorRef.markForCheck();
        }
      });
  }
  protected _scrollOptionIntoView(index: number): void {
    const labelCount = 0;
    const itemHeight = this._getItemHeight();

    this.panel.nativeElement.scrollTop = _getOptionScrollPosition(
      (index + labelCount) * itemHeight,
      itemHeight,
      this.panel.nativeElement.scrollTop,
      SELECT_PANEL_MAX_HEIGHT
    );
  }
  protected _positioningSettled() {
    // this._calculateOverlayOffsetX();
    // this.panel.nativeElement.scrollTop = this._scrollTop;
  }
  private _calculateOverlayOffsetX(): void {
    const overlayRect =
      this._overlayDir.overlayRef.overlayElement.getBoundingClientRect();
    const viewportSize = this._viewportRuler.getViewportSize();
    const isRtl = this._isRtl();
    const paddingWidth = SELECT_PANEL_PADDING_X * 2;
    let offsetX: number;

    // Adjust the offset, depending on the option padding.

    const selected = this._selectionModel.selected[0] || this.options.first;
    offsetX = SELECT_PANEL_PADDING_X;

    // Invert the offset in LTR.
    if (!isRtl) {
      offsetX *= -1;
    }

    // Determine how much the select overflows on each side.
    const leftOverflow =
      0 - (overlayRect.left + offsetX - (isRtl ? paddingWidth : 0));
    const rightOverflow =
      overlayRect.right +
      offsetX -
      viewportSize.width +
      (isRtl ? 0 : paddingWidth);

    // If the element overflows on either side, reduce the offset to allow it to fit.
    if (leftOverflow > 0) {
      offsetX += leftOverflow + SELECT_PANEL_VIEWPORT_PADDING;
    } else if (rightOverflow > 0) {
      offsetX -= rightOverflow + SELECT_PANEL_VIEWPORT_PADDING;
    }

    // Set the offset directly in order to avoid having to go through change detection and
    // potentially triggering "changed after it was checked" errors. Round the value to avoid
    // blurry content in some browsers.
    this._overlayDir.offsetX = Math.round(offsetX);
    this._overlayDir.overlayRef.updatePosition();
  }
  protected _getChangeEvent(value: any) {
    return new SelectChange(this, value);
  }
  private _getItemHeight(): number {
    return this._triggerFontSize * SELECT_ITEM_HEIGHT_EM;
  }

  /** Calculates the amount of items in the select. This includes options and group labels. */
  private _getItemCount(): number {
    return this.options.length;
  }
  private _calculateOverlayPosition(): void {
    const itemHeight = this._getItemHeight();
    const items = this._getItemCount();
    const panelHeight = Math.min(items * itemHeight, SELECT_PANEL_MAX_HEIGHT);
    const scrollContainerHeight = items * itemHeight;

    // The farthest the panel can be scrolled before it hits the bottom
    const maxScroll = scrollContainerHeight - panelHeight;

    // If no value is selected we open the popup to the first item.
    let selectedOptionOffset: number;

    if (this.empty) {
      selectedOptionOffset = 0;
    } else {
      selectedOptionOffset = Math.max(
        this.options.toArray().indexOf(this._selectionModel.selected[0]),
        0
      );
    }

    //TODO what ????
    // selectedOptionOffset += _countGroupLabelsBeforeOption(
    //   selectedOptionOffset,
    //   this.options,
    //   this.optionGroups
    // );

    // We must maintain a scroll buffer so the selected option will be scrolled to the
    // center of the overlay panel rather than the top.
    const scrollBuffer = panelHeight / 2;
    this._scrollTop = this._calculateOverlayScroll(
      selectedOptionOffset,
      scrollBuffer,
      maxScroll
    );
    this._offsetY = this._calculateOverlayOffsetY(
      selectedOptionOffset,
      scrollBuffer,
      maxScroll
    );

    this._checkOverlayWithinViewport(maxScroll);
  }
  private _checkOverlayWithinViewport(maxScroll: number): void {
    const itemHeight = this._getItemHeight();
    const viewportSize = this._viewportRuler.getViewportSize();

    const topSpaceAvailable =
      this._triggerRect!.top - SELECT_PANEL_VIEWPORT_PADDING;
    const bottomSpaceAvailable =
      viewportSize.height -
      this._triggerRect!.bottom -
      SELECT_PANEL_VIEWPORT_PADDING;

    const panelHeightTop = Math.abs(this._offsetY);
    const totalPanelHeight = Math.min(
      this._getItemCount() * itemHeight,
      SELECT_PANEL_MAX_HEIGHT
    );
    const panelHeightBottom =
      totalPanelHeight - panelHeightTop - this._triggerRect!.height;

    if (panelHeightBottom > bottomSpaceAvailable) {
      this._adjustPanelUp(panelHeightBottom, bottomSpaceAvailable);
    } else if (panelHeightTop > topSpaceAvailable) {
      this._adjustPanelDown(panelHeightTop, topSpaceAvailable, maxScroll);
    } else {
      this._transformOrigin = this._getOriginBasedOnOption();
    }
  }
  private _getOriginBasedOnOption(): string {
    const itemHeight = this._getItemHeight();
    const optionHeightAdjustment = (itemHeight - this._triggerRect!.height) / 2;
    const originY =
      Math.abs(this._offsetY) - optionHeightAdjustment + itemHeight / 2;
    return `50% ${originY}px 0px`;
  }
  private _calculateOverlayOffsetY(
    selectedIndex: number,
    scrollBuffer: number,
    maxScroll: number
  ): number {
    const itemHeight = this._getItemHeight();
    const optionHeightAdjustment = (itemHeight - this._triggerRect!.height) / 2;
    const maxOptionsDisplayed = Math.floor(
      SELECT_PANEL_MAX_HEIGHT / itemHeight
    );
    let optionOffsetFromPanelTop: number;

    // Disable offset if requested by user by returning 0 as value to offset

    if (this._scrollTop === 0) {
      optionOffsetFromPanelTop = selectedIndex * itemHeight;
    } else if (this._scrollTop === maxScroll) {
      const firstDisplayedIndex = this._getItemCount() - maxOptionsDisplayed;
      const selectedDisplayIndex = selectedIndex - firstDisplayedIndex;

      // The first item is partially out of the viewport. Therefore we need to calculate what
      // portion of it is shown in the viewport and account for it in our offset.
      const partialItemHeight =
        itemHeight -
        ((this._getItemCount() * itemHeight - SELECT_PANEL_MAX_HEIGHT) %
          itemHeight);

      // Because the panel height is longer than the height of the options alone,
      // there is always extra padding at the top or bottom of the panel. When
      // scrolled to the very bottom, this padding is at the top of the panel and
      // must be added to the offset.
      optionOffsetFromPanelTop =
        selectedDisplayIndex * itemHeight + partialItemHeight;
    } else {
      // If the option was scrolled to the middle of the panel using a scroll buffer,
      // its offset will be the scroll buffer minus the half height that was added to
      // center it.
      optionOffsetFromPanelTop = scrollBuffer - itemHeight / 2;
    }

    // The final offset is the option's offset from the top, adjusted for the height difference,
    // multiplied by -1 to ensure that the overlay moves in the correct direction up the page.
    // The value is rounded to prevent some browsers from blurring the content.
    return Math.round(optionOffsetFromPanelTop * -1 - optionHeightAdjustment);
  }
  _calculateOverlayScroll(
    selectedIndex: number,
    scrollBuffer: number,
    maxScroll: number
  ): number {
    const itemHeight = this._getItemHeight();
    const optionOffsetFromScrollTop = itemHeight * selectedIndex;
    const halfOptionHeight = itemHeight / 2;

    // Starts at the optionOffsetFromScrollTop, which scrolls the option to the top of the
    // scroll container, then subtracts the scroll buffer to scroll the option down to
    // the center of the overlay panel. Half the option height must be re-added to the
    // scrollTop so the option is centered based on its middle, not its top edge.
    const optimalScrollPosition =
      optionOffsetFromScrollTop - scrollBuffer + halfOptionHeight;
    return Math.min(Math.max(0, optimalScrollPosition), maxScroll);
  }
  /** Adjusts the overlay panel up to fit in the viewport. */
  private _adjustPanelUp(
    panelHeightBottom: number,
    bottomSpaceAvailable: number
  ) {
    // Browsers ignore fractional scroll offsets, so we need to round.
    const distanceBelowViewport = Math.round(
      panelHeightBottom - bottomSpaceAvailable
    );

    // Scrolls the panel up by the distance it was extending past the boundary, then
    // adjusts the offset by that amount to move the panel up into the viewport.
    this._scrollTop -= distanceBelowViewport;
    this._offsetY -= distanceBelowViewport;
    this._transformOrigin = this._getOriginBasedOnOption();

    // If the panel is scrolled to the very top, it won't be able to fit the panel
    // by scrolling, so set the offset to 0 to allow the fallback position to take
    // effect.
    if (this._scrollTop <= 0) {
      this._scrollTop = 0;
      this._offsetY = 0;
      this._transformOrigin = `50% bottom 0px`;
    }
  }
  /** Adjusts the overlay panel down to fit in the viewport. */
  private _adjustPanelDown(
    panelHeightTop: number,
    topSpaceAvailable: number,
    maxScroll: number
  ) {
    // Browsers ignore fractional scroll offsets, so we need to round.
    const distanceAboveViewport = Math.round(
      panelHeightTop - topSpaceAvailable
    );

    // Scrolls the panel down by the distance it was extending past the boundary, then
    // adjusts the offset by that amount to move the panel down into the viewport.
    this._scrollTop += distanceAboveViewport;
    this._offsetY += distanceAboveViewport;
    this._transformOrigin = this._getOriginBasedOnOption();

    // If the panel is scrolled to the very bottom, it won't be able to fit the
    // panel by scrolling, so set the offset to 0 to allow the fallback position
    // to take effect.
    if (this._scrollTop >= maxScroll) {
      this._scrollTop = maxScroll;
      this._offsetY = 0;
      this._transformOrigin = `50% top 0px`;
      return;
    }
  }

  override get empty(): boolean {
    return !this._selectionModel || this._selectionModel.isEmpty();
  }
}

export class SelectChange {
  constructor(
    /** Reference to the select that emitted the change event. */
    public source: SelectComponent,
    /** Current value of the select that emitted the event. */
    public value: any
  ) {}
}
export function _getOptionScrollPosition(
  optionOffset: number,
  optionHeight: number,
  currentScrollPosition: number,
  panelHeight: number
): number {
  if (optionOffset < currentScrollPosition) {
    return optionOffset;
  }

  if (optionOffset + optionHeight > currentScrollPosition + panelHeight) {
    return Math.max(0, optionOffset - panelHeight + optionHeight);
  }

  return currentScrollPosition;
}
