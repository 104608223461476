import { Pipe, PipeTransform } from '@angular/core';
import { ProcorePriorities } from '@simlab/procore/models';

export const ProcorePriorityLabel: Record<ProcorePriorities, string> = {
  [ProcorePriorities.Low]: $localize`:@@PROCORE_PRIORITIES_LOW:Low`,
  [ProcorePriorities.Medium]: $localize`:@@PROCORE_PRIORITIES_LOW:Medium`,
  [ProcorePriorities.High]: $localize`:@@PROCORE_PRIORITIES_LOW:High`,
};

@Pipe({ name: 'getProcorePriorityLabel', standalone: true })
export class ProcorePriorityLabelGetterPipe implements PipeTransform {
  transform(priority: ProcorePriorities): string {
    return ProcorePriorityLabel[priority];
  }
}
