import {
  ChangeDetectionStrategy,
  Component,
  inject,
  input
} from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { DesignIcon } from '@simlab/design/icon';
import { MediaObserverService } from '@simlab/design/layout';
import { DesignProgressSpinnerComponent } from '@simlab/design/progress-spinner';
import {
  DocumentStates,
  FileCardStates,
  FolderStates
} from '@simlab/documents/models';
import { UiCheckboxModule } from '@simlab/ui/checkbox';
import { DocumentIconGetterPipe } from '../document-icon-getter/document-icon-getter.pipe';

@Component({
    selector: 'documents-document-badge-state',
    imports: [
        UiCheckboxModule,
        DocumentIconGetterPipe,
        DesignIcon,
        DesignProgressSpinnerComponent
    ],
    templateUrl: './document-badge-state.component.html',
    styleUrl: './document-badge-state.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class DocumentBadgeStateComponent<
  TData extends DocumentStates | FileCardStates | FolderStates
> {
  private readonly _media = inject(MediaObserverService);

  protected readonly isTouchableScreen = toSignal(
    this._media.isTouchableScreen$
  );

  readonly data = input.required<TData>();
  readonly isSelected = input.required<boolean>();
}
