<design-menu-panel customPanelClass="annotation-action-menu">
  @for (action of actionsList(); track action) {
    <button
      design-menu-item
      (click)="action.trigger()"
      [disabled]="action.disabled !== undefined ? !!action.disabled() : false"
    >
      {{ (action.name | annotationPanelHeaderActionName)() }}
    </button>
  }
</design-menu-panel>

<ng-content select="button[type='button']"></ng-content>
