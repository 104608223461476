@if (displayElements(); as display) {
  <procore-annotation-panel-notifications
    [text]="display.statusDescription"
    [title]="display.status"
    [icon]="status() | getPunchItemStatusIcon"
    [type]="display.showYellowBackground ? 'Yellow' : 'Blue'"
  />

  <procore-punch-item-panel-location-3d [showEdit]="showEdit()" />

  <procore-request-container
    type="Issue"
    [title]="name()"
    [description]="richTextDescription()"
    [attachments]="punchItem().attachments"
  />

  <!-- <ui-image-info
  class="image-info"
  imageSrc="icon_open_in_browser"
  i18n-header="@@GO_TO_PROCORE_MANAGE_PUNCH_ITEM"
  header="Go to Procore to manage the Punch Item"
  [button]="button"
/> -->

  @if (isSendItemVisible()) {
    <procore-punch-item-send-item
      [punchItem]="punchItem()"
      [displayElements]="display"
      [assigneeOptions]="assigneeOptions()"
    />
  }

  <procore-punch-item-activity-section
    [isAddingCommentPending]="isAddingCommentPending()"
    [activities]="activities()"
    [activitiesLoading]="activitiesLoading()"
    (commentAdded)="commentAdded($event)"
  />
}
