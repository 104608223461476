import { CommonModule } from '@angular/common';
import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  Renderer2,
  ViewChild,
  ViewContainerRef,
  inject
} from '@angular/core';
import { Stage, StageComponent } from '@simlab/data-access';
import { ComponentHelper, ComponentsFacade } from '@simlab/data-store';
import {
  CustomPhases,
  MatterportConfig,
  MatterportManagerService,
  MatterportSdkBundleModule,
  MatterportService,
  MoveToClosestSweepConfig,
  SupportedLinks,
  Transition
} from '@simlab/matterport';
import { Camera, Mode } from '@simlab/matterport/assets/mpSdk/sdk';
import { UiFormFieldModule } from '@simlab/ui/form-field';
import { UiHelperModule } from '@simlab/ui/helper';
import { UiImageInfoModule } from '@simlab/ui/image-info';
import { UiMatterportLoadingModule } from '@simlab/ui/matterport-loading';
import { UiProgressSpinnerModule } from '@simlab/ui/progress-spinner';
import { UiSelectModule } from '@simlab/ui/select';

import { toSignal } from '@angular/core/rxjs-interop';
import { ENVIRONMENT_CONFIG, ShowcaseHelper } from '@simlab/util-shared';
import {
  BehaviorSubject,
  Observable,
  Subject,
  catchError,
  delay,
  distinctUntilChanged,
  filter,
  firstValueFrom,
  map,
  of,
  skip,
  switchMap,
  take,
  takeUntil,
  tap
} from 'rxjs';
import { Vector3 } from 'three';
import { selectSyncComponentsByStage } from '../../functions/component.functions';

const INFO_SELECT_COMPONENT = [
  $localize`:@@SELECT_COMPONENT_TO_COMPARE:Select component to compare.`
];
@Component({
    selector: 'simlab-matterport-compare-element',
    templateUrl: './matterport-compare-element.component.html',
    styleUrls: ['./matterport-compare-element.component.scss'],
    providers: [
        MatterportService,
        MatterportManagerService,
        {
            provide: MatterportConfig,
            useFactory: () => {
                const environment = inject(ENVIRONMENT_CONFIG);
                return {
                    key: environment.configuration.matterport.apiKey,
                    hideFullscreen: true,
                    hideHelp: true
                } as MatterportConfig;
            }
        }
    ],
    imports: [
        CommonModule,
        UiSelectModule,
        UiFormFieldModule,
        UiHelperModule,
        UiImageInfoModule,
        UiProgressSpinnerModule,
        UiMatterportLoadingModule,
        MatterportSdkBundleModule
    ]
})
export class MatterportCompareElementComponent implements OnInit, OnDestroy {
  @ViewChild('matterport', { read: ViewContainerRef, static: true })
  container!: ViewContainerRef;

  private readonly _renderer = inject(Renderer2);
  private readonly _elementRef = inject(ElementRef);
  private readonly _matterport = inject(MatterportService);
  private readonly _componentsFacade = inject(ComponentsFacade);

  private readonly _matterportManager = inject(MatterportManagerService);

  private readonly _destroySource = new Subject<void>();
  private readonly _destroyCameraMoveObserver = new Subject<void>();
  private readonly _reloadingMatterport = new BehaviorSubject<boolean>(false);
  private readonly _selectedStage: BehaviorSubject<StageComponent | undefined> =
    new BehaviorSubject<StageComponent | undefined>(undefined);

  readonly reloadingMatterport$ = this._reloadingMatterport.asObservable();
  readonly hasMatterport$ = this._matterport.hasMatterport$;
  readonly scanLoaded$ = this._matterport.scanLoaded$.pipe(map(() => true));
  readonly scanReloaded$: Observable<boolean> = this._selectedStage.pipe(
    switchMap(() => this.hasMatterport$),
    switchMap((hasMatterport) =>
      hasMatterport ? this.scanLoaded$ : of(hasMatterport)
    )
  );
  readonly descriptionImageInfoSelectComponent = INFO_SELECT_COMPONENT;

  private _focus = false;
  public get focus() {
    return this._focus;
  }
  public set focus(value) {
    if (this._focus === value) return;

    this._focus = value;
    if (value) {
      this.focusActivated.emit();
      this._renderer.addClass(
        (this._elementRef.nativeElement as Element).firstChild,
        'focused'
      );
    } else {
      this._renderer.removeClass(
        (this._elementRef.nativeElement as Element).firstChild,
        'focused'
      );
    }
  }

  private _latestCameraPose: Camera.Pose | undefined;
  public get latestCameraPose(): Camera.Pose | undefined {
    return this._latestCameraPose;
  }
  public set latestCameraPose(value: Camera.Pose | undefined) {
    this._latestCameraPose = value;
  }

  get lastCameraPosition() {
    if (this._latestCameraPose === undefined) {
      return new Vector3(0, 0, 0);
    }
    const position = this._latestCameraPose.position;
    return new Vector3(position.x, position.y, position.z);
  }

  private _latestFloor: number | undefined;
  get latestFloor() {
    return this._latestFloor;
  }

  get reloadingMatterport() {
    return this._reloadingMatterport.getValue();
  }

  get selectedStageId() {
    const stage = this._selectedStage.getValue();
    if (stage === undefined) throw new Error('Stage is undefined!');
    return stage.stageId;
  }

  readonly clickerDetected = toSignal(
    this.scanReloaded$.pipe(
      filter((scanReloaded: boolean) => scanReloaded),
      switchMap((e) => this._matterportManager.events.matterportDetectClick$)
    )
  );

  readonly matterportDetectClickPointerUp = toSignal(
    this.scanReloaded$.pipe(
      filter((scanReloaded: boolean) => scanReloaded),
      switchMap(
        (e) => this._matterportManager.events.matterportDetectClickPointerUp$
      )
    )
  );

  @Input({ required: true }) stages: Stage[] = [];
  @Input() set initialSelectedModel(value: StageComponent) {
    this._selectedStage.next(value);
  }
  @Input() set initCameraPose(value: Camera.Pose | undefined) {
    this.latestCameraPose = value;
  }
  private _initFloor: number | undefined;
  @Input() set initFloor(value: number | undefined) {
    this._initFloor = value;
  }
  @Output() focusActivated: EventEmitter<void> = new EventEmitter<void>();
  @Output() modeChanged: Observable<Mode.Mode | null> = this.scanReloaded$.pipe(
    filter((isOpen: boolean) => isOpen),
    switchMap(() => this._matterport.currentMode$),
    distinctUntilChanged()
  );
  @Output() floorChanged: Observable<number | undefined> =
    this.scanReloaded$.pipe(
      filter((isOpen: boolean) => isOpen),
      switchMap(() => this._matterport.currentFloor$),
      distinctUntilChanged(),
      tap((floor) => (this._latestFloor = floor))
    );

  readonly cameraMovement$ = this.scanReloaded$.pipe(
    filter((scanReloaded: boolean) => scanReloaded),
    switchMap(() =>
      this._matterport.positionChange$.pipe(
        skip(1),
        takeUntil(this._destroyCameraMoveObserver)
      )
    ),
    filter((cameraPose: Camera.Pose | null) => cameraPose !== null),
    filter(() => this.focus),
    filter(() => !this.reloadingMatterport),
    map((cameraPose: Camera.Pose | null) => {
      if (cameraPose === null)
        throw new Error('Camera pose is null during camera movement');

      return this._matterport.lastKnownCameraPose(cameraPose);
    }),
    tap((camera) => (this.latestCameraPose = camera))
  );

  ngOnInit(): void {
    this._init();
  }
  ngOnDestroy(): void {
    this._destroySource.next();
    this._destroySource.complete();
  }

  moveToCamera(camera: Camera.Pose) {
    if (camera.mode === 'mode.transitioning' || this.reloadingMatterport)
      return of(undefined);

    this.latestCameraPose = camera;
    return camera.mode === 'mode.inside'
      ? this._moveToCamera(camera)
      : this._moveCameraDollHouse(camera);
  }

  checkAndSwitchComponentByCamera(camera: Camera.Pose) {
    if (
      this.focus ||
      this.selectedStageId === undefined ||
      (this.latestCameraPose && camera.sweep === this.latestCameraPose.sweep)
    )
      return of(camera);

    const { x, y, z } = camera.position;

    return this.foundComponentByVector3(
      new Vector3(x, y, z),
      this.selectedStageId
    ).pipe(
      take(1),
      tap((stageComponent: StageComponent) => {
        if (this._selectedStage.getValue() !== stageComponent) {
          this.latestCameraPose = camera;
          this._selectedStage.next(stageComponent);
        }
      }),
      map(() => camera)
    );
  }

  changeMatterport(stageId: string) {
    firstValueFrom(
      this.foundComponentByVector3(this.lastCameraPosition, stageId).pipe(
        tap((stageComponent: StageComponent) => {
          this._selectedStage.next(stageComponent);
        })
      )
    );
  }

  setMode(mode: Mode.Mode | null) {
    if (this.reloadingMatterport || mode === null || this.focus) return;

    firstValueFrom(
      this._matterport.currentMode$.pipe(
        switchMap((currentMode) => {
          if (currentMode !== mode) {
            return this._matterport.changeMode$(mode);
          }
          return of(undefined);
        })
      )
    );
  }

  setFloor(floor: number | undefined) {
    const floorChanged = this._matterport.currentFloor?.sequence === floor;
    if (
      floor === undefined ||
      this.reloadingMatterport ||
      this.focus ||
      floorChanged
    )
      return;

    firstValueFrom(this._matterport.changeFloor$(floor));
  }

  private _init() {
    this._selectedStage
      .pipe(
        switchMap((stage: StageComponent | undefined) => {
          if (stage === undefined) throw new Error('Stage is undefined!');
          this._reloadingMatterport.next(true);
          this._destroyCameraMoveObserver.next();
          return this._createAndOpenScan(this.container, stage).pipe(
            catchError((e) => {
              console.log(e);
              return of(e);
            })
          );
        }),
        filter((status: CustomPhases) => status === CustomPhases.PLAYING),
        delay(1000),
        switchMap(() => this._setInitialPosition()),
        switchMap(() => this._setInitialFloor()),
        tap(() => this._reloadingMatterport.next(false)),
        takeUntil(this._destroySource)
      )
      .subscribe();
  }

  private _setInitialFloor() {
    if (this._initFloor === undefined) return of(undefined);

    return this._matterport.changeFloor$(this._initFloor);
  }

  private _setInitialPosition() {
    if (this.latestCameraPose === undefined) return of(undefined);

    return this.latestCameraPose.mode === 'mode.inside'
      ? this._moveToCamera(this.latestCameraPose)
      : this._moveCameraDollHouse(this.latestCameraPose);
  }

  private _moveCameraDollHouse(camera: Camera.Pose) {
    const transformConverter = this._matterportManager.transformConverter;
    const rotation = transformConverter.toMatterportRotation(
      new Vector3(camera.rotation.x, camera.rotation.y)
    );

    const position = transformConverter.toMatterportPosition(
      new Vector3(camera.position.x, camera.position.y, camera.position.z)
    );

    return this._matterport.moveToPose$({
      ...camera,
      position: position,
      rotation: rotation
    });
  }

  private _moveToCamera(camera: Camera.Pose) {
    return this._matterport.moveToClosestSweepWithOffset$({
      position: camera.position,
      rotation: camera.rotation,
      translation: Transition.INSTANT,
      transitionTime: 0
    } as MoveToClosestSweepConfig);
  }

  private _createAndOpenScan(
    container: ViewContainerRef,
    component: StageComponent
  ) {
    return this._matterport.createAndOpenScan$(
      container,
      ShowcaseHelper.findShowcaseId(component.componentUrl),
      component.offset,
      undefined,
      SupportedLinks.EN
    );
  }

  private foundComponentByVector3(value: Vector3, stageId: string) {
    return this._componentsFacade.allComponents$.pipe(
      take(1),
      map((components: StageComponent[]) => {
        const componentsSelected = selectSyncComponentsByStage(
          stageId,
          components
        );

        const anySweepExist = components.find(
          (stageComponent: StageComponent) => stageComponent.sweeps
        );
        if (anySweepExist === undefined) return componentsSelected[0];

        const foundedComponent = ComponentHelper.findClosestId(
          value,
          componentsSelected
        );
        return foundedComponent;
      })
    );
  }
}
