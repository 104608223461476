import { OverlayModule } from '@angular/cdk/overlay';
import { CommonModule } from '@angular/common';
import { Component, HostBinding, Input, inject } from '@angular/core';
import { Media } from '@simlab/data-access';
import { NotesFacade } from '@simlab/data-store';
import { DesignIconButton } from '@simlab/design/button';
import { DesignIcon } from '@simlab/design/icon';
import { UiButtonModule } from '@simlab/ui/button';
import { UiIconModule } from '@simlab/ui/icon';
import { DownloadFilesService } from '@simlab/util-shared';
import { Observable, defer, firstValueFrom, switchMap, tap } from 'rxjs';
import { BaseMedia } from '../../../directives/base-media.directive';

@Component({
    selector: 'feature-stages-note-media-document-item',
    imports: [
        CommonModule,
        OverlayModule,
        UiIconModule,
        UiButtonModule,
        DesignIconButton,
        DesignIcon
    ],
    templateUrl: './note-media-document-item.component.html',
    styleUrls: ['./note-media-document-item.component.scss']
})
export class NoteMediaDocumentItemComponent extends BaseMedia {
  private readonly _downloadFile = inject(DownloadFilesService);
  private readonly _notesFacade = inject(NotesFacade);
  @HostBinding('class.docs') class = true;

  /**
   * Open and close overlay menu
   */
  public isOpen = false;
  public selectedMenuIdx!: number;

  @Input() idx!: number;

  readonly document$: Observable<Media> = defer(() =>
    this.getSingleBlobSecureUrl(this.media)
  );

  open(index: number): void {
    this.isOpen = !this.isOpen;
    this.selectedMenuIdx = index;
  }

  close(): void {
    this.isOpen = false;
  }

  download(item: Media): void {
    const { name, url } = this._getDownloadParams(item);
    this.isOpen = false;

    this._downloadFile.downloadFileByLink(url, name, '_blank');
  }

  private _getDownloadParams(item: Media) {
    const params = { name: '', url: '' };

    if ('procoreDrawingId' in item) {
      params.name = this._getPreparedProcoreDrawingName(item.name);
      params.url = item.pdfUrl as string;
    } else {
      params.name = item.name;
      params.url = item.privateUrl as string;
    }

    return params;
  }

  private _getPreparedProcoreDrawingName(actualName: string): string {
    // NOTE (Łukasz) procore drawing documents should have pdf extension for corrected download
    const procoreDrawingExtension = '.pdf';
    if (actualName.includes(procoreDrawingExtension)) return actualName;

    return `${actualName}${procoreDrawingExtension}`;
  }

  delete(item: Media): void {
    firstValueFrom(
      this._notesFacade.selectedNoteId$.pipe(
        switchMap((selectedNoteId: string) =>
          this.deleteMedia$(item, selectedNoteId)
        ),
        tap(() => {
          this._notesFacade.removeDigitalNote(item.id, 'document');
          this.isOpen = false;
        })
      )
    );
  }
}
