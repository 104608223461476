import { DOCUMENT } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  Injector,
  ProviderToken,
  ViewEncapsulation,
  inject
} from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { ActivatedRoute, Data, NavigationEnd, Router } from '@angular/router';
import {
  BREADCRUMB_CONST,
  BREADCRUMB_LABEL,
  TBreadcrumb
} from '@simlab/data-access';
import {
  BehaviorSubject,
  Observable,
  filter,
  forkJoin,
  map,
  mergeMap,
  startWith,
  take
} from 'rxjs';

export interface BreadcrumbEntry {
  label: string;
  path: string;
}

export interface RouteEntry {
  tokens: ProviderToken<unknown>[];
  function: (
    ...params: unknown[]
  ) => Observable<{ path: string; label?: string }>;
}

export interface UiBreadcrumbData {
  adjacent?: RouteEntry;
  current: RouteEntry;
}

@Component({
    selector: 'ui-breadcrumb',
    templateUrl: './breadcrumb.component.html',
    styleUrls: ['./breadcrumb.component.scss'],
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
    // eslint-disable-next-line @angular-eslint/no-host-metadata-property
    host: {
        class: 'ui-breadcrumb'
    },
    standalone: false
})
export class BreadcrumbComponent {
  private readonly router = inject(Router);
  private readonly document = inject(DOCUMENT);
  private readonly _onRouterNavigationEnd$ = this.router.events.pipe(
    filter((event) => event instanceof NavigationEnd),
    map((event) => (<NavigationEnd>event).url)
  );

  readonly breadcrumbEntries = toSignal(
    this._onRouterNavigationEnd$.pipe(
      startWith(this.document.location.pathname),
      mergeMap(() => forkJoin(this._resolveTokens(this.route.firstChild))),
      map((e) => {
        return e.reduce(
          (uniqueBreadcrumb: BreadcrumbEntry[], nextBreadcrumb) => {
            if (
              !uniqueBreadcrumb.some(
                (entry) => entry.path === nextBreadcrumb.path
              )
            ) {
              uniqueBreadcrumb.push(nextBreadcrumb);
            }
            return uniqueBreadcrumb;
          },
          []
        );
      })
    )
  );

  constructor(
    private readonly route: ActivatedRoute,
    private readonly injector: Injector
  ) {}

  private _resolveTokens(
    firstChild: ActivatedRoute | null
  ): Observable<BreadcrumbEntry>[] {
    let currentChild: ActivatedRoute | undefined | null = firstChild;
    const tokens: Observable<BreadcrumbEntry>[] = [];

    do {
      const data: Data | undefined = (<BehaviorSubject<Data>>(
        currentChild?.data
      )).getValue();

      const breadcrumbData: UiBreadcrumbData | undefined = data['breadcrumb'];

      if (!data || !breadcrumbData) {
        continue;
      }

      const adjacentData: RouteEntry | undefined = breadcrumbData?.adjacent;

      if (adjacentData) {
        const params = adjacentData.tokens.map(
          (token: ProviderToken<unknown>) => this.injector.get(token)
        );

        const stream$: Observable<{ path: string; label?: string }> =
          adjacentData.function(...params);

        tokens.push(
          stream$.pipe(
            map(
              (bred: { path: string; label?: string }) =>
                <BreadcrumbEntry>{
                  label: bred.label || bred.path,
                  path: bred.path
                }
            ),
            take(1)
          )
        );
      }

      const currentData: RouteEntry | undefined = breadcrumbData?.current;

      if (currentData) {
        const params = currentData.tokens.map((token: ProviderToken<unknown>) =>
          this.injector.get(token)
        );

        const stream$: Observable<{ path: string; label?: string }> =
          currentData.function(...params);

        tokens.push(
          stream$.pipe(
            map(
              (bred: { path: string; label?: string }) =>
                <BreadcrumbEntry>{
                  label: bred.label || bred.path,
                  path: this.document.location.pathname.substring(
                    0,
                    this.document.location.pathname.lastIndexOf('/')
                  )
                }
            ),
            take(1)
          )
        );
      }
    } while ((currentChild = currentChild?.firstChild));

    return tokens;
  }

  trackByEntryLabel(index: number, entry: BreadcrumbEntry): string {
    return entry.label;
  }

  private translateLabel(value: TBreadcrumb) {
    const val = value.toLowerCase();
    if (Object.values(BREADCRUMB_CONST).includes(val)) {
      return BREADCRUMB_LABEL[value];
    }
    return value;
  }
}
