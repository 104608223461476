import { Injector } from '@angular/core';
import { toObservable } from '@angular/core/rxjs-interop';
import { GlobalFiltersStore } from '@simlab/annotations-filters/services';
import { NoteFiltersStore } from '@simlab/feature-stages/services';
import { ProcoreFiltersStore } from './procore-filters.store';
import { startWith, EMPTY, combineLatest, map } from 'rxjs';

export const filtersChangesObserver$ = (
  globalFiltersStore: InstanceType<typeof GlobalFiltersStore>,
  procoreFiltersStore: InstanceType<typeof ProcoreFiltersStore> | null,
  noteFiltersStore: InstanceType<typeof NoteFiltersStore>,
  injector: Injector
) => {
  const procoreFilters$ =
    procoreFiltersStore?.filter !== undefined
      ? toObservable(procoreFiltersStore.filter, { injector }).pipe(
          startWith(procoreFiltersStore.filter())
        )
      : EMPTY;

  return combineLatest([
    toObservable(globalFiltersStore.filter, { injector }).pipe(
      startWith(globalFiltersStore.filter())
    ),
    procoreFilters$,
    toObservable(noteFiltersStore.filter, { injector }).pipe(
      startWith(noteFiltersStore.filter())
    )
  ]).pipe(
    map(([globalFilters, procoreFilters, noteFilters]) => ({
      globalFilters,
      procoreFilters,
      noteFilters
    }))
  );
};
