@if (assignment(); as assignment) {
  <procore-info-item-container
    label="Name"
    i18n-label="@@PUNCH_ITEM_ASSIGNEES_NAME"
  >
    {{ assignment.loginInformation.name }}
  </procore-info-item-container>

  <div class="punch-item-assignment__wrapper">
    <procore-info-item-container
      label="Date Notified"
      i18n-label="@@PUNCH_ITEM_ASSIGNEES_DATE_NOTIFIED"
    >
      {{ assignment.notifiedAt | formatDateSignal }}
    </procore-info-item-container>

    <procore-info-item-container
      label="Date Resolved"
      i18n-label="@@PUNCH_ITEM_ASSIGNEES_DATE_RESOLVED"
    >
      {{ assignment.managerAcceptedAt | formatDateSignal }}
    </procore-info-item-container>
  </div>

  <div class="punch-item-assignment__wrapper">
    <procore-info-item-container
      label="Date Ready For Review"
      i18n-label="@@PUNCH_ITEM_ASSIGNEES_DATE_READY_FOR_REVIEW"
    >
      {{ assignment.notAcceptedAt | formatDateSignal }}
    </procore-info-item-container>

    <procore-info-item-container
      label="Date Work Not Accepted"
      i18n-label="@@PUNCH_ITEM_ASSIGNEES_DATE_WORK_NOT_ACCEPTED"
    >
      {{ assignment.respondedAt | formatDateSignal }}
    </procore-info-item-container>
  </div>

  <procore-info-item-container
    label="Response"
    i18n-label="@@PUNCH_ITEM_ASSIGNEES_RESPONSE"
  >
    <div class="punch-item-assignment__response">
      {{ assignment.status | getPunchItemAssignmentStatusLabel }}

      <button design-icon-button (click)="openResponseModal()">
        <i designIcon="edit_second" designSize="20px"></i>
      </button>
    </div>
  </procore-info-item-container>

  <design-comment
    [attachments]="assignment.attachments"
    [comment]="assignment.comment || '-'"
    [isEditable]="false"
  />
}
