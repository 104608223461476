<div class="content-wrapper" [ngClass]="{ error: hasError$ | async }">
  <div class="container-label" *ngIf="!hideLabel">
    <ng-content select="label"></ng-content>
    <span class="required-marker" *ngIf="!hideRequiredMarker">{{ ' *' }}</span>
  </div>
  <div class="container-field">
    <ng-content select="ui-input"></ng-content>
    <ng-content select="input"></ng-content>
    <ng-content select="ui-select"></ng-content>
    <ng-content select="ui-upload-image"></ng-content>
    <ng-content select="ui-datepicker"></ng-content>
    <ng-content select="ui-textarea"></ng-content>
    <ng-content select="ui-content-editable"></ng-content>
  </div>
  <div class="container-hint" *ngIf="!hideHint">
    <ng-content select="ui-hint" #hint></ng-content>
  </div>
</div>
