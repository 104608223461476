import {
  ChangeDetectionStrategy,
  Component,
  computed,
  output,
  signal
} from '@angular/core';
import { RFIStatus } from '@simlab/procore/models';
import { RFIIconTypeGetterPipe } from '@simlab/procore/rfi/ui';
import {
  AnnotationActionsBase,
  annotationsActionsToken
} from 'common/annotation-panel/models';
import { AnnotationPanelRootComponent } from 'common/annotation-panel/ui';

const TRANSLATIONS = {
  createNewRFI: $localize`:@@RFI_FORM_CREATE_NEW:New RFI`,
  editRFI: $localize`:@@RFI_FORM_EDIT:Edit RFI`
};

@Component({
  selector: 'procore-rfi-form-side-panel',
  imports: [AnnotationPanelRootComponent, RFIIconTypeGetterPipe],
  templateUrl: './rfi-form-side-panel.component.html',
  styleUrl: './rfi-form-side-panel.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: annotationsActionsToken,
      useExisting: RfiFormSidePanelComponent
    }
  ]
})
export class RfiFormSidePanelComponent implements AnnotationActionsBase {
  protected readonly translations = TRANSLATIONS;

  protected readonly name = computed(() => {
    const isInEditMode = this.isInEditMode();

    return isInEditMode
      ? this.translations.editRFI
      : this.translations.createNewRFI;
  });

  // NOTE (Łukasz F) mocked right now, in future data from store
  protected readonly isLoading = signal<boolean>(false);
  protected readonly isInEditMode = signal<boolean>(false);
  protected readonly status = signal<RFIStatus>(RFIStatus.Open);

  readonly onClosePanel = output<void>();

  closePanel() {
    this.onClosePanel.emit();
  }
}
