//NOTE: Enable to use types from google.maps
/// <reference types="google.maps" />

import { BooleanInput, coerceBooleanProperty } from '@angular/cdk/coercion';
import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Inject,
  Input,
  OnDestroy,
  Output,
  ViewChild,
} from '@angular/core';

import { GOOGLE_BASE_WINDOW, GoogleMapBaseWindow } from '@simlab/data-access';
import { FunctionResponse } from '@simlab/matterport/index';

import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { InputComponent } from '../../ui-input/components/input/input.component';
import { Place } from '../models/address';
import { Coordinates } from '../models/coordinates';
import { Location } from '../models/location';

const COORDINATES_SIMLAB: google.maps.LatLngAltitudeLiteral = {
  lat: 50.2775896223192,
  lng: 18.687398107781842,
  altitude: 0,
};

@Component({
    selector: 'ui-map',
    templateUrl: './ui-map.component.html',
    styleUrls: ['./ui-map.component.scss'],
    standalone: false
})
export class UiMapComponent implements OnDestroy, AfterViewInit {
  private readonly _destroySource: Subject<void> = new Subject<void>();
  private readonly _initialCoordinates = COORDINATES_SIMLAB;
  private readonly _zoomStep = 1;

  geolocationSupport = false;

  private readonly _markerSetted: BehaviorSubject<boolean> =
    new BehaviorSubject<boolean>(false);

  readonly markerSetted: Observable<boolean> =
    this._markerSetted.asObservable();

  @ViewChild('mapElement') mapElement!: ElementRef;
  @ViewChild('inputComponent') inputComponent!: InputComponent;

  @Output() coordinatesEmitter: EventEmitter<Location> =
    new EventEmitter<Location>();

  private _geolocation: Coordinates | undefined;
  @Input() set geolocation(value: Coordinates) {
    if (value.latitude && value.longitude) {
      this._initialCoordinates.lat = value.latitude;
      this._initialCoordinates.lng = value.longitude;
      this._geolocation = value;
      this._markerSetted.next(true);
    }
  }

  @Input()
  set setUsedExternally(value: BooleanInput) {
    this.usedExternally = coerceBooleanProperty(value);
  }
  usedExternally = false;

  @Input()
  get readonly(): boolean {
    return this._readonly;
  }
  set readonly(value: BooleanInput) {
    this._readonly = coerceBooleanProperty(value);
    this.geolocationSupport = !this._readonly;
    if (!this._readonly) {
      this._marker?.setDraggable(true);
    }
  }
  private _readonly = false;

  private _address: string | undefined;
  @Input() set address(value: string) {
    this._address = value;
  }

  private _geocoder?: google.maps.Geocoder;
  private _map!: google.maps.Map;
  private _marker?: google.maps.Marker;

  private _searchBoxListenerEvent: google.maps.MapsEventListener | undefined;
  private _markerListenerEvent: google.maps.MapsEventListener | undefined;

  constructor(
    @Inject(GOOGLE_BASE_WINDOW)
    private readonly googleMapWindow: GoogleMapBaseWindow
  ) {
    this.geolocationSupport = navigator.geolocation ? true : false;
  }

  ngAfterViewInit(): void {
    this._init();
  }

  ngOnDestroy(): void {
    this._markerListenerEvent?.remove();
    this._searchBoxListenerEvent?.remove();
    this._destroySource.next();
    this._destroySource.complete();
  }

  private _init(): void {
    this._map = new google.maps.Map(this.mapElement.nativeElement, {
      center: new google.maps.LatLng(this._initialCoordinates),
      zoom: 10,
      mapTypeId: google.maps.MapTypeId.ROADMAP,
      streetViewControl: false,
      disableDefaultUI: true,
      zoomControl: false,
    });

    this._geocoder = new google.maps.Geocoder();
    this._map.addListener('tilesloaded', () => {
      this._addCustomButtonsToMap();
      this._searchBoxListenerEvent = this._inputElement();
      this._setInitialMarker();
      this._markerListenerEvent = this._markerObserver();
    });
  }

  private _addCustomButtonsToMap(): void {
    const customButtons = document.getElementById('customButtons');

    if (customButtons) {
      this._map.controls[google.maps.ControlPosition.BOTTOM_RIGHT].push(
        customButtons
      );
      customButtons.style.display = 'flex';
    }
  }

  private _setInitialMarker(): void {
    if (this._geolocation) {
      this._marker = new google.maps.Marker({
        position: this._initialCoordinates,
        map: this._map,
        draggable: !this.readonly,
      });
      this._map.setCenter(this._initialCoordinates);
      this.inputComponent.inputElement.nativeElement.value = this._address;
    }
  }

  private _setMarket(
    value: google.maps.LatLngLiteral | google.maps.LatLng
  ): void {
    if (this._marker) {
      this._marker?.setPosition(value);
      return;
    }

    this._markerListenerEvent?.remove();
    this._marker = new google.maps.Marker({
      position: value,
      map: this._map,
      draggable: true,
    });
    this._markerListenerEvent = this._markerObserver();
  }

  private _inputElement(): google.maps.MapsEventListener | undefined {
    const searchBox = new google.maps.places.Autocomplete(
      this.inputComponent.inputElement.nativeElement
    );

    return searchBox.addListener('place_changed', () => {
      const places = searchBox.getPlace();

      const bounds = new google.maps.LatLngBounds();
      if (!places.geometry || !places.geometry.location) return;

      if (places.geometry.viewport) {
        bounds.union(places.geometry.viewport);
      } else {
        bounds.extend(places.geometry.location);
      }
      this._map.fitBounds(bounds);

      this._setMarket(places.geometry.location);

      const geolocation: Coordinates = {
        latitude: places.geometry.location.lat(),
        longitude: places.geometry.location.lng(),
      };

      this._componentOutput({
        coordinates: geolocation,
        address: places.formatted_address,
      });
    });
  }

  private _markerObserver(): google.maps.MapsEventListener | undefined {
    if (!this._geocoder) return;

    return this._marker?.addListener('mouseup', () => {
      if (this._marker) {
        this._geocoder
          ?.geocode({ location: this._marker.getPosition() })
          .then((response: google.maps.GeocoderResponse) => {
            if (response.results[0]) {
              this.inputComponent.inputElement.nativeElement.value =
                response.results[0].formatted_address;
              this._componentOutput({
                coordinates: {
                  latitude: response.results[0].geometry.location.lat(),
                  longitude: response.results[0].geometry.location.lng(),
                },
                address: response.results[0].formatted_address,
              });
            }
          });
      }
    });
  }

  setGeolocation(): void {
    navigator.geolocation.getCurrentPosition((position) => {
      const pos = {
        lat: position.coords.latitude,
        lng: position.coords.longitude,
      };
      this._setMarket(pos);
      this._map.setCenter(pos);

      this._geocoder
        ?.geocode({ location: pos })
        .then((response: google.maps.GeocoderResponse) => {
          this.inputComponent.inputElement.nativeElement.value =
            response.results[0].formatted_address;

          this._componentOutput({
            coordinates: {
              latitude: position.coords.latitude,
              longitude: position.coords.longitude,
            },
            address: response.results[0].formatted_address,
          });
        });
    });
  }

  zoomIn(): void {
    const zoom = this._map.getZoom();
    if (zoom) {
      this._map.setZoom(zoom + this._zoomStep);
    } else {
      this._map.setZoom(1);
    }
  }

  zoomOut(): void {
    const zoom = this._map.getZoom();
    if (zoom) {
      this._map.setZoom(zoom - this._zoomStep);
    }
  }

  setLocalizationByPlace(place: Place): void {
    const position: Partial<google.maps.LatLngAltitudeLiteral> = {
      lat: place.coordinates.latitude,
      lng: place.coordinates.longitude,
    };
    this._setMarket(position as google.maps.LatLngAltitudeLiteral);
    this._map.setCenter(position as google.maps.LatLngAltitudeLiteral);

    this.inputComponent.inputElement.nativeElement.value = place.address;
  }

  private _componentOutput(payload: Location): void {
    this._markerSetted.next(true);
    this.coordinatesEmitter.emit(payload);

    if (this.googleMapWindow.onGeolocation) {
      this.googleMapWindow?.onGeolocation(<FunctionResponse<Location>>{
        status: 'Success',
        data: payload,
      });
    }
  }

  inputValueChange(value: string | number): void {
    if (!value) {
      this.googleMapWindow.onEmptyAddress(<FunctionResponse<boolean>>{
        status: 'Success',
        data: true,
      });
    }
  }
}
