<design-radio-expansion-panel>
  <design-expansion-panel-header color="secondary">
    <div designLabel>
      <span class="punch-item-more-details__title" i18n="MORE_DETAILS_TITLE"
        >More details</span
      >
    </div>
  </design-expansion-panel-header>

  <ng-template designRadioExpansionPanelContent>
    @if (form(); as form) {
      <div class="punch-item-more-details__form" [formGroup]="form">
        @let locationControl = form.controls.locationId;
        @let distributionMemberIdsControl = form.controls.distributionMemberIds;
        @let priorityControl = form.controls.priority;
        @let punchItemTypeIdControl = form.controls.punchItemTypeId;
        @let scheduleImpactForm = form.controls.scheduleImpact;
        @let costImpactForm = form.controls.costImpact;
        @let costCodeIdControl = form.controls.costCodeId;
        @let tradeIdControl = form.controls.tradeId;
        @let referenceControl = form.controls.reference;

        @if (locationControl) {
          <procore-location-select [control]="locationControl" />
        }

        @if (distributionMemberIdsControl) {
          <ui-form-field>
            <label designLabel i18n="@@MORE_DETAILS_DISTRIBUTION"
              >Distribution</label
            >
            <ui-select
              i18n-placeholder="@@SELECT"
              placeholder="Select"
              formControlName="distributionMemberIds"
              [multiple]="true"
            >
              <design-form-field class="punch-item-more-details__form__search">
                <i
                  class="suffix"
                  designSuffix
                  designIcon="search"
                  designSize="1.3rem"
                ></i>
                <input
                  designInput
                  i18n-placeholder="@@SEARCH"
                  placeholder="Search"
                  [formControl]="filterDistributionMembers"
                />
              </design-form-field>

              @for (user of filteredDistributionMembers(); track user.id) {
                <ui-option [value]="user.id">
                  {{ user.name }}
                </ui-option>
              }
            </ui-select>
            <ui-hint
              uiRawColor="red"
              [validationErrors]="distributionMemberIdsControl.errors"
              [hidden]="
                distributionMemberIdsControl.untouched ||
                distributionMemberIdsControl.valid
              "
            ></ui-hint>
          </ui-form-field>
        }

        @if (priorityControl) {
          <ui-form-field>
            <label designLabel i18n="@@MORE_DETAILS_PRIORITY">Priority</label>
            <ui-select
              i18n-placeholder="@@SELECT"
              placeholder="Select"
              formControlName="priority"
            >
              @for (priority of priorityOptions; track priority) {
                <ui-option [value]="priority">
                  {{ priority | getProcorePriorityLabel }}
                </ui-option>
              }
            </ui-select>
            <ui-hint
              uiRawColor="red"
              [validationErrors]="priorityControl.errors"
              [hidden]="priorityControl.untouched || priorityControl.valid"
            ></ui-hint>
          </ui-form-field>
        }

        @if (punchItemTypeIdControl) {
          <ui-form-field>
            <label designLabel i18n="@@MORE_DETAILS_TYPE">Type</label>
            <ui-select
              i18n-placeholder="@@SELECT"
              placeholder="Select"
              formControlName="punchItemTypeId"
            >
              @for (type of punchItemTypes(); track type.id) {
                <ui-option [value]="type.id">
                  {{ type.name }}
                </ui-option>
              }
            </ui-select>
            <ui-hint
              uiRawColor="red"
              [validationErrors]="punchItemTypeIdControl.errors"
              [hidden]="
                punchItemTypeIdControl.untouched || punchItemTypeIdControl.valid
              "
            ></ui-hint>
          </ui-form-field>
        }

        @if (scheduleImpactForm) {
          <procore-schedule-impact
            [form]="scheduleImpactForm"
          ></procore-schedule-impact>
        }

        @if (costImpactForm) {
          <procore-cost-impact [form]="costImpactForm"></procore-cost-impact>
        }

        @if (costCodeIdControl) {
          <ui-form-field>
            <label designLabel i18n="@@MORE_DETAILS_COST_CODES"
              >Cost Codes</label
            >
            <ui-select
              i18n-placeholder="@@SELECT"
              placeholder="Select"
              formControlName="costCodeId"
            >
              @for (code of costCodes(); track code.id) {
                <ui-option [value]="code.id">
                  {{ code.fullCode }}
                </ui-option>
              }
            </ui-select>
            <ui-hint
              uiRawColor="red"
              [validationErrors]="costCodeIdControl.errors"
              [hidden]="costCodeIdControl.untouched || costCodeIdControl.valid"
            ></ui-hint>
          </ui-form-field>
        }

        @if (tradeIdControl) {
          <ui-form-field>
            <label designLabel i18n="@@MORE_DETAILS_TRADE">Trade</label>
            <ui-select
              i18n-placeholder="@@SELECT"
              placeholder="Select"
              formControlName="tradeId"
            >
              @for (trade of procoreTrades(); track trade.id) {
                <ui-option [value]="trade.id">
                  {{ trade.name }}
                </ui-option>
              }
            </ui-select>
            <ui-hint
              uiRawColor="red"
              [validationErrors]="tradeIdControl.errors"
              [hidden]="tradeIdControl.untouched || tradeIdControl.valid"
            ></ui-hint>
          </ui-form-field>
        }

        @if (referenceControl) {
          <design-form-field>
            <label designLabel i18n="@@MORE_DETAILS_REFERENCE">
              Reference
            </label>
            <input designInput formControlName="reference" />
            <design-error></design-error>
          </design-form-field>
        }
      </div>
    }
  </ng-template>
</design-radio-expansion-panel>
