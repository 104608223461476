import {
  ChangeDetectionStrategy,
  Component,
  ViewEncapsulation,
} from '@angular/core';

@Component({
    selector: 'design-status-badge',
    imports: [],
    templateUrl: './status-badge.component.html',
    styleUrl: './status-badge.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush,
    encapsulation: ViewEncapsulation.None,
    host: {
        class: 'design-status-badge',
    }
})
export class StatusBadgeComponent {}
