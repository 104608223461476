import { HttpErrorResponse } from '@angular/common/http';
import { inject } from '@angular/core';
import { AbstractConstructor, Constructor } from '@simlab/design/internal';
import { ToastService } from '@simlab/design/toast';
import { ApiProcoreService } from '@simlab/procore/data-access';
import { procoreBaseInfoPayload } from '@simlab/procore/models';
import { catchError, Observable, of } from 'rxjs';

export type PunchItemConditionallyPostComment = {
  conditionallyPostComment$(
    procoreId: number,
    comment?: string,
    formFiles?: File[]
  ): Observable<boolean | void>;
};

type TPunchItemComment = Constructor<PunchItemConditionallyPostComment> &
  AbstractConstructor<PunchItemConditionallyPostComment>;
export function mixinPunchItemConditionallyPostComment<
  T extends AbstractConstructor<any>
>(base: T): TPunchItemComment & T;

export function mixinPunchItemConditionallyPostComment<
  T extends Constructor<any>
>(base: T) {
  return class extends base implements PunchItemConditionallyPostComment {
    private readonly _api = inject(ApiProcoreService);
    private readonly _toastService = inject(ToastService);
    private readonly _baseInfoProcore = inject(procoreBaseInfoPayload);

    conditionallyPostComment$(
      procoreId: number,
      comment?: string,
      formFiles?: File[]
    ) {
      const baseInfoProcore = this._baseInfoProcore();

      if (!comment || baseInfoProcore === undefined) {
        return of(true);
      }

      const { procoreProjectId, procoreCompanyId } = baseInfoProcore;

      return this._api
        .createPunchItemComment({
          procoreCompanyId,
          procoreProjectId,
          procorePunchItemId: procoreId,
          comment: comment || '',
          formFiles: formFiles || [],
          prostoreFileIds: []
        })
        .pipe(
          catchError((e: HttpErrorResponse) => {
            this._toastService.open(
              $localize`:@@COMMENT_ADD_ERROR:An error occured while adding a comment.`,
              'Error'
            );
            console.error(e);
            return of(true);
          })
        );
    }
  };
}
