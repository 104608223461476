import { FocusableOption, FocusMonitor, FocusOrigin } from '@angular/cdk/a11y';
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  HostBinding,
  Input,
  OnDestroy,
  ViewEncapsulation,
} from '@angular/core';
import { BUTTON_HOST_ATTRIBUTES } from '../../models/attributes';
import { OnLoading } from '../../models/on-loading';

export abstract class ButtonBase implements OnLoading {
  loading = false;
  abstract loadingBegin(): void;
  abstract loadingEnd(): void;
}
@Component({
    selector: `button[ui-button], button[ui-raised-button], button[ui-stroked-button],  button[ui-flat-button], button[ui-icon-button], button[ui-fab-button], button[ui-mini-fab-button]`,
    exportAs: 'uiButton',
    templateUrl: './button.component.html',
    styleUrls: ['./button.component.scss'],
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [{ provide: ButtonBase, useExisting: ButtonComponent }],
    standalone: false
})
export class ButtonComponent
  extends ButtonBase
  implements FocusableOption, AfterViewInit, OnDestroy
{
  @Input()
  disabled?: boolean = false;

  @HostBinding('class.ui-button-disabled')
  get uiButtonDisabled(): boolean {
    return (this.disabled ?? false) || (this.loading ?? false);
  }
  private _iTagElement?: HTMLElement;
  @HostBinding('attr.disabled')
  get attrDisabled(): boolean | null {
    return this.disabled || this.loading || null;
  }

  @HostBinding('attr.aria-disabled')
  get attrAriaDisabled(): string {
    return ((this.disabled ?? false) || (this.loading ?? false)).toString();
  }

  constructor(
    private readonly elementRef: ElementRef,
    private focusMonitor: FocusMonitor,
    private readonly changeDetectorRef: ChangeDetectorRef
  ) {
    super();
    const hostElement = this.getHostElement();
    BUTTON_HOST_ATTRIBUTES.forEach((attribute: string) => {
      if (hostElement.hasAttribute(attribute)) {
        hostElement.classList.add(attribute);
      }
    });

    hostElement.classList.add('ui-button-base');
  }

  ngAfterViewInit(): void {
    this.focusMonitor.monitor(this.elementRef, true);

    this._iTagElement = this.getHostElement().getElementsByTagName('i')[0];
  }

  ngOnDestroy(): void {
    this.focusMonitor.stopMonitoring(this.elementRef);
  }

  loadingBegin(): void {
    this.loading = true;
    if (this._iTagElement) {
      this._iTagElement.style.display = 'none';
    }
    this.changeDetectorRef.markForCheck();
  }

  loadingEnd(): void {
    this.loading = false;
    if (this._iTagElement) {
      this._iTagElement.style.display = 'initial';
    }
    this.changeDetectorRef.markForCheck();
  }

  focus(origin?: FocusOrigin, options?: FocusOptions): void {
    if (origin) {
      this.focusMonitor.focusVia(this.getHostElement(), origin, options);
    } else {
      this.getHostElement().focus(options);
    }
  }

  private getHostElement(): HTMLElement {
    return this.elementRef.nativeElement;
  }
}
