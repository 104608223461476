import { DIALOG_DATA, DialogRef } from '@angular/cdk/dialog';

import {
  ChangeDetectionStrategy,
  Component,
  ComponentRef,
  inject,
  OnDestroy,
  OnInit,
  viewChild,
  ViewContainerRef,
  ViewEncapsulation
} from '@angular/core';
import {
  DesignFlatButton,
  DesignIconButton,
  DesignStrokedButton
} from '@simlab/design/button';
import { DesignDialogWrapperModule } from '@simlab/design/dialog';
import { DesignIcon } from '@simlab/design/icon';
import { FiltersData } from '@simlab/documents/data-access';

export type FiltersModalData = {
  formComponentRef: ComponentRef<FiltersData>;
};

@Component({
    selector: 'documents-filters-modal',
    imports: [
    DesignDialogWrapperModule,
    DesignIcon,
    DesignStrokedButton,
    DesignFlatButton,
    DesignIconButton
],
    templateUrl: './filters-modal.component.html',
    styleUrl: './filters-modal.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush,
    encapsulation: ViewEncapsulation.None,
    host: {
        class: 'documents-filters-modal'
    }
})
export class FiltersModalComponent implements OnDestroy, OnInit {
  private readonly _dialogRef = inject(DialogRef);
  private readonly _dynamicComponentOutlet = viewChild.required(
    'dynamicComponentOutlet',
    { read: ViewContainerRef }
  );

  private readonly _formComponentRef =
    inject<FiltersModalData>(DIALOG_DATA).formComponentRef;
  private readonly _formComponentInstance = this._formComponentRef.instance;

  ngOnInit(): void {
    this._dynamicComponentOutlet().clear();
    this._dynamicComponentOutlet().insert(this._formComponentRef.hostView);
  }

  ngOnDestroy(): void {
    this._dynamicComponentOutlet().clear();
  }

  protected onApply(): void {
    this._formComponentInstance.applyFilters();

    this._dialogRef.close(this._formComponentInstance.value());
  }

  protected onCancel(): void {
    this._dialogRef.close(undefined);
  }

  protected resetFilters(): void {
    this._formComponentInstance.resetFilters();
  }
}
