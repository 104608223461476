import { inject } from '@angular/core';
import { AbstractConstructor, Constructor } from '@simlab/design/internal';
import { ApiProcoreService } from '@simlab/procore/data-access';
import {
  procoreBaseInfoPayload,
  PunchItem,
  PunchListWorkflowStatus
} from '@simlab/procore/models';
import { EMPTY, exhaustMap, map, Observable } from 'rxjs';
import {
  mixinPunchItemConditionallyPostComment,
  PunchItemConditionallyPostComment
} from './punch-item-conditionally-add-comment.base';

export type PunchItemUpdateWorkflowAndComment = {
  updateWorkflowStatusAndConditionallyPostComment$(
    procoreId: number,
    workflowStatus: PunchListWorkflowStatus,
    comment?: string,
    formFiles?: File[]
  ): Observable<PunchItem>;
} & PunchItemConditionallyPostComment;

type TPunchItemUpdateWorkflowAndComment =
  Constructor<PunchItemUpdateWorkflowAndComment> &
    AbstractConstructor<PunchItemUpdateWorkflowAndComment>;
export function mixinPunchItemUpdateWorkflowAndComment<
  T extends AbstractConstructor<any>
>(base: T): TPunchItemUpdateWorkflowAndComment & T;

export function mixinPunchItemUpdateWorkflowAndComment<
  T extends Constructor<PunchItemConditionallyPostComment>
>(base: T) {
  return mixinPunchItemConditionallyPostComment(
    class extends base implements PunchItemUpdateWorkflowAndComment {
      private readonly _api = inject(ApiProcoreService);
      private readonly _baseInfoProcore = inject(procoreBaseInfoPayload);

      updateWorkflowStatusAndConditionallyPostComment$(
        procoreId: number,
        workflowStatus: PunchListWorkflowStatus,
        comment?: string,
        formFiles?: File[]
      ) {
        const baseInfoProcore = this._baseInfoProcore();
        if (baseInfoProcore === undefined) {
          console.error('baseInfoProcore is undefined');
          return EMPTY;
        }

        const { procoreCompanyId, procoreProjectId } = baseInfoProcore;
        return this._api
          .updatePunchItemWorkflowStatus({
            procoreCompanyId,
            procoreProjectId,
            procoreId,
            workflowStatus
          })
          .pipe(
            exhaustMap((punchItem) =>
              this.conditionallyPostComment$(
                procoreId,
                comment,
                formFiles
              ).pipe(map(() => punchItem))
            )
          );
      }
    }
  );
}
