/* eslint-disable @angular-eslint/no-host-metadata-property */
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  forwardRef,
  Inject,
  ViewEncapsulation,
} from '@angular/core';
import { DrawerContentComponent } from './drawer-content.component';
import { SidenavContainerComponent } from './sidenav-container/sidenav-container.component';

@Component({
    selector: 'ui-sidenav-content',
    template: `<ng-content></ng-content>`,
    changeDetection: ChangeDetectionStrategy.OnPush,
    encapsulation: ViewEncapsulation.None,
    host: {
        class: 'ui-drawer-content ui-sidenav-content',
        '[style.margin-left.px]': '_container.contentMargins.left',
        '[style.margin-right.px]': '_container.contentMargins.right',
    },
    standalone: false
})
export class SidenavContentComponent extends DrawerContentComponent {
  constructor(
    @Inject(forwardRef(() => SidenavContainerComponent))
    private readonly container: SidenavContainerComponent,
    private readonly changeDetectorRef: ChangeDetectorRef
  ) {
    super(container, changeDetectorRef);
  }
}
