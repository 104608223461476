@if (path().length > numberOfRenderedElementsOnScreen()) {
  <button
    design-icon-button
    type="button"
    class="menu-trigger-btn"
    [designMenuTriggerFor]="list"
  >
    <i designIcon="more_horiz" designSize="1.2rem"></i>
  </button>

  <design-menu-panel #list customPanelClass="breadcrumb-menu">
    @for (
      item of slicedPath().sliceForMenu;
      track item.id;
      let first = $first
    ) {
      <button
        design-menu-item
        type="button"
        class="element"
        (click)="onNavigate(item)"
      >
        <ng-container
          *ngTemplateOutlet="listElement; context: { $implicit: item, first }"
        ></ng-container>
      </button>
    }
  </design-menu-panel>

  @for (item of slicedPath().sliceForFirstScreen; track item.id) {
    <ng-container *ngTemplateOutlet="listElementIcon"></ng-container>

    <li (click)="onNavigate(item)" class="element element--without-list-style">
      <ng-container
        *ngTemplateOutlet="listElement; context: { $implicit: item }"
      ></ng-container>
    </li>
  }
} @else {
  @for (item of path(); track item.id; let first = $first) {
    @if (!first) {
      <ng-container *ngTemplateOutlet="listElementIcon"></ng-container>
    }
    <li (click)="onNavigate(item)" class="element element--without-list-style">
      <ng-container
        *ngTemplateOutlet="listElement; context: { $implicit: item, first }"
      ></ng-container>
    </li>
  }
}

<ng-template #listElementIcon>
  <li class="element--without-list-style">
    <i
      designIconPrefix
      designIcon="arrow_right"
      designSize="24px"
      class="arrow-icon"
    ></i>
  </li>
</ng-template>

<ng-template #listElement let-item let-first="first">
  @if (showIcon() || first || item.icon) {
    <i
      designIconPrefix
      [designIcon]="item.icon ?? (first ? 'cloud_filled' : 'folder')"
      designSize="20px"
      class="element__icon"
      [ngClass]="{
        'element__icon--first overlay-element__icon--first': first,
        'element__icon--has-label': item.name,
      }"
    ></i>
  }
  <div class="element__text overlay-element__text" [title]="item.name">
    {{ item.name }}
  </div>
</ng-template>
