@if (!isPunchItemLoading()) {
<div class="punch-item-information-additional">
  <div class="punch-item-information-additional__wrapper">
    <procore-info-item-container
      label="Schedule Impact"
      i18n-label="@@PUNCH_ITEM_INFORMATION_SCHEDULE_IMPACT"
    >
      {{ scheduleImpact() }}
    </procore-info-item-container>

    <procore-info-item-container
      label="Cost Impact"
      i18n-label="@@PUNCH_ITEM_INFORMATION_COST_IMPACT"
    >
      {{ costImpact() }}
    </procore-info-item-container>
  </div>

  <procore-info-item-container
    label="Cost Codes"
    i18n-label="@@PUNCH_ITEM_INFORMATION_COST_CODES"
  >
    {{ codeCode() }}
  </procore-info-item-container>

  <procore-info-item-container
    label="Linked Drawings"
    i18n-label="@@PUNCH_ITEM_INFORMATION_LINKED_DRAWINGS"
  >
    {{ linkedDrawings() }}
  </procore-info-item-container>

  <procore-info-item-container
    label="Reference"
    i18n-label="@@PUNCH_ITEM_INFORMATION_REFERENCE"
  >
    {{ reference() }}
  </procore-info-item-container>

  <procore-info-item-container
    label="Stages Link"
    i18n-label="@@PUNCH_ITEM_INFORMATION_STAGES_LINK"
  >
    {{ stagesLink() }}
  </procore-info-item-container>
</div>
}
