import {
  ChangeDetectionStrategy,
  Component,
  input,
  output
} from '@angular/core';
import {
  DesignButtonGroupComponent,
  DesignButtonToggleComponent
} from '@simlab/design/button-group';
import { DesignIcon } from '@simlab/design/icon';
import { ViewDocumentType } from '@simlab/documents/services';

type ViewType = ViewDocumentType | undefined;

@Component({
    selector: 'documents-view-switch',
    imports: [
        DesignButtonToggleComponent,
        DesignButtonGroupComponent,
        DesignIcon
    ],
    templateUrl: './documents-view-switch.component.html',
    styleUrl: './documents-view-switch.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class DocumentsViewSwitchComponent {
  readonly view = input.required<ViewType>();
  readonly switchView = output<ViewType>();

  protected onSwitchView(event: string | number | undefined) {
    const view = event as ViewType;
    this.switchView.emit(view);
  }
}
