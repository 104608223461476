import {
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  Output,
  ViewChild
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { IMAGE_MIME_TYPE_EXTENSIONS } from '@simlab/data-access';

@Component({
  selector: 'ui-upload-image',
  templateUrl: './upload-image.component.html',
  styleUrls: ['./upload-image.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: UploadImageComponent
    }
  ],
  host: {
    '[attr.data-cy]': '"upload-image"'
  },
  standalone: false
})
export class UploadImageComponent implements ControlValueAccessor {
  readonly acceptExtension = IMAGE_MIME_TYPE_EXTENSIONS.filter(
    (extension) => !extension.includes('svg')
  ).join(', ');
  @ViewChild('photo') inputImage!: ElementRef;
  private _disabled = false;
  private _selectedImage!: string;

  @Output()
  selected = new EventEmitter<File>();

  @Input()
  public get disabled(): boolean {
    return this._disabled;
  }
  public set disabled(value: boolean) {
    this._disabled = value;
  }

  private _value!: File;
  public get value(): File {
    return this._value;
  }
  public set value(value: File) {
    this._value = value;
  }

  @HostListener('click')
  upload() {
    this.inputImage.nativeElement.click();
  }

  //NOTE: onChange // registerOnChange // registerOnTouched
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  onTouch = () => {};
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  onChange: (value: any) => void = () => {};

  writeValue(obj: any): void {
    this._value = obj;
  }
  registerOnChange(fn: any): void {
    this.onChange = fn;
  }
  registerOnTouched(fn: any): void {
    this.onTouch = fn;
  }
  setDisabledState?(isDisabled: boolean): void {
    this._disabled = isDisabled;
  }

  get selectedImage(): string {
    return this._selectedImage;
  }

  processFile(inputImage: any) {
    if (inputImage.target?.files) {
      const reader = new FileReader();
      const file: File = inputImage.target?.files[0];
      if (file) {
        this.selected.emit(file);
        reader.addEventListener('load', (event: any) => {
          this._selectedImage = event.target.result;
        });
        reader.readAsDataURL(file);
        this._value = file;
        this.onChange(file);
        this.inputImage.nativeElement.value = '';
      }
    }
  }
}
