import {
  ChangeDetectionStrategy,
  Component,
  computed,
  inject,
  output,
  Signal
} from '@angular/core';
import {
  DesignFlatButton
} from '@simlab/design/button';
import { FormatDateSignalPipe } from '@simlab/design/format-date';
import { TEMP_PUNCH_ITEM_ID } from '@simlab/procore/annotation-panel/data-access';
import { PunchItemsListStore } from '@simlab/procore/services';
import {
  AnnotationActionsBase,
  annotationsActionsToken
} from 'common/annotation-panel/models';
import { AnnotationPanelRootComponent } from 'common/annotation-panel/ui';
import { PunchItemFormState } from '../../../../services/src/lib/punch-item-form.store';
import { GeneralInformationFormComponent } from '../general-information-form/general-information-form.component';
import { MoreDetailsFormComponent } from '../more-details-form/more-details-form.component';
import { PunchItemPanelLocation3DComponent } from '../punch-item-panel-location-3d/punch-item-panel-location-3d.component';
import { PunchItemFormSidePanelBase } from './punch-item-form-side-panel.base';

@Component({
  selector: 'procore-punch-item-form-side-panel',
  standalone: true,
  imports: [
    AnnotationPanelRootComponent,
    GeneralInformationFormComponent,
    MoreDetailsFormComponent,
    DesignFlatButton,
    PunchItemPanelLocation3DComponent
  ],
  templateUrl: './punch-item-form-side-panel.component.html',
  styleUrl: './punch-item-form-side-panel.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    PunchItemFormState,
    {
      provide: annotationsActionsToken,
      useExisting: PunchItemFormSidePanelComponent
    },
    FormatDateSignalPipe
  ]
})
export class PunchItemFormSidePanelComponent
  extends PunchItemFormSidePanelBase
  implements AnnotationActionsBase
{
  private readonly _store = inject(PunchItemsListStore);
  protected isLoading = this.state.isLoading;
  protected punchItem = this.state.punchItem;
  protected isSavingInProgress = this.state.isSavingInProgress;
  protected isPunchItemSelected = computed(() => {
    const punchItem = this.punchItem();
    return !!punchItem.id && punchItem.id !== TEMP_PUNCH_ITEM_ID;
  });
  protected panelHeaderTextNew = $localize`:@@PUNCH_ITEM_FORM_CREATE_NEW:New Punch List Item`;
  protected panelHeaderTextEdit = $localize`:@@PUNCH_ITEM_FORM_EDIT:Edit Punch Item`;
  protected readonly hasMarker: Signal<boolean> = computed(() => {
    return this._store.itemSelected()?.marker !== undefined;
  });
  readonly onClosePanel = output<void>();

  cancel(): void {
    if (this.formGroup()?.controls.id.value === TEMP_PUNCH_ITEM_ID) {
      this.formGroup()?.reset();
    } else {
      this.formGroup()?.patchValue(
        this.convertPunchItemToFormValues(this.punchItem())
      );
    }
  }

  closePanel(): void {
    this.onClosePanel.emit();
  }

  save(): void {
    this.state.savePunchItem();
  }
}
