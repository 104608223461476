/* eslint-disable prefer-const */
import { CommonModule, formatDate } from '@angular/common';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Inject,
  LOCALE_ID,
  OnDestroy,
  OnInit
} from '@angular/core';
import {
  ReactiveFormsModule,
  UntypedFormControl,
  UntypedFormGroup
} from '@angular/forms';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import {
  DesignFlatButton,
  DesignStrokedButton
} from '@simlab/design/button';
import { DesignCommonModule } from '@simlab/design/common';
import { DesignDialogWrapperModule } from '@simlab/design/dialog';
import { DesignIcon } from '@simlab/design/icon';

import { UiAudioModule } from '@simlab/ui/audio';
import { UiButtonModule } from '@simlab/ui/button';
import { UiDividerModule } from '@simlab/ui/divider';
import { UiFormFieldModule } from '@simlab/ui/form-field';
import { UiIconModule } from '@simlab/ui/icon';
import { UiInputModule } from '@simlab/ui/input';
import { ConfirmationModalRef } from '@simlab/ui/modal';
import { DesignFabButton } from 'libs/design/button/src/lib/components/fab-button/fab-button.component';
import {
  BehaviorSubject,
  Observable,
  Subject,
  defer,
  filter,
  map,
  race,
  takeUntil,
  tap,
  timer
} from 'rxjs';
import { AUDIO_SCRIPT_DIR } from './load-audio-script';
import { RecordAudioAnimation } from './record-audio-animation';

export const MAX_TICK_TIME = 60;
declare const WebAudioRecorder: any;
export const ENCODING_TYPE = 'mp3';
export const ENCODE_AFTER_RECORD = true;
export const TIME_LIMIT = 120;
export const QUALITY = 0.5;
export const BIT_RATE = 160;

export type Recording = {
  file: Blob;
  url: SafeUrl;
};

@Component({
    selector: 'feature-stages-record-audio-dialog',
    templateUrl: './record-audio-dialog.component.html',
    styleUrls: ['./record-audio-dialog.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    imports: [
    DesignIcon,
    UiButtonModule,
    DesignCommonModule,
    UiIconModule,
    DesignDialogWrapperModule,
    CommonModule,
    UiDividerModule,
    ReactiveFormsModule,
    UiAudioModule,
    UiFormFieldModule,
    UiInputModule,
    DesignFlatButton,
    DesignFabButton,
    DesignStrokedButton
],
    animations: [RecordAudioAnimation]
})
export class RecordAudioDialogComponent implements OnDestroy, OnInit {
  private readonly _destroySource = new Subject<void>();
  private readonly _stopRecorderSource = new Subject<void>();
  private _mediaRecorder!: MediaRecorder;

  private readonly _recordingSource: BehaviorSubject<Recording | null> =
    new BehaviorSubject<Recording | null>(null);

  readonly recording$: Observable<Recording> = this._recordingSource
    .asObservable()
    .pipe(
      filter((source: Recording | null) => !!source),
      map((source: Recording | null) => <Recording>source)
    );

  private _state = false;

  dialogFormGroup = new UntypedFormGroup({
    title: new UntypedFormControl('')
  });

  get title(): string {
    return this.dialogFormGroup.get('title')?.value;
  }

  private _permissionDenied = false;
  private _unknownError = false;
  get permissionDenied(): boolean {
    return this._permissionDenied;
  }

  get hasError(): boolean {
    return this._permissionDenied || this._unknownError;
  }

  readonly counter$: Observable<string> = defer(() => {
    return timer(0, 1000).pipe(
      takeUntil(race(this._destroySource, this._stopRecorderSource)),
      tap((tick: number) => {
        if (tick === MAX_TICK_TIME) {
          this._state = false;
          this._stopRecording();
        }
      }),
      map((tick: number) => {
        const minutes = Math.floor(tick / MAX_TICK_TIME);
        let seconds: number | string = tick - minutes * MAX_TICK_TIME;

        if (seconds < 10) {
          seconds = `0${seconds}`;
        }

        return `${minutes}:${seconds}` as string;
      })
    );
  });

  get state(): boolean {
    return this._state;
  }

  get hasRecording(): boolean {
    return !!this._recordingSource.value;
  }

  private _audioRecorder: any;
  private _mediaStream!: MediaStream;

  constructor(
    @Inject(LOCALE_ID) private readonly locale: string,
    @Inject(AUDIO_SCRIPT_DIR)
    private readonly audioScriptDir: string,
    private readonly modalRef: ConfirmationModalRef<{
      recording: Blob | null;
      title: string;
    }>,
    private readonly changeDetectorRef: ChangeDetectorRef,
    private readonly sanitizer: DomSanitizer
  ) {}

  ngOnInit(): void {
    this._initUserMedia();
  }

  private _initUserMedia(): void {
    try {
      this._connectToUserMedia();
    } catch (e) {
      this._unknownError = true;
      this.changeDetectorRef.markForCheck();
    }
  }

  ngOnDestroy(): void {
    this._disposeStream();
    this._destroySource.next();
    this._destroySource.complete();
  }

  toggle(): void {
    this._state = !this._state;

    if (this._state) {
      this._audioRecorder.startRecording();
    } else {
      this._stopRecording();
    }

    this.changeDetectorRef.markForCheck();
  }

  save(): void {
    if (this._recordingSource.value === null) {
      return;
    }

    const { file } = this._recordingSource.value;

    this._stopRecording();
    this.modalRef.emit({
      state: true,
      result: {
        recording: file,
        title: this.title
          ? this.title
          : 'Audio ' + formatDate(Date.now(), 'yyyy-MM-d HH:mm:ss', this.locale)
      }
    });
    this.modalRef.close();
  }

  close(): void {
    this._stopRecording();
    this.modalRef.emit({ state: false });
    this.modalRef.close();
  }

  private _toSafeUrlRecording(recording: Blob | MediaSource): SafeUrl {
    const audioURL = window.URL.createObjectURL(recording);
    return this.sanitizer.bypassSecurityTrustUrl(audioURL);
  }

  /**
   *
   */
  private _connectToUserMedia(): void {
    const constraints: MediaStreamConstraints = {
      audio: true,
      preferCurrentTab: true
    };

    if ('getUserMedia' in navigator.mediaDevices) {
      navigator.mediaDevices.getUserMedia(constraints).then(
        (stream: MediaStream) => this._onSuccess(stream),
        (error: DOMException) => this._onError(error)
      );
    } else {
      throw new Error('getUserMedia not supported by browser!');
    }
  }

  /**
   *
   * @param stream
   */
  private _onSuccess(stream: MediaStream): void {
    const audioContext = new AudioContext();

    this._mediaStream = stream;

    this._audioRecorder = new WebAudioRecorder(
      audioContext.createMediaStreamSource(stream),
      {
        workerDir: this.audioScriptDir,
        encoding: ENCODING_TYPE
      }
    );

    this._audioRecorder.onComplete = (recorder: any, blob: Blob) => {
      const recording = <Recording>{
        file: blob,
        url: this._toSafeUrlRecording(blob)
      };

      this._recordingSource.next(recording);
      this.changeDetectorRef.markForCheck();
      this._stopRecorderSource.next();
      this._initUserMedia();
    };

    this._audioRecorder.setOptions({
      timeLimit: TIME_LIMIT,
      encodeAfterRecord: ENCODE_AFTER_RECORD,
      ogg: {
        quality: QUALITY
      },
      mp3: {
        bitRate: BIT_RATE
      }
    });
  }

  /**
   *
   * @param error
   */
  private _onError(error: DOMException): void {
    this._permissionDenied = true;
    this._initUserMedia();
    this.changeDetectorRef.markForCheck();
  }

  /**
   *
   */
  private _disposeStream(): void {
    this._mediaRecorder?.stream
      .getTracks()
      .forEach((track: MediaStreamTrack) => track.stop());
  }

  /**
   *
   * @returns
   */
  private _stopRecording(): void {
    this._mediaStream?.getAudioTracks()[0].stop();
    this._audioRecorder?.finishRecording();
  }
}

//error when we dont have access to microphone!
//Error DOMException: Permission denied
