import {
  ChangeDetectionStrategy,
  Component,
  computed,
  inject,
  input,
  ViewEncapsulation
} from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { FormControl, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { DesignIconButton } from '@simlab/design/button';
import { DesignCommentComponent } from '@simlab/design/comment';
import { DesignLabel, DesignSuffix } from '@simlab/design/common';
import { DesignDatepickerInput } from '@simlab/design/datepicker';
import {
  DesignFormField,
  DesignFormFieldError
} from '@simlab/design/form-field';
import { DesignIcon } from '@simlab/design/icon';
import { DesignInput } from '@simlab/design/input';
import { ApiProcoreService } from '@simlab/procore/data-access';
import {
  procoreBaseInfoPayload,
  PunchItemForm,
  PunchItemAssignee
} from '@simlab/procore/models';
import { UiCheckboxModule } from '@simlab/ui/checkbox';
import { UiDatepickerModule } from '@simlab/ui/datepicker';
import { UiFormFieldModule } from '@simlab/ui/form-field';
import { UiHintModule } from '@simlab/ui/hint';
import { UiInputModule } from '@simlab/ui/input';
import { UiSelectModule } from '@simlab/ui/select';
import { UiTextareaModule } from '@simlab/ui/textarea';
import { derivedAsync } from 'ngxtension/derived-async';
import { of } from 'rxjs';

@Component({
  selector: 'procore-general-information-form',
  standalone: true,
  imports: [
    ReactiveFormsModule,
    UiFormFieldModule,
    UiInputModule,
    DesignFormField,
    DesignIcon,
    DesignInput,
    DesignSuffix,
    UiSelectModule,
    UiTextareaModule,
    UiCheckboxModule,
    UiDatepickerModule,
    UiHintModule,
    DesignDatepickerInput,
    DesignFormFieldError,
    DesignLabel,
    DesignIconButton,
    DesignCommentComponent
  ],
  templateUrl: './general-information-form.component.html',
  styleUrl: './general-information-form.component.scss',
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
  host: {
    class: 'general-information'
  }
})
export class GeneralInformationFormComponent {
  private readonly _apiProcoreService = inject(ApiProcoreService);
  private readonly _projectInfo = inject(procoreBaseInfoPayload);

  readonly form = input.required<FormGroup<PunchItemForm>>();

  protected get descriptionControl() {
    return this.form().controls.description;
  }

  protected get isPunchItemErrorHintVisible() {
    const punchItemManagerControl = this.form().get('punchItemManagerId');
    return !(
      punchItemManagerControl?.errors && punchItemManagerControl?.touched
    );
  }

  protected readonly availableManagers = derivedAsync(
    () => {
      const projectInfo = this._projectInfo();
      if (projectInfo === undefined) return of([]);

      return this._apiProcoreService.getAvailablePunchItemManagers(projectInfo);
    },
    { initialValue: [] }
  );

  protected readonly assignees = derivedAsync(
    () => {
      const projectInfo = this._projectInfo();
      if (projectInfo === undefined) return of([]);

      return this._apiProcoreService.getPunchListAssigneeOptions(projectInfo);
    },
    { initialValue: [] }
  );

  protected readonly filterManager = new FormControl('', { nonNullable: true });
  protected readonly filterManagerValueChanges = toSignal(
    this.filterManager.valueChanges,
    { initialValue: '' }
  );
  protected readonly filterAssignees = new FormControl('', {
    nonNullable: true
  });
  protected readonly filterAssigneesValueChanges = toSignal(
    this.filterAssignees.valueChanges,
    { initialValue: '' }
  );
  protected readonly filterFinalApprover = new FormControl('', {
    nonNullable: true
  });
  protected readonly filterFinalApproverValueChanges = toSignal(
    this.filterFinalApprover.valueChanges,
    { initialValue: '' }
  );

  protected readonly filterManagerAssignees = computed(() => {
    const Assignees = this.availableManagers();
    const filterValue = this.filterManagerValueChanges();

    return this._filterAssignees(Assignees, filterValue);
  });

  protected readonly filterAssigneeAssignees = computed(() => {
    const Assignees = this.assignees();
    const filterValue = this.filterAssigneesValueChanges();

    return this._filterAssignees(Assignees, filterValue);
  });

  protected readonly filterFinalApproverAssignees = computed(() => {
    const Assignees = this.assignees();
    const filterValue = this.filterFinalApproverValueChanges();

    return this._filterAssignees(Assignees, filterValue);
  });

  private _filterAssignees(
    Assignees: PunchItemAssignee[],
    filterValue: string
  ): PunchItemAssignee[] {
    return Assignees.filter((collaborator) => {
      return collaborator.name
        .toLocaleLowerCase()
        .includes(filterValue.toLocaleLowerCase());
    });
  }
}
