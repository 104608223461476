import {
  ChangeDetectionStrategy,
  Component,
  HostBinding,
  inject,
  Input,
  OnDestroy,
  viewChild,
  ViewEncapsulation
} from '@angular/core';
import { CommentBase } from '@simlab/data-access';
import { MentionComponent, MentionData } from '@simlab/design/mention';

import { AsyncPipe, NgClass, NgIf, NgTemplateOutlet } from '@angular/common';
import { DesignFlatButton, DesignIconButton } from '@simlab/design/button';
import { FormatDatePipe } from '@simlab/design/format-date';
import { DesignIcon } from '@simlab/design/icon';
import { UiMenuPanelModule } from '@simlab/design/menu-panel';
import { UiMenuModule } from '@simlab/ui/menu';
import {
  Observable,
  Subject,
  firstValueFrom,
  take,
  takeUntil,
  tap
} from 'rxjs';
import { ProjectLimitsService } from '../../services/project-limits.service';
import { AddCommentComponent } from '../add-comment/add-comment.component';
import { CommentsService } from '../comment-list/comments.service';
import { AddEditDeleteCommentsPipe } from './add-edit-delete-comments.pipe';
import { ToastService } from '@simlab/design/toast';
import { CommentLengthBase } from '../comment-list/comment-length.base';

const COMMENT_REMOVED = $localize`:@@COMMENT_REMOVED:Comment has been removed`;

@Component({
    selector: 'feature-stages-comment',
    templateUrl: './comment.component.html',
    styleUrls: ['./comment.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    encapsulation: ViewEncapsulation.None,
    imports: [
        NgTemplateOutlet,
        NgIf,
        NgClass,
        FormatDatePipe,
        UiMenuModule,
        UiMenuPanelModule,
        AsyncPipe,
        DesignIcon,
        DesignIconButton,
        DesignFlatButton,
        MentionComponent,
        AddCommentComponent,
        AddEditDeleteCommentsPipe
    ]
})
export class CommentComponent extends CommentLengthBase implements OnDestroy {
  private readonly _toastService = inject(ToastService);
  private readonly _commentsService = inject(CommentsService);
  private readonly _projectLimitsService = inject(ProjectLimitsService);

  @HostBinding('class.feature-stages-comment') class = true;
  private _mention = viewChild.required(MentionComponent);
  private readonly _destroySource = new Subject<void>();
  showReply = false;
  readonly = true;
  @Input() mentionData: MentionData[] = [];
  @Input()
  set comment(value: CommentBase) {
    this._comment = value;
  }
  get comment(): CommentBase {
    return this._comment;
  }

  get commentText(): string {
    return this.comment.isRemoved ? COMMENT_REMOVED : this.comment.text;
  }

  private _comment!: CommentBase;

  readonly projectIsActive$: Observable<boolean> =
    this._projectLimitsService.projectIsActive$;


  ngOnDestroy(): void {
    this._destroySource.next();
    this._destroySource.complete();
  }

  removeComment(commentId: string) {
    this._commentsService
      .removeComment$(commentId)
      .pipe(takeUntil(this._destroySource), take(1))
      .subscribe();
  }

  async saveChanges(mention: MentionComponent): Promise<void> {
    if (this.readonly) return;

    this.readonly = true;
    const text = await mention.contentTextValue;
    if (this._comment.text === text) return;

    const maxLength = this.maxLength();
    if (maxLength && text.length > maxLength) {
      this._toastService.open(this.maxLengthErrorMessage(), 'Error');
      this._mention().changeValue(this.commentText);
      this.commentLength.set(0);
    } else {
      firstValueFrom(
        this._commentsService
          .editComment$(this._comment.id, text)
          .pipe(takeUntil(this._destroySource))
      );
    }
  }

  addReply(text: string): void {
    if (text) {
      this._commentsService
        .replayToComment$(this.comment.id, text)
        .pipe(
          tap(() => {
            this.showReply = false;
          }),
          takeUntil(this._destroySource),
          take(1)
        )
        .subscribe();
    }
  }
}
