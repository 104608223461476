import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  inject,
  Input,
  Output,
  ViewChild
} from '@angular/core';
import { MediaInfo, MediaSlideShow, MediaType } from '@simlab/data-access';

@Component({
    selector: 'ui-slideshow',
    templateUrl: './ui-slideshow.component.html',
    styleUrls: ['./ui-slideshow.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class UiSlideshowComponent {
  @Output() readonly currentMedia = new EventEmitter<MediaInfo>();
  @ViewChild('photoElement', { read: ElementRef }) photoElement!: ElementRef;

  private readonly _elementRef = inject(ElementRef);
  private _elements!: MediaSlideShow[];
  private _type!: MediaType;
  private _currentIndex = 0;

  get hasNext() {
    return (
      this.collectionLength > 0 && this.collectionLength > this.currentIndex
    );
  }

  get hasPrevious() {
    return this.currentIndex > 0;
  }

  get collectionLength(): number {
    return (this._elements?.length ?? 0) - 1;
  }

  imageLoaded = false;

  @Input()
  set collection(value: MediaSlideShow[]) {
    this._elements = value;
  }

  get collection(): MediaSlideShow[] {
    return this._elements;
  }

  @Input()
  set type(type: MediaType) {
    this._type = type;
  }

  get type(): MediaType {
    return this._type;
  }

  @Input()
  set currentIndex(index: number) {
    this._currentIndex = index;
    if (this.collectionLength >= 0) {
      this.currentMedia.emit(this.collection[index]);
    }
  }

  get currentIndex(): number {
    return this._currentIndex;
  }

  @HostListener('document:keyup.ArrowLeft', ['$event'])
  previousElement(): void {
    if (!this.hasPrevious) {
      return;
    }

    this.currentIndex--;
    this.imageLoaded = false;
  }

  @HostListener('document:keyup.ArrowRight', ['$event'])
  nextElement(): void {
    if (!this.hasNext) {
      return;
    }

    this.currentIndex++;
    this.imageLoaded = false;
  }

  getDimensions(): { width: number; height: number } {
    return {
      height: this.photoElement.nativeElement.height,
      width: this.photoElement.nativeElement.width
    };
  }
}
