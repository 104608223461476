import {
  booleanAttribute,
  ChangeDetectionStrategy,
  Component,
  computed,
  inject,
  input
} from '@angular/core';
import { CommentWithFiles } from '@simlab/design/comment';
import {
  PunchItemStateService,
  PunchItemUpdateStatus
} from '@simlab/procore/annotation-panel/services';
import {
  PunchItemPanelBaseDirective,
  PunchItemSendItemComponent
} from '@simlab/procore/annotation-panel/ui';
import {
  procoreBaseInfoPayload,
  PunchItemActivity,
  PunchItemAssignee,
  PunchListWorkflowStatus
} from '@simlab/procore/models';
import { PunchItemStatusIconGetterPipe } from '@simlab/procore/pipes';
import { UiImageInfoModule } from '@simlab/ui/image-info';
import { NotificationsComponent } from '../notifications/notifications.component';
import { PunchItemActivitySectionComponent } from '../punch-item-activity-section/punch-item-activity-section/punch-item-activity-section.component';
import { PunchItemPanelLocation3DComponent } from '../punch-item-panel-location-3d/punch-item-panel-location-3d.component';
import { RequestContainerComponent } from '../request-container/request-container.component';

@Component({
  selector: 'procore-punch-item-side-panel-main',
  standalone: true,
  imports: [
    NotificationsComponent,
    UiImageInfoModule,
    RequestContainerComponent,
    PunchItemStatusIconGetterPipe,
    PunchItemActivitySectionComponent,
    PunchItemPanelLocation3DComponent,
    PunchItemActivitySectionComponent,
    PunchItemSendItemComponent
  ],
  templateUrl: './punch-item-side-panel-main.component.html',
  styleUrl: './punch-item-side-panel-main.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PunchItemSidePanelMainComponent extends PunchItemPanelBaseDirective {
  private readonly _state = inject(PunchItemStateService);
  private readonly _procoreBaseInfo = inject(procoreBaseInfoPayload);

  readonly activities = input<PunchItemActivity[]>([]);
  readonly activitiesLoading = input(false, { transform: booleanAttribute });
  readonly assigneeOptions = input<PunchItemAssignee[]>();
  readonly addingCommentStatus = input<PunchItemUpdateStatus>();

  readonly isAddingCommentPending = computed(() => {
    const status = this.addingCommentStatus();
    return status === 'PENDING';
  });

  protected readonly isSendItemVisible = computed(() => {
    const displayElements = this.displayElements();
    const { workflowStatus } = this.punchItem();
    if (displayElements) {
      const { showYellowBackground } = displayElements;
      return (
        showYellowBackground &&
        workflowStatus !== PunchListWorkflowStatus.Closed
      );
    }
    return false;
  });

  protected readonly richTextDescription = computed(() => {
    const { richTextDescription } = this.punchItem();
    return richTextDescription;
  });

  protected readonly itemUrl = computed(() => {
    const { itemUrl } = this.punchItem();
    return itemUrl;
  });

  protected readonly name = computed(() => {
    const { name } = this.punchItem();
    return name;
  });

  protected readonly status = computed(() => {
    const { status } = this.punchItem();
    return status;
  });

  protected readonly showEdit = computed(
    () => !!this._state.displayStatus()?.showEdit
  );

  // get button() {
  //   return {
  //     title: $localize`:@@OPEN_IN_PROCORE:Open in Procore`,
  //     action$: this.openNewTab.openUrlInNewTabWithSafariSupport(
  //       this.punchItem().itemUrl
  //     )
  //   };
  // }

  protected commentAdded(comment: CommentWithFiles) {
    const procoreBaseInfo = this._procoreBaseInfo();

    if (procoreBaseInfo === undefined) {
      console.error('ProcoreBaseInfo is undefined');
      return;
    }

    const { procoreProjectId, procoreCompanyId } = procoreBaseInfo;
    this._state.addComment({
      comment: comment.comment,
      procoreProjectId: procoreProjectId,
      procoreCompanyId: procoreCompanyId,
      procorePunchItemId: this.punchItem().procoreId,
      formFiles: comment.files || [],
      prostoreFileIds: []
    });
  }
}
