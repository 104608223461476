import { ProcoreAnnotationFieldsetType } from './procore-annotation-type.model';
import { ProcoreBaseProjectInfo } from './procore-base.model';

export type ProjectConfigurableFieldsetPayload = {
  type: ProcoreAnnotationFieldsetType;
} & ProcoreBaseProjectInfo;

const commonDefaultFieldsetKeys = [
  'private',
  'position',
  'reference',
  'costImpact',
  'locationId',
  'costCodeId',
  'scheduleImpact'
] as const;

export const punchItemDefaultFieldsetKeys = [
  'due',
  'name',
  'priority',
  'tradeId',
  'description',
  'finalApproverId',
  'prostoreFileIds',
  'punchItemTypeId',
  'loginInformationIds',
  'punchItemManagerId',
  'distributionMemberIds',
  ...commonDefaultFieldsetKeys
] as const;

export const rfiDefaultFieldsetKeys = [
  'subject',
  'dueDate',
  'assignees',
  'subJobId',
  'drawingNumber',
  'rfiManagerId',
  'distributionIds',
  'projectStageId',
  'specificationSectionId',
  'responsibleContractorId',
  'receivedFromLoginInformationId',
  'number',
  ...commonDefaultFieldsetKeys
] as const;

export type PunchItemDefaultFieldsetKeys =
  (typeof punchItemDefaultFieldsetKeys)[number];
export type RFIDefaultFieldsetKeys = (typeof rfiDefaultFieldsetKeys)[number];

export type ProcoreConfigurableFieldset<T> =
  T extends ProcoreAnnotationFieldsetType.PunchItem
    ? PunchItemConfigurableFieldset
    : RFIConfigurableFieldset;

export type PunchItemConfigurableFieldset = {
  type: ProcoreAnnotationFieldsetType.PunchItem;
  fields: ProcoreFields<PunchItemDefaultFieldsetKeys>;
};

export type RFIConfigurableFieldset = {
  type: ProcoreAnnotationFieldsetType.RFI;
  fields: ProcoreFields<RFIDefaultFieldsetKeys>;
};

export type ProcoreFields<
  T extends PunchItemDefaultFieldsetKeys | RFIDefaultFieldsetKeys
> = {
  [key: string]: DefaultProcoreField<T> | CustomProcoreField;
};

export type PunchItemDefaultFields = {
  [key in PunchItemDefaultFieldsetKeys]: DefaultProcoreField<PunchItemDefaultFieldsetKeys>;
};

export type FieldsConfigurationByType<T> = {
  defaultFields: T extends ProcoreAnnotationFieldsetType.PunchItem
    ? PunchItemDefaultFields
    : RFIDefaultFields;
  customFields: CustomProcoreField[];
};

export type PunchItemtFieldsConfiguration =
  FieldsConfigurationByType<ProcoreAnnotationFieldsetType.PunchItem>;

export type RFIDefaultFields = {
  [key in RFIDefaultFieldsetKeys]: DefaultProcoreField<RFIDefaultFieldsetKeys>;
};

export type RFIFieldsConfiguration =
  FieldsConfigurationByType<ProcoreAnnotationFieldsetType.RFI>;

export type DefaultProcoreField<
  T extends PunchItemDefaultFieldsetKeys | RFIDefaultFieldsetKeys
> = {
  name: T;
  defaultValue?: string;
  required: boolean;
  visible: boolean;
};

export type CustomProcoreField = {
  label: string;
  name: string;
  dataType: ProcoreFieldDataType;
  column: number;
  columnWidth: number;
  customFieldDefinitionId: number;
  position: number;
  required: false;
  row: number;
  visible: boolean;
  listOfValues?: ProcoreFieldOption[];
};

export type ProcoreFieldOption = {
  id: number;
  active: boolean;
  label: string;
  position: number;
};

export enum ProcoreFieldDataType {
  Checkbox,
  Date,
  Number,
  Location,
  ProjectDirectoryUserSingle, // or Tool User - same dataType, but Project Directory User (Single Select) comes with variant: "project_directory"
  ProjectDirectoryUserMulti,
  MultiSelect,
  Dropdown,
  FileUpload,
  ReadOnlyEntry, // or Rich Text (Long), but Read Only Entry comes with variant:
  PlainTextShort,
  Company
}
