import { CdkPortal } from '@angular/cdk/portal';
import {
  Directive,
  Inject,
  InjectionToken,
  Optional,
  TemplateRef,
  ViewContainerRef,
} from '@angular/core';

export const TAB_LABEL = new InjectionToken<TabLabelDirective>('TabLabel');

/**
 * Used to provide a tab label to a tab without causing a circular dependency.
 * @docs-private
 */
export const TAB = new InjectionToken<any>('TAB');
@Directive({
    selector: '[designTabLabel]',
    providers: [{ provide: TAB_LABEL, useExisting: TabLabelDirective }],
    standalone: false
})
export class TabLabelDirective extends CdkPortal {
  constructor(
    templateRef: TemplateRef<any>,
    viewContainerRef: ViewContainerRef,
    @Inject(TAB) @Optional() public _closestTab: any,
  ) {
    super(templateRef, viewContainerRef);
  }
}
