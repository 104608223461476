import { inject, Injectable } from '@angular/core';
import { PunchItemStateService } from '@simlab/procore/annotation-panel/services';
import { PunchItemsListStore } from '@simlab/procore/services';
import { OpenNewTabService } from '@simlab/util-shared';

@Injectable()
export abstract class BasePunchItemSidePanel {
  protected readonly store = inject(PunchItemsListStore);
  protected readonly punchItemState = inject(PunchItemStateService);
  protected readonly openNewTab = inject(OpenNewTabService);
  protected readonly isPunchItemLoading = this.store.isItemLoading;
  protected readonly punchItem = this.store.itemSelected;
  protected readonly isLoading = this.punchItemState.isLoading;
  protected readonly displayStatus = this.punchItemState.displayStatus;
}
