import { Directive, input } from '@angular/core';
import { PunchItem, PunchItemDisplayElements } from '@simlab/procore/models';

@Directive({
  selector: '[punchItemPanelBase]',
  standalone: true
})
export abstract class PunchItemPanelBaseDirective {
  readonly punchItem = input.required<PunchItem>();
  readonly displayElements = input<PunchItemDisplayElements>();
  readonly isPunchItemLoading = input<boolean>();
  protected readonly noValueStr = '-';
}
