import { computed } from '@angular/core';
import {
  patchState,
  signalStore,
  withComputed,
  withMethods,
  withState
} from '@ngrx/signals';
import {
  ProcorePunchItemStatusesKeys,
  ProcoreRFIStatusesKeys
} from '@simlab/procore/models';

export type ProcoreFiltersState = {
  filter: {
    punchItemStatuses: ProcorePunchItemStatusesKeys[];
    punchItemBallInCourt: number[];
    punchItemDueDate: string | undefined;
    rfiStatuses: ProcoreRFIStatusesKeys[];
    rfiDueDate: string | Date | undefined;
    rfiBallInCourt: number[];
  };
  areFiltersActive: boolean;
};

const initialState: ProcoreFiltersState = {
  filter: {
    punchItemStatuses: [],
    punchItemBallInCourt: [],
    punchItemDueDate: undefined,
    rfiStatuses: [],
    rfiDueDate: undefined,
    rfiBallInCourt: []
  },
  areFiltersActive: false
};

const areActiveRFIFilters = (data: Partial<ProcoreFiltersState['filter']>) => {
  const { rfiStatuses, rfiBallInCourt, rfiDueDate } = data;
  return !!rfiStatuses?.length || !!rfiBallInCourt?.length || !!rfiDueDate;
};

const areActivePunchItemFilters = (
  data: Partial<ProcoreFiltersState['filter']>
) => {
  const { punchItemStatuses, punchItemBallInCourt, punchItemDueDate } = data;

  return (
    !!punchItemStatuses?.length ||
    !!punchItemBallInCourt?.length ||
    !!punchItemDueDate
  );
};

export const ProcoreFiltersStore = signalStore(
  withState(initialState),
  withMethods((store) => ({
    resetFilters(): void {
      patchState(store, () => ({
        ...initialState
      }));
    },
    setFilters(newFilter: Partial<ProcoreFiltersState['filter']>): void {
      patchState(store, ({ filter }) => ({
        filter: { ...filter, ...newFilter },
        areFiltersActive:
          areActiveRFIFilters(newFilter) || areActivePunchItemFilters(newFilter)
      }));
    }
  })),
  withComputed(({ filter }) => ({
    filtersLength: computed(
      () =>
        filter().punchItemStatuses.length +
        filter().punchItemBallInCourt.length +
        filter().rfiStatuses.length +
        filter().rfiBallInCourt.length +
        Number(!!filter().punchItemDueDate) +
        Number(!!filter().rfiDueDate)
    ),
    areActivePunchItemFilters: computed(() =>
      areActivePunchItemFilters(filter())
    ),
    areActiveRFIFilters: computed(() => areActiveRFIFilters(filter()))
  }))
);
