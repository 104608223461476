/* eslint-disable @angular-eslint/no-input-rename */
/* eslint-disable @angular-eslint/no-inputs-metadata-property */
import {
  Component,
  ElementRef,
  Input,
  Renderer2,
  ViewChild,
  ViewEncapsulation,
  inject,
} from '@angular/core';
import { DesignHelperModule } from '@simlab/design/helper';
import { CanColor, mixinColor } from '@simlab/design/internal';

const _SpinnerBase = mixinColor(
  class {
    public _elementRef = inject(ElementRef);
  },
);

@Component({
  selector: 'design-progress-spinner',
  exportAs: 'progressSpinner',
  templateUrl: './progress-spinner.component.html',
  styleUrls: ['./progress-spinner.component.scss'],
  encapsulation: ViewEncapsulation.None,
  imports: [DesignHelperModule],
  // eslint-disable-next-line @angular-eslint/no-host-metadata-property
  host: {
    class: 'design-progress-spinner',
  },
  inputs: ['color: designColor'],
})
export class DesignProgressSpinnerComponent
  extends _SpinnerBase
  implements CanColor
{
  private readonly _renderer = inject(Renderer2);

  @ViewChild('spinner') set spinner(value: ElementRef<HTMLDivElement>) {
    this._renderer.setStyle(value.nativeElement, 'display', 'block');
    this._renderer.setStyle(
      value.nativeElement,
      'height',
      `${this.diameter}px`,
    );
    this._renderer.setStyle(value.nativeElement, 'width', `${this.diameter}px`);
    this._renderer.setStyle(
      value.nativeElement,
      'border-width',
      `${this.strokeWidth}px`,
    );
  }

  private _diameter = 100;

  @Input()
  set diameter(value: number) {
    this._diameter = value;
  }

  get diameter(): number {
    return this._diameter;
  }

  private _strokeWidth = 10;

  @Input()
  get strokeWidth(): number {
    return this._strokeWidth;
  }
  set strokeWidth(value: number) {
    this._strokeWidth = value;
  }
}
