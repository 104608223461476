import { Dialog, DialogModule } from '@angular/cdk/dialog';
import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  NgZone,
  Signal,
  ViewChild,
  computed,
  inject,
  input,
  output
} from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { SystemOfMeasurement } from '@simlab/data-access';
import { DesignFlatButton } from '@simlab/design/button';
import { DesignIcon } from '@simlab/design/icon';
import { UiTooltip } from '@simlab/design/tooltip';
import {
  IRemoveAreaMeasurementElement,
  TAddMeasurementsToGroup,
  TAreaMeasurementGroupBase,
  TMeasurement,
  TMeasurementData,
  TMeasurementGroup,
  TMeasurementGroups,
  TVerticle
} from '@simlab/feature-measurement/models';
import {
  CalculateMeasurementSumPipe,
  UnitsPreferredByUserPipe
} from '@simlab/feature-measurement/pipes';
import { UiHelperModule } from '@simlab/ui/helper';
import { UiImageInfoModule } from '@simlab/ui/image-info';
import { UiMenuPanelModule } from '@simlab/design/menu-panel';
import { Observable, defer, switchMap, take, tap } from 'rxjs';
import { MeasurementPermission } from '../../../services/measurement.privileges';
import {
  DISPLAY_VALUE,
  MEASUREMENT_CONVERSION,
  TYPE_MEASUREMENT
} from '../../../tokens/token';
import { TCalculateDistanceMeasurementGroup } from '../dialogs/calculators/calculator-distance/distance-group-calculator.component';
import {
  MeasurementGroupItemComponent,
  TMeasurementGroupExtended
} from '../measurement-group-item/measurement-group-item.component';
import {
  FilterChange,
  SearchAndSortFilterComponent,
  SortOption
} from '../search-and-sort-filter/search-and-sort-filter.component';

const DESCRIPTION_IMAGE_INFO = [
  $localize`:@@AREM_NO_GROUP_INFO_1:Create a group to attach and group areas.`,
  $localize`:@@AREM_NO_GROUP_INFO_2:Sum up areas and groups of measurements.`
];

const ADD_AREA_TO_GROUP: import('../dialogs/multiselect-list/multiselect-list.component').MultiselectListData =
  {
    description: $localize`:@@AREM_SELECT_WHICH_WANT_ADD_TO_GROUP:Select the measurement(s) which you want to add to this group.`,
    title: $localize`:@@AREM_ADD_GROUP:Add to group`,
    list: []
  };
const DELETE_GROUP_CONFIRMATION: import('../dialogs/delete-measurement-group/delete-measurement-group.component').DeleteConfirmation =
  {
    description: $localize`:@@AREM_DELETE_GROUP_INFO:Are you sure you want to delete the measurement group? `,
    title: $localize`:@@AREM_DELETE_GROUP:Delete Measurement Group`
  };
const SET_GROUP_NAME: import('../dialogs/set-name/set-name.component').SetNameDialogData =
  {
    title: $localize`:@@AREM_GROUP_NAME:Measurement Group Name`,
    current: undefined,
    description: $localize`:@@AREM_GROUP_NAME_ENTER:Enter Area Measurement group name.`,
    inputLabel: $localize`:@@AREM_GROUP_NAME:Measurement Group Name`,
    inputPlaceholder: $localize`:@@AREM_SET_GROUP_NAME:Set group name`
  };

const GROUP_CONTAIN_AREA_TOOLTIP = $localize`:@@AREM_IS_IN_THIS_GROUP:This Area Measurement is in this group`;
const SORT_OPTION: SortOption[] = [
  {
    direction: 'asc',
    display: $localize`:@@NAME_SORT_A_Z:Name A-Z`,
    propertyName: 'name'
  },
  {
    direction: 'desc',
    display: $localize`:@@NAME_SORT_Z_A:Name Z-A`,
    propertyName: 'name'
  },
  {
    direction: 'asc',
    display: $localize`:@@SUMMARY_SIZE_ASC:Summary Size Asc`,
    propertyName: 'summaryDisplayValue'
  },
  {
    direction: 'desc',
    display: $localize`:@@SUMMARY_SIZE_DESC:Summary Size Desc`,
    propertyName: 'summaryDisplayValue'
  }
];

@Component({
    selector: 'feature-measurements-group',
    imports: [
    CommonModule,
    DesignFlatButton,
    UiMenuPanelModule,
    UiImageInfoModule,
    DesignIcon,
    DialogModule,
    UiTooltip,
    UiHelperModule,
    SearchAndSortFilterComponent,
    MeasurementGroupItemComponent
    // CalculateMeasurementSumPipeModule
],
    templateUrl: './measurements-group.component.html',
    styleUrl: './measurements-group.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class MeasurementsGroupComponent extends MeasurementPermission {
  private readonly _unitsPreferredByUserPipe = inject(UnitsPreferredByUserPipe);
  private readonly _typeMeasurement = inject(TYPE_MEASUREMENT);
  readonly measurmentConversion = inject(MEASUREMENT_CONVERSION);
  readonly _calculateMeasurmentSum = inject(CalculateMeasurementSumPipe, {
    optional: true
  });

  readonly descriptions = DESCRIPTION_IMAGE_INFO;
  readonly measurementsGroup = input<TMeasurementGroups[]>([]);
  readonly measurements = input<TMeasurement[]>([]);
  readonly systemOfMeasurement = input<SystemOfMeasurement>();

  readonly addMeasurementsToGroup = output<TAddMeasurementsToGroup>();
  readonly deleteMeasurementGroup = output<IRemoveAreaMeasurementElement>();
  readonly createGroup = output<string>();

  readonly goToMeasurementList = output<string>();
  readonly changeName = output<TAreaMeasurementGroupBase>();
  readonly sortOptions: SortOption[] = SORT_OPTION;
  readonly removeMeasurementFromGroup = output<{
    id: string;
    groupId: string;
  }>();

  async openCreateGroupModal() {
    if (!this.canAddMeasurementGroup()) return;
    const _dialogRef = await this._groupNameModal();
    _dialogRef.closed
      .pipe(tap((groupName) => groupName && this.createGroup.emit(groupName)))
      .subscribe();
  }

  async calculator() {
    if (this._typeMeasurement === 'measurement-area') {
      const calculatorModal = (
        await import(
          '../dialogs/calculators/calculator-area/group-calculator.component'
        )
      ).GroupCalculatorComponent;
      this._dialog.open<
        string,
        import('../dialogs/calculators/calculator-area/group-calculator.component').GroupCalculatorData,
        import('../dialogs/calculators/calculator-area/group-calculator.component').GroupCalculatorComponent
      >(calculatorModal, {
        width: 'min(90%, 450px)',
        maxHeight: 'min(750px,90dvh)',
        data: {
          data: this.measurementsGroup().map((e) => ({
            ...e,
            measurements: e.measurements.map((z) => {
              return {
                ...z,
                data: z.data as TMeasurementData
              };
            })
          })),
          units: this.systemOfMeasurement() || SystemOfMeasurement.Metric
        }
      });
    } else {
      const calculatorModal = (
        await import(
          '../dialogs/calculators/calculator-distance/distance-group-calculator.component'
        )
      ).GroupCalculatorComponent;
      this._dialog.open<
        string,
        import('../dialogs/calculators/calculator-distance/distance-group-calculator.component').GroupCalculatorData,
        import('../dialogs/calculators/calculator-distance/distance-group-calculator.component').GroupCalculatorComponent
      >(calculatorModal, {
        width: 'min(90%, 450px)',
        maxHeight: 'min(750px,90dvh)',
        data: {
          data: this.measurementsGroup().map(
            (e) =>
              ({
                ...e,
                measurements: e.measurements.map((z) => {
                  return {
                    ...z,
                    data: z.data as TVerticle[],
                    distance: 0
                  };
                })
              }) as TCalculateDistanceMeasurementGroup
          ),
          units: this.systemOfMeasurement() || SystemOfMeasurement.Metric
        }
      });
    }
  }

  private readonly _ngZone = inject(NgZone);
  @ViewChild(SearchAndSortFilterComponent, { static: true })
  filters!: SearchAndSortFilterComponent;
  readonly filters$: Observable<FilterChange> = defer(() => {
    const filters = this.filters;
    if (filters) {
      return filters.filterChange;
    }
    return this._ngZone.onStable.pipe(
      take(1),
      switchMap(() => this.filters$)
    );
  });

  readonly filtersSignal = toSignal(this.filters$);
  get units() {
    return this.systemOfMeasurement() || SystemOfMeasurement.Metric;
  }

  readonly filteredMeasurements: Signal<TMeasurementGroupExtended[]> = computed(
    () => {
      const units = this.units;
      const extendedGroup: any[] = this.measurementsGroup().map((group) => {
        if (this._calculateMeasurmentSum === null) {
          throw 'CalculateMeasurementSumPipe are not provided';
        }

        return {
          ...group,
          summaryDisplayValue: Number(
            this._calculateMeasurmentSum
              .transform(group.measurements, units)
              .toFixed(4)
          ),
          measurements: group.measurements.map((measurement) => {
            const measurementAct = this.measurements()?.find(
              (meas: TMeasurement) => meas.id === measurement.id
            );
            return {
              ...measurement,
              ...measurementAct,
              displayValue: this.displayValue(
                measurementAct || measurement,
                units,
                this._unitsPreferredByUserPipe
              )
            };
          })
        } as TMeasurementGroupExtended;
      });

      const filterValue = this.filtersSignal()?.filter || '';
      const sortByValue = this.filtersSignal()?.sortBy || '';

      return extendedGroup
        ?.filter((measurement) => {
          return `${measurement.name}${measurement.summaryDisplayValue}`
            .toLocaleLowerCase()
            .includes(filterValue.toLocaleLowerCase());
        })
        .sort((a: TMeasurementGroupExtended, b: TMeasurementGroupExtended) => {
          if (!sortByValue) return 1;
          const propertyName: keyof TMeasurementGroupExtended =
            sortByValue.propertyName as keyof TMeasurementGroupExtended;

          if (sortByValue.direction === 'asc') {
            if (a[propertyName] < b[propertyName]) {
              return -1;
            }
            if (a[propertyName] > b[propertyName]) {
              return 1;
            }
            return 0;
          } else {
            if (a[propertyName] > b[propertyName]) {
              return -1;
            }
            if (a[propertyName] < b[propertyName]) {
              return 1;
            }
            return 0;
          }
        });
    }
  );

  trackByGroupId = (_: number, item: TMeasurementGroupExtended) => item.id;
  private displayValue = inject(DISPLAY_VALUE);
  private readonly _dialog = inject(Dialog);
  async addMeasurementsToGroupModal(groupId: string, creatorId: string) {
    if (!this.canEditDeleteMeasurementGroup(creatorId)) return;
    const units = this.units;
    const multiselectList: import('../dialogs/multiselect-list/multiselect-list.component').MultiselectList[] =
      this.measurements().map((mesurment) => {
        const selectedGroup = this.measurementsGroup().find(
          ({ id: mId }) => mId === groupId
        );
        const groupContainArea =
          (selectedGroup?.measurements.findIndex(
            (area) => area.id === mesurment.id
          ) ?? -1) !== -1;

        return {
          id: mesurment.id,
          name: `${this.displayValue(mesurment, units, this._unitsPreferredByUserPipe)} ${mesurment.title}`,
          disabled: groupContainArea,
          selected: groupContainArea,
          disableDescription: `${
            groupContainArea ? GROUP_CONTAIN_AREA_TOOLTIP : ''
          }`
        } as import('../dialogs/multiselect-list/multiselect-list.component').MultiselectList;
      });
    const multiselectData: import('../dialogs/multiselect-list/multiselect-list.component').MultiselectListData =
      {
        ...ADD_AREA_TO_GROUP,
        list: multiselectList
      };
    const addMeasurementsToGroupModal = (
      await import('../dialogs/multiselect-list/multiselect-list.component')
    ).MultiselectListComponent;

    const _dialogRef = this._dialog.open<
      string[],
      import('../dialogs/multiselect-list/multiselect-list.component').MultiselectListData,
      import('../dialogs/multiselect-list/multiselect-list.component').MultiselectListComponent
    >(addMeasurementsToGroupModal, {
      width: 'min(90%, 450px)',
      maxHeight: 'min(750px,90dvh)',
      data: multiselectData
    });
    _dialogRef.closed
      .pipe(
        tap((ids: string[] | undefined) => {
          ids &&
            ids.length &&
            this.addMeasurementsToGroup.emit({ groupId, ids });
        })
      )
      .subscribe();
  }

  async openRenameGroupModal({
    id,
    name: oldName,
    creatorId
  }: TMeasurementGroup) {
    if (!this.canEditDeleteMeasurementGroup(creatorId)) return;
    const _dialogRef = await this._groupNameModal(oldName);
    _dialogRef.closed
      .pipe(tap((name) => name && this.changeName.emit({ id, name })))
      .subscribe();
  }

  async deleteGroupModal({ id, creatorId }: TMeasurementGroupExtended) {
    if (!this.canEditDeleteMeasurementGroup(creatorId)) return;
    const deleteGroupModal = (
      await import(
        '../dialogs/delete-measurement-group/delete-measurement-group.component'
      )
    ).DeleteMeasurementGroupComponent;

    const _dialogRef = this._dialog.open<
      boolean,
      import('../dialogs/delete-measurement-group/delete-measurement-group.component').DeleteConfirmation,
      import('../dialogs/delete-measurement-group/delete-measurement-group.component').DeleteMeasurementGroupComponent
    >(deleteGroupModal, {
      width: 'min(90%, 450px)',
      data: DELETE_GROUP_CONFIRMATION
    });
    _dialogRef.closed
      .pipe(
        tap(
          (deleteConfirm) =>
            deleteConfirm && this.deleteMeasurementGroup.emit({ id })
        )
      )
      .subscribe();
  }

  private async _groupNameModal(actName?: string) {
    const createGroupModal = (
      await import('../dialogs/set-name/set-name.component')
    ).SetNameComponent;
    return this._dialog.open<
      string,
      import('../dialogs/set-name/set-name.component').SetNameDialogData,
      import('../dialogs/set-name/set-name.component').SetNameComponent
    >(createGroupModal, {
      width: 'min(90%, 450px)',
      data: {
        ...SET_GROUP_NAME,
        current: actName
      }
    });
  }
}
