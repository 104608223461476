import { AddProjectDialogComponent } from './components/add-project-dialog/add-project-dialog.component';
import {
  DocumentsModalComponent,
  DocumentsModalComponentData,
  SelectedDocumentsForNotes
} from './components/documents-modal/documents-modal.component';
import { LimitReachedActivateProjectComponent } from './components/limit-reached-activate-project/limit-reached-activate-project.component';
import { NoAccessDialogComponent } from './components/no-access-dialog/no-access-dialog.component';
import ProjectInfoDialogComponent, {
  ProjectInfoDialogResponse,
  TProjectInfoBase
} from './components/project-info-dialog/project-info-dialog.component';
import { StagesDocumentsFiltersComponent } from './components/stages-documents-filters/stages-documents-filters.component';
import { ViewOnlyDialogComponent } from './components/view-only-dialog/view-only-dialog.component';
import { FeatureProjectsModule } from './feature-projects.module';
import { projectRoutes } from './feature-projects.routing';
import { ProcoreNoPermissionsComponent } from './pages/documents-page/procore-no-permissions/procore-no-permissions.component';
import {
  ProcoreProjectPermittedElements,
  ProcoreProjectPermittedElementsStates
} from './services/procore-permitted-document-elements.service';

export {
  AddProjectDialogComponent,
  DocumentsModalComponent,
  DocumentsModalComponentData,
  FeatureProjectsModule,
  LimitReachedActivateProjectComponent,
  NoAccessDialogComponent,
  ProcoreNoPermissionsComponent,
  ProcoreProjectPermittedElements,
  ProcoreProjectPermittedElementsStates,
  ProjectInfoDialogComponent,
  ProjectInfoDialogResponse,
  projectRoutes,
  SelectedDocumentsForNotes,
  StagesDocumentsFiltersComponent,
  TProjectInfoBase,
  ViewOnlyDialogComponent
};
