import {
  animate,
  state,
  style,
  transition,
  trigger
} from '@angular/animations';
import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  Inject,
  OnDestroy
} from '@angular/core';
import {
  AbstractControl,
  ReactiveFormsModule,
  UntypedFormControl,
  UntypedFormGroup,
  Validators
} from '@angular/forms';
import { Event, Router, RouterEvent } from '@angular/router';
import { Media, Note } from '@simlab/data-access';
import { NotesFacade, StagesFacade } from '@simlab/data-store';
import {
  DesignFlatButtonModule,
  DesignIconButton
} from '@simlab/design/button';
import { DesignIcon } from '@simlab/design/icon';
import { MATTERPORT_TOKEN } from '@simlab/feature/matterport';
import { MatterportService } from '@simlab/matterport';
import { UiFormFieldModule } from '@simlab/ui/form-field';
import { UiHintModule } from '@simlab/ui/hint';
import { UiInputModule } from '@simlab/ui/input';
import { ConfirmationModalRef } from '@simlab/ui/modal';
import { RouterStoreParams, createFormDataImage } from '@simlab/util-shared';
import {
  Observable,
  Subject,
  defer,
  firstValueFrom,
  map,
  of,
  switchMap,
  take,
  takeUntil,
  tap
} from 'rxjs';
import { NoteMediaService } from '../../services/note-media.service';
import { UiImageDrawingComponent } from '../image-drawing/ui-image-drawing.component';

export const screenshotAnimation = trigger('takePicture', [
  state(
    'doneScreenshot',
    style({
      background: 'none',
      opacity: 1
    })
  ),
  state(
    'doScreenshot',
    style({
      background: 'white',
      opacity: 0.5
    })
  ),
  transition('doScreenshot => doneScreenshot', [animate('0.1s')]),
  transition('doneScreenshot => doScreenshot', [animate('0.3s')])
]);

const DEFAULT_NAME = 'Screenshot_';

@Component({
    selector: 'feature-stages-screenshot-dialog',
    templateUrl: './screenshot-dialog.component.html',
    styleUrls: ['./screenshot-dialog.component.scss'],
    providers: [NoteMediaService],
    animations: [screenshotAnimation],
    changeDetection: ChangeDetectionStrategy.OnPush,
    imports: [
    CommonModule,
    UiImageDrawingComponent,
    UiFormFieldModule,
    UiInputModule,
    UiHintModule,
    DesignIconButton,
    DesignIcon,
    ReactiveFormsModule,
    DesignFlatButtonModule,
    DesignIcon
]
})
export class ScreenshotDialogComponent implements OnDestroy {
  private readonly _destroySource: Subject<void> = new Subject<void>();
  animationsDisabled = false;
  isOpen = true;

  imageToDraw: undefined | string;

  readonly formGroup: UntypedFormGroup = new UntypedFormGroup({
    screenshotName: new UntypedFormControl('', [
      Validators.required,
      Validators.minLength(4),
      Validators.maxLength(94)
    ])
  });

  get screenshotNameAbstractControl(): AbstractControl {
    return this.formGroup.controls['screenshotName'];
  }

  get screenshotName(): string {
    if (this.formGroup.controls['screenshotName'].value === '') {
      return this._defaultNameOfScreenshot;
    }
    return this.formGroup.controls['screenshotName'].value;
  }

  readonly projectId$: Observable<string> = defer(() =>
    this.stagesFacade.getRouteNestedParams$.pipe(
      map((params: RouterStoreParams) => params.params['projectId'])
    )
  );

  readonly savingImage$ = this.notesFacade.updateMedia$;
  private _defaultNameOfScreenshot!: string;

  readonly takeScreenshot$: Observable<string | undefined> = defer(() =>
    of(true).pipe(
      tap(() => {
        this.isOpen = false;
      }),
      switchMap(() =>
        this.matterportService
          .screenshot(
            {
              height: this.elementRef?.nativeElement?.offsetHeight ?? 600,
              width: this.elementRef?.nativeElement?.offsetWidth ?? 800
            },
            {
              mattertags: false,
              sweeps: false
            }
          )
          .pipe(
            take(1),
            tap((image: string | undefined) => {
              if (image) {
                this.saveDrawingScreenshot(image);
              }
            }),
            takeUntil(this._destroySource)
          )
      )
    )
  );

  readonly takeScreenshotAndDraw$: Observable<string | undefined> = defer(() =>
    of(true).pipe(
      tap(() => {
        this.isOpen = false;
      }),
      switchMap(() =>
        this.matterportService
          .screenshot(
            {
              height: this.elementRef?.nativeElement?.offsetHeight ?? 600,
              width: this.elementRef?.nativeElement?.offsetWidth ?? 800
            },
            {
              mattertags: false,
              sweeps: false
            }
          )
          .pipe(
            take(1),
            tap((image: string | undefined) => {
              if (image) {
                this.imageToDraw = image;
              }
            })
          )
      )
    )
  );

  constructor(
    private readonly elementRef: ElementRef,
    private readonly modalRef: ConfirmationModalRef<unknown>,
    private readonly noteMediaService: NoteMediaService,
    private readonly stagesFacade: StagesFacade,
    private readonly notesFacade: NotesFacade,
    @Inject(MATTERPORT_TOKEN)
    private readonly matterportService: MatterportService,
    private readonly router: Router
  ) {
    this._getDefaultNameOfScreenshot();
    this._closeDialogAfterChangeRouter();
  }

  private _closeDialogAfterChangeRouter(): void {
    this.router.events
      .pipe(
        tap((data: Event) => {
          if (data instanceof RouterEvent && !data.url.includes('matterport')) {
            this.close();
          }
        }),
        takeUntil(this._destroySource)
      )
      .subscribe();
  }

  private _getDefaultNameOfScreenshot(): void {
    this.notesFacade.selectedNote$
      .pipe(
        tap((note: Note) => {
          const defaultNamesUsed = note.photoNotes
            .filter((photoNote: Media) => photoNote.name.includes(DEFAULT_NAME))
            .map((value: Media) => {
              return Number.parseInt(value.name.replace(DEFAULT_NAME, ''));
            });
          if (defaultNamesUsed.length === 0) {
            this._defaultNameOfScreenshot = `${DEFAULT_NAME}00`;
            return;
          }
          const nextOrdinalNumber = Math.max(...defaultNamesUsed) + 1;
          this._defaultNameOfScreenshot =
            DEFAULT_NAME +
            (nextOrdinalNumber.toString().length === 1
              ? `0${nextOrdinalNumber.toString()}`
              : nextOrdinalNumber.toString());
        }),
        take(1),
        takeUntil(this._destroySource)
      )
      .subscribe();
  }

  checkScreenShotIsValid(): boolean {
    return (
      this.screenshotNameAbstractControl.valid === false &&
      (this.screenshotNameAbstractControl.value as string).length > 0
    );
  }

  ngOnDestroy(): void {
    this._destroySource.next();
    this._destroySource.complete();
  }

  saveDrawingScreenshot(image: string): void {
    firstValueFrom(
      this.projectId$.pipe(
        tap((projectId) => {
          if (image) {
            let nameScreenshot = this.screenshotName;
            const extension = nameScreenshot.endsWith('.jpeg');
            if (!extension) {
              nameScreenshot = nameScreenshot + '.jpeg';
            }
            this.noteMediaService.uploadScreenshotFile(
              createFormDataImage(image, projectId),
              'photo',
              nameScreenshot
            );
          }
          this.close();
        })
      )
    );
  }

  close(): void {
    this.imageToDraw = undefined;
    this.modalRef.emit({ state: false });
    this.modalRef.close();
  }
}
