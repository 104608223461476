<div class="header-icon-wrapper">
  <i
    ui-icon
    [name]="iconType()"
    [defaultColor]="true"
    size="img"
    class="header-icon-wrapper--icon"
  >
  </i>
</div>
<div class="header-content">
  <h2 class="header-content--name">
    @if (nameTemplate() !== undefined) {
      <ng-container [ngTemplateOutlet]="nameTemplate() || null"></ng-container>
    } @else {
      {{ name() }}
    }
  </h2>
  <label class="header-content--subName">{{ subName() }}</label>
</div>
