@if (!isPunchItemLoading()) {
  <div class="punch-item-workflow">
    <div class="punch-item-workflow__wrapper">
      <procore-info-item-container
        label="Date Created"
        i18n-label="@@PUNCH_ITEM_WORKFLOW_DATE_CREATED"
      >
        {{ createdAt() | formatDateSignal }}
      </procore-info-item-container>

      <procore-info-item-container
        label="Due Date"
        i18n-label="@@PUNCH_ITEM_WORKFLOW_DUE_DATE"
      >
        {{
          dueDate() !== noValueStr ? (dueDate() | formatDateSignal) : noValueStr
        }}
      </procore-info-item-container>
    </div>

    <procore-info-item-container
      label="Creator"
      i18n-label="@@PUNCH_ITEM_WORKFLOW_CREATOR"
    >
      {{ creator() }}
    </procore-info-item-container>

    <procore-info-item-container
      label="Punch Item Manager"
      i18n-label="@@PUNCH_ITEM_WORKFLOW_PUNCH_ITEM_MANAGER"
    >
      {{ punchItemManager() }}
    </procore-info-item-container>

    <procore-info-item-container
      label="Final Approver"
      i18n-label="@@PUNCH_ITEM_WORKFLOW_FINAL_APPROVER"
    >
      {{ finalApprover() }}
    </procore-info-item-container>

    <procore-info-item-container
      label="Ball in court"
      i18n-label="@@PUNCH_ITEM_WORKFLOW_BALL_IN_COURT"
    >
      {{ ballInCourt() }}
    </procore-info-item-container>

    <procore-info-item-container
      label="Distribution List"
      i18n-label="@@PUNCH_ITEM_WORKFLOW_DISTRIBUTION_LIST"
    >
      {{ distributionMembers() }}
    </procore-info-item-container>
  </div>
}
