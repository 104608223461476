/* eslint-disable @angular-eslint/component-class-suffix */
/* eslint-disable @angular-eslint/no-host-metadata-property */
/* eslint-disable @angular-eslint/no-input-rename */
/* eslint-disable @angular-eslint/no-inputs-metadata-property */
/* eslint-disable @angular-eslint/component-selector */
import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  HostBinding,
  NgModule,
  ViewEncapsulation
} from '@angular/core';
import { ColorDirective } from '@simlab/design/helper';
import { DirectionDirective } from '../directives/direction.directive';
import { FireOnClickDirective } from '../directives/fire-on-click.directive';
import { FireOnSetDirective } from '../directives/fire-on-set.directive';
import { ButtonBase } from './button-base.directive';
import { ButtonLoaderComponent } from './button-loader.component';
import { DesignButton } from './button.component';

@Component({
    selector: 'button[design-flat-button], a[design-flat-button]',
    exportAs: 'designFlatButton',
    imports: [CommonModule],
    template: ` <ng-content select="[designIconPrefix]"></ng-content>
    <ng-content></ng-content>
    <ng-content select="[designIconSuffix]"></ng-content>`,
    styleUrls: ['./flat-button.component.scss'],
    encapsulation: ViewEncapsulation.None,
    providers: [{ provide: ButtonBase, useExisting: DesignFlatButton }],
    changeDetection: ChangeDetectionStrategy.OnPush,
    host: {
        class: 'design-flat-button',
        '[class.design-color--primary]': 'color === "primary" || color === undefined',
        '[class.design-color--secondary]': 'color === "secondary"',
        '[class.design-color--accent]': 'color === "accent"',
        '[class.design-color--error]': 'color === "error"',
        '[class.design-color--success]': 'color === "success"',
        '[class.design-color--warning]': 'color === "warning"',
        '[class.design-color--primary--selected]': 'selected && color === "primary"',
        '[class.design-color--secondary--selected]': 'selected && color === "secondary"',
    },
    inputs: [
        'disabled',
        'disabled: designButtonDisabled',
        'loaded: designButtonLoaded',
        'selected: designButtonSelected',
        'color: designButtonColor',
    ]
})
export class DesignFlatButton extends ButtonBase {
  @HostBinding('class.design-flat-button--suffix')
  get hasIconSuffix(): boolean {
    return this._hasIconSuffix;
  }

  @HostBinding('class.design-flat-button--prefix')
  get hasIconPrefix(): boolean {
    return this._hasIconPrefix;
  }
}

@NgModule({
  exports: [
    DesignFlatButton,
    FireOnClickDirective,
    ButtonLoaderComponent,
    FireOnSetDirective,
    ColorDirective,
  ],
  imports: [
    DesignFlatButton,
    DesignButton,
    ColorDirective,
    FireOnSetDirective,
    FireOnClickDirective,
    DirectionDirective,
    ButtonLoaderComponent,
  ],
})
export class DesignFlatButtonModule {}
