export const NoteTypeRecords: Record<NoteType, string> = {
  Issue: $localize`:@@NOTE_TYPE_ISSUE:Issue Note`,
  Information: $localize`:@@NOTE_TYPE_INFORMATION:Information Note`
} as const;

export const NoteIssueTypeStatusRecords: Record<
  NoteExtendStatus<'Issue'>,
  string
> = {
  Pending: $localize`:@@NOTE_STATUS_PENDING:Pending`,
  InProgress: $localize`:@@NOTE_STATUS_IN_PROGRESS:In Progress`,
  Resolved: $localize`:@@NOTE_STATUS_RESOLVED:Resolved`,
  Unresolved: $localize`:@@NOTE_STATUS_UNRESOLVED:Unresolved`
} as const;

export const NoteTypeWithStatusesMap: NoteTypeWithStatusesMap = {
  Information: {
    None: 'None'
  },
  Issue: {
    ...NoteIssueTypeStatusRecords
  },
} as const;

export type NoteTypeWithStatusesMap = {
  [K in NoteType]: Record<NoteExtendStatus<K>, string>;
};

export type NoteExtendStatus<T extends NoteType> = T extends 'Issue'
  ? IssueNoteStatus
  : InformationNoteStatus;

export type NoteType = 'Issue' | 'Information';
export type IssueNoteStatus =
  | 'Pending'
  | 'InProgress'
  | 'Resolved'
  | 'Unresolved';
export type InformationNoteStatus = 'None';

export type NoteStatus = IssueNoteStatus | InformationNoteStatus;
