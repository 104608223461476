import { CommonModule } from '@angular/common';
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  Renderer2,
  ViewChild
} from '@angular/core';
import {
  DesignFlatButtonModule,
  DesignIconButton
} from '@simlab/design/button';
import { DesignDialogWrapperModule } from '@simlab/design/dialog';
import { DesignIcon } from '@simlab/design/icon';
import { UiButtonLoaderModule, UiButtonModule } from '@simlab/ui/button';
import { UiHelperModule } from '@simlab/ui/helper';
import {
  DialogResponse,
  ModalService,
  NotSavedChangesDialogComponent
} from '@simlab/ui/modal';
import { UiSliderComponent } from '@simlab/ui/slider';
import {
  Canvas,
  Circle,
  Ellipse,
  FabricImage,
  FabricObject,
  Rect,
  TPointerEvent,
  TPointerEventInfo
} from 'fabric';

import { Observable, ignoreElements, take, tap } from 'rxjs';

export interface Position {
  left: number;
  top: number;
}
export type ToolsAction =
  | 'drawBrush'
  | 'drawSquare'
  | 'drawCircle'
  | 'marking'
  | undefined;

@Component({
  selector: 'feature-stages-image-drawing',
  templateUrl: './ui-image-drawing.component.html',
  styleUrls: ['./ui-image-drawing.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    DesignDialogWrapperModule,
    DesignFlatButtonModule,
    DesignIconButton,
    DesignIcon,
    CommonModule,
    UiButtonModule,
    // UiCommonModule,
    UiHelperModule,
    UiSliderComponent,
    UiButtonLoaderModule
  ]
})
export class UiImageDrawingComponent implements OnInit, AfterViewInit {
  @ViewChild('brushButton', { read: ElementRef }) brushButton!: ElementRef;
  @ViewChild('buttonConfirm', { read: ElementRef }) buttonConfirm!: ElementRef;

  @ViewChild('containerCanvas', { read: ElementRef })
  containerCanvas!: ElementRef<HTMLDivElement>;

  @Output() closeEmitter: EventEmitter<void> = new EventEmitter<void>();
  @Output() saveImageEmitter: EventEmitter<string> = new EventEmitter<string>();

  defaultBrush = 10;

  @Input()
  set dimension(value: { width: number; height: number } | undefined) {
    this._dimension = value;
  }
  get dimension(): { width: number; height: number } | undefined {
    return this._dimension;
  }
  private _dimension: { width: number; height: number } | undefined;

  @Input()
  set imageName(newValue: string | undefined) {
    this._imageName = newValue;
  }
  get imageName(): string | undefined {
    return this._imageName;
  }
  private _imageName: string | undefined;

  private _disableDraw = false;
  public get disableDraw() {
    return this._disableDraw;
  }
  public set disableDraw(value) {
    this._disableDraw = value;
  }

  @Input()
  set image(newValue: string | undefined) {
    this._image = newValue;
  }
  get image(): string | undefined {
    return this._image;
  }
  private _image: string | undefined;
  divPos: Position = {
    left: 0,
    top: 0
  };

  @Input()
  set savingImage$(newValue$: Observable<any>) {
    this._savingImage$ = newValue$;
  }
  get savingImage$(): Observable<any> {
    return this._savingImage$;
  }
  private _savingImage$!: Observable<any>;

  toolsAction: ToolsAction = 'drawBrush';

  private _canvas: Canvas = new Canvas('canvas', {
    hoverCursor: 'pointer',
    isDrawingMode: true,
    selection: false
  });
  private _rect: Rect | undefined;
  private _circle: Circle | undefined;
  private _ellipse: Ellipse | undefined;
  private _isDown?: boolean;
  private _startPoseX = 0;
  private _startPoseY = 0;
  private _canvasImagePadding = 100;
  private _imageMaxHeight = 0;
  private _imageMaxWidth = 0;

  constructor(
    private readonly elem: ElementRef<HTMLElement>,
    private readonly renderer: Renderer2,
    private readonly modalService: ModalService
  ) {}

  ngOnInit(): void {
    this.canvasBrash.width = this.defaultBrush;
    FabricObject.prototype.selectable = false;
    if (this._image)
      FabricImage.fromURL(this._image, { crossOrigin: 'anonymous' }).then(
        (img: FabricImage) => {
          if (this._dimension) {
            img.set({
              left: 0,
              top: 0
            });
            img.scaleToHeight(this._dimension.height);
            img.scaleToWidth(this._dimension.width);
          }
          this.setImage(
            img,
            this._image ? this._image.includes('base64') : false
          );
        }
      );

    const elements = this.elem.nativeElement.querySelector('.canvas-container');
    if (elements) {
      this.renderer.setStyle(elements, 'max-height', '100%');
      this.renderer.setStyle(elements, 'margin-bottom', '50px');
    }
  }
  setCursorPosition(event: MouseEvent): void {
    this.divPos = {
      left: event.offsetX,
      top: event.offsetY
    };
  }
  get canvasBrash() {
    if (!this._canvas.freeDrawingBrush)
      throw Error('Canvas brush does not exist');
    return this._canvas.freeDrawingBrush;
  }

  setImage(img: FabricImage, base64: boolean): void {
    this._canvas.backgroundImage = img;
    if (img.height && img.width) {
      this._imageMaxHeight = base64
        ? img.height - this._canvasImagePadding
        : img.height;
      this._imageMaxWidth = base64
        ? img.width - this._canvasImagePadding
        : img.width;

      if (this._dimension) {
        this._canvas?.setHeight(this._dimension.height);
        this._canvas?.setWidth(this._dimension.width);
      } else {
        this._canvas?.setHeight(this._imageMaxHeight);
        this._canvas?.setWidth(this._imageMaxWidth);
      }
    }
  }

  ngAfterViewInit(): void {
    this.containerCanvas.nativeElement.addEventListener(
      'mousedown',
      (event: MouseEvent) => {
        this.setCursorPosition(event);
      }
    );
  }

  setBrushColor(value: string): void {
    this.brushButton.nativeElement.style.backgroundColor = value;

    if (this._canvas) {
      this.canvasBrash.color = value;
    }
  }

  changeColorOfBrush(value: string): void {
    this.brushButton.nativeElement.style.color = value;
  }

  setBrushThickness(value: number): void {
    if (this._canvas) {
      this.canvasBrash.width = value;
    }
  }

  drawBrush(): void {
    this.toolsAction = 'drawBrush';
    if (this._canvas) {
      this._canvas.off('mouse:down');
      this._canvas.off('mouse:move');
      this._canvas.off('mouse:up');

      this._canvas.isDrawingMode = true;
    }
  }

  drawCircle(): void {
    this.toolsAction = 'drawCircle';
    this._restCanvasEvents();
    if (this._canvas) {
      this._canvas.selection = false;
      this._canvas.isDrawingMode = false;
      this._rect = undefined;
      this._canvas.on(
        'mouse:down',
        (event: TPointerEventInfo<TPointerEvent>) => {
          if (this._canvas) {
            this._isDown = true;
            const pointer = this._canvas.getPointer(event.e);
            this._startPoseX = pointer.x;
            this._startPoseY = pointer.y;
            this._circle = new Circle({
              radius: 0,
              left: this._startPoseX,
              top: this._startPoseY,
              originX: 'left',
              originY: 'top',
              stroke: this.canvasBrash.color,
              strokeWidth: this.canvasBrash.width,
              fill: ''
            });
            this._canvas.add(this._circle);
          }
        }
      );

      this._canvas.on(
        'mouse:move',
        (event: TPointerEventInfo<TPointerEvent>) => {
          if (!this._isDown) return;

          if (this._canvas) {
            const pointer = this._canvas.getPointer(event.e);

            const circleLeft = this._circle?.left;
            const circleTop = this._circle?.top;

            if (circleLeft && circleTop) {
              const offsetX = pointer.x - circleLeft;
              const offsetY = pointer.y - circleTop;
              if (offsetX > offsetY) {
                this._circle?.set({
                  radius: Math.round(Math.abs(offsetY / 2)),
                  width: offsetY,
                  height: offsetY
                });
              } else {
                this._circle?.set({
                  radius: Math.round(Math.abs(offsetX / 2)),
                  width: offsetX,
                  height: offsetX
                });
              }
            }
            this._canvas.renderAll();
          }
        }
      );

      this._canvas.on('mouse:up', () => {
        this._isDown = false;
        this._circle?.setCoords();
      });
    }
  }

  drawEllipsis(): void {
    this.toolsAction = 'drawCircle';
    this._restCanvasEvents();
    if (this._canvas) {
      this._canvas.selection = false;
      this._canvas.isDrawingMode = false;
      this._rect = undefined;
      this._canvas.on(
        'mouse:down',
        (event: TPointerEventInfo<TPointerEvent>) => {
          if (this._canvas) {
            this._isDown = true;
            const pointer = this._canvas.getPointer(event.e);
            this._startPoseX = pointer.x;
            this._startPoseY = pointer.y;
            this._ellipse = new Ellipse({
              left: this._startPoseX,
              top: this._startPoseY,
              originX: 'left',
              originY: 'top',
              rx: 0,
              ry: 0,
              stroke: this.canvasBrash.color,
              strokeWidth: this.canvasBrash.width,
              fill: ''
            });
            this._canvas.add(this._ellipse);
          }
        }
      );

      this._canvas.on(
        'mouse:move',
        (event: TPointerEventInfo<TPointerEvent>) => {
          if (!this._isDown) return;

          if (this._canvas) {
            const pointer = this._canvas.getPointer(event.e);

            let rx = Math.abs(this._startPoseX - pointer.x) / 2;
            let ry = Math.abs(this._startPoseY - pointer.y) / 2;

            if (this._ellipse && this._ellipse.strokeWidth) {
              if (rx > this._ellipse?.strokeWidth) {
                rx -= this._ellipse?.strokeWidth / 2;
              }
              if (ry > this._ellipse.strokeWidth) {
                ry -= this._ellipse.strokeWidth / 2;
              }
            }
            this._ellipse?.set({ rx: rx, ry: ry });

            if (this._startPoseX > pointer.x) {
              this._ellipse?.set({ originX: 'right' });
            } else {
              this._ellipse?.set({ originX: 'left' });
            }
            if (this._startPoseY > pointer.y) {
              this._ellipse?.set({ originY: 'bottom' });
            } else {
              this._ellipse?.set({ originY: 'top' });
            }

            this._canvas.renderAll();
          }
        }
      );

      this._canvas.on('mouse:up', () => {
        this._isDown = false;
        this._ellipse?.setCoords();
      });
    }
  }

  drawSquare(): void {
    this.toolsAction = 'drawSquare';
    this._restCanvasEvents();
    if (this._canvas) {
      this._canvas.selection = false;
      this._canvas.isDrawingMode = false;
      this._canvas.on(
        'mouse:down',
        (event: TPointerEventInfo<TPointerEvent>) => {
          if (this._canvas) {
            this._isDown = true;
            const pointer = this._canvas.getPointer(event.e);
            this._startPoseX = pointer.x;
            this._startPoseY = pointer.y;
            this._rect = new Rect({
              left: this._startPoseX,
              top: this._startPoseY,
              originX: 'left',
              originY: 'top',
              width: 0,
              height: 0,
              stroke: this.canvasBrash.color,
              strokeWidth: this.canvasBrash.width,
              fill: ''
            });
            this._canvas.add(this._rect);
          }
        }
      );

      this._canvas.on(
        'mouse:move',
        (event: TPointerEventInfo<TPointerEvent>) => {
          if (!this._isDown) return;

          if (this._canvas) {
            const maxHeight = this._imageMaxHeight - this.canvasBrash.width;
            const maxWidth = this._imageMaxWidth - this.canvasBrash.width;

            const pointer = this._canvas.getPointer(event.e);

            if (this._startPoseX > pointer.x) {
              this._rect?.set({ left: Math.max(0, pointer.x) });
            }

            if (this._startPoseY > pointer.y) {
              this._rect?.set({ top: Math.max(0, pointer.y) });
            }

            if (pointer.x > 0 && pointer.x < maxWidth) {
              this._rect?.set({
                width: Math.abs(this._startPoseX - pointer.x)
              });
            }

            if (pointer.x > maxWidth) {
              this._rect?.set({
                width: Math.abs(this._startPoseX - maxWidth)
              });
            }

            if (pointer.y > 0 && pointer.y < maxHeight)
              this._rect?.set({
                height: Math.abs(this._startPoseY - pointer.y)
              });

            if (pointer.y > maxHeight) {
              this._rect?.set({
                height: Math.abs(this._startPoseY - maxHeight)
              });
            }

            this._canvas.renderAll();
          }
        }
      );

      this._canvas.on('mouse:up', () => {
        this._isDown = false;
        this._rect?.setCoords();
      });
    }
  }

  private _restCanvasEvents(): void {
    if (this._canvas) {
      this._canvas.off('mouse:down');
      this._canvas.off('mouse:move');
      this._canvas.off('mouse:up');
      this._canvas.selection = false;
      FabricObject.prototype.selectable = false;
    }
  }

  undoAction(): void {
    if (this._canvas) {
      const size = this._canvas.size();
      if (size > 0) {
        const item = this._canvas.item(size - 1);
        this._canvas.remove(item as unknown as FabricObject);
      }
    }
  }

  markingElement(): void {
    this.toolsAction = 'marking';
    this._restCanvasEvents();
    if (this._canvas) {
      this._canvas.isDrawingMode = false;
      this._canvas.selection = true;
      FabricObject.prototype.selectable = true;
    }
  }

  close(): void {
    const size = this._canvas ? this._canvas.size() : 0;
    if (size === 0) {
      this.closeEmitter.next();
      return;
    }

    this._openWarningDialog().pipe(take(1)).subscribe();
  }

  private _openWarningDialog(): Observable<never> {
    const dialogRef = this.modalService.createModal(
      NotSavedChangesDialogComponent,
      {
        maxWidth: 'min(90%, 450px)'
      },
      {
        text: `This Picture ${this.imageName} has been modified. \nDo you want to save the changes?`,
        confirmButton: 'Exit Brush'
      }
    );
    return dialogRef.events$.pipe(
      tap((response: DialogResponse<any>) => {
        switch (response.result) {
          case 'save':
            this.buttonConfirm.nativeElement.click();
            return;
          case 'cancel':
            return;
          case 'notSave':
            this.closeEmitter.next();
            return;
        }
      }),
      ignoreElements()
    );
  }

  save(): void {
    this.disableDraw = true;
    if (this._canvas) {
      this.toolsAction = undefined;
      this._canvas.off('mouse:down');
      this._canvas.off('mouse:move');
      this._canvas.off('mouse:up');
      this._canvas.isDrawingMode = false;
    }
    this.saveImageEmitter.emit(
      this._canvas?.toDataURL({
        format: 'jpeg',
        multiplier: 0
      })
    );
  }
}
