import { NgClass, NgTemplateOutlet } from '@angular/common';
import {
  booleanAttribute,
  ChangeDetectionStrategy,
  Component,
  computed,
  contentChild,
  input,
  output,
  signal,
  TemplateRef
} from '@angular/core';
import { DesignProgressSpinnerComponent } from '@simlab/design/progress-spinner';
import { ICON_TYPE } from '@simlab/ui/icon';
import { AnnotationPanelMenuItem } from 'common/annotation-panel/models';
import { AnnotationPanelHeaderBtnComponent } from '../annotation-panel-header-btn/annotation-panel-header-btn.component';
import { AnnotationPanelHeaderComponent } from '../annotation-panel-header/annotation-panel-header.component';
import { AnnotationPanelMenuComponent } from '../annotation-panel-menu/annotation-panel-menu.component';

@Component({
  selector: 'common-annotation-panel-root',
  imports: [
    NgClass,
    AnnotationPanelHeaderComponent,
    AnnotationPanelHeaderBtnComponent,
    DesignProgressSpinnerComponent,
    NgTemplateOutlet,
    AnnotationPanelMenuComponent
  ],
  templateUrl: './annotation-panel-root.component.html',
  styleUrl: './annotation-panel-root.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AnnotationPanelRootComponent {
  protected readonly nameTemplate =
    contentChild<TemplateRef<unknown>>('nameTemplate');
  readonly isMenuTriggerVisible = input<boolean>(true);
  readonly name = input('', {
    transform: (nameValue: string | undefined | null) => nameValue ?? ''
  });
  readonly subName = input('', {
    transform: (subNameValue: string | undefined | null) => subNameValue ?? ''
  });
  readonly iconType = input.required<ICON_TYPE>();
  readonly loading = input(false, { transform: booleanAttribute });
  readonly itemSelected = output<string>();

  readonly panelMenuItems = input<AnnotationPanelMenuItem[]>([]);
  protected readonly isMenu = computed(() => {
    const { length } = this.panelMenuItems();
    return !!length;
  });

  protected readonly selectedKey = signal<string>('');
  protected readonly selectedKeyOrDefault = computed(() => {
    const menuItems = this.panelMenuItems();
    const selectedKey = this.selectedKey();
    return selectedKey || menuItems[0]?.key || '';
  });

  protected onItemSelect(item: AnnotationPanelMenuItem) {
    this.selectedKey.set(item.key);
    this.itemSelected.emit(item.key);
  }
}
