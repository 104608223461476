import { Pipe, PipeTransform } from '@angular/core';
import { PunchItemAssignmentStatus } from '@simlab/procore/models';

export const PunchItemAssignmentStatusLabel: Record<
  PunchItemAssignmentStatus,
  string
> = {
  [PunchItemAssignmentStatus.Resolved]: $localize`:@@RESOLVED:Resolved`,
  [PunchItemAssignmentStatus.ReadyForReview]: $localize`:@@READY_FOR_REVIEW:Ready For Review`,
  [PunchItemAssignmentStatus.WorkNotAccepted]: $localize`:@@WORK_NOT_ACCEPTED:Work Not Accepted`,
  [PunchItemAssignmentStatus.Unresolved]: $localize`:@@WORK_REQUIRED:Work Required`
};

@Pipe({ name: 'getPunchItemAssignmentStatusLabel', standalone: true })
export class PunchItemAssignmentStatusLabelGetterPipe implements PipeTransform {
  transform(status: PunchItemAssignmentStatus): string {
    return PunchItemAssignmentStatusLabel[status];
  }
}
