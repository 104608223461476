/* eslint-disable @angular-eslint/no-host-metadata-property */
import {
  AfterContentInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  forwardRef,
  Inject,
  ViewEncapsulation,
} from '@angular/core';
import { DrawerContainerComponent } from './drawer-container/drawer-container.component';

@Component({
    selector: 'ui-drawer-content',
    template: `<ng-content></ng-content>`,
    changeDetection: ChangeDetectionStrategy.OnPush,
    encapsulation: ViewEncapsulation.None,
    host: {
        class: 'ui-drawer-content',
        '[style.margin-left.px]': '_container.contentMargins.left',
        '[style.margin-right.px]': '_container.contentMargins.right',
    },
    standalone: false
})
export class DrawerContentComponent implements AfterContentInit {
  constructor(
    @Inject(forwardRef(() => DrawerContainerComponent))
    public readonly _container: DrawerContainerComponent,
    private readonly _changeDetectorRef: ChangeDetectorRef
  ) {}

  ngAfterContentInit(): void {
    this._container._contentMarginChanges.subscribe((x: any) => {
      this._changeDetectorRef.markForCheck();
    });
  }
}
