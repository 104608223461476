import { Dialog } from '@angular/cdk/dialog';
import { computed, inject, Injectable } from '@angular/core';
import { Params } from '@angular/router';
import type { ModalData } from '@simlab/modal/ui';
import { RFIState } from '@simlab/procore/annotation-panel/services';
import { RFIListStore } from '@simlab/procore/services';
import { RouterFacadeService } from '@simlab/util-shared';
import {
  AnnotationAction,
  AnnotationActionsBase
} from 'common/annotation-panel/models';
import { exhaustMap, firstValueFrom, from, take, tap } from 'rxjs';
import { RfiSidePanelComponent } from './rfi-side-panel.component';

const TEXT = {
  transferRFI: $localize`:@@TRANSFER_RFI:Transfer RFI`,
  deleteRFI: $localize`:@@DELETE_RFI:Delete RFI`,
  title: (name: string) =>
    $localize`:@@REMOVE_RFI_ITEM:Are you sure you want to delete ${name}?`,
  message: $localize`:@@REMOVE_RFI_ITEM_LABEL:This document will be removed from both platforms. You will be able to restore it in the Recycle Bin in Procore.`,
  delete: $localize`:@@DELETE:Delete`,
  cancel: $localize`:@@CANCEL:Cancel`,
  edit: $localize`:@@EDIT:Edit`
};

@Injectable()
export class RFISidePanelAction implements AnnotationActionsBase {
  private readonly _dialog = inject(Dialog);
  private readonly _rfiState = inject(RFIState);
  private readonly _rfiStore = inject(RFIListStore);
  private readonly _parent = inject(RfiSidePanelComponent);
  private readonly _routerFacade = inject(RouterFacadeService);

  closePanel() {
    this._rfiStore.setSelected(undefined);
    this._parent.onClosePanel.emit();

    const queryParams: Params = { sidenavContent: null, rfiId: null };
    this._routerFacade.setQueryParams(undefined, queryParams, 'merge');
  }
  actionList: AnnotationAction[] = [
    {
      name: TEXT.edit,
      trigger: () => {
        this._parent.editRFI();
      }
    },
    {
      name: TEXT.transferRFI,
      trigger: () => {
        const { id, status } = this._rfiState.rfi();

        this._parent.onTransferElement.emit({ id, status });
      }
    },
    {
      name: TEXT.deleteRFI,
      trigger: () => {
        firstValueFrom(
          this._getModalDelete$().pipe(
            tap((res) => {
              if (!res) return;
              this._rfiState.deleteRFI();
              this._parent.onClosePanel.emit();
            })
          )
        );
      },
      disabled: computed(() => !this._rfiState.allowedActions()?.showDelete)
    }
  ];

  private _getModalDelete$() {
    const { title, number } = this._rfiState.rfi();
    const data: ModalData = {
      title: TEXT.title(`#${number ?? ''}: ${title}`),
      message: TEXT.message,
      type: 'alert',
      btnContent: {
        confirm: {
          label: TEXT.delete,
          returnValue: true
        },
        cancel: {
          label: TEXT.cancel,
          returnValue: false
        }
      }
    };

    return from(import('@simlab/modal/ui')).pipe(
      exhaustMap(
        ({ ModalComponent }) =>
          this._dialog.open<boolean, ModalData>(ModalComponent, {
            data,
            width: 'min(90%, 610px)'
          }).closed
      ),
      take(1)
    );
  }
}
