<nav aria-label="breadcrumb">
  <ul>
    @for (item of breadcrumbEntries(); let last = $last; track $index) {
      <li class="icon-arrow-big" [class.last]="last">
        <a
          ui-button
          [routerLink]="item.path"
          [disabled]="last"
          [attr.data-cy]="item.label"
        >
          <span class="breadcrumb__label">
            {{ item.label }}
          </span>
        </a>
      </li>
    }
  </ul>
</nav>
