import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { DpDatePickerModule } from 'ng-datepicker';
import { DatepickerComponent } from './components/datepicker/datepicker.component';

@NgModule({
  declarations: [DatepickerComponent],
  imports: [DpDatePickerModule, FormsModule],
  exports: [DatepickerComponent]
})
export class UiDatepickerModule {}
