<common-annotation-panel-root
  [name]="name()"
  [subName]="stageName()"
  [iconType]="
    displayStatus()?.showYellowBackground
      ? 'icon_punch_draft_status'
      : 'icon_punch_closed_status'
  "
  [loading]="isLoading()"
  [panelMenuItems]="menu"
  (itemSelected)="itemSelected($event)"
>
  @if (!isLoading()) {
    @switch (selectedMenuItemKey()) {
      @case ('main') {
        <procore-punch-item-side-panel-main
          [isPunchItemLoading]="isPunchItemLoading()"
          [punchItem]="punchItem()"
          [activities]="activities()"
          [activitiesLoading]="isActivitiesLoading()"
          [addingCommentStatus]="addingCommentStatus()"
          [displayElements]="displayStatus()"
          [assigneeOptions]="assigneeOptions()"
        />
      }

      @case ('item-info') {
        <procore-punch-item-side-panel-information
          [currencySymbol]="currencySymbol()"
          [isPunchItemLoading]="isPunchItemLoading()"
          [punchItem]="punchItem()"
          [displayElements]="displayStatus()"
        />
      }

      @case ('workflow') {
        <procore-punch-item-side-panel-workflow
          [punchItem]="punchItem()"
          [isPunchItemLoading]="isPunchItemLoading()"
        />
      }

      @case ('assignees') {
        <procore-punch-item-side-panel-assignees
          [punchItem]="punchItem()"
          [isPunchItemLoading]="isPunchItemLoading()"
          (allWorkComplete)="allWorkComplete()"
        />
      }
    }
  }
</common-annotation-panel-root>
