import { HttpErrorResponse } from '@angular/common/http';
import { inject } from '@angular/core';
import { tapResponse } from '@ngrx/operators';
import { rxMethod } from '@ngrx/signals/rxjs-interop';
import { AbstractConstructor, Constructor } from '@simlab/design/internal';
import { ApiProcoreService } from '@simlab/procore/data-access';
import {
  procoreBaseInfoPayload,
  UpdatePunchItemAssignment
} from '@simlab/procore/models';
import { PunchItemsListStore } from '@simlab/procore/services';
import { EMPTY, exhaustMap, tap } from 'rxjs';
import { PunchItemState } from './_punch-item.state';

import { RxMethod } from './_punch-item-store.service';
import {
  mixinPunchItemUploadFiles,
  PunchItemUploadFilesAndReturnIds
} from './punch-item-upload-files.base';

export type PunchItemUpdateAssignment = {
  updateAssignment: RxMethod<UpdatePunchItemAssignment>;
} & PunchItemUploadFilesAndReturnIds;

type TPunchItemUpdateAssignments = Constructor<PunchItemUpdateAssignment> &
  AbstractConstructor<PunchItemUpdateAssignment>;
export function mixinPunchItemUpdateAssignment<
  T extends AbstractConstructor<any>
>(base: T): TPunchItemUpdateAssignments & T;

export function mixinPunchItemUpdateAssignment<
  T extends Constructor<PunchItemUploadFilesAndReturnIds>
>(base: T) {
  return mixinPunchItemUploadFiles(
    class extends base implements PunchItemUpdateAssignment {
      private readonly _state = inject(PunchItemState);
      private readonly _listStore = inject(PunchItemsListStore);
      private readonly _api = inject(ApiProcoreService);
      private readonly _baseInfoProcore = inject(procoreBaseInfoPayload);

      readonly updateAssignment = rxMethod<UpdatePunchItemAssignment>(
        exhaustMap((assignmentUpdate) => {
          this._state.patchItemState({
            assignmentUpdateStatus: 'PENDING'
          });

          const baseInfoProcore = this._baseInfoProcore();
          if (baseInfoProcore === undefined) {
            console.error('baseInforProcore is undefined');
            this._state.patchItemState({
              assignmentUpdateStatus: 'FAIL'
            });
            return EMPTY;
          }

          const { procoreProjectId, procoreCompanyId } = baseInfoProcore;
          return this.uploadFilesAndReturnIds$(assignmentUpdate.files).pipe(
            exhaustMap((uploadIds) => {
              const _uploadIds = uploadIds.filter((id) => !!id);
              return this._api.updatePunchItemAssignment(
                this._state.createUpdateItemAssignmentPayload(
                  assignmentUpdate,
                  _uploadIds
                )
              );
            }),
            tap(() => {
              this._state.patchItemState({
                assignmentUpdateStatus: 'SUCCESS'
              });
            }),
            exhaustMap(() =>
              this._api.getPunchItemAssignment({
                procorePunchItemId: assignmentUpdate.procorePunchItemId,
                punchItemAssignmentId: assignmentUpdate.punchItemAssignmentId,
                procoreProjectId: procoreProjectId,
                procoreCompanyId: procoreCompanyId
              })
            ),
            tapResponse({
              next: (assignment) => {
                this._listStore.patchAssignment(assignment);
              },
              error: (e: HttpErrorResponse) => {
                console.error(e);
                this._state.patchItemState({
                  assignmentUpdateStatus: 'FAIL'
                });
              },
              finalize: () => {
                queueMicrotask(() => {
                  this._state.patchItemState({
                    assignmentUpdateStatus: 'IDLE'
                  });
                });
              }
            })
          );
        })
      );
    }
  );
}
