import {
  Injectable,
  InjectionToken,
  OnDestroy,
  ViewContainerRef,
  inject
} from '@angular/core';
import { toObservable } from '@angular/core/rxjs-interop';
import {
  AnnotationModules,
  ApiFacadeService,
  ComponentTypes,
  Note,
  PunchItemWithMarker,
  RFIItemWithMarker,
  StageComponent,
  rfiMatterportIcons
} from '@simlab/data-access';
import {
  AnnotationsFacade,
  ComponentsFacade,
  NotesFacade,
  ShowcaseNotesFacade,
  StagesFacade
} from '@simlab/data-store';

import {
  CustomPhases,
  ICONS,
  MatterportComponent,
  MatterportManagerService,
  MatterportService,
  SupportedLinks,
  TagNoteTypes,
  Transition
} from '@simlab/matterport';

import { Camera } from '@simlab/matterport/assets/mpSdk/sdk';
import {
  ClickEventEmitter,
  ComponentConfiguration,
  SpriteConfiguration
} from '@simlab/simlab-facility-management/scene-object';
import { Transform } from '@simlab/transform';
import { PositionCameraService, ShowcaseHelper } from '@simlab/util-shared';
import {
  BehaviorSubject,
  Observable,
  Subject,
  catchError,
  combineLatest,
  defaultIfEmpty,
  delay,
  filter,
  firstValueFrom,
  forkJoin,
  iif,
  map,
  merge,
  mergeMap,
  of,
  race,
  startWith,
  switchMap,
  take,
  takeUntil,
  tap,
  throttleTime
} from 'rxjs';
import { Vector3 } from 'three';
import { MatterportOauthService, OauthState } from './matterport-oauth.service';

type AnnotationModulesWithEmptyState = AnnotationModules | 'none';

export const MATTERPORT_TOKEN = new InjectionToken('Matterport token');

export function injectMatterport(matterportManager: MatterportManagerService) {
  return new MatterportService(matterportManager);
}

@Injectable()
export class MatterportBaseService implements OnDestroy {
  private readonly positionCameraService = inject(PositionCameraService, {
    optional: true
  });
  private readonly apiFacadeService = inject(ApiFacadeService);
  private readonly componentsFacade = inject(ComponentsFacade);
  private readonly stageFacade = inject(StagesFacade);
  private readonly showcaseNotesFacade = inject(ShowcaseNotesFacade);

  private readonly _annotationsFacade = inject(AnnotationsFacade);

  private readonly notesFacade = inject(NotesFacade);
  private readonly matterportService =
    inject<MatterportService>(MATTERPORT_TOKEN);
  private readonly matterportOauthService = inject(MatterportOauthService);
  private readonly _destroySource: Subject<void> = new Subject<void>();
  private readonly _switchScanSource: Subject<void> = new Subject<void>();
  readonly changedComponent: Subject<void> = new Subject<void>();
  private readonly _matterportViewListenerDestroy: Subject<void> =
    new Subject<void>();

  private readonly _matterportComponent: BehaviorSubject<
    MatterportComponent[]
  > = new BehaviorSubject<MatterportComponent[]>([]);
  private readonly _matterportIsOpen: BehaviorSubject<boolean> =
    new BehaviorSubject<boolean>(false);

  private readonly _isCompletelyLoaded: BehaviorSubject<boolean> =
    new BehaviorSubject<boolean>(false);
  readonly isCompletelyLoaded$ = this._isCompletelyLoaded.asObservable();

  private readonly _isMatterportLoading: BehaviorSubject<boolean> =
    new BehaviorSubject<boolean>(true);
  readonly isMatterportLoading$: Observable<boolean> =
    this._isMatterportLoading.asObservable();

  set addMatterportComponent(value: MatterportComponent) {
    this._matterportComponent.next([
      ...this._matterportComponent.getValue(),
      value
    ]);
  }

  get matterportComponents(): MatterportComponent[] {
    return this._matterportComponent.getValue();
  }

  readonly refreshOpenScan: Subject<void> = new Subject<void>();
  readonly matterportIsOpen$ = this._matterportIsOpen.asObservable();
  readonly locationServerChanged: Subject<void> = new Subject<void>();

  readonly hasMatterport$: Observable<boolean> =
    this.matterportService.hasMatterport$;

  private _hideOverlayElements = false;
  set hideOverlayElements(value: boolean) {
    this._hideOverlayElements = value;
  }
  get hideOverlayElements() {
    return this._hideOverlayElements;
  }

  private _lastKnownFloor: number | undefined = undefined;
  set lastKnownFloor(value: number | undefined) {
    this._lastKnownFloor = value;
  }
  get lastKnownFloor(): number | undefined {
    return this._lastKnownFloor;
  }

  private _lastKnownCameraPose: Camera.Pose | undefined;
  set lastKnownCameraPose(value: Camera.Pose | undefined) {
    if (!this.positionCameraService) {
      console.warn('positionCameraService not providedd');
      return;
    }
    this.positionCameraService.cameraPose = value;
    this._lastKnownCameraPose = value;
  }
  get lastKnownCameraPose(): Camera.Pose | undefined {
    return this._lastKnownCameraPose;
  }
  public _lastKnownMatterportOffset: string | undefined;
  readonly oauthState$: Observable<OauthState> =
    this.matterportOauthService.oauthState$;
  private _goToMarker: AnnotationModulesWithEmptyState = 'none';
  private _goToSweep = false;
  private readonly _refreshOpenScan$ = this.refreshOpenScan
    .asObservable()
    .pipe(
      mergeMap(() => this.apiFacadeService.tokens.removeMatterportUserToken())
    );

  private readonly _synchronizedModel$: Observable<StageComponent | undefined> =
    this.componentsFacade.selectedComponentId$.pipe(
      takeUntil(this._matterportViewListenerDestroy),
      tap((componentsId: string | undefined) => {
        this.changedComponent.next();
        if (componentsId === undefined || componentsId === '') {
          this.matterportOauthService.componentUndefined();
          this._isMatterportLoading.next(false);
        }
      }),
      filter(
        (componentsId: string | undefined) =>
          componentsId !== undefined && componentsId !== ''
      ),
      map((componentsId: string | undefined) => {
        return componentsId ? componentsId : '';
      }),
      switchMap((componentId: string) =>
        this.componentsFacade.getComponentsById$(componentId).pipe(take(1))
      ),
      filter(
        (components: StageComponent | undefined) => components !== undefined
      ),
      map((components: StageComponent | undefined) => {
        const condition =
          components &&
          (components.isSynchronizingAccepted || this._hideOverlayElements);

        if (components?.modelType !== ComponentTypes.Matterport) {
          this.matterportOauthService.componentUndefined();
          return undefined;
        }

        if (!condition) {
          this.matterportOauthService.componentNotSynchronized();
          return undefined;
        }
        return components;
      })
    );

  private readonly _markers$: Observable<unknown[]> = combineLatest([
    this.showcaseNotesFacade.allShowcaseNotes$,
    toObservable(this._annotationsFacade.punchItemActiveMarkers),
    toObservable(this._annotationsFacade.rfiItemActiveMarkers),
    this.showcaseNotesFacade.notesHidden$
  ]).pipe(
    switchMap(
      ([notes, punchItems, rfiItems, hidden]: [
        Note[],
        PunchItemWithMarker[],
        RFIItemWithMarker[],
        boolean
      ]) =>
        this.matterportIsOpen$.pipe(
          filter((data: boolean) => data),
          map(
            () =>
              [notes, punchItems, rfiItems, hidden] satisfies [
                Note[],
                PunchItemWithMarker[],
                RFIItemWithMarker[],
                boolean
              ]
          )
        )
    ),
    switchMap(
      ([notes, punchItems, rfiItems, hidden]: [
        Note[],
        PunchItemWithMarker[],
        RFIItemWithMarker[],
        boolean
      ]) =>
        this.matterportService.clearAllNotes$().pipe(
          filter(() => !hidden),
          map(() =>
            [
              ...this._getRFIESAsMatterportComponents(rfiItems),
              ...this._getPunchItemAsMatterportComponents(punchItems),
              ...this._getNotesAsMatterportComponents(notes)
            ].map((configuration) => {
              return this.matterportService
                .addComponentWithOffset$(configuration)
                .pipe(
                  tap((value: MatterportComponent | undefined) => {
                    if (value === undefined) return;

                    this._matterportComponent.next([
                      ...this._matterportComponent.getValue(),
                      value
                    ]);
                  })
                );
            })
          ),

          mergeMap((markers: Observable<unknown>[]) =>
            forkJoin(markers).pipe(defaultIfEmpty([]))
          )
        )
    ),
    takeUntil(this.changedComponent)
  );

  readonly hasSpinner$: Observable<boolean> = combineLatest([
    this.matterportService.hasMatterport$,
    this.oauthState$,
    this.isMatterportLoading$
  ]).pipe(
    map(
      ([hasMatterport, oauthState, isLoading]: [
        boolean,
        OauthState,
        boolean
      ]) => {
        switch (oauthState) {
          default:
          case OauthState.ERROR:
          case OauthState.LOADED: {
            this._isCompletelyLoaded.next(true);
            return hasMatterport && false;
          }

          case OauthState.LOADING:
          case OauthState.EXTERNAL:
            return (isLoading || hasMatterport) && true;
        }
      }
    )
  );

  private _getRFIESAsMatterportComponents(
    rfies: RFIItemWithMarker[]
  ): ComponentConfiguration[] {
    return rfies
      .filter((rfiItem) => rfiItem.marker)
      .map((rfiItem) => {
        const position = rfiItem.marker?.position;
        let normal = new Vector3(0, 0, 0);
        if (rfiItem.marker?.anchorPointNormal) {
          const normalVector = this.matterportService
            .transformConverter()
            .to3dPosition(
              new Vector3(
                rfiItem.marker?.anchorPointNormal.x,
                rfiItem.marker?.anchorPointNormal.y,
                rfiItem.marker?.anchorPointNormal.z
              )
            );
          normal = new Vector3(normalVector.x, normalVector.y, normalVector.z);
        }

        return <ComponentConfiguration>{
          id: rfiItem.procoreId.toString(),
          position: new Vector3(
            position?.x || 0,
            position?.y || 0,
            position?.z || 0
          ),
          normal,
          stemHeight: 0.00003,
          userData: { annotationType: 'rfi' },
          scale: { x: 0.12, y: 0.12, z: 0.12 } as Vector3,
          objects: [
            new SpriteConfiguration({
              icon: rfiMatterportIcons[rfiItem.status]
            })
          ]
        };
      });
  }

  private _getNotesAsMatterportComponents(
    notes: Note[]
  ): ComponentConfiguration[] {
    return notes
      .filter((note: Note) => note.marker)
      .map((note: Note) => {
        const type: TagNoteTypes =
          note.type === 'Information'
            ? TagNoteTypes.INFO
            : this.noteStatusAdapter(note.status);

        const position = note.marker?.position;
        let normal = new Vector3(0, 0, 0);
        if (note.marker?.anchorPointNormal) {
          const normalVector = this.matterportService
            .transformConverter()
            .to3dPosition(
              new Vector3(
                note.marker?.anchorPointNormal.x,
                note.marker?.anchorPointNormal.y,
                note.marker?.anchorPointNormal.z
              )
            );
          normal = new Vector3(normalVector.x, normalVector.y, normalVector.z);
        }
        return <ComponentConfiguration>{
          id: note.id,
          position: new Vector3(
            position?.x || 0,
            position?.y || 0,
            position?.z || 0
          ),
          normal,
          stemHeight: 0.00003,
          userData: { annotationType: 'note' },
          scale: { x: 0.12, y: 0.12, z: 0.12 } as Vector3,
          objects: [
            new SpriteConfiguration({
              icon: ICONS[type]
            })
          ]
        };
      });
  }

  private _getPunchItemAsMatterportComponents(
    punchItems: PunchItemWithMarker[]
  ): ComponentConfiguration[] {
    return punchItems
      .filter((punchItem: PunchItemWithMarker) => punchItem.marker)
      .map((punchItem) => {
        const position = punchItem.marker?.position;
        let normal = new Vector3(0, 0, 0);
        if (punchItem.marker?.anchorPointNormal) {
          const normalVector = this.matterportService
            .transformConverter()
            .to3dPosition(
              new Vector3(
                punchItem.marker?.anchorPointNormal.x,
                punchItem.marker?.anchorPointNormal.y,
                punchItem.marker?.anchorPointNormal.z
              )
            );
          normal = new Vector3(normalVector.x, normalVector.y, normalVector.z);
        }
        return <ComponentConfiguration>{
          id: punchItem.procoreId.toString(),
          position: new Vector3(
            position?.x || 0,
            position?.y || 0,
            position?.z || 0
          ),
          normal,
          stemHeight: 0.00003,
          userData: { annotationType: 'punch-item' },
          scale: { x: 0.12, y: 0.12, z: 0.12 } as Vector3,
          objects: [
            new SpriteConfiguration({
              icon: punchItem.showYellowBackground
                ? ICONS['PUNCH_DRAFT']
                : ICONS['PUNCH_CLOSED']
            })
          ]
        };
      });
  }

  private _componentsForSelectedStage$ = this.stageFacade.selectedId$.pipe(
    switchMap((stageId: string) =>
      this.componentsFacade.matterportComponentsForStage$(stageId)
    )
  );
  private _getComponentsAndCreatePortal(component: StageComponent) {
    firstValueFrom(this._componentsForSelectedStage$).then((components) => {
      if (components.length > 1) this._createPortals(component, components);
    });
  }

  private readonly _openScan$ = (
    component: StageComponent | undefined,
    showcaseSocket: ViewContainerRef,
    blockPrivateLink = false
  ): Observable<CustomPhases> =>
    of({}).pipe(
      switchMap(() => {
        if (!component) {
          throw new Error('Component is undefined!');
        }

        this._goToSweep = true;
        this._lastKnownMatterportOffset = component.offset;

        const showcaseId = ShowcaseHelper.findShowcaseId(
          component.componentUrl
        );

        return this.matterportOauthService
          .createAndOpenScan$({
            containerRef: showcaseSocket,
            matterportScanId: showcaseId,
            matterportOffset: component.offset,
            language: SupportedLinks.EN, //To Delete
            blockPrivateLink
          })
          .pipe(
            filter((status: CustomPhases) => status === CustomPhases.PLAYING),
            delay(1000),
            tap((status: CustomPhases) => {
              if (status === CustomPhases.PLAYING) {
                this._getComponentsAndCreatePortal(component);
              }
            }),
            catchError((e) => {
              return of(e);
            })
          );
      }),
      take(1),
      catchError((e) => {
        return of(e);
      })
    );

  constructor() {
    this.oauthState$
      .pipe(
        tap((state: OauthState) => {
          console.log(
            `%c${state}`,
            'background-color: black; color: lightgreen; padding: 2px; text-transform: Uppercase'
          );

          if (state === OauthState.ERROR || state === OauthState.LOADED)
            this._isMatterportLoading.next(false);
        }),
        takeUntil(this._destroySource)
      )
      .subscribe();
  }

  ngOnDestroy(): void {
    this._destroySource.next();
    this._destroySource.complete();

    this.matterportService.destroy();
  }

  goToSelectedMarker(annotationType: AnnotationModulesWithEmptyState): void {
    this._goToMarker = annotationType;
  }

  start(showcaseSocket: ViewContainerRef, blockPrivateLink = false): void {
    this._refreshOpenScan$
      .pipe(
        startWith(undefined),
        switchMap(() => this.locationServerChanged.pipe(startWith(undefined))),
        switchMap(() => this._synchronizedModel$),
        mergeMap((component: StageComponent | undefined) => {
          this._matterportIsOpen.next(false);
          this._isMatterportLoading.next(true);
          this._isCompletelyLoaded.next(false);
          if (component) {
            return this._openScan$(
              component,
              showcaseSocket,
              blockPrivateLink
            ).pipe(
              tap(() => {
                this._switchScanSource.next();
                this.listenOnPoseChange();
                this.listenOnFloorChange();
              }),
              mergeMap(() => {
                if (this._goToMarker !== 'none') {
                  return this.goToMarker$;
                } else if (this._goToSweep && this._lastKnownCameraPose) {
                  if (this._lastKnownCameraPose.mode !== 'mode.inside') {
                    return this.matterportService
                      .changeMode$(this._lastKnownCameraPose.mode)
                      .pipe(
                        mergeMap(() =>
                          this.matterportService.moveToPose$(
                            this._lastKnownCameraPose as any
                          )
                        ),
                        mergeMap(() => {
                          if (this.lastKnownFloor !== undefined)
                            return this.matterportService.changeFloor$(
                              this.lastKnownFloor
                            );

                          return of();
                        }),
                        tap(() => {
                          this._isCompletelyLoaded.next(true);
                        })
                      );
                  } else {
                    const pos = this._lastKnownCameraPose.position;
                    const position = new Vector3(pos.x, pos.y, pos.z);
                    const { x, y, z } = this._lastKnownCameraPose
                      .rotation as any;
                    const rotation = new Vector3(x, y, z);
                    return this.matterportService
                      .moveToClosestSweepWithOffset$({
                        position,
                        rotation,
                        translation: Transition.INSTANT,
                        transitionTime: 0
                      })
                      .pipe(
                        tap(() => {
                          this._goToSweep = false;
                          this._isCompletelyLoaded.next(true);
                        })
                      );
                  }
                }
                this._isCompletelyLoaded.next(true);
                return of(defaultIfEmpty(null));
              }),
              tap((e) => {
                this._matterportIsOpen.next(true);
                this._isMatterportLoading.next(false);
              }),
              tap(() => {
                this._goToMarker = 'none';
                this._goToSweep = false;
              }),
              switchMap(() =>
                iif(
                  () => !this._hideOverlayElements,
                  this._markers$,
                  of(undefined)
                )
              )
            );
          } else {
            this._isCompletelyLoaded.next(true);
            this._isMatterportLoading.next(false);
            return of(undefined).pipe(
              tap(() => {
                this.matterportService.destroy();
              })
            );
          }
        }),
        takeUntil(this._destroySource),
        catchError((error) => {
          console.error(error);
          return of(error);
        })
      )
      .subscribe(() => {
        this._goToMarker = 'none';
        this._goToSweep = false;
      });
  }

  readonly goToMarker$ = merge(
    this.notesFacade.selectedNote$.pipe(
      filter(() => this._goToMarker === 'notes')
    ),
    this._annotationsFacade
      .getSelectedPunchItem$()
      .pipe(filter(() => this._goToMarker === 'punchList')),
    this._annotationsFacade
      .getSelectedRfiItem$()
      .pipe(filter(() => this._goToMarker === 'RFI'))
  ).pipe(
    take(1),
    mergeMap(({ id, marker }) => {
      this.matterportService.selectedNote = id;
      return this.matterportService.moveTo$(
        new Vector3(
          marker?.position.x || 0,
          marker?.position.y || 0,
          marker?.position.z || 0
        )
      );
    })
  );

  private listenOnFloorChange(): void {
    this.matterportIsOpen$
      .pipe(
        filter((isOpen) => isOpen),
        switchMap(() => {
          return this.matterportService.currentFloor$.pipe(
            tap((floor: number | undefined) => {
              if (this._matterportIsOpen.getValue())
                this.lastKnownFloor = floor;
            })
          );
        }),
        takeUntil(race(this._destroySource, this._switchScanSource))
      )
      .subscribe();
  }

  private listenOnPoseChange(): void {
    this.matterportIsOpen$
      .pipe(
        filter((isOpen) => isOpen),
        switchMap(() => {
          return this.matterportService.positionChange$.pipe(
            filter((cameraPose: Camera.Pose | null) => cameraPose !== null),
            throttleTime(1000),
            filter(() => !this._goToSweep),
            tap((cameraPose: Camera.Pose | null) => {
              this.lastKnownCameraPose =
                this.matterportService.lastKnownCameraPose(
                  cameraPose as Camera.Pose
                );
            })
          );
        }),
        takeUntil(race(this._destroySource, this._switchScanSource))
      )

      .subscribe();
  }

  private noteStatusAdapter(status: string): TagNoteTypes {
    switch (status) {
      case 'InProgress':
        return TagNoteTypes.INPROGRESS;

      case 'Resolved':
        return TagNoteTypes.RESOLVED;

      case 'Pending':
        return TagNoteTypes.PENDING;

      case 'Unresolved':
        return TagNoteTypes.UNRESOLVED;

      default:
        return TagNoteTypes.INFO;
    }
  }
  private _createPortals(scan: StageComponent, models: StageComponent[]) {
    const currentMtpOffset = scan.offset
      ? JSON.parse(scan.offset)
      : {
          ...Transform.default
        };
    this._portalClickObserver();
    models
      .filter((model) => {
        return model.id !== scan.id && model.isSynchronizingAccepted;
      })
      .forEach((model) => {
        this.matterportService.createPortal({
          id: model.id,
          currentMtpOffset,
          currentMtpSweeps: scan.sweeps,
          otherMtpOffset: model.offset
            ? JSON.parse(model.offset)
            : { ...Transform.default },
          otherMtpSweeps: model.sweeps || [],
          componentName: model.name
        });
      });
  }

  private _portalClickObserver() {
    this.matterportService.componentClicked$
      .pipe(
        filter(
          (component: ClickEventEmitter) =>
            component.id !== undefined &&
            component.userData?.['type'] === 'portal'
        ),
        map((component: ClickEventEmitter) => component.id as string),
        takeUntil(this._destroySource),
        tap((componentId: string) => {
          this.componentsFacade.setSelectedComponentById(componentId);
        })
      )
      .subscribe();
  }
  hideAllNotes(hideAll: boolean) {
    this.showcaseNotesFacade.hideAllNotes(hideAll);
  }
}
