import { NgTemplateOutlet } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  contentChild,
  input,
  TemplateRef
} from '@angular/core';
import { ICON_TYPE, UiIconModule } from '@simlab/ui/icon';

@Component({
  selector: 'lib-annotation-panel-header',
  imports: [UiIconModule, NgTemplateOutlet],
  templateUrl: './annotation-panel-header.component.html',
  styleUrl: './annotation-panel-header.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AnnotationPanelHeaderComponent {
  protected readonly nameTemplate =
    contentChild<TemplateRef<unknown>>('nameContent');

  readonly name = input<string>('');
  readonly subName = input.required<string>();
  readonly iconType = input.required<ICON_TYPE>();
}
