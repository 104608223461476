import { Pipe, PipeTransform } from '@angular/core';
import { RootNoteData, RootNoteStatus } from '@simlab/data-access';

@Pipe({
    name: 'noteCount',
    standalone: false
})
export class RootNoteCountPipe implements PipeTransform {
  /**
   *
   * @param data rootNotesCounts
   * @param status
   * @returns
   */
  transform(data: RootNoteData[], status: RootNoteStatus): number {
    return data.find(
      (rootNoteData: RootNoteData) => rootNoteData.rootNoteStatus === status
    )?.rootNoteByStatusCount as number;
  }
}
