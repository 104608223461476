import { inject } from '@angular/core';
import { tapResponse } from '@ngrx/operators';
import { AbstractConstructor, Constructor } from '@simlab/design/internal';
import { ToastService } from '@simlab/design/toast';
import { ApiProcoreService } from '@simlab/procore/data-access';
import {
  procoreBaseInfoPayload,
  PunchListWorkflowStatus,
  SetAsReadyToReview,
  UpdatePunchItemAssignment,
  UpdatePunchItemAssignments,
  UpdatePunchItemWorkflowStatus
} from '@simlab/procore/models';
import { PunchItemsListStore } from '@simlab/procore/services';
import { EMPTY, exhaustMap, map, throwError } from 'rxjs';

import { rxMethod } from '@ngrx/signals/rxjs-interop';
import { RxMethod } from './_punch-item-store.service';
import { PunchItemState } from './_punch-item.state';
import {
  mixinPunchItemUpdateWorkflowAndComment,
  PunchItemUpdateWorkflowAndComment
} from './punch-item-update-workflow-and-conditionally-comment.base';
import {
  mixinPunchItemUploadFiles,
  PunchItemUploadFilesAndReturnIds
} from './punch-item-upload-files.base';

type SendToAssignees = {
  workflowStatus: PunchListWorkflowStatus;
  procoreUserIds: number[];
  comment: string;
  formFiles: File[];
};

export type PunchItemSendItem = {
  updatePunchItemAssignmentsStatus: RxMethod<UpdatePunchItemAssignments>;
  updatePunchItemWorkflowStatus: RxMethod<UpdatePunchItemWorkflowStatus>;
  sendToAssignees: RxMethod<SendToAssignees>;
  setAsReadyForReview: RxMethod<SetAsReadyToReview>;
} & PunchItemUpdateWorkflowAndComment &
  PunchItemUploadFilesAndReturnIds;

type TPunchItemSendItem = Constructor<PunchItemSendItem> &
  AbstractConstructor<PunchItemSendItem>;
export function mixinPunchItemSendItem<T extends AbstractConstructor<any>>(
  base: T
): TPunchItemSendItem & T;

export function mixinPunchItemSendItem<
  T extends Constructor<
    PunchItemUpdateWorkflowAndComment & PunchItemUploadFilesAndReturnIds
  >
>(base: T) {
  return mixinPunchItemUploadFiles(
    mixinPunchItemUpdateWorkflowAndComment(
      class extends base implements PunchItemSendItem {
        private readonly _state = inject(PunchItemState);
        private readonly _listStore = inject(PunchItemsListStore);
        private readonly _api = inject(ApiProcoreService);
        private readonly _toastService = inject(ToastService);
        private readonly _baseInfoProcore = inject(procoreBaseInfoPayload);
        private readonly _punchItem = this._listStore.itemSelected;

        readonly updatePunchItemAssignmentsStatus =
          rxMethod<UpdatePunchItemAssignments>(
            exhaustMap(
              ({ punchItemAssignmentIds, status, comment, formFiles }) => {
                const baseInfoProcore = this._baseInfoProcore();
                if (baseInfoProcore === undefined) {
                  console.error('baseInfoProcore is undefined');
                  this._state.patchItemState({
                    sendItemUpdateStatus: 'FAIL'
                  });

                  return EMPTY;
                }

                const { procoreProjectId, procoreCompanyId } = baseInfoProcore;
                const procorePunchItemId = this._punchItem().procoreId;

                this._state.patchItemState({
                  sendItemUpdateStatus: 'PENDING'
                });
                return this._api
                  .updatePunchItemAssignments({
                    procoreProjectId,
                    procoreCompanyId,
                    status,
                    punchItemAssignmentIds,
                    procorePunchItemId
                  })
                  .pipe(
                    exhaustMap(() => {
                      return this.conditionallyPostComment$(
                        procorePunchItemId,
                        comment,
                        formFiles
                      );
                    }),
                    tapResponse({
                      next: () => {
                        this._listStore.fetchPunchItem(procorePunchItemId);
                        this._state.fetchStatus(procorePunchItemId);
                        this._state.fetchActivities(procorePunchItemId);
                        this._state.patchItemState({
                          sendItemUpdateStatus: 'SUCCESS'
                        });
                      },
                      error: (error) => {
                        console.error(error);
                        this._state.patchItemState({
                          sendItemUpdateStatus: 'FAIL'
                        });
                      },
                      finalize: () => {
                        queueMicrotask(() => {
                          this._state.patchItemState({
                            sendItemUpdateStatus: 'IDLE'
                          });
                        });
                      }
                    })
                  );
              }
            )
          );

        readonly updatePunchItemWorkflowStatus = rxMethod<{
          workflowStatus: PunchListWorkflowStatus;
          comment: string;
          formFiles: File[];
        }>(
          exhaustMap(({ workflowStatus, comment, formFiles }) => {
            const procoreId = this._punchItem().procoreId;
            this._state.patchItemState({
              sendItemUpdateStatus: 'PENDING'
            });
            return this.updateWorkflowStatusAndConditionallyPostComment$(
              procoreId,
              workflowStatus,
              comment,
              formFiles
            ).pipe(
              tapResponse({
                next: (punchItem) => {
                  this._state.patchItemState({
                    sendItemUpdateStatus: 'SUCCESS'
                  });
                  this._listStore.patchPunchItem(punchItem);
                  this._state.fetchStatus(punchItem.procoreId);
                  this._state.fetchActivities(punchItem.procoreId);
                },
                error: (error) => {
                  this._toastService.open(
                    $localize`:@@UPDATE_WORKFLOW_STATUS_ERROR:An error occured while updating the workflow status of a punch item.`,
                    'Error'
                  );
                  console.error(error);
                  this._state.patchItemState({
                    sendItemUpdateStatus: 'FAIL'
                  });
                },
                finalize: () => {
                  queueMicrotask(() => {
                    this._state.patchItemState({
                      sendItemUpdateStatus: 'IDLE'
                    });
                  });
                }
              })
            );
          })
        );

        readonly sendToAssignees = rxMethod<{
          workflowStatus: PunchListWorkflowStatus;
          procoreUserIds: number[];
          comment: string;
          formFiles: File[];
        }>(
          exhaustMap(
            ({ workflowStatus, procoreUserIds, comment, formFiles }) => {
              this._state.patchItemState({
                sendItemUpdateStatus: 'PENDING'
              });
              const procoreId = this._punchItem().procoreId;
              const baseInfoProcore = this._baseInfoProcore();
              if (baseInfoProcore === undefined) {
                console.error('baseInfoProcore is undefined');
                this._state.patchItemState({
                  sendItemUpdateStatus: 'FAIL'
                });
                return EMPTY;
              }
              const { procoreProjectId, procoreCompanyId } = baseInfoProcore;
              const marker = this._punchItem().marker;

              return this._api
                .updatePunchItemAssignees({
                  procoreCompanyId,
                  procoreProjectId,
                  procoreId,
                  procoreUserIds
                })
                .pipe(
                  exhaustMap(() => {
                    return this.updateWorkflowStatusAndConditionallyPostComment$(
                      procoreId,
                      workflowStatus,
                      comment,
                      formFiles
                    );
                  }),
                  tapResponse({
                    next: (punchItem) => {
                      this._listStore.patchPunchItem(punchItem);
                      this._listStore.updateSelectedItemMarker(marker);
                      this._state.fetchStatus(punchItem.procoreId);
                      this._state.fetchActivities(punchItem.procoreId);
                      this._state.patchItemState({
                        sendItemUpdateStatus: 'SUCCESS'
                      });
                    },
                    error: (error) => {
                      console.error(error);
                      this._state.patchItemState({
                        sendItemUpdateStatus: 'FAIL'
                      });
                    },
                    finalize: () => {
                      queueMicrotask(() => {
                        this._state.patchItemState({
                          sendItemUpdateStatus: 'IDLE'
                        });
                      });
                    }
                  })
                );
            }
          )
        );

        readonly setAsReadyForReview = rxMethod<SetAsReadyToReview>(
          exhaustMap((assignmentUpdate) => {
            const procorePunchItemId = this._punchItem().procoreId;
            this._state.patchItemState({
              sendItemUpdateStatus: 'PENDING'
            });

            return this._api
              .getProcoreUserInfo()
              .pipe(
                map((userInfo) => userInfo.id),
                exhaustMap((loginInformationId) => {
                  const punchItemAssignmentId =
                    this._getAssignmentIdForCurrentUser(loginInformationId);

                  if (!punchItemAssignmentId) {
                    this._showSendToReviewErrorMessage();
                    return throwError(
                      () =>
                        new Error(
                          'No punch item assignment with Procore user id!'
                        )
                    );
                  }

                  return this.uploadFilesAndReturnIds$(
                    assignmentUpdate.files
                  ).pipe(
                    exhaustMap((uploadIds) => {
                      const updatePunchItemAssignment: UpdatePunchItemAssignment =
                        {
                          ...assignmentUpdate,
                          loginInformationId,
                          procorePunchItemId,
                          punchItemAssignmentId
                        };

                      return this._api.updatePunchItemAssignment(
                        this._state.createUpdateItemAssignmentPayload(
                          updatePunchItemAssignment,
                          uploadIds
                        )
                      );
                    })
                  );
                })
              )
              .pipe(
                tapResponse({
                  next: () => {
                    this._listStore.fetchPunchItem(procorePunchItemId);
                    this._state.fetchStatus(procorePunchItemId);
                    this._state.fetchActivities(procorePunchItemId);
                    this._state.patchItemState({
                      sendItemUpdateStatus: 'SUCCESS'
                    });
                  },
                  error: (error) => {
                    this._showSendToReviewErrorMessage();
                    console.error(error);
                    this._state.patchItemState({
                      sendItemUpdateStatus: 'FAIL'
                    });
                  },
                  finalize: () =>
                    queueMicrotask(() => {
                      this._state.patchItemState({
                        sendItemUpdateStatus: 'IDLE'
                      });
                    })
                })
              );
          })
        );

        private _showSendToReviewErrorMessage() {
          this._toastService.open(
            $localize`:@@SEND_TO_REVIEW_ERROR:An error occured while setting the punch item status as Ready to Review.`,
            'Error'
          );
        }

        private _getAssignmentIdForCurrentUser(procoreUserId: number) {
          const { assignments } = this._punchItem();
          return assignments.find(
            (assignment) => assignment.loginInformation.id === procoreUserId
          )?.id;
        }
      }
    )
  );
}
