import { computed, inject, Injector } from '@angular/core';
import { takeUntilDestroyed, toObservable } from '@angular/core/rxjs-interop';
import { ActivatedRoute, ActivatedRouteSnapshot } from '@angular/router';
import { tapResponse } from '@ngrx/operators';
import {
  patchState,
  signalStore,
  withComputed,
  withHooks,
  withMethods,
  withState,
  WritableStateSource
} from '@ngrx/signals';
import { rxMethod } from '@ngrx/signals/rxjs-interop';
import { GlobalFiltersStore } from '@simlab/annotations-filters/services';
import { Page, ProjectPaging, RFIItemWithMarker } from '@simlab/data-access';
import { StagesFacade, UserPreferenceFacade } from '@simlab/data-store';
import { DesignDateValidators } from '@simlab/design/datepicker';
import { EmptyObject, SortModel } from '@simlab/design/sort-switch';
import { NoteFiltersStore } from '@simlab/feature-stages/services';
import {
  ApiProcoreService,
  canRenderProcoreEntity
} from '@simlab/procore/data-access';
import {
  DeleteRFIItemPayload,
  RFI,
  RFIGetPayloadFilters,
  RFIListItem
} from '@simlab/procore/models';
import {
  catchError,
  combineLatest,
  distinctUntilChanged,
  distinctUntilKeyChanged,
  EMPTY,
  exhaustMap,
  filter,
  map,
  of,
  pipe,
  switchMap,
  tap
} from 'rxjs';
import { AnnotationsProcorePermissionsService } from './annotations-procore-permissions/annotations-procore-permissions.service';
import { ProcoreFiltersStore } from './procore-filters.store';
import { filtersChangesObserver$ } from './procore-list-store-shared-logic';

const isProjectConnectedWithProcore$ = (
  activatedRouteSnapshot: ActivatedRouteSnapshot,
  permissions: AnnotationsProcorePermissionsService
) =>
  switchMap(() =>
    combineLatest([
      canRenderProcoreEntity(activatedRouteSnapshot),
      permissions.hasUserRfiTemplatePermissions$.pipe(
        filter((value): value is boolean => value !== undefined)
      )
    ]).pipe(
      map(
        ([isProjectConnectedWithProcore, hasUserTemplatePermission]) =>
          isProjectConnectedWithProcore && hasUserTemplatePermission
      )
    )
  );

const resetStoreState = (store: WritableStateSource<State>) => {
  const initStateWithoutSeveralVariables = initState.omitProperty(
    'projectId',
    'pageStageId',
    'page'
  );
  // NOTE (Łukasz): recreate page state, because we wanna trigger change reference procedure.
  patchState(store, {
    ...{ ...initStateWithoutSeveralVariables, page: { ...initState.page } }
  });
};

const cantRenderRFIData = (
  globalFiltersStore: InstanceType<typeof GlobalFiltersStore>,
  procoreFiltersStore: InstanceType<typeof ProcoreFiltersStore> | null,
  noteFiltersStore: InstanceType<typeof NoteFiltersStore>
) => {
  const areActiveNoteFilters = noteFiltersStore.areFiltersActive();
  const areActivePunchItemFilters =
    procoreFiltersStore?.areActivePunchItemFilters();
  const areActiveRFIFilters = procoreFiltersStore?.areActiveRFIFilters();

  const hasOnlySelectedUsersFromStages =
    globalFiltersStore.hasOnlySelectedAuthorFromStages() ||
    globalFiltersStore.hasActiveStakeholders();

  return (
    (areActiveNoteFilters ||
      areActivePunchItemFilters ||
      hasOnlySelectedUsersFromStages) &&
    !areActiveRFIFilters
  );
};

const isStagesFilterTruth = (
  stages: State['filters']['stages']
): stages is Exclude<State['filters']['stages'], null | undefined> =>
  Array.isArray(stages) && !!stages.length;

const INIT_PAGING_STATE = { pageSize: 20, pageNumber: 1 } as const;

type State = {
  projectId: string;
  pageStageId: string;
  items: RFIListItem[];
  isLoading: boolean;
  isItemLoading: boolean;
  metaData: Page | null;
  searchInput: string;
  sort: SortModel | EmptyObject;
  page: ProjectPaging;
  filters: Partial<RFIGetPayloadFilters>;
  itemSelected: RFI | undefined;
  activateMarkers: RFIItemWithMarker[];
  isManuallyTriggeredFilterMode: boolean;
};

const initState: State = {
  projectId: '',
  pageStageId: '',
  items: [],
  isLoading: true,
  isItemLoading: true,
  metaData: null,
  searchInput: '',
  sort: {},
  page: INIT_PAGING_STATE,
  itemSelected: undefined,
  filters: {},
  activateMarkers: [],
  isManuallyTriggeredFilterMode: false
};

export const RFIListStore = signalStore(
  withState(initState),
  withComputed((store) => ({
    fetchQuery: computed(() => {
      const filterStages = store.filters().stages;
      const pageStageId = store.pageStageId();

      const stages = isStagesFilterTruth(filterStages)
        ? [...filterStages]
        : [pageStageId];

      return {
        projectId: store.projectId(),
        filter: {
          ...store.filters(),
          search: store.searchInput(),
          stages: [...stages]
        },
        page: {
          pageSize: store.page().pageSize,
          pageNumber: store.page().pageNumber
        },
        sortColumn: store.sort().sortColumn ?? null,
        isAscending: store.sort().isAscending ?? null
      };
    }),
    isFilterMode: computed(() => {
      const filters = { ...store.filters(), search: store.searchInput() };

      if (store.isManuallyTriggeredFilterMode()) return true;

      return Object.values(filters).some(
        (value) =>
          (Array.isArray(value) && value.length > 0) ||
          (!Array.isArray(value) && !!value)
      );
    })
  })),
  withMethods(
    (
      store,
      api = inject(ApiProcoreService),
      globalFiltersStore = inject(GlobalFiltersStore),
      procoreFiltersStore = inject(ProcoreFiltersStore, { optional: true }),
      noteFiltersStore = inject(NoteFiltersStore),
      userPreferences = inject(UserPreferenceFacade),
      stageFacade = inject(StagesFacade),
      activatedRoute = inject(ActivatedRoute),
      injector = inject(Injector),
      permissions = inject(AnnotationsProcorePermissionsService)
    ) => ({
      updateProjectId: (projectId: State['projectId']) => {
        patchState(store, { projectId });
      },
      updatePageStageId: (pageStageId: State['pageStageId']) => {
        patchState(store, { pageStageId, items: [], isLoading: true });
      },
      updateSearch: (searchInput: State['searchInput']) => {
        patchState(store, {
          searchInput,
          items: [],
          isLoading: true,
          page: INIT_PAGING_STATE,
          sort: {}
        });
      },
      updateSort: (sort: State['sort']) => {
        patchState(store, { sort, items: [], isLoading: true });
      },
      updatePage: (page: State['page']) => {
        patchState(store, { page, items: [], isLoading: true, sort: {} });
      },
      resetState: () => {
        globalFiltersStore.resetFilters();
        procoreFiltersStore?.resetFilters();
        noteFiltersStore.resetFilters();

        resetStoreState(store);
      },
      resetListStates: () => {
        patchState(store, { items: [], activateMarkers: [] });
      },
      deleteItem: rxMethod<DeleteRFIItemPayload>(
        pipe(
          exhaustMap((payload) =>
            api.deleteRfi(payload).pipe(
              tapResponse({
                next: () => {
                  stageFacade.decrementRfiCount(
                    store.pageStageId(),
                    payload.status
                  );
                  patchState(store, ({ items, activateMarkers }) => {
                    return {
                      itemSelected: undefined,
                      items: items.filter(
                        (item) => item.procoreId !== payload.procoreId
                      ),
                      activateMarkers: activateMarkers.filter(
                        (item) => item.procoreId !== payload.procoreId
                      )
                    };
                  });
                },
                error: console.error,
                finalize: () => {
                  patchState(store, { isLoading: false });
                }
              })
            )
          )
        )
      ),
      deleteItemFromStore: (itemId: State['items'][number]['id']) => {
        const items = store.items().filter((item) => item.id !== itemId);
        patchState(store, { items });
      },
      deleteItemMarkerFromStore: (itemId: State['items'][number]['id']) => {
        const activateMarkers = store
          .activateMarkers()
          .filter((item) => item.id !== itemId);
        patchState(store, { activateMarkers });
      },
      setSelected: (itemSelected: State['itemSelected']) => {
        patchState(store, { itemSelected });
      },
      setSelectedById: rxMethod<State['items'][number]['procoreId']>(
        pipe(
          tap(() => patchState(store, { isItemLoading: true })),
          exhaustMap((id: State['items'][number]['procoreId']) =>
            api.getRfi({ procoreId: id, projectId: store.projectId() }).pipe(
              catchError((err) => {
                console.error(err);
                return of(undefined);
              }),
              tap({
                next: (data) => {
                  patchState(store, {
                    itemSelected: data,
                    isItemLoading: false
                  });
                },
                finalize: () => patchState(store, { isItemLoading: false })
              })
            )
          )
        )
      ),
      updateSelectedItemMarker: (marker: State['items'][number]['marker']) => {
        const selectedItem = store.itemSelected();
        if (selectedItem === undefined) {
          console.error('Selected item is undefined');
          return;
        }

        patchState(store, (actualData) => {
          const actualActiveMarkers = actualData.activateMarkers;

          const activeMarkerToUpdate = actualActiveMarkers.find(
            (activeMarker) => activeMarker.id === selectedItem.id
          );
          if (activeMarkerToUpdate !== undefined) {
            activeMarkerToUpdate.marker = marker;
          } else {
            if (marker !== undefined)
              actualActiveMarkers.push({
                ...selectedItem,
                marker
              } satisfies RFIItemWithMarker);
          }

          return {
            itemSelected: { ...selectedItem, marker },
            activateMarkers: [...actualActiveMarkers]
          };
        });
      },
      autoMarkersFetch: rxMethod<void>(
        pipe(
          isProjectConnectedWithProcore$(activatedRoute.snapshot, permissions),
          switchMap((isProjectConnectedWithProcore) =>
            toObservable(store.fetchQuery, { injector })
              .pipe(
                filter(() => isProjectConnectedWithProcore),
                filter(
                  ({ projectId, filter: { stages } }) =>
                    projectId !== '' && !!stages[0]
                )
              )
              .pipe(
                switchMap((fetchQuery) => {
                  const cantRender = cantRenderRFIData(
                    globalFiltersStore,
                    procoreFiltersStore,
                    noteFiltersStore
                  );

                  if (cantRender) {
                    patchState(store, {
                      activateMarkers: []
                    });
                    return EMPTY;
                  }

                  return api.getRfisWithMarkersForStages(fetchQuery).pipe(
                    tap(({ items }) => {
                      const activateMarkers = items.filter(
                        ({ marker }) => marker !== undefined
                      );
                      patchState(store, { activateMarkers });
                    }),
                    catchError(() => of([]))
                  );
                })
              )
          )
        )
      ),
      setFilters: rxMethod<void>(() => {
        return filtersChangesObserver$(
          globalFiltersStore,
          procoreFiltersStore,
          noteFiltersStore,
          injector
        ).pipe(
          tap(({ globalFilters, procoreFilters }) => {
            const formattedDueDate = procoreFilters.rfiDueDate
              ? DesignDateValidators.transformStringDateToDate(
                  procoreFilters.rfiDueDate as string,
                  userPreferences.getDateFormat()
                ).toISOString()
              : undefined;

            patchState(store, {
              filters: {
                ...store.filters(),
                authors: globalFilters.authors
                  .map(({ procoreUserId }) => procoreUserId)
                  .filter((id): id is number => id !== undefined),
                assignees: globalFilters.stakeholders
                  .map(({ procoreUserId }) => procoreUserId)
                  .filter((id): id is number => id !== undefined),
                createdAtFrom: globalFilters.dateCreation.from || undefined,
                createdAtTo: globalFilters.dateCreation.to || undefined,
                modyfiedAtFrom:
                  globalFilters.dateModification.from || undefined,
                modyfiedAtTo: globalFilters.dateModification.to || undefined,
                statuses: procoreFilters.rfiStatuses as unknown as string,
                ballInCourts: procoreFilters.rfiBallInCourt,
                dueDate: formattedDueDate ?? undefined,
                stages: globalFilters.stagesRange
              },
              isLoading: true,
              isManuallyTriggeredFilterMode: false,
              items: [],
              page: INIT_PAGING_STATE,
              sort: {},
              searchInput: ''
            });
          })
        );
      }),
      autoFetch: rxMethod<void>(
        pipe(
          isProjectConnectedWithProcore$(activatedRoute.snapshot, permissions),
          switchMap((isProjectConnectedWithProcore) =>
            toObservable(store.fetchQuery, { injector }).pipe(
              filter(
                ({ projectId, filter: { stages } }) =>
                  projectId !== '' && !!stages[0]
              ),
              filter(() => {
                if (!isProjectConnectedWithProcore) {
                  patchState(store, { isLoading: false });
                }

                return isProjectConnectedWithProcore;
              }),
              switchMap((dataQuery) => {
                const cantRender = cantRenderRFIData(
                  globalFiltersStore,
                  procoreFiltersStore,
                  noteFiltersStore
                );

                if (cantRender) {
                  patchState(store, {
                    items: [],
                    isLoading: false,
                    isManuallyTriggeredFilterMode: true
                  });
                  return EMPTY;
                }

                return api.getRfiList(dataQuery).pipe(
                  tapResponse({
                    next: (data) => {
                      const { items, ...metaData } = data;
                      patchState(store, (actualData) => ({
                        ...actualData,
                        items: [...actualData.items, ...items],
                        metaData,
                        isManuallyTriggeredFilterMode: false
                      }));
                    },
                    error: (err) => {
                      console.error(err);
                    },
                    finalize: () => {
                      patchState(store, {
                        isLoading: false,
                        isManuallyTriggeredFilterMode: false
                      });
                    }
                  })
                );
              })
            )
          )
        )
      ),
      getItemSelected$: () =>
        toObservable(store.itemSelected).pipe(
          filter(
            (itemSelected): itemSelected is RFI => itemSelected !== undefined
          )
        ),
      getSelectedItemId$: () =>
        toObservable(store.itemSelected).pipe(
          filter(
            (itemSelected): itemSelected is RFI => itemSelected !== undefined
          ),
          map(({ id }) => id)
        )
    })
  ),
  withHooks({
    onInit(store, activatedRoute = inject(ActivatedRoute)) {
      activatedRoute.params
        .pipe(
          map((params) => params['projectId'] as string),
          distinctUntilChanged(),
          takeUntilDestroyed()
        )
        .subscribe((id) => {
          if (!id) return;
          store.updateProjectId(id);
        });

      activatedRoute.queryParams
        .pipe(
          map((params) => params['stageId'] as string),
          distinctUntilChanged(),
          takeUntilDestroyed()
        )
        .subscribe((id) => {
          if (!id) return;

          store.updatePageStageId(id);
        });

      activatedRoute.queryParams
        .pipe(
          map((params) => ({
            sidenavContent: params['sidenavContent'],
            rfiId: params['rfiId']
          })),
          distinctUntilKeyChanged('rfiId'),
          takeUntilDestroyed()
        )
        .subscribe(({ rfiId }) =>
          !rfiId ? store.setSelected(undefined) : store.setSelectedById(rfiId)
        );

      store.autoFetch();
      store.setFilters();
      store.autoMarkersFetch();
    }
  })
);
