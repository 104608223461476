import {
  computed,
  DestroyRef,
  Directive,
  inject,
  InputSignal,
  OnInit,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ProcoreImpacts } from '@simlab/procore/models';
import { derivedAsync } from 'ngxtension/derived-async';
import { startWith } from 'rxjs';

@Directive()
export abstract class _ImpactFormBase implements OnInit {
  private readonly _destroyRef = inject(DestroyRef);

  protected abstract readonly impactControl: FormControl<ProcoreImpacts | null>;
  protected abstract readonly impactValueControl: FormControl<number | null>;
  protected abstract form: InputSignal<FormGroup>;

  protected readonly impactOptions = [null, ...Object.values(ProcoreImpacts)];

  protected readonly isImpactAmountControlVisible = computed(() => {
    const impact = this._impactControlValueChanges();
    return !!impact && this._isProperProcoreImpact(impact);
  });

  private readonly _impactControlValueChanges = derivedAsync(() => {
    return this.impactControl.valueChanges.pipe(
      startWith(this.impactControl.value)
    );
  });


  ngOnInit() {
    this._prepareSubscriptionForDaysValidation();
  }

  private _isProperProcoreImpact(impact: ProcoreImpacts): boolean {
    return impact === ProcoreImpacts.YesKnown;
  }

  private _prepareSubscriptionForDaysValidation() {
    this.impactControl.valueChanges
      .pipe(takeUntilDestroyed(this._destroyRef))
      .subscribe((value) => {
        const valueControl = this.impactValueControl;

        if (value === ProcoreImpacts.YesKnown) {
          valueControl?.addValidators(Validators.required);
        } else {
          valueControl?.removeValidators(Validators.required);
          valueControl?.setValue(null);
        }
      });

    this.impactControl.updateValueAndValidity();
  }
}
