import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import {
  DesignFlatButton,
  DesignStrokedButton
} from '@simlab/design/button';
import { DesignCommonModule } from '@simlab/design/common';
import { DesignDialogWrapperModule } from '@simlab/design/dialog';


import { ConfirmationModalRef, MODAL_DATA } from '@simlab/ui/modal';

@Component({
    selector: 'feature-stages-synchronize-confirmation-dialog',
    templateUrl: './synchronize-confirmation-dialog.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    imports: [
    DesignFlatButton,
    DesignCommonModule,
    DesignDialogWrapperModule,
    DesignStrokedButton
]
})
export class SynchronizeConfirmationDialogComponent {
  constructor(
    private readonly modalRef: ConfirmationModalRef<unknown>,
    @Inject(MODAL_DATA)
    readonly data: string
  ) {}

  cancel(): void {
    this.modalRef.emit({ state: false });
    this.modalRef.close();
  }

  confirm(): void {
    this.modalRef.emit({ state: true });
    this.modalRef.close();
  }
}
