import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Output,
  ViewChild,
} from '@angular/core';

const DEFAULT_MAX_HEIGHT = 240;

@Component({
    selector: 'ui-slider-color',
    templateUrl: './slider-color.component.html',
    styleUrls: ['./slider-color.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class SliderColorComponent implements AfterViewInit {
  @ViewChild('canvas')
  canvas!: ElementRef<HTMLCanvasElement>;

  @ViewChild('pointer')
  pointer!: ElementRef<HTMLElement>;

  @Output()
  color: EventEmitter<string> = new EventEmitter();

  @Output()
  changeColorOfBrush: EventEmitter<string> = new EventEmitter();

  private _colors: 'white' | 'black' = 'white';
  private _renderingContext!: CanvasRenderingContext2D | null;
  private _selectedHeight = 0;

  ngAfterViewInit(): void {
    this.draw();
  }

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  @HostListener('touchmove', ['$event']) touchMove(): void {} //INFO: This use of the function enable the disable refresh page

  draw(): void {
    if (!this._renderingContext) {
      this._renderingContext = this.canvas.nativeElement.getContext('2d');
    }
    const width = this.canvas.nativeElement.width;
    const height = (this.canvas.nativeElement.height = DEFAULT_MAX_HEIGHT);
    if (this._renderingContext) {
      this._renderingContext.clearRect(0, 0, width, height);

      const gradient = this._renderingContext.createLinearGradient(
        0,
        0,
        0,
        height
      );
      gradient.addColorStop(0, 'rgba(255, 255, 255, 1)');
      gradient.addColorStop(0.15, 'rgba(0, 0, 0, 1)');
      gradient.addColorStop(0.3, 'rgba(255, 0, 0, 1)');
      gradient.addColorStop(0.4, 'rgba(245, 0, 255, 1)');
      gradient.addColorStop(0.6, 'rgba(10, 0, 255, 1)');
      gradient.addColorStop(0.7, 'rgba(0, 255, 245, 1)');
      gradient.addColorStop(0.8, 'rgba(0, 255, 0, 1)');
      gradient.addColorStop(0.92, 'rgba(255, 255, 0, 1)');
      gradient.addColorStop(1, 'rgba(255, 0, 0, 1)');
      this._renderingContext.beginPath();
      this._renderingContext.rect(0, 0, width, height);

      this._renderingContext.fillStyle = gradient;
      this._renderingContext.fill();
      this._renderingContext.closePath();

      if (
        this._selectedHeight >= 0 &&
        this._selectedHeight < DEFAULT_MAX_HEIGHT
      ) {
        this.pointer.nativeElement.style.top = `${this._selectedHeight - 5}px`;
      }
    }
  }

  emitColor(x: number, y: number): void {
    if (x < 0 || y < 0) return;

    if (this._selectedHeight > 0 && this._selectedHeight < DEFAULT_MAX_HEIGHT) {
      const rgbaColor = this.getColorAtPosition(x, y);
      this.color.emit(rgbaColor);
      this.pointer.nativeElement.style.background = rgbaColor;
    }

    if (this._colors === 'white' && y < 20) {
      this._colors = 'black';
      this.changeColorOfBrush.emit(this._colors);
    } else if (y >= 20) {
      this._colors = 'white';
      this.changeColorOfBrush.emit(this._colors);
    }
  }

  getColorAtPosition(x: number, y: number): string {
    if (!this._renderingContext) return 'rgba(0,0,0,1)';

    const imageData = this._renderingContext.getImageData(x, y, 1, 1).data;
    return `rgba(${imageData[0]},${imageData[1]},${imageData[2]},1)`;
  }

  trackInput(ev: Event): void {
    this._selectedHeight = Number((ev.target as HTMLInputElement).value);
    this.draw();
    this.emitColor(0, this._selectedHeight);
  }
}
