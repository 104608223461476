import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import {
  ReactiveFormsModule,
  UntypedFormControl,
  UntypedFormGroup,
  Validators
} from '@angular/forms';
import {
  DesignFlatButton,
  DesignStrokedButton
} from '@simlab/design/button';
import { DesignCommonModule } from '@simlab/design/common';
import { DesignDialogWrapperModule } from '@simlab/design/dialog';


import { UiButtonModule } from '@simlab/ui/button';
import { UiCheckboxModule } from '@simlab/ui/checkbox';
import { UiDividerModule } from '@simlab/ui/divider';
import { UiFormFieldModule } from '@simlab/ui/form-field';
import { UiIconModule } from '@simlab/ui/icon';
import { ConfirmationModalRef, MODAL_DATA } from '@simlab/ui/modal';
import { UiSelectModule } from '@simlab/ui/select';
import { Observable, map, tap } from 'rxjs';

@Component({
    selector: 'feature-stages-transfer-note-dialog',
    templateUrl: './transfer-note-dialog.component.html',
    styleUrls: ['./transfer-note-dialog.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    imports: [
    UiButtonModule,
    DesignCommonModule,
    UiIconModule,
    DesignDialogWrapperModule,
    CommonModule,
    ReactiveFormsModule,
    UiFormFieldModule,
    UiSelectModule,
    UiDividerModule,
    UiButtonModule,
    UiIconModule,
    UiCheckboxModule,
    DesignFlatButton,
    DesignStrokedButton
]
})
export class TransferNoteDialogComponent {
  readonly formGroup: UntypedFormGroup = new UntypedFormGroup({
    selectedStage: new UntypedFormControl('', Validators.required),
    keepPosition: new UntypedFormControl('', Validators.required),
    noteId: new UntypedFormControl('', Validators.required)
  });

  readonly containMeasurements$ = this.data$.pipe(
    map((data) => data.containMeasurements)
  );
  readonly test$ = this.data$.pipe(
    tap(
      (data: {
        stages: {
          id: string;
          name: string;
        }[];
        containMeasurements: boolean;
        noteId: string;
      }) => {
        this.formGroup.patchValue({
          noteId: data.noteId,
          keepPosition: false
        });
      }
    )
  );

  constructor(
    private readonly modalRef: ConfirmationModalRef<unknown>,
    @Inject(MODAL_DATA)
    private readonly data$: Observable<{
      stages: {
        id: string;
        name: string;
      }[];
      containMeasurements: boolean;

      noteId: string;
    }>
  ) {}

  closeDialog(): void {
    this.modalRef.emit({ state: false });
    this.modalRef.close();
  }

  confirmDialog(): void {
    if (!this.formGroup.valid) {
      return;
    }

    this.modalRef.emit({
      state: true,
      result: {
        rootNoteId: this.formGroup.controls['noteId'].value,
        stageId: this.formGroup.controls['selectedStage'].value,
        moveMarker: this.formGroup.controls['keepPosition'].value
      }
    });
    this.modalRef.close();
  }

  trackDataByStageId(
    index: number,
    stage: {
      id: string;
      name: string;
    }
  ): string {
    return stage.id;
  }
}
