/* eslint-disable @angular-eslint/no-host-metadata-property */
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  Inject,
  Optional,
  ViewEncapsulation,
} from '@angular/core';

import { DesignCheckbox } from '@simlab/design/checkbox';
import {
  MAT_OPTION_PARENT_COMPONENT,
  MatOptionParentComponent,
} from '../../modals/options.modals';
import { _OptionBase } from '../option-base/option-base.directive';

@Component({
    selector: 'design-option',
    exportAs: 'designOption',
    host: {
        role: 'option',
        '[attr.tabindex]': '_getTabIndex()',
        '[class.selected]': 'selected',
        '[class.option-multiple]': 'multiple',
        '[class.active]': 'active',
        '[id]': 'id',
        '[attr.aria-selected]': '_getAriaSelected()',
        '[attr.aria-disabled]': 'disabled.toString()',
        '[class.option-disabled]': 'disabled',
        '(click)': '_selectViaInteraction()',
        '(keydown)': '_handleKeydown($event)',
        class: 'design-option focus-indicator',
    },
    styleUrls: ['option.component.scss'],
    templateUrl: 'option.component.html',
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
    imports: [DesignCheckbox]
})
export class DesignOptionComponent extends _OptionBase {
  constructor(
    element: ElementRef<HTMLElement>,
    changeDetectorRef: ChangeDetectorRef,
    @Optional()
    @Inject(MAT_OPTION_PARENT_COMPONENT)
    parent: MatOptionParentComponent,
  ) {
    super(element, changeDetectorRef, parent);
  }
}
