import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  OnInit,
  Output,
  ViewChild,
  inject,
  output,
  signal
} from '@angular/core';
import { DesignFlatButton, DesignIconButton } from '@simlab/design/button';
import { DesignIcon } from '@simlab/design/icon';
import {
  Observable,
  Subject,
  filter,
  first,
  firstValueFrom,
  map,
  race
} from 'rxjs';

import { outputFromObservable } from '@angular/core/rxjs-interop';
import { UiTooltip } from '@simlab/design/tooltip';
import {
  IUpdateAreaMeasurementData,
  TMeasurementGroups,
  TypeMeasurement
} from '@simlab/feature-measurement/models';
import { UiDividerModule } from '@simlab/ui/divider';
import { TYPE_MEASUREMENT } from '../../../tokens/token';
import { MeasurementsGroupComponent } from '../measurements-group/measurements-group.component';
import { MeasurementsComponent } from '../measurements/measurements.component';
import { _MeasurementBase } from './measurement-base.component.base';

export type TMeasurementTabs = 'measurements' | 'measurements_groups';
@Component({
    selector: 'measurement-base',
    imports: [
        CommonModule,
        UiTooltip,
        DesignFlatButton,
        DesignIconButton,
        DesignIcon,
        UiDividerModule,
        MeasurementsGroupComponent,
        MeasurementsComponent
    ],
    templateUrl: './measurement-base.component.html',
    styleUrl: './measurement-base.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class MeasurementBaseComponent
  extends _MeasurementBase
  implements OnInit
{
  private readonly typeMeasurement = inject(TYPE_MEASUREMENT);
  @ViewChild(MeasurementsGroupComponent)
  private _groupComponent: MeasurementsGroupComponent | undefined;
  readonly measurementsVisibility = signal<boolean>(true);
  // set selectedMeasurement(selectedMeasurement: string | undefined) {
  //   this._measurmentFacade.selectedMeasurement(selectedMeasurement);
  // }
  readonly selectedContentChange: Subject<
    MeasurementsGroupComponent | MeasurementsComponent
  > = new Subject<MeasurementsGroupComponent | MeasurementsComponent>();
  // private readonly _userPreferences = inject(UserPreferenceFacade);
  // readonly userPreferences = toSignal(this._userPreferences.getUserPreference$);
  // readonly measurementsGroup: WritableSignal<TAreaMeasurementGroup[]> = signal<
  //   TAreaMeasurementGroup[]
  // >([]);
  // readonly measurements = toSignal(this._measurmentFacade.allMeasurement$);
  override async ngOnInit() {
    super.ngOnInit();
    const hidden = await firstValueFrom(
      this._measurementFacade.allMeasurementsHidden$
    );
    this.measurementsVisibility.set(!hidden);
  }

  readonly closeAction: Subject<
    'measurement-area' | 'measurement-distance' | null
  > = new Subject<'measurement-area' | 'measurement-distance' | null>();

  readonly onClose = output();
  readonly onCloseWithType = output();
  readonly onEdit = output();
  readonly onCloseAction = outputFromObservable(
    this.closeAction.asObservable()
  );

  selected: TMeasurementTabs = 'measurements';
  // readonly openEditor: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(
  //   false
  // );
  // get editorOpened() {
  //   return this.openEditor.getValue();
  // }
  toggleVisibility() {
    this.measurementsVisibility.set(!this.measurementsVisibility());
    this.hideAllArea(!this.measurementsVisibility());
  }

  readonly closePanelAction$: Observable<void> = this.closeAction
    .asObservable()
    .pipe(
      map((sidenavContent: TypeMeasurement | null) => {
        this.onCloseWithType.emit();

        if (!sidenavContent) {
          this.selectedMeasurement = undefined;
          this.hideAllArea(!this.measurementsVisibility());
        }
        return undefined;
      })
    );

  @Output() openPanelAction$: EventEmitter<void> = new EventEmitter<void>();
  override add(addMeasurementTo: void) {
    this.closeAction.next(this.typeMeasurement);

    race(this.cancel, this._measurementFacade.addSuccess$)
      .pipe(first())
      .subscribe(() => this.openPanelAction$.next());
    super.add(addMeasurementTo);
  }

  override edit(payload: IUpdateAreaMeasurementData) {
    this.closeAction.next(this.typeMeasurement);
    this.onEdit.emit();
    race(this.cancel, this._measurementFacade.updateDataSuccess$)
      .pipe(first())
      .subscribe(() => this.openPanelAction$.next());
    super.edit(payload);
  }
  override createGroup(name: string) {
    return super.createGroup(name).then((a: TMeasurementGroups) => {
      this._groupComponent &&
        this._groupComponent.addMeasurementsToGroupModal(a.id, a.creatorId);
      return a;
    });
  }

  override goToMeasurementList(payload: string) {
    this.selected = 'measurements';
    super.goToMeasurementList(payload);
    this.selectedContentChange
      .pipe(
        filter((component) => component instanceof MeasurementsComponent),
        first()
      )
      .subscribe((component) => {
        (component as MeasurementsComponent).showSelectedInView();
      });
  }
}
