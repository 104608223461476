import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { DesignFlatButton, DesignStrokedButton } from '@simlab/design/button';
import { DesignCommonModule } from '@simlab/design/common';
import { DesignDialogWrapperModule } from '@simlab/design/dialog';
import { UiIconModule } from '../../../ui-icon/public-api';
import { ConfirmationModalRef, MODAL_DATA } from '../../public-api';

@Component({
    selector: 'ui-warning-customize-modal',
    templateUrl: './warning-customize-modal.component.html',
    styleUrls: ['./warning-customize-modal.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    imports: [
        CommonModule,
        DesignCommonModule,
        DesignDialogWrapperModule,
        UiIconModule,
        DesignFlatButton,
        DesignStrokedButton,
    ]
})
export class WarningCustomizeModalComponent {
  constructor(
    @Inject(MODAL_DATA)
    readonly data: { title: string; text: string; buttonText: string },
    private readonly modalRef: ConfirmationModalRef<unknown>
  ) {}

  confirm(): void {
    this.modalRef.emit({ state: true });
    this.modalRef.close();
  }

  cancel(): void {
    this.modalRef.emit({ state: false });
    this.modalRef.close();
  }
}
