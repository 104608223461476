import { inject, Injectable } from '@angular/core';
import { FolderStates } from '@simlab/documents/models';
import { ApiProcoreService } from '@simlab/procore/data-access';
import {
  procoreBaseInfoPayload,
  ProcoreElements,
  ProcorePermission,
  ProcoreUserAccessLevels
} from '@simlab/procore/models';
import {
  BehaviorSubject,
  catchError,
  map,
  Observable,
  of,
  startWith,
  switchMap,
  take,
  tap
} from 'rxjs';

export type ProcoreProjectPermittedElementsStates =
  | { state: 'LOADING' }
  | { state: 'LOADED'; data: FolderStates[] }
  | { state: 'NO_PERMISSIONS' };

@Injectable()
export class ProcoreProjectPermittedElements {
  private readonly _procoreBaseInfo = inject(procoreBaseInfoPayload);
  private readonly _api = inject(ApiProcoreService);

  private readonly _store = new BehaviorSubject<
    ProcoreProjectPermittedElementsStates | undefined
  >(undefined);

  resetStore() {
    this._store.next(undefined);
  }

  getProcoreProjectPermittedElements$(): Observable<
    ProcoreProjectPermittedElementsStates | undefined
  > {
    const procoreBaseInfoInstance = this._procoreBaseInfo();

    if (!procoreBaseInfoInstance) {
      this._store.next({ state: 'NO_PERMISSIONS' });
      return this._store.asObservable().pipe(take(1));
    }

    return this._store.asObservable().pipe(
      take(1),
      switchMap((store) => {
        if (store !== undefined) return of(store);

        return this._api
          .getUserProjectPermissions(procoreBaseInfoInstance)
          .pipe(
            map((permissions) => {
              const procoreElements: FolderStates[] =
                this._getPermittedProcoreElements(permissions);

              if (!procoreElements.length)
                return {
                  state: 'NO_PERMISSIONS'
                } as const;

              return {
                state: 'LOADED',
                data: procoreElements
              } as const;
            }),
            startWith({
              state: 'LOADING'
            } as const),
            catchError((err) => {
              console.error(err);
              return of({
                state: 'NO_PERMISSIONS'
              } as const);
            })
          )
          .pipe(
            tap((projectPermittedElements) =>
              this._store.next(projectPermittedElements)
            )
          );
      })
    );
  }

  private _getPermittedProcoreElements(
    permissions: ProcorePermission[]
  ): FolderStates[] {
    const procoreElements: FolderStates[] = [];

    if (
      permissions.find(({ element }) => element === ProcoreElements.Documents)
        ?.accessType !== ProcoreUserAccessLevels.None
    ) {
      procoreElements.push({
        state: 'LOADED',
        data: {
          id: 'documents',
          isDirectory: true,
          name: $localize`:@@PROCORE_DOCUMENTS:Documents`
        }
      });
    }

    if (
      permissions.find(({ element }) => element === ProcoreElements.Drawings)
        ?.accessType !== ProcoreUserAccessLevels.None
    ) {
      procoreElements.push({
        state: 'LOADED',
        data: {
          id: 'drawings',
          isDirectory: true,
          name: $localize`:@@PROCORE_DRAWINGS:Drawings`
        }
      });
    }

    if (
      permissions.find(({ element }) => element === ProcoreElements.Photos)
        ?.accessType !== ProcoreUserAccessLevels.None
    ) {
      procoreElements.push({
        state: 'LOADED',
        data: {
          id: 'pictures',
          isDirectory: true,
          name: $localize`:@@PROCORE_PICTURES:Pictures`
        }
      });
    }

    return procoreElements;
  }
}
