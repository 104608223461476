import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import {
  DesignFlatButtonModule,
  DesignStrokedButton
} from '@simlab/design/button';
import { DesignDialogWrapperModule } from '@simlab/design/dialog';
import { DesignIcon } from '@simlab/design/icon';
import { ConfirmationModalRef } from '@simlab/ui/modal';
@Component({
    templateUrl: './new-update-modal.component.html',
    styleUrls: ['./new-update-modal.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    imports: [
        CommonModule,
        DesignDialogWrapperModule,
        DesignFlatButtonModule,
        DesignStrokedButton,
        DesignIcon
    ]
})
export class NewUpdateModalComponent {
      private readonly modalRef = inject<ConfirmationModalRef<boolean>>(ConfirmationModalRef<boolean>);

  confirm(): void {
    this.modalRef.emit({ state: true });
    this.modalRef.close();
  }

  cancel(): void {
    this.modalRef.emit({ state: false });
    this.modalRef.close();
  }
}
