import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  Input,
  Output,
  ViewEncapsulation,
  inject
} from '@angular/core';
import { FormControl, ReactiveFormsModule } from '@angular/forms';
import { FormatDate, UserPreferences } from '@simlab/data-access';
import { UiSelectModule } from '@simlab/ui/select';
import { Observable, map } from 'rxjs';
import { PersonalSettingBase } from '../../personal-settings.component';
import { UserPreferencesToken } from '../units/units.component';

@Component({
    selector: 'simlab-date-time',
    imports: [CommonModule, UiSelectModule, ReactiveFormsModule],
    template: `<label i18n="@@CALENDAR_FORMAT"> Calendar Format </label>
    <ui-select
      [value]="userPreferences.dateFormat"
      [formControl]="formatDateFormControl"
    >
      <ui-option *ngFor="let item of regions" [value]="item.value">
        {{ item.displayName }}
      </ui-option>
    </ui-select>`,
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
    styleUrls: ['./date-time.component.scss'],
    // eslint-disable-next-line @angular-eslint/no-host-metadata-property
    host: {
        class: 'simlab-date-time'
    }
})
export class DateTimeComponent implements PersonalSettingBase {
  readonly regions: {
    displayName: string;
    value: 'dd/MM/yyyy' | 'MM/dd/yyyy';
  }[] = [
    {
      displayName: `dd/mm/yyyy`,
      value: 'dd/MM/yyyy'
    },
    {
      displayName: `mm/dd/yyyy`,
      value: 'MM/dd/yyyy'
    }
  ];

  private _userPreferences: UserPreferences = inject(UserPreferencesToken);

  @Input() set userPreferences(userPreferences: UserPreferences) {
    this._userPreferences = userPreferences;
  }
  get userPreferences() {
    return this._userPreferences;
  }

  readonly formatDateFormControl = new FormControl<FormatDate>('dd/MM/yyyy');

  constructor() {
    this.formatDateFormControl.patchValue(this._userPreferences.dateFormat);
  }

  @Output() readonly userPreferencesChange: Observable<UserPreferences> =
    this.formatDateFormControl.valueChanges.pipe(
      map((value) => {
        if (!value) return this.userPreferences;
        return {
          ...this.userPreferences,
          dateFormat: value
        };
      })
    );
}
