import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  ViewEncapsulation,
} from '@angular/core';
import { Media } from '@simlab/data-access';
import { NotesFacade } from '@simlab/data-store';
import { Observable, catchError, map, of } from 'rxjs';
import { NoteMediaDocumentItemComponent } from './note-media-document-item/note-media-document-item.component';

@Component({
    selector: 'feature-stages-note-media-document',
    templateUrl: './note-media-document.component.html',
    styleUrls: ['./note-media-document.component.scss'],
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
    imports: [CommonModule, NoteMediaDocumentItemComponent]
})
export class NoteMediaDocumentComponent {
  readonly docs$: Observable<Media[]> = this.notesFacade.docs$.pipe(
    map((docs: Media[] | null) => {
      if (docs?.length) {
        return docs;
      } else {
        return [];
      }
    }),
    catchError((e) => {
      console.log(e);
      return of(e);
    })
  );

  constructor(private readonly notesFacade: NotesFacade) {}

  trackById(index: number, item: Media): string {
    return item.id;
  }
}
