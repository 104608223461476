import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, input } from '@angular/core';
import { DesignProgressSpinnerComponent } from '@simlab/design/progress-spinner';
import { UiIconModule } from '@simlab/ui/icon';

import { TEventWorkStatus } from '../ui-event-element/ui-event-element.component';

@Component({
  selector: 'event-ui-event-element-without-progress',
  imports: [CommonModule, DesignProgressSpinnerComponent, UiIconModule],
  templateUrl: './ui-event-element-without-progress.component.html',
  styleUrl: './ui-event-element-without-progress.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  host: {
    '[class.last]': 'last()'
  }
})
export class UiEventElementWithoutProgressComponent {
  readonly title = input.required<string>();
  readonly status = input.required<TEventWorkStatus>();
  readonly last = input<boolean>(false);
}
