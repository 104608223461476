import {
  ChangeDetectionStrategy,
  Component,
  computed,
  inject,
  input,
  ViewEncapsulation
} from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { FormControl, FormGroup, ReactiveFormsModule } from '@angular/forms';
import {
  DesignRadioExpansionPanel,
  DesignRadioExpansionPanelContent,
  ExpansionPanelHeaderComponent
} from '@simlab/design/accordion';
import { DesignLabel } from '@simlab/design/common';
import {
  DesignFormField,
  DesignFormFieldError
} from '@simlab/design/form-field';
import { DesignInput } from '@simlab/design/input';
import {
  CostImpactComponent,
  ScheduleImpactComponent
} from '@simlab/procore/shared/ui';
import { ApiProcoreService } from '@simlab/procore/data-access';
import {
  procoreBaseInfoPayload,
  ProcorePriorities,
  PunchItemDistributionalMember,
  PunchItemForm
} from '@simlab/procore/models';
import { ProcorePriorityLabelGetterPipe } from '@simlab/procore/pipes';
import { UiFormFieldModule } from '@simlab/ui/form-field';
import { UiSelectModule } from '@simlab/ui/select';
import { derivedAsync } from 'ngxtension/derived-async';
import { map, of } from 'rxjs';
import { LocationSelectComponent } from '../location-select/location-select.component';
import { UiHelperModule } from '@simlab/ui/helper';
import { UiHintModule } from '@simlab/ui/hint';

@Component({
  selector: 'procore-more-details-form',
  standalone: true,
  imports: [
    ReactiveFormsModule,
    UiFormFieldModule,
    DesignInput,
    DesignLabel,
    DesignFormField,
    UiSelectModule,
    DesignFormFieldError,
    ProcorePriorityLabelGetterPipe,
    DesignRadioExpansionPanel,
    DesignRadioExpansionPanelContent,
    ExpansionPanelHeaderComponent,
    LocationSelectComponent,
    ScheduleImpactComponent,
    CostImpactComponent,
    CostImpactComponent,
    UiHelperModule,
    UiHintModule
  ],
  templateUrl: './more-details-form.component.html',
  styleUrl: './more-details-form.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [],
  encapsulation: ViewEncapsulation.None,
  host: {
    class: 'punch-item-more-details'
  }
})
export class MoreDetailsFormComponent {
  private readonly _apiProcoreService = inject(ApiProcoreService);
  private readonly _projectInfo = inject(procoreBaseInfoPayload);

  readonly form = input.required<FormGroup<PunchItemForm>>();

  protected readonly distributionalMembers = derivedAsync(
    () => {
      const projectInfo = this._projectInfo();
      if (projectInfo === undefined) return of([]);

      return this._apiProcoreService
        .getPunchItemPotentialDistributionMembers(projectInfo)
        .pipe(map((members) => members.items));
    },
    { initialValue: [] }
  );

  protected readonly costCodes = derivedAsync(
    () => {
      const projectInfo = this._projectInfo();
      if (projectInfo === undefined) return of([]);

      return this._apiProcoreService.getProcoreCostCodes(projectInfo);
    },
    { initialValue: [] }
  );

  protected readonly punchItemTypes = derivedAsync(
    () => {
      const projectInfo = this._projectInfo();
      if (projectInfo === undefined) return of([]);

      return this._apiProcoreService.getPunchItemTypes(projectInfo);
    },
    { initialValue: [] }
  );

  protected readonly procoreTrades = derivedAsync(
    () => {
      const projectInfo = this._projectInfo();
      if (projectInfo === undefined) return of([]);

      return this._apiProcoreService.getTrades(projectInfo.procoreCompanyId);
    },
    { initialValue: [] }
  );

  protected readonly priorityOptions = Object.values(ProcorePriorities);

  protected readonly filterDistributionMembers = new FormControl('', {
    nonNullable: true
  });
  protected readonly filterDistributionMembersValueChanges = toSignal(
    this.filterDistributionMembers.valueChanges,
    { initialValue: '' }
  );

  protected readonly filteredDistributionMembers = computed(() => {
    const members = this.distributionalMembers();
    const filterValue = this.filterDistributionMembersValueChanges();

    return this._filterDistributionalMembers(members, filterValue);
  });

  private _filterDistributionalMembers(
    collaborators: PunchItemDistributionalMember[],
    filterValue: string
  ): PunchItemDistributionalMember[] {
    return collaborators.filter((collaborator) => {
      return collaborator.name
        .toLocaleLowerCase()
        .includes(filterValue.toLocaleLowerCase());
    });
  }
}
