/* eslint-disable @angular-eslint/no-inputs-metadata-property */
/* eslint-disable @angular-eslint/no-host-metadata-property */
/* eslint-disable @angular-eslint/component-class-suffix */
import { FocusableOption, FocusOrigin } from '@angular/cdk/a11y';
import { CommonModule } from '@angular/common';
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  Host,
  OnDestroy,
  ViewEncapsulation
} from '@angular/core';
import { CanColor } from '@simlab/design/internal';
import { tap } from 'rxjs';
import { ExpansionPanelHeader } from '../expansion-panel-header.directive';
import { DesignRadioExpansionPanel } from './radio-expansion-panel.component';

@Component({
    selector: 'design-radio-expansion-panel-header',
    imports: [CommonModule],
    templateUrl: './radio-expansion-panel-header.component.html',
    styleUrls: ['./radio-expansion-panel-header.component.scss'],
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
    host: {
        class: 'design-radio-expansion-panel-header design-focus-indicator',
        role: 'button',
        '[attr.aria-expanded]': '_isExpanded()',
        '[class.design-radio-expansion-panel-header--expanded]': '_isExpanded()',
        '(click)': '_toggle()',
        '[class.design-color--primary]': 'color === "primary"',
        '[class.design-color--secondary]': 'color === "secondary"',
        '[class.design-color--error]': 'color === "error"',
    },
    inputs: ['color'],
    providers: [
        {
            provide: ExpansionPanelHeader,
            useExisting: DesignRadioExpansionPanelHeader,
        },
    ]
})
export class DesignRadioExpansionPanelHeader
  extends ExpansionPanelHeader
  implements FocusableOption, AfterViewInit, OnDestroy, CanColor
{

  constructor(    @Host()
  public panel: DesignRadioExpansionPanel,
) {
    super(panel);

    // Avoids focus being lost if the panel contained the focused element and was closed.
    // panel.closed
    // .pipe(filter(() => panel._containsFocus()))
    // .subscribe(() => _focusMonitor.focusVia(_elementRef, 'program'));
  }

  ngAfterViewInit(): void {
    this._focusMonitor
      .monitor(this._elementRef)
      .pipe(
        tap((origin: FocusOrigin) => {
          if (origin && this.panel.accordion) {
            this.panel.accordion._handleHeaderFocus(this);
          }
        })
      )
      .subscribe();
  }

  ngOnDestroy(): void {
    this._parentChangeSubscription.unsubscribe();
    this._focusMonitor.stopMonitoring(this._elementRef);
  }
}
