import {
  ChangeDetectionStrategy,
  Component,
  inject,
  input,
} from '@angular/core';
import { outputToObservable, toSignal } from '@angular/core/rxjs-interop';
import { DesignIcon } from '@simlab/design/icon';
import { distinctUntilChanged, map, merge } from 'rxjs';
import { DragAndDropDirective } from './drag-and-drop.directive';

@Component({
    selector: 'design-drag-and-drop',
    imports: [DesignIcon],
    template: `
    @if (showOverlay() && !disabled()) {
      <div class="drag-and-drop__overlay flex-center">
        <div class="flex-center drag-and-drop__overlay--container">
          <div><i designIcon="upload" designSize="50px"></i></div>
          <div class="flex-center">
            <h1>Drop files or folders</h1>
            <h2 class="text-regular">to upload them</h2>
          </div>
        </div>
      </div>
    }
    <ng-content></ng-content>
  `,
    hostDirectives: [DragAndDropDirective],
    styleUrl: './drag-and-drop.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class DragAndDropComponent {
  readonly disabled = input(false);
  protected dragAndDrop = inject(DragAndDropDirective);
  protected showOverlay = toSignal(
    merge(
      outputToObservable(this.dragAndDrop.dragEnter).pipe(map(() => true)),
      outputToObservable(this.dragAndDrop.dragLeave).pipe(map(() => false)),
      outputToObservable(this.dragAndDrop.dropedFiles).pipe(map(() => false)),
    ).pipe(distinctUntilChanged()),
  );
}
