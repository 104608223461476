@if (label()) {
  <label>{{ label() }}</label>
} @else {
  <label i18n="@@COMMENT">Comment</label>
}

<div
  class="design-comment__content"
  [class.design-comment__content--focused]="hasFocus()"
  [class.design-comment__content--is-editable]="isEditable()"
  [class.design-comment__content--disabled]="disabled()"
>
  <div #editableElement class="design-comment__textarea">
    <br />
  </div>

  <div class="design-comment__footer">
    <div
      class="design-comment__attachments"
      [class.design-comment__attachments--editable]="isEditable()"
    >
      @if (isEditable()) {
        @for (file of files(); track file) {
          <div class="design-comment__chip-wrapper">
            <design-chip
              [removable]="true"
              (eClose)="removeSelectedFile(file)"
              [label]="file.name"
            >
            </design-chip>
          </div>
        }
      } @else {
        @for (attachment of attachments(); track attachment) {
          <div class="design-comment__chip-wrapper">
            <design-chip [removable]="false">
              <i
                designIconPrefix
                [designIcon]="
                  attachment.filename
                    | getAttachmentIcon: attachment.documentType
                "
                designSize="16px"
                class="design-comment__document-icon"
              ></i>
              <span style="vertical-align: middle">{{
                attachment.filename
              }}</span>
            </design-chip>
          </div>
        }
      }
    </div>

    @if (isEditable()) {
      <div class="design-comment__buttons">
        <button
          type="button"
          design-icon-button
          class="design-comment__button--attachment"
          (click)="fileElement.click()"
        >
          <i designIcon="attach_file" designSize="1.3rem" class="pointer"></i>
        </button>

        @if (showSendButton()) {
          <button
            type="button"
            design-icon-button
            designButtonColor="accent"
            class="design-comment__button--send"
            (click)="sendComment()"
          >
            <i designIcon="send" designSize="1.3rem" class="pointer"></i>
          </button>
        }
      </div>
    }
  </div>
</div>

<input
  #fileElement
  (change)="addFile()"
  type="file"
  style="display: none"
  multiple
/>
