import { BooleanInput, coerceBooleanProperty } from '@angular/cdk/coercion';
import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  HostBinding,
  Input,
  Optional,
  Output,
  Self,
  TemplateRef,
  ViewChild,
  ViewEncapsulation
} from '@angular/core';
import { ControlValueAccessor, NgControl, Validators } from '@angular/forms';
import { FormFieldControl } from '../../../ui-form-field/directives/form-field-control.directive';

let nextUniqueId = 0;
const INPUT_SUPPORTED_TYPES = ['text', 'number', 'password', 'date'];

@Component({
    selector: 'ui-input',
    templateUrl: './input.component.html',
    styleUrls: ['./input.component.scss'],
    providers: [{ provide: FormFieldControl, useExisting: InputComponent }],
    encapsulation: ViewEncapsulation.None,
    standalone: false
})
export class InputComponent
  implements FormFieldControl<any>, ControlValueAccessor, AfterViewInit
{
  @HostBinding('class.ui-input') class = true;
  @HostBinding('class.disable') setDisabled = false;
  @HostBinding('class.disable') get isDisabled(): boolean {
    return this._disabled;
  }
  @HostBinding('class.notEditable') get isNotEditable(): boolean {
    return this._notEditable;
  }
  /** Automatic uuid for control */
  protected _uid = `ui-input-${nextUniqueId++}`;

  @Input() prefix?: TemplateRef<any>;

  @Input() suffix?: TemplateRef<any>;

  /** Information about focus */
  focused = false;

  /** Value section start*/
  @Input()
  get value(): string | number {
    return this._value;
  }

  set value(newValue: string | number) {
    this._value = newValue;
  }

  private _value: string | number = '';

  /** value section end */

  /** Required section start*/

  // get required(): boolean {
  //   return this._required;
  // }
  // @Input()
  // set required(value: boolean) {
  //   this._required = coerceBooleanProperty(value);
  // }

  // protected _required = false;
  /** Required section end*/

  /** Readonly section start */

  get readonly(): boolean {
    return this._readonly;
  }

  @Input()
  set readonly(value: boolean) {
    this._readonly = coerceBooleanProperty(value);
  }
  private _readonly = false;
  /** Readonly section end */

  /** Type element section start */

  get type(): string {
    return this._type;
  }

  @Input()
  set type(value: string) {
    this._type = value || 'text';
    this._validateType();
    (this._elementRef.nativeElement as HTMLInputElement).type = this._type;
  }
  protected _type = 'text';
  /** Type element section end */

  get deleteButton(): BooleanInput {
    return this._deleteButton;
  }

  @Input()
  set deleteButton(value: BooleanInput) {
    this._deleteButton = coerceBooleanProperty(value);
  }
  private _deleteButton = false;

  /** Disabled element section start */
  get disabled(): boolean {
    return this._disabled;
  }
  @Input()
  set disabled(state: boolean) {
    this._disabled = coerceBooleanProperty(state);
    if (this.focused) {
      this.focused = false;
    }
    if (state) {
      this.setDisabled = true;
    }
  }
  protected _disabled = false;
  /** Disabled element section end */

  @Input()
  set notEditable(state: BooleanInput) {
    this._notEditable = coerceBooleanProperty(state);

    this.readonly = this._notEditable;
  }
  get notEditable(): boolean {
    return this._notEditable;
  }
  protected _notEditable = false;
  /**
   * Implemented as part of MatFormFieldControl.
   * @docs-private
   */

  get id(): string {
    return this._id;
  }
  @Input()
  set id(value: string) {
    this._id = value || this._uid;
  }
  protected _id: string = this._uid;

  /**
   * Input placeholder
   */
  @Input() set placeholder(value: string) {
    this._placeholder = value;
  }
  get placeholder() {
    return this._placeholder ?? '';
  }
  protected _placeholder?: string;

  @Output() readonly valueChange: EventEmitter<string> =
    new EventEmitter<string>();

  @ViewChild('input', { static: false }) private _input!: ElementRef;

  get inputElement(): ElementRef {
    return this._input;
  }

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  onTouched = () => {};

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  onChange: (value: any) => void = () => {};

  // constructor(private readonly _elementRef: ElementRef) {}
  /**
   * Implement as part of ControlValueAccessor - don't delete
   */

  ngAfterViewInit(): void {
    const element = this._input.nativeElement;
    if (this.value) {
      element.value = this.value;
    }
    if (this.placeholder) {
      element.setAttribute('placeholder', this.placeholder);
    }
  }

  /**
   * Sets control value. Part of the ControlValueAccessor interface
   * required to integrate with Angular's core forms API.
   * @param value
   */
  writeValue(value: string | number): void {
    this.value = value;
  }

  /**
   * Part of the ControlValueAccessor interface required to integrate with Angular's core forms API.
   * @param fn
   */
  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  /**
   * Part of the ControlValueAccessor interface required to integrate with Angular's core forms API.
   * @param fn
   */
  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  /** Make sure the input is a supported type. */
  protected _validateType() {
    if (INPUT_SUPPORTED_TYPES.indexOf(this._type) === -1) {
      throw new Error(`Unsupported type ${this._type}`);
    }
  }

  /** Callback for the cases where the focused state of the input changes. */
  focusChanged(isFocused: boolean) {
    if (isFocused !== this.focused) {
      this.focused = isFocused;
    }
  }

  emitChangeEvent(event: Event) {
    this.onTouched();
    this.value = (event.target as HTMLInputElement).value;
    this.onChange(this.value);
    this.valueChange.emit(this.value);
  }

  cleanValue() {
    this.value = '';
    this.onChange(this.value);
    this.valueChange.emit(this.value);
  }

  // _onInput() {
  //   // This is a noop function and is used to let Angular know whenever the value changes.
  //   // Angular will run a new change detection each time the `input` event has been dispatched.
  //   // It's necessary that Angular recognizes the value change, because when floatingLabel
  //   // is set to false and Angular forms aren't used, the placeholder won't recognize the
  //   // value changes and will not disappear.
  //   // Listening to the input event wouldn't be necessary when the input is using the
  //   // FormsModule or ReactiveFormsModule, because Angular forms also listens to input events.
  // }

  ////////////////////////////////////////////////////////////////////

  @Input()
  get required(): boolean {
    return (
      this._required ??
      this.ngControl?.control?.hasValidator(Validators.required) ??
      false
    );
  }
  set required(value: BooleanInput) {
    this._required = coerceBooleanProperty(value);
  }
  // eslint-disable-next-line @typescript-eslint/member-ordering
  _required: boolean | undefined;

  constructor(
    @Optional() @Self() private ngControl: NgControl,
    private readonly _elementRef: ElementRef
  ) {
    if (this.ngControl != null) {
      ngControl.valueAccessor = this;
    }
  }
}
