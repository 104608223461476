import { inject, InjectionToken, Provider } from '@angular/core';
import { Params } from '@angular/router';
import { StagesRootService } from '@simlab/feature/stages';
import { RouterFacadeService } from '@simlab/util-shared';
import { firstValueFrom, tap } from 'rxjs';
import { TSidenavContent } from '../src/lib/stages-root/stages-root.component';

export const closeSideNavOfForbiddenInstance = new InjectionToken<
  ReturnType<typeof closeSideNavOfForbiddenInstanceFn>
>('Close sidenav of forbidden instance');

export const closeSideNavOfForbiddenInstanceFn = () => {
  const routerFacade = inject(RouterFacadeService);
  const stagesRootService = inject(StagesRootService);

  return (currentAnnotation: TSidenavContent[] | TSidenavContent) => {
    firstValueFrom(
      routerFacade.getRouteNestedParams$.pipe(
        tap((params) => {
          const sideNav = params.queryParams['sidenavContent'] as
            | TSidenavContent
            | undefined;
          const isActiveCurrentAnnotation = Array.isArray(currentAnnotation)
            ? !!sideNav && currentAnnotation.includes(sideNav)
            : sideNav === currentAnnotation;

          if (!isActiveCurrentAnnotation) {
            const queryParams: Params = {
              sidenavContent: null,
              noteId: null,
              punchItemId: null
            };
            routerFacade.setQueryParams(undefined, queryParams, 'merge');
            stagesRootService.rightPanelState = 'close';
          }
        })
      )
    );
  };
};

export const closeSideNavOfForbiddenInstanceProvider: Provider = {
  provide: closeSideNavOfForbiddenInstance,
  deps: [RouterFacadeService, StagesRootService],
  useFactory: () => closeSideNavOfForbiddenInstanceFn()
};
