/* eslint-disable @angular-eslint/no-input-rename */
/* eslint-disable @angular-eslint/no-inputs-metadata-property */
/* eslint-disable @angular-eslint/component-selector */
/* eslint-disable @angular-eslint/no-host-metadata-property */
/* eslint-disable @angular-eslint/component-class-suffix */
import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  ViewEncapsulation,
} from '@angular/core';
import { ButtonBase } from '../../../components/button-base.directive';

@Component({
    selector: 'button[design-mini-fab-button]',
    exportAs: 'designMiniFabButton',
    imports: [CommonModule],
    template: '<ng-content></ng-content>',
    styleUrls: ['./mini-fab-button.component.scss'],
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
    host: {
        class: 'design-base-button design-mini-fab-button',
        '[class.design-color--primary]': 'color === "primary"',
        '[class.design-color--secondary]': 'color === "secondary"',
        '[class.design-color--error]': 'color === "error"',
    },
    inputs: [
        'disabled',
        'disabled: designButtonDisabled',
        'color: designButtonColor',
    ]
})
export class DesignMiniFabButton extends ButtonBase {}
