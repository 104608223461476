import { ChangeDetectionStrategy, Component, input } from '@angular/core';
import {
  ILLUSTRATION_TYPE,
  IllustrationComponent,
} from '@simlab/design/illustration';

@Component({
    template: `
    <i
      design-illustration
      [name]="illustration()"
      defaultColor
      class="illustration"
      size="img"
    ></i>
    <h3 class="title">
      <ng-content select="[titleSlot]"></ng-content>
    </h3>
    <p class="description">
      <ng-content select="[descriptionSlot]"></ng-content>
    </p>

    <ng-content></ng-content>
  `,
    selector: 'design-empty-placeholder',
    imports: [IllustrationComponent],
    styleUrl: './empty-placeholder.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class EmptyPlaceholderComponent {
  readonly illustration = input.required<ILLUSTRATION_TYPE>();
}
