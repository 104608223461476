import {
  AfterViewInit,
  booleanAttribute,
  Component,
  computed,
  DestroyRef,
  ElementRef,
  inject,
  InjectionToken,
  input,
  model,
  OnChanges,
  output,
  signal,
  SimpleChanges,
  viewChild,
  ViewEncapsulation,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import {
  ControlValueAccessor,
  FormsModule,
  NG_VALUE_ACCESSOR,
} from '@angular/forms';
import { AttachmentIconGetterPipe } from '@simlab/design/attachment-icon';
import { DesignButtonModule, DesignIconButton } from '@simlab/design/button';
import { DesignChip } from '@simlab/design/chip';
import { DesignIcon } from '@simlab/design/icon';
import { derivedAsync } from 'ngxtension/derived-async';
import { debounceTime, fromEvent, map, merge } from 'rxjs';
import { CommentOptions } from '../../models/comment-options.model';
import {
  CommentAttachment,
  CommentWithFiles,
} from '../../models/comment.models';

export const CommentToken = new InjectionToken<CommentOptions>('COMMENT');

@Component({
  selector: 'design-comment',
  standalone: true,
  imports: [
    FormsModule,
    DesignIcon,
    DesignIconButton,
    DesignButtonModule,
    DesignChip,
    AttachmentIconGetterPipe,
  ],
  templateUrl: './comment.component.html',
  styleUrl: './comment.component.scss',
  encapsulation: ViewEncapsulation.None,
  host: {
    class: 'design-comment',
  },
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: DesignCommentComponent,
    },
    { provide: CommentToken, useExisting: DesignCommentComponent },
  ],
})
export class DesignCommentComponent
  implements ControlValueAccessor, OnChanges, AfterViewInit, CommentOptions
{
  private readonly _destroyRef = inject(DestroyRef);
  private readonly _isContentEditable = computed(() => {
    return this.isEditable() && !this.disabled();
  });
  private readonly _inputDebounceTime = 100;

  readonly comment = model<string>('');
  readonly attachments = input<CommentAttachment[]>([]);
  readonly isEditable = input(true, { transform: booleanAttribute });
  readonly disabled = model<boolean>(false);
  readonly label = input<string>();
  readonly showSendButton = input(false, { transform: booleanAttribute });
  readonly commentAdded = output<CommentWithFiles>();
  protected readonly fileElement =
    viewChild.required<ElementRef<HTMLInputElement>>('fileElement');
  protected readonly editableElement =
    viewChild.required<ElementRef<HTMLSpanElement>>('editableElement');
  protected readonly files = signal<File[]>([]);

  protected readonly hasFocus = derivedAsync(() => {
    return merge(
      fromEvent(this.editableElement().nativeElement, 'focus').pipe(
        map(() => true),
      ),
      fromEvent(this.editableElement().nativeElement, 'blur').pipe(
        map(() => false),
      ),
    );
  });

  registerOnChange(fn: any): void {
    this._onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this._onTouched = fn;
  }

  protected _onChange: (value: CommentWithFiles) => void = () => {};
  protected _onTouched = () => {};

  ngOnChanges(changes: SimpleChanges) {
    if (Object.hasOwn(changes, 'comment')) {
      this._setInnerTextValue(this.comment());
    }

    if (
      Object.hasOwn(changes, 'isEditable') ||
      Object.hasOwn(changes, 'disabled')
    ) {
      this._setContentEditable(this._isContentEditable());
    }
  }

  ngAfterViewInit() {
    fromEvent<Event>(this.editableElement().nativeElement, 'input')
      .pipe(
        debounceTime(this._inputDebounceTime),
        takeUntilDestroyed(this._destroyRef),
      )
      .subscribe((e) => {
        const target = e.target as HTMLInputElement;

        this.comment.set(target.innerText);
        this._emitValues();
      });
  }

  clearValues() {
    this.comment.set('');
    this._setInnerTextValue('');
    this.files.set([]);
  }

  protected sendComment() {
    this.commentAdded.emit({
      comment: this.comment(),
      files: this.files(),
    });
  }

  writeValue(data: CommentWithFiles) {
    if (!data) return;

    if (Object.hasOwn(data, 'comment')) {
      this.comment.set(data.comment);
      this._setInnerTextValue(data.comment);
    }

    if (Object.hasOwn(data, 'files')) {
      this.files.set(data.files ?? []);
    }
  }

  protected addFile() {
    const filesToAdd = Array.from(this.fileElement().nativeElement.files || []);
    this.files.update((files) => [...files, ...filesToAdd]);
    this._emitValues();
  }

  protected removeSelectedFile(file: File) {
    this.files.update((files) => files.filter((f) => f !== file));
    this._emitValues();
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled.set(isDisabled);
    this._setContentEditable(this._isContentEditable());
  }

  private _emitValues() {
    const commentWithFiles: CommentWithFiles = {
      comment: this.comment(),
      files: this.files(),
    };

    this._onChange(commentWithFiles);
  }

  private _setInnerTextValue(value: string) {
    this.editableElement().nativeElement.innerText = value;
  }

  private _setContentEditable(editable: boolean) {
    this.editableElement().nativeElement.contentEditable = editable
      ? 'true'
      : 'false';
  }
}
