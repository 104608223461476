import {
  ChangeDetectionStrategy,
  Component,
  ViewEncapsulation,
} from '@angular/core';

@Component({
    selector: 'ui-matterport-loading',
    templateUrl: './ui-matterport-loading.component.html',
    styleUrls: ['./ui-matterport-loading.component.scss'],
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
    // eslint-disable-next-line @angular-eslint/no-host-metadata-property
    host: {
        class: 'ui-matterport-loading',
    },
    standalone: false
})
export class UiMatterportLoadingComponent {}
