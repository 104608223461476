import { computed } from '@angular/core';
import {
  patchState,
  signalStore,
  withComputed,
  withMethods,
  withState
} from '@ngrx/signals';
import { NoteExtendStatus, NoteType } from 'common/annotation-note/models';

export type NoteFiltersState = {
  filter: {
    noteTypes: NoteType[];
    noteStatuses: NoteExtendStatus<NoteType>[];
  };
};

const initialState: NoteFiltersState = {
  filter: {
    noteTypes: [],
    noteStatuses: []
  }
};

const areActiveNoteFilters = (data: Partial<NoteFiltersState['filter']>) => {
  const { noteTypes, noteStatuses } = data;

  return !!noteTypes?.length || !!noteStatuses?.length;
};

function toggleValue<T>(noteTypes: T[], type: T) {
  return noteTypes.includes(type)
    ? noteTypes.filter((currentType) => currentType !== type)
    : [...noteTypes, type];
}

export const NoteFiltersStore = signalStore(
  withState(initialState),
  withMethods((store) => ({
    resetFilters(): void {
      patchState(store, () => ({
        ...initialState
      }));
    },
    setFilters(newFilter: Partial<NoteFiltersState['filter']>): void {
      patchState(store, ({ filter }) => ({
        filter: { ...filter, ...newFilter }
      }));
    },
    toggleNoteType(type: NoteType): void {
      patchState(store, (state) => ({
        filter: {
          ...state.filter,
          noteTypes: [...toggleValue(state.filter.noteTypes, type)]
        }
      }));
    },
    toggleNoteStatus(status: NoteExtendStatus<NoteType>): void {
      patchState(store, (state) => ({
        filter: {
          ...state.filter,
          noteStatuses: [...toggleValue(state.filter.noteStatuses, status)]
        }
      }));
    }
  })),
  withComputed(({ filter }) => ({
    filtersLength: computed(() => {
      return (
        filter().noteTypes.filter((type) => type !== 'Issue').length +
        filter().noteStatuses.length
      );
    }),
    areFiltersActive: computed(() => areActiveNoteFilters(filter()))
  }))
);
