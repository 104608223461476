import {
  CdkPortalOutlet,
  ComponentPortal,
  ComponentType,
  PortalModule
} from '@angular/cdk/portal';
import { CommonModule } from '@angular/common';
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  ComponentRef,
  EmbeddedViewRef,
  Injector,
  ViewChild,
  ViewEncapsulation,
  inject,
  signal
} from '@angular/core';
import {
  STAGES_USER_LANGUAGE,
  SupportedLanguage,
  UserPreferences
} from '@simlab/data-access';
import { UserPreferenceFacade } from '@simlab/data-store';
import { DesignFlatButton } from '@simlab/design/button';
import { DesignDialogWrapperModule } from '@simlab/design/dialog';
import { ToastService } from '@simlab/design/toast';

import { DIALOG_DATA, DialogRef } from '@angular/cdk/dialog';
import { UiButtonModule } from '@simlab/ui/button';
import { UiIconModule } from '@simlab/ui/icon';
import { Observable, firstValueFrom, of, startWith, switchMap } from 'rxjs';
import { AppearanceComponent } from './components/appearance/appearance.component';
import { DateTimeComponent } from './components/date-time/date-time.component';
import { ProcoreConnectionComponent } from './components/integrations/procore-connection/procore-connection.component';
import { LanguageComponent } from './components/language/language.component';
import { MatterportAccountComponent } from './components/matterport-account/matterport-account.component';
import {
  UnitsComponent,
  UserPreferencesToken
} from './components/units/units.component';

const PREFERENCE_UPDATED = $localize`:@@PREFERENCE_UPDATED:Preferences updated successfully`;

export type PersonalSettingCard =
  | LanguageComponent
  | MatterportAccountComponent
  | AppearanceComponent
  | DateTimeComponent
  | UnitsComponent
  | ProcoreConnectionComponent;

type MenuIds =
  | 'Langauge'
  | 'Apperance'
  | 'Units'
  | 'CalendarFormat'
  | 'Integrations';

type MenuItem = {
  [TKey in MenuIds]: {
    id: TKey;
    dataCy: string;
    label: string;
    instance: ComponentPortal<PersonalSettingCard>;
  };
}[MenuIds];

export interface PersonalSettingBase {
  userPreferences: UserPreferences;
  userPreferencesChange: Observable<UserPreferences>;
}
@Component({
  selector: 'simlab-personal-settings',
  templateUrl: './personal-settings.component.html',
  styleUrls: ['./personal-settings.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
  host: {
    class: 'simlab-personal-settings'
  },
  imports: [
    CommonModule,
    DesignDialogWrapperModule,
    UiButtonModule,
    UiIconModule,
    PortalModule,
    DesignFlatButton
  ]
})
export class PersonalSettingsComponent implements AfterViewInit {
  private readonly _modalRef = inject(DialogRef);
  private readonly _injector = inject(Injector);
  private readonly _toastService: ToastService = inject(ToastService);
  private readonly _userPreferenceFacade: UserPreferenceFacade =
    inject(UserPreferenceFacade);
  public userPreferences = inject<UserPreferences>(DIALOG_DATA);

  protected readonly selectedMenuItem = signal<MenuItem['id']>('Langauge');
  protected readonly menuList: MenuItem[] = [
    {
      id: 'Langauge',
      dataCy: 'personal-settings-language',
      label: $localize`:@@LANGUAGE:Language`,
      instance: this.languagePortal
    },
    {
      id: 'Apperance',
      dataCy: 'personal-settings-appearance',
      label: $localize`:@@APPEARANCE:Appearance`,
      instance: this.appearancePortal
    },
    {
      id: 'Units',
      dataCy: 'personal-settings-units',
      label: $localize`:@@UNITS:Units`,
      instance: this.unitsPortal
    },
    {
      id: 'CalendarFormat',
      dataCy: 'personal-settings-calendar-format',
      label: $localize`:@@CALENDAR_FORMAT:Calendar Format`,
      instance: this.dateFormatPortal
    },
    {
      id: 'Integrations',
      dataCy: 'personal-settings-integrations',
      label: $localize`:@@INTEGRATIONS:Integrations`,
      instance: this.procoreConnectionPortal
    }
  ] as const;

  @ViewChild('outlet') outlet!: CdkPortalOutlet;
  readonly matterportAccountPortal: ComponentPortal<MatterportAccountComponent> =
    new ComponentPortal(MatterportAccountComponent);

  selectedPortal: ComponentPortal<PersonalSettingCard> = this.languagePortal;

  ngAfterViewInit(): void {
    this.outlet?.attached
      .pipe(
        startWith(this.outlet.attachedRef),
        switchMap(
          (
            component:
              | ComponentRef<PersonalSettingCard>
              | EmbeddedViewRef<PersonalSettingCard>
              | null
          ) => {
            if (
              !component ||
              !(component instanceof ComponentRef) ||
              !component.instance.userPreferencesChange
            )
              return of(undefined);
            return component.instance
              .userPreferencesChange as Observable<UserPreferences>;
          }
        )
      )
      .subscribe((userPreferences: UserPreferences | undefined) => {
        if (!userPreferences) return;
        firstValueFrom(
          this._userPreferenceFacade.userPreferencesUpdatedSuccessfully$
        ).then((error) => {
          if (!error) {
            if (userPreferences.language !== this.userPreferences.language) {
              this._changeLanguage(userPreferences.language);
            }
            this._toastService.open(PREFERENCE_UPDATED, 'Success');
            this.userPreferences = userPreferences;
          } else {
            this._toastService.open(`${error.errorMessage}`, 'Error');
          }
        });
        this._userPreferenceFacade.updateUserPreferences(userPreferences);
        //TODO: (olek) change userPreferences as readed from store
      });
  }
  private _changeLanguage(language: SupportedLanguage) {
    localStorage.setItem(STAGES_USER_LANGUAGE, language);

    location.href =
      location.origin +
      location.pathname
        .split('/')
        .map((element, index) => {
          if (index === 1) {
            return language;
          }
          return element;
        })
        .join('/');
  }

  protected selectView(item: MenuItem) {
    this.selectedPortal = item.instance;
    this.selectedMenuItem.set(item.id);
  }

  cancel(): void {
    this._modalRef.close({ state: false });
  }

  get unitsPortal() {
    return this._getInjector(UnitsComponent);
  }

  get dateFormatPortal() {
    return this._getInjector(DateTimeComponent);
  }

  get procoreConnectionPortal() {
    return this._getInjector(ProcoreConnectionComponent);
  }

  get appearancePortal() {
    return this._getInjector(AppearanceComponent);
  }

  get languagePortal() {
    return this._getInjector(LanguageComponent);
  }

  private _getInjector<PersonalSettingsPage>(
    comp: ComponentType<PersonalSettingsPage>
  ) {
    const injector = Injector.create({
      ...this._injector,
      providers: [
        {
          provide: UserPreferencesToken,
          useValue: this.userPreferences
        }
      ]
    });
    return new ComponentPortal(comp, null, injector);
  }
}
