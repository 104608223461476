import { CommonModule } from '@angular/common';
import {
  Component,
  ElementRef,
  Input,
  Renderer2,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { UiHelperModule } from '../../ui-helper/ui-helper.module';

@Component({
    selector: 'ui-progress-spinner',
    exportAs: 'progressSpinner',
    templateUrl: './ui-progress-spinner.component.html',
    styleUrls: ['./ui-progress-spinner.component.scss'],
    encapsulation: ViewEncapsulation.None,
    imports: [CommonModule, UiHelperModule],
    // eslint-disable-next-line @angular-eslint/no-host-metadata-property
    host: {
        class: 'ui-progress-spinner',
    }
})
export class UiProgressSpinnerComponent {
  @ViewChild('spinner') set spinner(value: ElementRef<HTMLDivElement>) {
    this.renderer.setStyle(value.nativeElement, 'display', 'block');
    this.renderer.setStyle(value.nativeElement, 'height', `${this.diameter}px`);
    this.renderer.setStyle(value.nativeElement, 'width', `${this.diameter}px`);
    this.renderer.setStyle(
      value.nativeElement,
      'border-width',
      `${this.strokeWidth}px`
    );
  }

  private _diameter = 100;

  @Input()
  set diameter(value: number) {
    this._diameter = value;
  }

  get diameter(): number {
    return this._diameter;
  }

  private _strokeWidth = 10;

  @Input()
  get strokeWidth(): number {
    return this._strokeWidth;
  }
  set strokeWidth(value: number) {
    this._strokeWidth = value;
  }

  constructor(private readonly renderer: Renderer2) {}
}
