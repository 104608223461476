import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { DesignDialogWrapperModule } from '@simlab/design/dialog';
import { UiButtonModule } from '@simlab/ui/button';
import { MODAL_DATA } from '@simlab/ui/modal';
import { UiProgressBarComponent } from '@simlab/ui/progress-bar';
import { BehaviorSubject } from 'rxjs';
export type Progression = {
  current: number;
  total: number;
};
@Component({
    imports: [
        DesignDialogWrapperModule,
        UiButtonModule,
        UiProgressBarComponent,
        CommonModule
    ],
    templateUrl: './tag-importer-progress-modal.component.html',
    styleUrls: ['./tag-importer-progress-modal.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class TagImporterProgressModalComponent {
  constructor(
    @Inject(MODAL_DATA)
    readonly progression: BehaviorSubject<Progression>
  ) {}
}
