import { coerceBooleanProperty } from '@angular/cdk/coercion';
import {
  ChangeDetectionStrategy,
  Component,
  ContentChild,
  HostBinding,
  Input,
  OnDestroy,
  ViewEncapsulation
} from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { HintComponent } from '../../../ui-hint/components/hint/hint.component';
import { FormFieldControl } from '../../directives/form-field-control.directive';

@Component({
    selector: 'ui-form-field',
    templateUrl: './form-field.component.html',
    styleUrls: ['./form-field.component.scss'],
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class FormFieldComponent implements OnDestroy {
  @ContentChild(FormFieldControl)
  _controlNonStatic!: FormFieldControl<any>;
  @ContentChild(FormFieldControl, { static: true })
  _controlStatic!: FormFieldControl<any>;

  get _control() {
    return (
      this._explicitFormFieldControl ||
      this._controlNonStatic ||
      this._controlStatic
    );
  }
  set _control(value) {
    this._explicitFormFieldControl = value;
  }
  private _explicitFormFieldControl!: FormFieldControl<any>;

  @HostBinding('class.form-field') class = true;
  @ContentChild(HintComponent) hint!: HintComponent;
  private _destroy$: Subject<void> = new Subject<void>();

  get hasError$(): Observable<boolean> | undefined {
    return this.hint?.hasError$;
  }

  /** Whether the required marker should be hidden. */
  @Input()
  get hideRequiredMarker(): boolean {
    return this._control ? !this._control.required : this._hideRequiredMarker;
  }
  set hideRequiredMarker(value: boolean) {
    this._hideRequiredMarker = coerceBooleanProperty(value);
  }
  private _hideRequiredMarker = true;

  @Input()
  get hideHint(): boolean {
    return this._hideHint;
  }
  set hideHint(value: boolean) {
    this._hideHint = coerceBooleanProperty(value);
  }
  private _hideHint = false;

  @Input()
  get hideLabel(): boolean {
    return this._hideLabel;
  }
  set hideLabel(value: boolean) {
    this._hideLabel = coerceBooleanProperty(value);
  }
  private _hideLabel = false;

  ngOnDestroy(): void {
    this._destroy$.next();
    this._destroy$.complete();
  }
}
