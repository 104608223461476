export const DocumentTypeEnum = {
  Unknown: 0,
  Image: 1,
  Video: 2,
  Audio: 3,
  Doc: 4,
  Model3D: 5,
  Blueprint: 6,
} as const;

export type DocumentTypeEnumValues =
  (typeof DocumentTypeEnum)[keyof typeof DocumentTypeEnum];
