import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  computed,
  inject,
  TemplateRef,
  viewChild,
  ViewEncapsulation
} from '@angular/core';
import { DesignIconButton } from '@simlab/design/button';
import { EmptyPlaceholderComponent } from '@simlab/design/empty-placeholder';
import { DesignIcon } from '@simlab/design/icon';
import {
  documentsActionsToken,
  documentsApiToken
} from '@simlab/documents/data-access';
import {
  DocumentsPlatformKeyEventsService,
  DocumentsStateService,
  DocumentsStore
} from '@simlab/documents/services';
import {
  DocumentActionPanelComponent,
  DocumentsRootComponent,
  DocumentsSelectedItemActionPanelComponent
} from '@simlab/documents/ui';
import { TEventElementItem } from '@simlab/event-queue';
import { StagesDocumentsApiService } from '../../../pages/documents-page/stages-documents/data-access/stages-documents-api.service';
import { StagesDocumentsFiltersComponent } from '../../stages-documents-filters/stages-documents-filters.component';
import { StagesDocumentsInModalActionsService } from './data-access/stages-documents-in-modal-actions/stages-documents-in-modal-actions.service';
import { StagesDocumentsInModalActionsBase } from './data-access/stages-documents-in-modal-menu-actions.token';
import { StagesDocumentsInModalStateService } from './stages-documents-in-modal-state.service';

@Component({
    selector: 'feature-projects-stages-documents-in-modal',
    imports: [
        DocumentsRootComponent,
        DocumentActionPanelComponent,
        DocumentsSelectedItemActionPanelComponent,
        DesignIconButton,
        DesignIcon,
        EmptyPlaceholderComponent,
        StagesDocumentsFiltersComponent
    ],
    templateUrl: './stages-documents-in-modal.component.html',
    styleUrl: './stages-documents-in-modal.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush,
    encapsulation: ViewEncapsulation.None,
    providers: [
        { provide: documentsApiToken, useClass: StagesDocumentsApiService },
        DocumentsStore,
        DocumentsStateService,
        StagesDocumentsInModalStateService,
        DocumentsPlatformKeyEventsService,
        {
            provide: documentsActionsToken,
            useClass: StagesDocumentsInModalActionsService
        }
    ],
    host: {
        class: 'feature-projects-stages-documents-in-modal'
    }
})
export class StagesDocumentsInModalComponent implements AfterViewInit {
  private readonly _actions = inject<StagesDocumentsInModalActionsBase>(
    documentsActionsToken
  );

  private readonly _rootComponent = viewChild.required(DocumentsRootComponent);

  protected readonly downloadTask = viewChild('downloadTask', {
    read: TemplateRef<{ context: TEventElementItem }>
  });
  protected readonly filters = inject(DocumentsStore).filters;

  readonly selectedItems = computed(() =>
    this._rootComponent().selectedItems()
  );

  ngAfterViewInit(): void {
    this._actions.templateToDownload = this.downloadTask();
  }

  resetSelectedItems(): void {
    this._rootComponent().clearSelection();
  }

  protected downloadDocuments(): void {
    const data = this.selectedItems().map((item) => item.data().data);
    this._actions.download(data);
  }
}
