import {
  AfterContentInit,
  ChangeDetectorRef,
  ContentChildren,
  Directive,
  ElementRef,
  HostBinding,
  Input,
  OnDestroy,
  QueryList, inject
} from '@angular/core';
import {
  DesignPrefix,
  DesignSuffix,
  designPrefixToken,
  designSuffixToken,
} from '@simlab/design/common';
import {
  CanColor,
  CanDisable,
  CanLoad,
  CanSelect,
  mixinColor,
  mixinDisabled,
  mixinLoaded,
  mixinSelected,
} from '@simlab/design/internal';
import { Subject, merge, takeUntil, tap } from 'rxjs';

let _uniqueIdCounter = 0;

const _ButtonBase = mixinDisabled(
  mixinLoaded(
    mixinSelected(
      mixinColor(
        class {
         public _elementRef = inject(ElementRef);
        }
      )
    )
  )
);

@Directive({
    host: {
        '[id]': 'id',
    },
    standalone: false
})
export abstract class ButtonBase
  extends _ButtonBase
  implements
  CanDisable,
  CanLoad,
  CanColor,
  CanSelect,
  AfterContentInit,
  OnDestroy {
  private readonly _changeDetectorRef = inject(ChangeDetectorRef);
  protected readonly _destroyed: Subject<void> = new Subject<void>();

  /** The unique ID of the option. */
  @Input() id = `sim-button-${_uniqueIdCounter++}`;

  @Input() shape: 'circle' | 'square' = 'circle';

  @ContentChildren(designPrefixToken, { descendants: true })
  protected _prefixChildren!: QueryList<DesignPrefix>;

  @ContentChildren(designSuffixToken, { descendants: true })
  protected _suffixChildren!: QueryList<DesignSuffix>;

  protected _hasIconPrefix = false;
  protected _hasIconSuffix = false;
  loading = false;

  private _iTagElement?: HTMLElement;

  ngAfterContentInit(): void {
    this._iTagElement = this.getHostElement().getElementsByTagName('i')[0];
    this._initializePrefixAndSuffix();
  }

  ngOnDestroy(): void {
    this._destroyed.next();
    this._destroyed.complete();
  }

  loadingBegin(): void {
    this.loading = true;
    if (this._iTagElement) {
      this._iTagElement.style.display = 'none';
    }
    this._changeDetectorRef.markForCheck();
  }

  private getHostElement(): HTMLElement {
    return this._elementRef.nativeElement;
  }

  loadingEnd(): void {
    this.loading = false;
    if (this._iTagElement) {
      this._iTagElement.style.display = 'initial';
    }
    this._changeDetectorRef.markForCheck();
  }

  @HostBinding('class.design-icon-button--square')
  get uiButtonSquare(): boolean {
    return this.shape === 'square'
  }

  @HostBinding('class.design-button-disabled')
  get uiButtonDisabled(): boolean {
    return (this.disabled ?? false) || (this.loading ?? false);
  }

  private _initializePrefixAndSuffix(): void {
    this._checkPrefixAndSuffixTypes();

    // Mark the form field as dirty whenever the prefix or suffix children change. This
    // is necessary because we conditionally display the prefix/suffix containers based
    // on whether there is projected content.
    merge(this._prefixChildren.changes, this._suffixChildren.changes)
      .pipe(
        takeUntil(this._destroyed),
        tap(() => {
          this._checkPrefixAndSuffixTypes();
          this._changeDetectorRef.markForCheck();
        })
      )
      .subscribe();
  }

  private _checkPrefixAndSuffixTypes() {
    this._hasIconPrefix = !!this._prefixChildren.find((p) => !p.isText);
    this._hasIconSuffix = !!this._suffixChildren.find((s) => !s.isText);
  }
}
