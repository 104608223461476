import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component } from '@angular/core';
import {
  AREA_CONVERSION,
  CalculateAreaMeasurementSumPipe,
  CalculateMeasurementSumPipe,
  UnitsAreaPreferredByUserPipe,
  UnitsPreferredByUserPipe
} from '@simlab/feature-measurement/pipes';
import {
  AreaFacade,
  MeasurementFacade
} from '@simlab/feature-measurement/state';
import { TAreaMesh } from '@simlab/simlab-facility-management/sub-features/measurement';
import { SIDENAV_CONTENT } from '@simlab/ui/sidenav';
import { displayAreaMeasurementValue } from '../../../functions/displayValue';
import { areaParamsToSort } from '../../../functions/params-to-sort';
import {
  DISPLAY_VALUE,
  MEASUREMENT_CONVERSION,
  PARAMS_TO_SORT,
  SORT_OPTION,
  TYPE_MEASUREMENT
} from '../../../tokens/token';
import { MeasurementBaseComponent } from '../measurement-base/measurement-base.component';
import { MeasurementSidenavContent } from '../measurement-sidenav-content';
import { SortOption } from '../search-and-sort-filter/search-and-sort-filter.component';

const AREA_SORT_OPTION: SortOption[] = [
  {
    direction: 'asc',
    display: $localize`:@@NAME_SORT_A_Z:Name A-Z`,
    propertyName: 'title'
  },
  {
    direction: 'desc',
    display: $localize`:@@NAME_SORT_Z_A:Name Z-A`,
    propertyName: 'title'
  },
  {
    direction: 'asc',
    display: $localize`:@@SIZE_SORT_ASC:Size Asc`,
    propertyName: 'surfaceSize'
  },
  {
    direction: 'desc',
    display: $localize`:@@SIZE_SORT_DESC:Size Desc`,
    propertyName: 'surfaceSize'
  }
];

@Component({
    selector: 'feature-measurement-area',
    imports: [CommonModule, MeasurementBaseComponent],
    templateUrl: './area.component.html',
    styleUrl: './area.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [
        { provide: MeasurementFacade, useClass: AreaFacade },
        { provide: SORT_OPTION, useValue: AREA_SORT_OPTION },
        {
            provide: CalculateMeasurementSumPipe,
            useClass: CalculateAreaMeasurementSumPipe
        },
        {
            provide: UnitsPreferredByUserPipe,
            useClass: UnitsAreaPreferredByUserPipe
        },
        { provide: MEASUREMENT_CONVERSION, useValue: AREA_CONVERSION },
        {
            provide: TYPE_MEASUREMENT,
            useValue: 'measurement-area'
        },
        {
            provide: SIDENAV_CONTENT,
            useExisting: AreaComponent
        },
        {
            provide: DISPLAY_VALUE,
            useValue: displayAreaMeasurementValue
        },
        {
            provide: PARAMS_TO_SORT,
            useValue: areaParamsToSort
        }
    ]
})
export class AreaComponent extends MeasurementSidenavContent<TAreaMesh> {}
