import { NgClass } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  input,
  output
} from '@angular/core';
import { DesignIcon } from '@simlab/design/icon';
import { AnnotationPanelMenuItem } from 'common/annotation-panel/models';

@Component({
  selector: 'lib-annotation-panel-menu',
  standalone: true,
  imports: [DesignIcon, NgClass],
  templateUrl: './annotation-panel-menu.component.html',
  styleUrl: './annotation-panel-menu.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AnnotationPanelMenuComponent {
  readonly panelMenuItems = input<AnnotationPanelMenuItem[]>([]);
  readonly selectedItemKey = input<string>();
  readonly onItemSelect = output<AnnotationPanelMenuItem>();
}
