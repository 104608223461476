import { CommonModule, DOCUMENT } from '@angular/common';
import {
  Component,
  ElementRef,
  Inject,
  Input,
  OnDestroy,
  ViewChild,
  ViewContainerRef,
  ViewEncapsulation,
  computed,
  inject,
  input
} from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import {
  ACCEPTED_AUDIO,
  ACCEPTED_DOCUMENT,
  ACCEPTED_PHOTO,
  ACCEPTED_VIDEO,
  MediaType,
  Note
} from '@simlab/data-access';
// eslint-disable-next-line @nx/enforce-module-boundaries
import { Dialog } from '@angular/cdk/dialog';
import { NEW_NOTE_LOCALLY_ID, NotesFacade } from '@simlab/data-store';
import { DesignStrokedButton } from '@simlab/design/button';
import { DesignCommonModule } from '@simlab/design/common';
import { DesignIcon } from '@simlab/design/icon';
import { UiMenuPanelModule } from '@simlab/design/menu-panel';
import { ToastService } from '@simlab/design/toast';
import {
  DocumentsModalComponent,
  DocumentsModalComponentData,
  SelectedDocumentsForNotes
} from '@simlab/feature/projects';
import { ProcoreDownloadResourceGetterService } from '@simlab/procore/services';
import {
  ConfirmationModalRef,
  DialogResponse,
  ModalService
} from '@simlab/ui/modal';
import { UiProgressSpinnerModule } from '@simlab/ui/progress-spinner';
import { UiSelectModule } from '@simlab/ui/select';

import { MatterportNoteControlService } from '@simlab/annotation/data-access';
import { breakpointsObserver } from '@simlab/design/layout';
import { UiTabsModule } from '@simlab/design/tabs';
import { UiDividerModule } from '@simlab/ui/divider';
import {
  Observable,
  Subject,
  catchError,
  combineLatest,
  filter,
  firstValueFrom,
  forkJoin,
  map,
  mergeMap,
  of,
  switchMap,
  take,
  takeUntil,
  tap
} from 'rxjs';
import { MediaCounts } from '../../models/media-counts';
import { NoteMediaService } from '../../services/note-media.service';
import { PanelRightService } from '../../services/panel-right.service';
import { ProjectLimitsService } from '../../services/project-limits.service';
import { NoteMediaAudioComponent } from '../note-media-audio/note-media-audio.component';
import { NoteMediaDocumentComponent } from '../note-media-document/note-media-document.component';
import { NoteMediaPhotoComponent } from '../note-media-photo/note-media-photo.component';
import { NoteMediaVideoComponent } from '../note-media-video/note-media-video.component';
import {
  AUDIO_SCRIPT,
  AUDIO_SCRIPT_DIR,
  loadAudioScript
} from '../record-audio-dialog/load-audio-script';
import { RecordAudioDialogComponent } from '../record-audio-dialog/record-audio-dialog.component';

export interface AudioRecordingData {
  status: boolean;
  title: string;
  recording: Blob;
}

export interface AudioRecordingDialogResult {
  selectedNote: Note;
  modalData: DialogResponse<{ recording: Blob | null; title: string }>;
}

const formats: { format: string; mediaType: MediaType }[] = [
  ...ACCEPTED_VIDEO.map((format) => ({
    format,
    mediaType: <MediaType>'video'
  })),
  ...ACCEPTED_AUDIO.map((format) => ({
    format,
    mediaType: <MediaType>'audio'
  })),
  ...ACCEPTED_PHOTO.map((format) => ({
    format,
    mediaType: <MediaType>'photo'
  })),
  ...ACCEPTED_DOCUMENT.map((format) => ({
    format,
    mediaType: <MediaType>'document'
  }))
];

@Component({
  selector: 'feature-stages-note-media-container',
  templateUrl: './note-media-container.component.html',
  styleUrls: ['./note-media-container.component.scss'],
  encapsulation: ViewEncapsulation.None,
  host: {
    class: 'feature-stages-note-media-container'
  },
  imports: [
    CommonModule,
    UiTabsModule,
    UiMenuPanelModule,
    UiProgressSpinnerModule,
    DesignIcon,
    DesignStrokedButton,
    DesignCommonModule,
    UiSelectModule,
    UiDividerModule,
    NoteMediaVideoComponent,
    NoteMediaPhotoComponent,
    NoteMediaAudioComponent,
    NoteMediaDocumentComponent
  ],
  providers: [
    {
      provide: AUDIO_SCRIPT,
      deps: [DOCUMENT],
      useFactory: loadAudioScript
    },
    ProcoreDownloadResourceGetterService
  ]
})
export class NoteMediaContainerComponent implements OnDestroy {
  private readonly _dialog = inject(Dialog);
  private readonly _viewRef = inject(ViewContainerRef);
  private readonly _breakpoints = inject(breakpointsObserver);

  private readonly _destroySource = new Subject<void>();
  readonly changeNote$: Observable<boolean> =
    this.notesFacade.getNoteRightPanelLoaded$;
  @ViewChild('fileElement') fileElement!: ElementRef<HTMLInputElement>;
  @ViewChild('photoWrapper') photoWrapper!: ElementRef<HTMLDivElement>;

  protected isComputerDevice = computed(() => {
    const isTablet = this._breakpoints.tablet();
    const isPhone = this._breakpoints.phone();
    const lessThanMd = this._breakpoints.lessThanMd();

    return !isTablet && !isPhone && !lessThanMd;
  });

  protected readonly acceptedFileFormats = formats
    .map(({ format }) => format)
    .join(',');

  readonly noteMediaCounts$: Observable<MediaCounts> = this.notesFacade.counts$;
  open = false;

  get isMatterport(): boolean {
    return this.router.url.includes('matterport');
  }

  readonly projectId = input.required<string>();

  private _noteId!: string;

  @Input()
  disabled = true;

  readonly projectIsActive$: Observable<boolean> =
    this.projectLimitsService.projectIsActive$;

  readonly checkViewOnly$: Observable<boolean> =
    this.projectLimitsService.checkViewOnly$;

  constructor(
    @Inject(AUDIO_SCRIPT) readonly audioScript$: Observable<unknown>,
    private readonly noteMediaService: NoteMediaService,
    private readonly notesFacade: NotesFacade,
    private readonly modalService: ModalService,
    private readonly projectLimitsService: ProjectLimitsService,
    private readonly panelRightService: PanelRightService,
    private readonly toastService: ToastService,
    private readonly matterportNoteControlService: MatterportNoteControlService,
    private readonly router: Router,
    private readonly activatedRoute: ActivatedRoute // private readonly privilegesFacade: PrivilegesFacade
  ) {
    firstValueFrom(audioScript$);
    this.notesFacade.selectedNoteId$
      .pipe(
        tap((noteId: string) => {
          this._noteId = noteId;
          if (this.photoWrapper !== undefined) {
            this.changingImages = false;
            this.photoWrapper.nativeElement.style.display = 'none';
          }
        }),
        takeUntil(this._destroySource)
      )
      .subscribe();
  }
  changingImages = true;

  changingImagesUrl(value: boolean) {
    if (this.photoWrapper !== undefined) {
      if (value) {
        this.changingImages = value;
        this.photoWrapper.nativeElement.style.display = 'initial';
      }
    }
  }

  ngOnDestroy(): void {
    this._destroySource.next();
    this._destroySource.complete();
  }

  async uploadFile(input: HTMLInputElement): Promise<void> {
    let nodeId = this._noteId;
    if (this._noteId === NEW_NOTE_LOCALLY_ID) {
      this.notesFacade.saveLocalNote();
      nodeId = await firstValueFrom(
        this.notesFacade.noteAdded$.pipe(
          map((note: Note) => {
            return note.id;
          })
        )
      );
    }
    if (input?.files?.length) {
      for (let i = 0; i < input.files.length; i++) {
        const file = input.files[i];
        const extension = file.name.split('.')[1];
        const type = this.getFileType(file.type, extension);
        if (type === undefined) {
          this.toastService.open(
            $localize`:@@NOT_ACCEPTED_EXTENSION: Extension: .${extension} is not acceptable.`,
            'Warning'
          );
          return;
        }
        if (file.size >= 15 * 1024 * 1024 && type === 'photo') {
          this.toastService.open(
            $localize`:@@FILE_TO_LARGE: File is too large ${file.name}, Max size is 15MB`,
            'Warning'
          );
        } else {
          this.noteMediaService.uploadFile(file, type, nodeId);
        }
      }
      this._cleanInputs();
    }
    this.open = false;
  }

  private getFileType(rawFormat: string, extension: string | undefined) {
    const format = formats.find(({ format }) => {
      const _extension = extension?.startsWith('.')
        ? extension
        : `.${extension}`;
      return format === rawFormat || format === _extension;
    });
    if (format === undefined) return undefined;

    return format.mediaType;
  }

  close(): void {
    this.open = false;
  }

  private _createRecordAudioDialog<T>(): ConfirmationModalRef<T> {
    return this.modalService.createModalWithProviders(
      RecordAudioDialogComponent,
      {
        centered: true,
        offset: {
          x: 0,
          y: 0
        },
        width: 'min(400px,80vw)'
      },
      [
        {
          provide: AUDIO_SCRIPT_DIR,
          useValue: 'assets/web-audio-recorder/'
        }
      ]
    );
  }

  showAudioRecordDialog(): void {
    const modalRef = this._createRecordAudioDialog<{
      recording: Blob | null;
      title: string;
    }>();

    combineLatest({
      selectedNote: this.notesFacade.selectedNote$.pipe(take(1)),
      modalData: modalRef.events$.pipe(take(1))
    })
      .pipe(
        switchMap((e) => {
          if (e.selectedNote.id === NEW_NOTE_LOCALLY_ID) {
            this.notesFacade.saveLocalNote();
            return this.notesFacade.noteAdded$.pipe(
              map((note: Note) => {
                return {
                  ...e,
                  selectedNote: {
                    ...e.selectedNote,
                    id: note.id
                  }
                };
              })
            );
          }
          return of(e);
        }),
        take(1)
      )
      .subscribe((dialogResult: AudioRecordingDialogResult) => {
        if (dialogResult.modalData.state) {
          // const projectId = this.route.snapshot.queryParams['projectId'];
          const noteId = dialogResult.selectedNote.id;
          const title = dialogResult.modalData.result?.title
            ? `${dialogResult.modalData.result.title}.mp3`
            : `.mp3`;
          const file = new File(
            [dialogResult.modalData.result?.recording as Blob],
            title
          );

          this.notesFacade.addMedia(
            this.projectId(),
            noteId,
            file,
            'audio',
            dialogResult.modalData.result?.recording?.type.split('/')[0]
          );
        }
      });
  }

  takeScreenshotDialog(): void {
    this.panelRightService.navigateToMatterport().then(() => {
      this.matterportNoteControlService.takeScreenshot();
    });
  }
  addMeasurement() {
    const queryParams: Params = {
      sidenavContent: 'measurement',
      addMeasurementToNote: true
    };

    this.router.navigate(['./matterport'], {
      relativeTo: this.activatedRoute,
      queryParams,
      queryParamsHandling: 'merge'
    });
  }

  protected openDocumentsModal() {
    const acceptedFormat = formats.map(({ format }) => format);
    this._dialog
      .open<
        SelectedDocumentsForNotes,
        DocumentsModalComponentData,
        DocumentsModalComponent
      >(DocumentsModalComponent, {
        panelClass: 'documents-modal-instance',
        viewContainerRef: this._viewRef,
        data: {
          projectId: this.projectId(),
          acceptedFormat: acceptedFormat
        }
      })
      .closed.pipe(
        filter(
          (
            selectedDocuments: SelectedDocumentsForNotes | undefined
          ): selectedDocuments is SelectedDocumentsForNotes =>
            selectedDocuments !== undefined
        ),
        mergeMap((selectedDocuments) => {
          if (this._noteId === NEW_NOTE_LOCALLY_ID) {
            this.notesFacade.saveLocalNote();
            return this.notesFacade.noteAdded$.pipe(
              map(({ id }) => {
                this._noteId = id;
                return selectedDocuments;
              })
            );
          }
          return of(selectedDocuments);
        }),
        mergeMap((selectedDocuments) =>
          forkJoin(
            selectedDocuments.documents.map((item) =>
              this.noteMediaService
                .addFileFromDocuments$(
                  this._noteId,
                  item,
                  selectedDocuments.from
                )
                .pipe(
                  catchError((err) => {
                    console.error(err);

                    return of(null);
                  })
                )
            )
          )
        )
      )
      .subscribe();
  }

  private _cleanInputs(): void {
    this.fileElement.nativeElement.files = new DataTransfer().files;
  }
}
