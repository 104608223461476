import { BooleanInput, coerceBooleanProperty } from '@angular/cdk/coercion';
import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  Output,
  ViewEncapsulation,
  inject,
} from '@angular/core';
let _uniqueIdCounter = 0;

@Component({
    selector: 'design-button-toggle',
    imports: [CommonModule],
    template: ` <ng-content></ng-content> `,
    styleUrls: ['./design-button-toggle.component.scss'],
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
    // eslint-disable-next-line @angular-eslint/no-host-metadata-property
    host: {
        class: 'design-button-toggle',
        '[class.selected]': 'selected',
        '[class.disabled]': 'disabled || parentDisabled',
        '[attr.disabled]': '(disabled || parentDisabled) || null',
        '[attr.tabindex]': '_getTabIndex()',
        '(click)': '!(disabled || parentDisabled) && _handleClick()',
    }
})
export class DesignButtonToggleComponent {
  private readonly _cdr = inject(ChangeDetectorRef);
  private _parentDisabled = false;
  public get parentDisabled() {
    return this._parentDisabled;
  }
  public set parentDisabled(value) {
    this._parentDisabled = value;
  }
  private _selected: BooleanInput = false;
  @Input() id = `design-button-toggle-${_uniqueIdCounter++}`;

  @Output() selectionChange = new EventEmitter<string | number | undefined>();
  public get selected(): BooleanInput {
    return this._selected;
  }
  @Input() public set selected(value: BooleanInput) {
    this._selected = coerceBooleanProperty(value);
    this._cdr.markForCheck();
  }

  private _disabled: BooleanInput = false;
  public get disabled(): BooleanInput {
    return this._disabled;
  }
  @Input() public set disabled(value: BooleanInput) {
    this._disabled = coerceBooleanProperty(value);
    this._cdr.markForCheck();
  }
  private _value: string | number | undefined;
  public get value(): string | number | undefined {
    return this._value;
  }
  @Input() public set value(value: string | number | undefined) {
    this._value = value;
  }

  _handleClick() {
    this._selected = !this._selected;
    const emittedValue = (this._selected && this.value) || undefined;
    this.selectionChange.emit(emittedValue);
  }
  _getTabIndex(): string {
    return this.disabled ? '-1' : '0';
  }

  deselect() {
    this._selected = false;
  }
}
