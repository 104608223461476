import { AsyncPipe, NgOptimizedImage, NgTemplateOutlet } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  computed,
  ElementRef,
  input,
  viewChild
} from '@angular/core';
import { toObservable, toSignal } from '@angular/core/rxjs-interop';
import { DocumentTypeEnumValues } from '@simlab/design/attachment-icon';
import { UiIconModule } from '@simlab/ui/icon';

import { filter, fromEvent, map, merge, mergeMap } from 'rxjs';
import { DocumentsThumbnailIconGetterPipe } from '../../documents-thumbnail-icon-getter/documents-thumbnail-icon-getter.pipe';

@Component({
  selector: 'documents-item-card-image',
  imports: [
    AsyncPipe,
    UiIconModule,
    DocumentsThumbnailIconGetterPipe,
    NgOptimizedImage,
    NgTemplateOutlet
  ],
  templateUrl: './item-card-image.component.html',
  styleUrl: './item-card-image.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ItemCardImageComponent {
  readonly thumbnailUrl = input<string>();
  readonly name = input.required<string>();
  readonly documentType = input.required<DocumentTypeEnumValues>();
  private readonly _image = viewChild<ElementRef<HTMLElement>>('imgRef');

  readonly thumbnailUrlWithoutSvg = computed(() => {
    const thumbnailUrl = this.thumbnailUrl();

    if (!thumbnailUrl || thumbnailUrl.includes('.svg')) return undefined;
    return thumbnailUrl;
  });

  protected readonly isImageLoaded$ = toObservable(this._image).pipe(
    filter((image): image is ElementRef<HTMLElement> => image !== undefined),
    mergeMap(({ nativeElement }) =>
      merge(
        fromEvent(nativeElement, 'load').pipe(map(() => true)),
        fromEvent(nativeElement, 'error').pipe(map(() => false))
      )
    )
  );
  protected readonly isImageLoaded = toSignal(this.isImageLoaded$);
}
