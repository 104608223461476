import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  computed,
  inject,
  TemplateRef,
  viewChild
} from '@angular/core';
import { DesignIconButton } from '@simlab/design/button';
import { DesignIcon } from '@simlab/design/icon';
import {
  CUSTOM_ROOT_FOLDER,
  documentsActionsToken,
  documentsApiToken,
  FolderAsPathItem
} from '@simlab/documents/data-access';
import {
  DocumentsStateService,
  DocumentsStore
} from '@simlab/documents/services';
import {
  DocumentActionPanelComponent,
  DocumentsRootComponent,
  DocumentsSelectedItemActionPanelComponent
} from '@simlab/documents/ui';
import {
  TEventElementItem,
  UiEventElementComponent
} from '@simlab/event-queue';
import { ProcoreDownloadResourceGetterService } from '@simlab/procore/services';
import { ProcoreDocumentsStateService } from '../../../../../services/procore-documents-state.service';
import { ProcoreInModalSelectStateService } from '../../services/procore-in-modal-select-state.service';
import { ProcoreDrawingsApiService } from './../../../../../data-access/procore-drawings-api.service';
import {
  ProcoreDrawingsActionsBase,
  ProcoreDrawingsInModalActionsService
} from './data-access/procore-drawings-in-modal-actions/procore-drawings-in-modal-actions.service';

@Component({
    selector: 'feature-projects-procore-drawings-in-modal',
    imports: [
    DocumentsRootComponent,
    DocumentActionPanelComponent,
    DocumentsSelectedItemActionPanelComponent,
    DesignIcon,
    DesignIconButton,
    UiEventElementComponent
],
    templateUrl: './procore-drawings-in-modal.component.html',
    styleUrl: './procore-drawings-in-modal.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [
        ProcoreDownloadResourceGetterService,
        { provide: documentsApiToken, useClass: ProcoreDrawingsApiService },
        DocumentsStore,
        DocumentsStateService,
        {
            provide: CUSTOM_ROOT_FOLDER,
            useFactory: () => {
                const state = inject(ProcoreInModalSelectStateService);
                return [
                    {
                        id: '1',
                        name: 'Procore',
                        clickHandler: () => state.setSelectedState(undefined)
                    },
                    {
                        id: '2',
                        name: 'Drawings',
                        clickHandler: () => state.setSelectedState('drawings')
                    }
                ] as FolderAsPathItem[];
            }
        },
        ProcoreDocumentsStateService,
        {
            provide: documentsActionsToken,
            useClass: ProcoreDrawingsInModalActionsService
        }
    ]
})
export class ProcoreDrawingsInModalComponent implements AfterViewInit {
  private readonly _actions = inject<ProcoreDrawingsActionsBase>(
    documentsActionsToken
  );
  protected readonly _rootComponent = viewChild.required(
    DocumentsRootComponent
  );

  readonly downloadTask = viewChild('downloadTask', {
    read: TemplateRef<{ context: TEventElementItem }>
  });

  readonly selectedItems = computed(() =>
    this._rootComponent().selectedItems()
  );

  ngAfterViewInit(): void {
    this._actions.templateToDownload = this.downloadTask();
  }

  protected downloadDocuments(): void {
    const data = this.selectedItems().map((item) => item.data().data);

    this._actions.download(data);
  }

  resetSelectedItems(): void {
    this._rootComponent().clearSelection();
  }
}
