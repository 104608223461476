import { Dialog } from '@angular/cdk/dialog';
import {
  ChangeDetectionStrategy,
  Component,
  inject,
  input
} from '@angular/core';
import { DesignIconButton } from '@simlab/design/button';
import { DesignCommentComponent } from '@simlab/design/comment';
import { FormatDateSignalPipe } from '@simlab/design/format-date';
import { DesignIcon } from '@simlab/design/icon';
import { PunchItemStateService } from '@simlab/procore/annotation-panel/services';
import { InfoItemContainerComponent } from '@simlab/procore/annotation-panel/ui';
import { Assignment } from '@simlab/procore/models';
import { PunchItemAssignmentStatusLabelGetterPipe } from '@simlab/procore/pipes';
import { PunchItemSidePanelAssigneeResponseModalComponent } from '../punch-item-side-panel-assignee-response-modal/punch-item-side-panel-assignee-response-modal.component';

@Component({
  selector: 'procore-punch-item-side-panel-assignee-item',
  standalone: true,
  imports: [
    InfoItemContainerComponent,
    FormatDateSignalPipe,
    PunchItemAssignmentStatusLabelGetterPipe,
    DesignIconButton,
    DesignIcon,
    DesignCommentComponent
  ],
  templateUrl: './punch-item-side-panel-assignee-item.component.html',
  styleUrl: './punch-item-side-panel-assignee-item.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PunchItemSidePanelAssigneeItemComponent {
  private readonly _state = inject(PunchItemStateService);
  private readonly _dialog = inject(Dialog);
  readonly assignment = input.required<Assignment>();

  openResponseModal() {
    this._dialog.open(PunchItemSidePanelAssigneeResponseModalComponent, {
      minWidth: 'min(90%, 562px)',
      maxWidth: 'min(90%, 562px)',
      data: {
        assignment: this.assignment(),
        procorePunchItemId: this._state.punchItem().procoreId,
        state: this._state
      }
    });
  }
}
