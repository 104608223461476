import { Directive, ElementRef, Input } from '@angular/core';
import { ThemeColors } from '../models/theme-colors';

@Directive({
    selector: '[uiColor]',
    standalone: false
})
export class ColorDirective {
  @Input() set uiColor(value: ThemeColors) {
    this.el.nativeElement.classList.forEach((el: any) => {
      if (el.includes('ui-color-')) {
        this.el.nativeElement.classList.remove(el);
      }
    });
    this.el.nativeElement.classList.add(`ui-color-${value}`);
  }
  constructor(private readonly el: ElementRef) {}
}
