import { DatePipe } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  computed,
  inject,
  input,
  ViewEncapsulation
} from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { AttachmentIconGetterPipe } from '@simlab/design/attachment-icon';
import { FormatDateSignalPipe } from '@simlab/design/format-date';
import { DesignIcon } from '@simlab/design/icon';
import { PunchItemActivity, PunchItemAttachment } from '@simlab/procore/models';
import {
  PunchItemAssignmentStatusLabel,
  PunchItemStatusLabel
} from '@simlab/procore/pipes';
import { UiChipsModule } from '@simlab/ui/chips';
import { DownloadFilesService } from '@simlab/util-shared';

@Component({
  selector: 'procore-punch-item-activity',
  standalone: true,
  imports: [
    DesignIcon,
    DatePipe,
    FormatDateSignalPipe,
    UiChipsModule,
    AttachmentIconGetterPipe
  ],
  templateUrl: './punch-item-activity.component.html',
  styleUrl: './punch-item-activity.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  host: {
    class: 'punch-item-activity',
    '[class.punch-item-activity--status-change-owner]':
      'isStatusChange() && isOwner()',
    '[class.punch-item-activity--status-change]':
      'isStatusChange() && !isOwner()'
  }
})
export class PunchItemActivityComponent {
  private readonly _downloadFile = inject(DownloadFilesService);
  private readonly _sanitized = inject(DomSanitizer);
  readonly activity = input.required<PunchItemActivity>();

  protected readonly heading = computed(() => {
    const workflowStatus = this.activity().status;
    const activityType = this.activity().type;
    const userName = this.activity().createdBy.name;
    const assigneeName = this.activity().assignee?.userName;
    const assigneeResponseStatus = this.activity().assignee?.status;

    const localizedStatus = PunchItemStatusLabel[workflowStatus];
    const localizedAssigneeResponseStatus = assigneeResponseStatus
      ? PunchItemAssignmentStatusLabel[assigneeResponseStatus]
      : '';

    switch (activityType) {
      case 'PunchItemStatusChange':
        return $localize`:@@PUNCH_ITEM_ACTIVITY_STATUS_CHANGE:Status Changed to ${localizedStatus} by ${userName}`;

      case 'Comment':
        return $localize`:PUNCH_ITEM_ACTIVITY_COMMENT:${userName} Commented`;

      case 'PunchItemAssigneeResponse':
        return $localize`:@@PUNCH_ITEM_ACTIVITY_RESPONSE_CHANGE:Response Changed to ${localizedAssigneeResponseStatus} by ${assigneeName}`;

      default:
        console.error('Unhandled punch item activity type');
        return '';
    }
  });

  protected readonly comment = computed(() => {
    const { comment, body } = this.activity();
    if (!comment && !body) return '';
    return this._sanitized.bypassSecurityTrustHtml(comment || body);
  });

  protected readonly isOwner = computed(() => {
    const { isOwner } = this.activity();
    return isOwner;
  });

  protected readonly isStatusChange = computed(() => {
    const { type } = this.activity();
    return type === 'PunchItemStatusChange';
  });

  protected readonly areAnyAttachments = computed(() => {
    const { attachments } = this.activity();
    return !!attachments?.length;
  });

  protected downloadFile(attachment: PunchItemAttachment) {
    this._downloadFile
      .downloadFile(attachment.url, attachment.filename)
      .subscribe();
  }
}
