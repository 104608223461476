<header class="punch-item-activity-section__header">
  <h4 i18n="@@ACTIVITY">Activity</h4>
  <button
    design-flat-button
    designButtonColor="secondary"
    (click)="switchCommentVisibility()"
  >
    <div
      class="punch-item-activity-section__header__button"
      i18n="@@ACTIVITY_ADD_COMMENT_BUTTON"
    >
      <i designIcon="add" designSize="20px"></i>
      Comment
    </div>
  </button>
</header>

@if (isCommentFormVisible()) {
  <design-comment
    [isEditable]="true"
    [showSendButton]="true"
    [disabled]="isAddingCommentPending()"
    (commentAdded)="commentAdded.emit($event)"
  ></design-comment>
}

@if (activitiesLoading()) {
  <div style="display: flex; justify-content: center; align-items: center">
    <design-progress-spinner
      [diameter]="40"
      [strokeWidth]="10"
      class="spinner"
      designColor="accent"
    ></design-progress-spinner>
  </div>
}

<div class="punch-item-activity-section__activities">
  @for (activity of activities(); track activity.id) {
    <procore-punch-item-activity
      [activity]="activity"
    ></procore-punch-item-activity>
  }
</div>
