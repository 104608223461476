import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { DesignFlatButton, DesignStrokedButton } from '@simlab/design/button';
import { DesignCommonModule } from '@simlab/design/common';
import { DesignDialogWrapperModule } from '@simlab/design/dialog';
import { UiIconModule } from '../../../ui-icon/ui-icon.module';
import { ConfirmationModalRef, MODAL_DATA } from '../../public-api';

export type SaveAction = 'cancel' | 'notSave' | 'save';

@Component({
    selector: 'ui-not-saved-changes-dialog',
    templateUrl: './not-saved-changes-dialog.component.html',
    styles: [
        `
      :host {
        width: 100%;

        .header {
          display: flex;
          flex-direction: column;
          row-gap: 50px;

          > i {
            align-self: center;
          }
        }
      }
    `,
    ],
    changeDetection: ChangeDetectionStrategy.OnPush,
    imports: [
        CommonModule,
        DesignCommonModule,
        UiIconModule,
        DesignDialogWrapperModule,
        DesignFlatButton,
        DesignStrokedButton,
    ]
})
export class NotSavedChangesDialogComponent {
  set confirmButton(value: string) {
    this._confirmButton = value;
  }
  get confirmButton(): string {
    return this._confirmButton;
  }
  private _confirmButton = 'Confirm';

  constructor(
    @Inject(MODAL_DATA)
    public readonly modalData: { text: string; confirmButton?: string },
    private readonly modalRef: ConfirmationModalRef<SaveAction>
  ) {
    this.confirmButton = modalData.confirmButton
      ? modalData.confirmButton
      : 'Confirm';
  }

  cancel(): void {
    this.modalRef.emit({ result: 'cancel', state: false });
    this.modalRef.close();
  }

  notSave(): void {
    this.modalRef.emit({ result: 'notSave', state: true });
    this.modalRef.close();
  }

  save(): void {
    this.modalRef.emit({ result: 'save', state: false });
    this.modalRef.close();
  }
}
