import {
  booleanAttribute,
  ChangeDetectionStrategy,
  Component,
  input,
  OnChanges,
  output,
  signal,
  SimpleChanges,
  viewChild
} from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { DesignButtonModule, DesignFlatButton } from '@simlab/design/button';
import {
  CommentOptions,
  CommentToken,
  CommentWithFiles,
  DesignCommentComponent
} from '@simlab/design/comment';
import { DesignIcon } from '@simlab/design/icon';
import { DesignProgressSpinnerComponent } from '@simlab/design/progress-spinner';
import { PunchItemActivityComponent } from '@simlab/procore/annotation-panel/ui';
import { PunchItemActivity } from '@simlab/procore/models';

@Component({
  selector: 'procore-punch-item-activity-section',
  standalone: true,
  imports: [
    ReactiveFormsModule,
    PunchItemActivityComponent,
    DesignButtonModule,
    DesignFlatButton,
    DesignIcon,
    DesignCommentComponent,
    DesignProgressSpinnerComponent
  ],
  templateUrl: './punch-item-activity-section.component.html',
  styleUrl: './punch-item-activity-section.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PunchItemActivitySectionComponent implements OnChanges {
  private readonly _comment = viewChild<CommentOptions>(CommentToken);
  readonly activities = input<PunchItemActivity[]>();
  readonly activitiesLoading = input(false, { transform: booleanAttribute });
  readonly isAddingCommentPending = input(false, {
    transform: booleanAttribute
  });
  readonly commentAdded = output<CommentWithFiles>();

  protected readonly isCommentFormVisible = signal<boolean>(false);

  protected switchCommentVisibility() {
    this.isCommentFormVisible.update((isVisible) => !isVisible);
  }

  ngOnChanges(changes: SimpleChanges) {
    if (Object.hasOwn(changes, 'activities') && this.activities()) {
      this._comment()?.clearValues();
    }
  }
}
