import {
  ChangeDetectionStrategy,
  Component,
  NgModule,
  ViewEncapsulation,
} from '@angular/core';
import { UiProgressSpinnerModule } from '../../../ui-progress-spinner/ui-progress-spinner.module';

const HEIGHT_SCALE_MULTIPLIER = 0.6;
const STROKE_SCALE_MULTIPLIER = 0.08;

export abstract class ButtonLoaderBase {
  abstract setHeight(value: number): void;
}

@Component({
    selector: 'ui-button-loader',
    template: `<ui-progress-spinner
    [diameter]="diameter"
    [strokeWidth]="stroke"
  ></ui-progress-spinner>`,
    styleUrls: ['./button-loader.component.scss'],
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [
        { provide: ButtonLoaderBase, useExisting: ButtonLoaderComponent },
    ],
    // eslint-disable-next-line @angular-eslint/no-host-metadata-property
    host: {
        class: 'ui-button-loader',
    },
    standalone: false
})
export class ButtonLoaderComponent extends ButtonLoaderBase {
  diameter = 0;
  stroke = 0;

  setHeight(value: number): void {
    this.diameter = value * HEIGHT_SCALE_MULTIPLIER;
    this.stroke = value * STROKE_SCALE_MULTIPLIER;
  }
}

@NgModule({
  declarations: [ButtonLoaderComponent],
  imports: [UiProgressSpinnerModule],
  exports: [ButtonLoaderComponent],
})
export class UiButtonLoaderModule {}
