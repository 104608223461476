import { DialogRef } from '@angular/cdk/dialog';
import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import {
  DesignFlatButtonModule,
  DesignStrokedButton
} from '@simlab/design/button';
import { DesignDialogWrapperModule } from '@simlab/design/dialog';


@Component({
    selector: 'simlab-open-new-tab-safari',
    imports: [
    DesignDialogWrapperModule,
    DesignFlatButtonModule,
    DesignStrokedButton
],
    templateUrl: './open-new-tab-safari.component.html',
    styleUrl: './open-new-tab-safari.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class OpenNewTabSafariComponent {
  private readonly _modalRef = inject(DialogRef<boolean>);

  close() {
    this._modalRef.close(false);
  }
  open() {
    this._modalRef.close(true);
  }
}
