import {
  ChangeDetectionStrategy,
  Component,
  computed,
  inject,
  input
} from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { MatterportAnnotationControlService } from '@simlab/annotation/data-access';
import { ProjectPrivilegesFacade } from '@simlab/data-store';
import { PunchItemsListStore } from '@simlab/procore/services';
import { AnnotationPanelLocation3DComponent } from 'common/annotation-panel/ui';

@Component({
  selector: 'procore-punch-item-panel-location-3d',
  standalone: true,
  templateUrl: 'punch-item-panel-location-3d.component.html',
  styleUrl: './punch-item-panel-location-3d.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [AnnotationPanelLocation3DComponent]
})
export class PunchItemPanelLocation3DComponent {
  private readonly _store = inject(PunchItemsListStore);
  private readonly _privileges = inject(ProjectPrivilegesFacade);

  private readonly _matterportAnnotationControl = inject(
    MatterportAnnotationControlService
  );

  private readonly _hasViewerPrivileges = toSignal(
    this._privileges.noEditPrivilegesInProject$
  );

  protected readonly hasMarker = computed(
    () => this._store.itemSelected()?.marker !== undefined
  );

  protected readonly canEdit = computed(() => {
    return this.showEdit() && !this._hasViewerPrivileges();
  });

  readonly showEdit = input.required<boolean>();

  protected goTo() {
    this._matterportAnnotationControl.moveToAnnotation('punchList');
  }

  protected setLocation() {
    this._matterportAnnotationControl.triggerAnnotationMarkerAddMode(
      'punchList',
      true
    );
  }

  protected reassignLocation() {
    this._matterportAnnotationControl.triggerAnnotationMarkerChangeMode(
      'punchList'
    );
  }
}
