<ng-container [formGroup]="form()">
  <header class="general-information__header">
    <h2 i18n="@@GENERAL_INFORMATION_HEADER">General information</h2>
    <ui-checkbox type="customize" formControlName="private">
      <label i18n="@@GENERAL_INFORMATION_PRIVATE">Private</label>
    </ui-checkbox>
  </header>

  <div class="general-information__form">
    <design-form-field>
      <label designLabel i18n="@@GENERAL_INFORMATION_TITLE"> Title </label>
      <input designInput formControlName="name" />
      <design-error></design-error>
    </design-form-field>

    <div class="general-information__controls-wrapper">
      <design-form-field>
        <label designLabel i18n="@@GENERAL_INFORMATION_NUMBER">
          Number&nbsp;#
        </label>
        <input designInput formControlName="position" />
        <design-error></design-error>
      </design-form-field>

      <design-form-field>
        <label designLabel i18n="@@GENERAL_INFORMATION_DUE_DATE">
          Due date
        </label>
        <input
          #stageDateRef="designDatepickerInput"
          designInput
          designDatepicker
          formControlName="due"
          [maxlength]="10"
        />
        <button design-icon-button designSuffix (click)="stageDateRef.open()">
          <i [designIcon]="'Calendar'" designSize="1.25rem"></i>
        </button>

        <design-error></design-error>
      </design-form-field>
    </div>

    <ui-form-field [hideRequiredMarker]="false">
      <label designLabel i18n="@@GENERAL_INFORMATION_PUNCH_ITEM_MANAGER"
        >Punch Item Manager</label
      >
      <ui-select
        i18n-placeholder="@@SELECT"
        placeholder="Select"
        formControlName="punchItemManagerId"
      >
        <design-form-field class="general-information__search">
          <i
            class="suffix"
            designSuffix
            designIcon="search"
            designSize="1.3rem"
          ></i>
          <input
            designInput
            i18n-placeholder="@@SEARCH"
            placeholder="Search"
            [formControl]="filterManager"
          />
        </design-form-field>

        @for (user of filterManagerAssignees(); track user.id) {
          <ui-option [value]="user.id">
            {{ user.name }}
          </ui-option>
        }
      </ui-select>

      <ui-hint
        [validationErrors]="form().get('punchItemManagerId')?.errors"
        [hidden]="isPunchItemErrorHintVisible"
      >
      </ui-hint>
    </ui-form-field>

    <ui-form-field>
      <label i18n="@@GENERAL_INFORMATION_ASSIGNEE">Assignee(s)</label>
      <ui-select
        i18n-placeholder="@@SELECT"
        placeholder="Select"
        formControlName="loginInformationIds"
        [multiple]="true"
        [showPlaceholder]="true"
      >
        <design-form-field class="general-information__search">
          <i
            class="suffix"
            designSuffix
            designIcon="search"
            designSize="1.3rem"
          ></i>
          <input
            designInput
            i18n-placeholder="@@SEARCH"
            placeholder="Search"
            [formControl]="filterAssignees"
          />
        </design-form-field>

        @for (user of filterAssigneeAssignees(); track user.id) {
          <ui-option [value]="user.id">
            {{ user.name }}
          </ui-option>
        }
      </ui-select>
    </ui-form-field>

    <ui-form-field [hideRequiredMarker]="false">
      <label i18n="@@GENERAL_INFORMATION_FINAL_APPROVER">Final Approver</label>
      <ui-select
        i18n-placeholder="@@SELECT"
        placeholder="Select"
        formControlName="finalApproverId"
      >
        <design-form-field class="general-information__search">
          <i
            class="suffix"
            designSuffix
            designIcon="search"
            designSize="1.3rem"
          ></i>
          <input
            designInput
            i18n-placeholder="@@SEARCH"
            placeholder="Search"
            [formControl]="filterFinalApprover"
          />
        </design-form-field>

        @for (user of filterFinalApproverAssignees(); track user.id) {
          <ui-option [value]="user.id">
            {{ user.name }}
          </ui-option>
        }
      </ui-select>
    </ui-form-field>

    @if (descriptionControl) {
      <design-comment
        label="Description"
        i18n-label="@@GENERAL_INFORMATION_DESCRIPTION"
        formControlName="description"
        [isEditable]="true"
      />
    }
  </div>
</ng-container>
