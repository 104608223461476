import { CommonModule, LocationStrategy } from '@angular/common';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  HostListener,
  OnDestroy,
  ViewChild,
  inject
} from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import {
  ApiFacadeService,
  Media,
  MediaInfo,
  MediaSlideShow,
  MediaType
} from '@simlab/data-access';
import { NotesFacade, StagesFacade } from '@simlab/data-store';
import { DesignIconButton } from '@simlab/design/button';
import { DesignIcon } from '@simlab/design/icon';
import { ProcoreDownloadResourceGetterService } from '@simlab/procore/services';
import { UiFormFieldModule } from '@simlab/ui/form-field';
import { UiHintModule } from '@simlab/ui/hint';
import { UiInputModule } from '@simlab/ui/input';
import {
  ConfirmationModalRef,
  MODAL_DATA,
  ModalService,
  SimpleAcceptModalDialogComponent
} from '@simlab/ui/modal';
import { UiSlideshowComponent, UiSlideshowModule } from '@simlab/ui/slideshow';
import { RouterStoreParams, createFormDataImage } from '@simlab/util-shared';
import {
  Observable,
  concatAll,
  defer,
  distinctUntilChanged,
  exhaustMap,
  first,
  firstValueFrom,
  map,
  mergeMap,
  of,
  switchMap,
  take,
  tap
} from 'rxjs';
import { BaseMedia } from '../../directives/base-media.directive';
import { NoteMediaService } from '../../services/note-media.service';
import { ProjectLimitsService } from '../../services/project-limits.service';
import { UiImageDrawingComponent } from '../image-drawing/ui-image-drawing.component';

@Component({
    selector: 'feature-stages-media-modal',
    templateUrl: './media-modal.component.html',
    styleUrls: ['./media-modal.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [ProjectLimitsService, ProcoreDownloadResourceGetterService],
    imports: [
        CommonModule,
        UiImageDrawingComponent,
        UiFormFieldModule,
        UiInputModule,
        UiHintModule,
        DesignIconButton,
        DesignIcon,
        ReactiveFormsModule,
        UiSlideshowModule
    ]
})
export class MediaModalComponent extends BaseMedia implements OnDestroy {
  private readonly modalRef = inject(ConfirmationModalRef<unknown>);
  private readonly apiFacadeService = inject(ApiFacadeService);
  private readonly notesFacade = inject(NotesFacade);
  private readonly projectLimitsService = inject(ProjectLimitsService);
  private readonly noteMediaService = inject(NoteMediaService);
  private readonly stagesFacade = inject(StagesFacade);
  private readonly location = inject(LocationStrategy);
  private readonly changeDetectorRef = inject(ChangeDetectorRef);
  private readonly modalService = inject(ModalService);

  public readonly data: {
    media: Observable<Media[]>;
    type: Extract<MediaType, 'photo' | 'video'>;
    noteId: string;
    openImageIndex: number;
    currentName: string;
    mediaId: string;
  } = inject(MODAL_DATA);

  @ViewChild(UiSlideshowComponent)
  uiSlideshowComponent!: UiSlideshowComponent;

  readonly media$: Observable<MediaSlideShow[]> = this.data.media.pipe(
    map((data: Media[]) => {
      return data.map((media) => {
        return {
          ...media,
          url$: this.getObservableBlobSecureUrl(media, 'None').pipe(
            distinctUntilChanged()
          )
        };
      });
    })
  );

  _currentMediaInfo: MediaInfo = { name: '', id: '', type: undefined };
  get currentMediaInfo(): MediaInfo {
    return this._currentMediaInfo;
  }
  set currentMediaInfo(value: MediaInfo) {
    this._currentMediaInfo = value;
  }

  _currentFileId?: string;
  get currentFileId(): string {
    return this._currentFileId ? this._currentFileId : '';
  }
  set currentFileId(value: string) {
    this._currentFileId = value;
  }

  dimension: { width: number; height: number } | undefined;
  imageToDraw: undefined | string;
  private _currentDrawingImage?: Media;

  savingImage$ = this.notesFacade.updateMedia$.pipe(
    tap(() => {
      this.close();
    })
  );

  readonly projectIsActive$: Observable<boolean> =
    this.projectLimitsService.projectIsActive$;

  readonly projectId$: Observable<string> = defer(() =>
    this.stagesFacade.getRouteNestedParams$.pipe(
      map((params: RouterStoreParams) => params.params['projectId'])
    )
  );

  @HostListener('document:keyup.escape', ['$event']) onKeydownHandler(
    event: KeyboardEvent
  ) {
    this.close();
  }

  constructor() {
    super();
    this.currentMediaInfo = {
      name: this.data.currentName,
      id: this.data.mediaId,
      type: this._getMediaInfoTypeBy(this.data.type)
    };

    this.location.onPopState(() => {
      this.close();
    });
  }
  ngOnDestroy(): void {
    this._destroySource.next();
    this._destroySource.complete();
  }

  close(): void {
    this.imageToDraw = undefined;
    this.modalRef.close();
  }

  private _getMediaInfoTypeBy(
    mediaType: Extract<MediaType, 'photo' | 'video'>
  ): MediaInfo['type'] {
    if (mediaType === 'photo') return 'Photo';
    if (mediaType === 'video') return 'Video';
    return undefined;
  }

  private _abortProjectTransferDialog(): ConfirmationModalRef<SimpleAcceptModalDialogComponent> {
    return this.modalService.createModalWithProviders(
      SimpleAcceptModalDialogComponent,
      {
        width: 'min(90%, 380px)'
      },
      [
        {
          provide: MODAL_DATA,
          useValue: {
            titleModal: 'Delete image',
            content: 'Are you sure you want to delete the image?',
            buttonText: 'Yes, Delete'
          }
        }
      ]
    );
  }

  delete(): void {
    firstValueFrom(
      this._abortProjectTransferDialog().events$.pipe(
        exhaustMap((data) => {
          if (!data.state) return of(undefined);

          return this.deleteMedia$(
            this.currentMediaInfo,
            this.data.noteId
          ).pipe(
            tap(() => {
              this.close();
              this.notesFacade.removeDigitalNote(
                this.currentMediaInfo.id,
                this.data.type as MediaType
              );
            })
          );
        })
      )
    );
  }

  setCurrentMediaInfo(value: MediaInfo): void {
    this.currentMediaInfo = value;
  }

  saveDrawingImage(image: string): void {
    if (this._currentDrawingImage) {
      firstValueFrom(
        this.projectId$.pipe(
          mergeMap((projectId) =>
            this.noteMediaService.uploadDrawingImageFile(
              createFormDataImage(image, projectId),
              this._currentDrawingImage as Media
            )
          )
        )
      );
    }
  }

  openImageDrawing(): void {
    firstValueFrom(
      this.media$.pipe(
        map((medias: MediaSlideShow[]) =>
          medias.filter(
            (media: MediaSlideShow) => media.id === this.currentMediaInfo.id
          )
        ),
        concatAll(),
        first(),
        switchMap((media: MediaSlideShow) => {
          if (media.url$)
            return media.url$.pipe(
              take(1),
              tap((privateUrl: string) => {
                if (privateUrl) {
                  this.imageToDraw = privateUrl;
                  this.dimension = this.uiSlideshowComponent.getDimensions();
                  delete media['url$'];
                  this._currentDrawingImage = media;
                  this.changeDetectorRef.detectChanges();
                }
              })
            );
          return of(undefined);
        })
      )
    );
  }

  protected get isProcoreDocuments(): boolean {
    return (
      this.isProcoreImageMedia(this.currentMediaInfo) ||
      this.isProcoreDocumentMedia(this.currentMediaInfo) ||
      this.isProcoreDrawingMedia(this.currentMediaInfo)
    );
  }
}
