import { InjectionToken, Signal } from '@angular/core';

export interface AnnotationAction {
  name: string | Signal<string>;
  trigger: () => unknown;
  disabled?: Signal<boolean | undefined>;
}

export interface AnnotationActionsBase {
  actionList?: AnnotationAction[];
  closePanel: () => unknown;
}

export interface AnnotationLocation {
  goTo: () => void;
  assignPosition: () => void;
}

export const annotationLocationToken =
  new InjectionToken<AnnotationActionsBase>('annotations-location-token');

export const annotationsActionsToken =
  new InjectionToken<AnnotationActionsBase>('annotations-actions-token');
