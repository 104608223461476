import { NgClass } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  input,
  ViewEncapsulation
} from '@angular/core';
import { StatusBadgeComponent } from '@simlab/design/status-badge';
import { PunchListWorkflowStatus } from '@simlab/procore/models';
import { PunchItemStatusLabelGetterPipe } from '@simlab/procore/pipes';

@Component({
  selector: 'procore-punch-item-status-badge',
  standalone: true,
  imports: [NgClass, StatusBadgeComponent, PunchItemStatusLabelGetterPipe],
  templateUrl: './punch-item-status-badge.component.html',
  styleUrl: './punch-item-status-badge.component.scss',
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
  host: {
    class: 'punch-item-status-badge'
  }
})
export class PunchItemStatusBadgeComponent {
  readonly workflowStatus = input<PunchListWorkflowStatus>();
  readonly showYellowBackground = input<boolean>();

  protected readonly status = PunchListWorkflowStatus;
}
