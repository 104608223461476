import { Overlay } from '@angular/cdk/overlay';
import {
  ChangeDetectionStrategy,
  Component,
  OnInit,
  ViewChild,
  inject
} from '@angular/core';
import { Router } from '@angular/router';
import {
  ApiFacadeService,
  FormatDate,
  OrganizationLimitsFacade,
  Page,
  Project,
  ProjectPaging,
  ProjectsFilterBase,
  ProjectsSort,
  SortingType
} from '@simlab/data-access';
// eslint-disable-next-line @nx/enforce-module-boundaries
import { ProjectsFacade, UserPreferenceFacade } from '@simlab/data-store';
import {
  BehaviorSubject,
  Observable,
  filter,
  iif,
  map,
  mergeMap,
  of,
  switchMap,
  take,
  takeUntil,
  tap
} from 'rxjs';

import {
  ConfirmationModalRef,
  DialogResponse,
  MODAL_DATA,
  ModalService
} from '@simlab/ui/modal';
import { MAT_SELECT_SCROLL_STRATEGY_PROVIDER } from '@simlab/ui/select';
import { DefaultSidenavBehavior, SidenavComponent } from '@simlab/ui/sidenav';
import { AddProjectDialogComponent } from '../../components/add-project-dialog/add-project-dialog.component';
import { NoAccessDialogComponent } from '../../components/no-access-dialog/no-access-dialog.component';
import { OrganizationSelectionDialogComponent } from '../../components/organization-selection-dialog/organization-selection-dialog.component';
import { ProjectsFilter } from '../../models/projects-filters-base';

const LIMIT_OF_PROJECTS_OVER = $localize`:@@LIMIT_OF_PROJECTS_OVER:Limit of projects is over. \nGo to a higher plan to solve the problem.`;
const DESCRIPTION_IMAGE_INFO = [
  $localize`:@@NO_PROJECT_DETECTED_INFO_1:You don't have any project or haven't been invited to any.`,
  $localize`:@@NO_PROJECT_DETECTED_INFO_2:Add a project or accept an invitation.`
];

@Component({
    selector: 'feature-projects-list',
    templateUrl: './list.component.html',
    styleUrls: ['./list.component.scss'],
    viewProviders: [OrganizationLimitsFacade],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class ListComponent extends DefaultSidenavBehavior implements OnInit {
  private readonly _projectsFacade = inject(ProjectsFacade);
  private readonly _modalService = inject(ModalService);
  private readonly _apiFacadeService = inject(ApiFacadeService);
  private readonly _overlay = inject(Overlay);
  private readonly _router = inject(Router);
  private readonly _organizationLimitsFacade = inject(OrganizationLimitsFacade);
  readonly descriptionImageInfo = DESCRIPTION_IMAGE_INFO;

  @ViewChild('sidenav', { read: SidenavComponent }) sidenav!: SidenavComponent;
  title = 'Projects List';
  private _projectsConfig: {
    filters?: ProjectsFilterBase;
    sort?: ProjectsSort;
    paging?: ProjectPaging;
  } = {
    filters: {},
    sort: { ascending: true, type: SortingType.Name }
  };

  private readonly _creatingProject$: BehaviorSubject<boolean> =
    new BehaviorSubject<boolean>(false);
  readonly creatingProject$ = this._creatingProject$.asObservable();

  private readonly _userPreferenceFacade = inject(UserPreferenceFacade);
  private _formatDate: FormatDate = 'dd/MM/yyyy';
  readonly projects$: Observable<Project[]> = this._projectsFacade.allProjects$;
  readonly projectLoaded$: Observable<boolean> = this._projectsFacade.loaded$;
  readonly metadata$: Observable<Page> = this._projectsFacade.metadata$.pipe(
    filter((result: Page | undefined) => !!result),
    map((result: Page | undefined) => result as Page)
  );

  readonly createDialogAction$: Observable<boolean> = of('').pipe(
    map(() => this._createOrganizationSelectionDialog()),
    mergeMap(
      (dialogRef: ConfirmationModalRef<OrganizationSelectionDialogComponent>) =>
        dialogRef.events$.pipe(
          mergeMap(
            (response: DialogResponse<OrganizationSelectionDialogComponent>) =>
              iif(
                () => response.state,
                of(true).pipe(
                  switchMap(() =>
                    iif(
                      () => response.result?.organizationId !== undefined,
                      of(true).pipe(
                        switchMap(() =>
                          this._organizationLimitsFacade
                            .canAddProject(
                              response.result?.organizationId as string
                            )
                            .pipe(
                              switchMap((possibleToAdd: boolean) =>
                                iif(
                                  () => possibleToAdd,
                                  of(true).pipe(
                                    switchMap(() =>
                                      this._addProjectDialog(response)
                                    )
                                  ),
                                  of(false).pipe(
                                    switchMap(() =>
                                      this._noAccessLimitDialog(
                                        response.result
                                          ?.organizationId as string
                                      ).events$.pipe(map(() => false))
                                    )
                                  )
                                )
                              )
                            )
                        )
                      ),
                      of(false).pipe(
                        tap(() => {
                          this._router.navigate(['/organizations']);
                        })
                      )
                    )
                  )
                ),
                of(false)
              )
          )
        )
    ),
    take(1),
    takeUntil(this._destroySource)
  );

  ngOnInit(): void {
    this._userPreferenceFacade.getDateFormat$
      .pipe(takeUntil(this._destroySource))
      .subscribe((e) => {
        this._formatDate = e;
      });
  }

  trackProjectById(index: number, project: Project): string {
    return project.id;
  }

  filterChange(filter: ProjectsFilter): void {
    this._projectsConfig.filters = filter;
    this._projectsFacade.initStore(this._projectsConfig);
  }

  orderChange(order: {
    name: keyof typeof SortingType;
    displayName: string;
    isAscending: boolean;
  }): void {
    this._projectsConfig.sort = {
      ascending: order.isAscending,
      type: SortingType[order.name]
    };
    this._projectsFacade.initStore(this._projectsConfig); //TODO: CODE: 79525
  }

  handlePageSelected(paging: ProjectPaging): void {
    this._projectsFacade.initStore({
      ...this._projectsConfig,
      paging: paging
    });
  }

  private _createOrganizationSelectionDialog(): ConfirmationModalRef<OrganizationSelectionDialogComponent> {
    return this._modalService.createModalWithProviders(
      OrganizationSelectionDialogComponent,
      {
        minWidth: 'min(85%, 400px)',
        maxWidth: 'min(85%, 400px)'
      },
      [
        { provide: ApiFacadeService, useValue: this._apiFacadeService },
        {
          provide: Overlay,
          useValue: this._overlay
        },
        MAT_SELECT_SCROLL_STRATEGY_PROVIDER
      ]
    );
  }

  private _addProjectDialog(
    response: DialogResponse<OrganizationSelectionDialogComponent>
  ) {
    return this._createAddProjectDialog(
      response.result?.organizationId as string
    ).events$.pipe(
      switchMap((response: DialogResponse<unknown>) =>
        iif(
          () => response.state,
          of(true).pipe(mergeMap(() => this.dialogResponse(response))),
          of(false)
        )
      ),
      take(1)
    );
  }

  private _createAddProjectDialog(
    organizationId: string
  ): ConfirmationModalRef<AddProjectDialogComponent> {
    return this._modalService.createModalWithProviders(
      AddProjectDialogComponent,
      {
        minWidth: 'min(90%, 850px)',
        maxWidth: 'min(90%, 850px)',
        maxHeight: 'min(90%, 1000px)'
      },
      [
        {
          provide: MODAL_DATA,
          useValue: organizationId
        }
      ]
    );
  }

  private _noAccessLimitDialog(
    organizationId: string
  ): ConfirmationModalRef<NoAccessDialogComponent> {
    return this._modalService.createModalWithProviders(
      NoAccessDialogComponent,
      {
        width: '100%',
        maxWidth: 'min(90%, 380px)',
        maxHeight: 'min(90%, 1000px)'
      },
      [
        {
          provide: MODAL_DATA,
          useValue: {
            organizationId,
            text: LIMIT_OF_PROJECTS_OVER
          }
        }
      ]
    );
  }

  dialogResponse(response: DialogResponse<unknown>): Observable<boolean> {
    this._creatingProject$.next(true);
    return (response.result as Observable<unknown>).pipe(
      map(() => {
        this._creatingProject$.next(false);
        return true;
      }),
      take(1),
      takeUntil(this._destroySource)
    );
  }
}
