import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  Input,
  Renderer2,
  ViewEncapsulation,
} from '@angular/core';

@Component({
    selector: 'ui-badge',
    templateUrl: './badge.component.html',
    styleUrls: ['./badge.component.scss'],
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class BadgeComponent {
  constructor(
    private readonly elementRef: ElementRef,
    private readonly renderer: Renderer2
  ) {}

  @Input('context')
  get context(): number | undefined {
    return this._context;
  }

  set context(value: number | undefined) {
    if (value === 0) {
      this._context = undefined
      return;
    }
    this._context = value;
  }
  private _context: number | undefined;
}
