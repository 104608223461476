import { AsyncPipe, NgIf } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  DestroyRef,
  inject,
  input
} from '@angular/core';
import { takeUntilDestroyed, toSignal } from '@angular/core/rxjs-interop';
import {
  AbstractControl,
  FormsModule,
  ReactiveFormsModule,
  UntypedFormControl,
  UntypedFormGroup,
  Validators
} from '@angular/forms';
import {
  ProjectPrivilegesFacade,
  StageWithCount,
  StagesFacade
} from '@simlab/data-store';
import { DesignIconButton } from '@simlab/design/button';
import { FormatDatePipe } from '@simlab/design/format-date';
import { DesignIcon } from '@simlab/design/icon';
import { UiMenuPanelModule } from '@simlab/design/menu-panel';
import { UiTooltip } from '@simlab/design/tooltip';
import { UiFormFieldModule } from '@simlab/ui/form-field';
import { UiIconModule } from '@simlab/ui/icon';
import { UiImageInfoModule } from '@simlab/ui/image-info';
import {
  ConfirmationModalRef,
  DialogResponse,
  MODAL_DATA,
  ModalService,
  WarningConfirmationModalComponent
} from '@simlab/ui/modal';
import { UiTextareaModule } from '@simlab/ui/textarea';
import {
  EMPTY,
  Observable,
  debounceTime,
  firstValueFrom,
  forkJoin,
  iif,
  mergeMap,
  of,
  pairwise,
  skip,
  switchMap,
  take,
  tap
} from 'rxjs';
import { ProjectLimitsService } from '../../services/project-limits.service';
import { StagesRootService } from '../../services/stages-root.service';
import {
  TStageFormDetail,
  TStageFormResponse
} from '../stage-form-dialog/stage-form-details';
import { StageFormDialogComponent } from '../stage-form-dialog/stage-form-dialog.component';
import { AnnotationItemInfoComponent } from './annotation-item-info/annotation-item-info.component';

const DELETE_STAGE_PROMPT_TEXT = $localize`:@@DELETE_STAGE_PROMPT_TEXT:Are you sure you want to delete this stage?`;
const DELETE = $localize`:@@DELETE:Delete`;
const DESCRIPTION_IMAGE_INFO_NO_STAGES: string[] = [
  $localize`:@@NO_STAGES_IN_PROJECT:There aren't any stages in this project yet.`,
  $localize`:@@ADD_STAGE_TO_START:Add stage to start.`
];
const EDIT_STAGE = $localize`:@@EDIT_STAGE:Edit stage`;

const FORM_CHANGE_DEBOUNCE = 500;
@Component({
  selector: 'feature-stages-stage-info',
  templateUrl: './stage-info.component.html',
  styleUrls: ['./stage-info.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    NgIf,
    AsyncPipe,
    FormsModule,
    ReactiveFormsModule,
    UiTooltip,
    UiMenuPanelModule,
    DesignIconButton,
    DesignIcon,
    FormatDatePipe,
    UiFormFieldModule,
    UiTextareaModule,
    UiIconModule,
    UiImageInfoModule,
    AnnotationItemInfoComponent
  ]
})
export class StageInfoComponent {
  readonly descriptionImageInfoNoStages = DESCRIPTION_IMAGE_INFO_NO_STAGES;
  private readonly _destroyRef = inject(DestroyRef);
  private readonly _formGroup: UntypedFormGroup = new UntypedFormGroup({
    name: new UntypedFormControl({ value: '', disabled: true }, [
      Validators.required,
      Validators.minLength(3),
      Validators.maxLength(65)
    ]),
    description: new UntypedFormControl('')
  });
  private _stageId?: string;
  public open = false;
  private _stageData = '';

  readonly projectId = input.required<string>();

  readonly selectedStage$: Observable<StageWithCount | undefined> =
    this.stagesFacade.selectedStages$.pipe(
      tap((selectedStage) => {
        this._stageId = selectedStage?.id;
        this._stageData = selectedStage?.stageDate || '';
        this.formGroup.setValue({
          name: selectedStage?.name ? selectedStage.name : '',
          description: selectedStage?.description
            ? selectedStage?.description
            : ''
        });
      })
    );

  readonly addEditDeleteStages$ = this.stagesRootService.owner$.pipe(
    mergeMap((owner: boolean) =>
      iif(
        () => owner,
        this.privilegesFacade.addEditDeleteStagesInOwnProjects$,
        this.privilegesFacade.AddEditDeleteStagesInOtherUsersProjects$
      )
    ),
    tap((privileges) => {
      if (privileges) {
        this.descriptionControl.enable({ emitEvent: false });
      } else {
        this.descriptionControl.disable({ emitEvent: false });
      }
    })
  );

  public get formGroup(): UntypedFormGroup {
    return this._formGroup;
  }

  get nameControl(): AbstractControl {
    return this.formGroup.controls['name'];
  }

  get descriptionControl(): AbstractControl {
    return this.formGroup.controls['description'];
  }

  readonly projectIsActive$: Observable<boolean> =
    this.projectLimitsService.projectIsActive$.pipe(
      tap((isActivate) => {
        if (!isActivate) {
          this.descriptionControl.disable();
        }
      })
    );

  protected readonly selectedStages = toSignal(
    this.stagesFacade.selectedStages$
  );

  constructor(
    private readonly stagesFacade: StagesFacade,
    private readonly stagesRootService: StagesRootService,
    private readonly projectLimitsService: ProjectLimitsService,
    private readonly privilegesFacade: ProjectPrivilegesFacade,
    private readonly modalService: ModalService
  ) {
    this._valueChangeObserver();
  }

  private _valueChangeObserver(): void {
    this.formGroup.valueChanges
      .pipe(
        skip(1), //NOTE: fix for 'updateStageName' being called when we visit the page for the first time
        pairwise(),
        debounceTime(FORM_CHANGE_DEBOUNCE),
        mergeMap(([prev, next]) => {
          const changes: { [name: string]: Observable<any> } = {};

          if (prev.id !== next.id) {
            return forkJoin(changes);
          }

          if (prev.name !== next.name && this.nameControl.valid) {
            if (this._stageId) {
              this.stagesFacade.updateStageName(next.name, this._stageId);
            }
          }

          return forkJoin(changes);
        }),
        takeUntilDestroyed(this._destroyRef)
      )
      .subscribe();
  }

  openPanel(): void {
    this.stagesRootService.leftPanelState = 'open';
  }

  remove(): void {
    this.open = !this.open;
    if (this.projectId()) {
      this._openDeleteProjectModal()
        .events$.pipe(
          switchMap((response: DialogResponse<unknown>) =>
            iif(
              () => response.state,
              of(true).pipe(switchMap(() => this._removeStage$())),
              of(false)
            )
          ),
          take(1),
          takeUntilDestroyed(this._destroyRef)
        )
        .subscribe();
    }
  }

  private _removeStage$(): Observable<StageWithCount | undefined> {
    return this.selectedStage$.pipe(
      tap((stage: StageWithCount | undefined) => {
        if (stage?.id) {
          this.stagesFacade.removeStage(stage?.id, this.projectId());
        }
      })
    );
  }

  private _openDeleteProjectModal(): ConfirmationModalRef<unknown> {
    return this.modalService.createModalWithProviders(
      WarningConfirmationModalComponent,
      {},
      [
        {
          provide: MODAL_DATA,
          useValue: {
            text: DELETE_STAGE_PROMPT_TEXT,
            buttonText: DELETE
          }
        }
      ]
    );
  }

  edit(): void {
    firstValueFrom(
      this._editStageFormDialog().events$.pipe(
        switchMap((data: DialogResponse<TStageFormResponse>) => {
          if (data.state && this._stageId) {
            this.stagesFacade.editStage(
              this._stageId,
              data.result?.name || this.nameControl.value,
              data.result?.stageDate || this._stageData
            );
          }
          return of(EMPTY);
        })
      )
    );
  }

  stopEditing(): void {
    this.nameControl.disable({ emitEvent: false });
  }

  saveChanges(text: string): void {
    this.selectedStage$
      .pipe(
        tap((stage: StageWithCount | undefined) => {
          if (stage?.id) {
            if (stage.description !== text) {
              this.stagesFacade.updateStageDescription(text, stage.id);
            }
          }
        }),
        take(1),
        takeUntilDestroyed(this._destroyRef)
      )
      .subscribe();
  }

  private _editStageFormDialog(): ConfirmationModalRef<TStageFormResponse> {
    return this.modalService.createModal<
      StageFormDialogComponent,
      TStageFormResponse,
      TStageFormDetail
    >(
      StageFormDialogComponent,
      {
        width: 'min(600px,100vw)',
        maxWidth: 'min(90%, 500px)'
      },
      {
        title: EDIT_STAGE,
        form: {
          name: this.nameControl.value,
          stageDate: this._stageData
        }
      }
    );
  }
}
