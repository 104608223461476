@if (workflowStatus(); as workflowStatus) {
  <design-status-badge
    [ngClass]="{
      'status-badge--work-required':
        workflowStatus !== status.Closed && showYellowBackground,
      'status-badge--was-sent-to-assignees':
        workflowStatus !== status.Draft && !showYellowBackground,
      'status-badge--draft': workflowStatus === status.Draft,
      'status-badge--closed': workflowStatus === status.Closed
    }"
    [title]="workflowStatus | getPunchItemStatusLabel"
    >{{ workflowStatus | getPunchItemStatusLabel }}</design-status-badge
  >
}
