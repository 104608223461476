@if (!isPunchItemLoading()) {
  <div class="punch-item-information-basic">
    <div class="punch-item-information-basic__wrapper">
      <procore-info-item-container
        label="Status"
        i18n-label="@@PUNCH_ITEM_INFORMATION_STATUS"
        [withBottomBorder]="false"
      >
        <procore-punch-item-status-badge
          [showYellowBackground]="showYellowBackground()"
          [workflowStatus]="punchItem().workflowStatus"
        >
        </procore-punch-item-status-badge>
      </procore-info-item-container>

      <procore-info-item-container
        label="Private"
        i18n-label="@@PUNCH_ITEM_INFORMATION_PRIVATE"
        [withBottomBorder]="false"
      >
        {{ private() }}
      </procore-info-item-container>
    </div>

    <procore-info-item-container
      label="Number#"
      i18n-label="@@PUNCH_ITEM_INFORMATION_NUMBER"
    >
      {{ punchItem().position }}
    </procore-info-item-container>

    <procore-info-item-container
      label="Location"
      i18n-label="@@PUNCH_ITEM_INFORMATION_LOCATION"
    >
      {{ location() }}
    </procore-info-item-container>

    <procore-info-item-container
      label="Type"
      i18n-label="@@PUNCH_ITEM_INFORMATION_TYPE"
    >
      {{ type() }}
    </procore-info-item-container>

    <procore-info-item-container
      label="Trade"
      i18n-label="@@PUNCH_ITEM_INFORMATION_TRADE"
    >
      {{ trade() }}
    </procore-info-item-container>

    <procore-info-item-container
      label="Priority"
      i18n-label="@@PUNCH_ITEM_INFORMATION_PRIORITY"
    >
      @if (punchItem().priority; as priority) {
        {{ priority | getProcorePriorityLabel }}
      } @else {
        {{ noValueStr }}
      }
    </procore-info-item-container>
  </div>
}
