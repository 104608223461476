/* eslint-disable @angular-eslint/component-class-suffix */
/* eslint-disable @angular-eslint/component-selector */
/* eslint-disable @angular-eslint/no-host-metadata-property */
import {
  ChangeDetectionStrategy,
  Component,
  ViewEncapsulation,
} from '@angular/core';
import { DpDatePickerModule } from 'ng-datepicker';
import { DatepickerBase } from './datepicker-base.directive';

@Component({
  selector: 'input[designDatepicker]',
  exportAs: 'designDatepickerInput',
  imports: [DpDatePickerModule],
  template: ``,
  styleUrls: ['./datepicker.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
  host: {
    class: 'design-datepicker',
    '(input)': '_onInput($event)',
  },
})
export class DesignDatepickerInput extends DatepickerBase {}
