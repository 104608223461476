import { computed, inject, Injectable } from '@angular/core';
import { tapResponse } from '@ngrx/operators';
import { PartialStateUpdater, patchState, signalState } from '@ngrx/signals';
import { rxMethod } from '@ngrx/signals/rxjs-interop';
import { TEMP_PUNCH_ITEM_ID } from '@simlab/procore/annotation-panel/data-access';
import { ApiProcoreService } from '@simlab/procore/data-access';
import {
  procoreBaseInfoPayload,
  PunchItem,
  PunchItemActivity,
  PunchItemDisplayElements,
  UpdatePunchItemAssignment,
  UpdatePunchItemAssignmentPayload
} from '@simlab/procore/models';
import { PunchItemsListStore } from '@simlab/procore/services';
import { ProcoreBaseProjectInfo } from '@simlab/procore/shared/models';
import {
  distinctUntilChanged,
  EMPTY,
  filter,
  Observable,
  switchMap,
  tap
} from 'rxjs';

export type PunchItemUpdateStatus = 'IDLE' | 'PENDING' | 'SUCCESS' | 'FAIL';

function filterTempPunchItem() {
  return (source: Observable<PunchItem>) =>
    source.pipe(filter((punch) => punch.id !== TEMP_PUNCH_ITEM_ID));
}

type TPunchItemState = {
  displayStatus: PunchItemDisplayElements | undefined;
  isDisplayStatusLoading: boolean;
  activities: PunchItemActivity[];
  isActivitiesLoading: boolean;
  addingCommentStatus: PunchItemUpdateStatus;
  assignmentUpdateStatus: PunchItemUpdateStatus;
  sendItemUpdateStatus: PunchItemUpdateStatus;
};

const initialState: TPunchItemState = {
  displayStatus: undefined,
  isDisplayStatusLoading: false,
  activities: [],
  isActivitiesLoading: false,
  addingCommentStatus: 'IDLE',
  assignmentUpdateStatus: 'IDLE',
  sendItemUpdateStatus: 'IDLE'
};

@Injectable()
export class PunchItemState {
  private readonly _listStore = inject(PunchItemsListStore);
  private readonly _baseInfoProcore = inject(procoreBaseInfoPayload);
  private readonly _api = inject(ApiProcoreService);

  private readonly _activeItemState = signalState({
    ...initialState
  });

  readonly isLoading = computed(() => {
    return (
      this._activeItemState.isDisplayStatusLoading() ||
      this._listStore.isItemLoading()
    );
  });

  readonly punchItem = this._listStore.itemSelected;
  readonly punchItemStageId = computed(() => {
    const punchItem = this.punchItem();
    return punchItem.stageId;
  });

  readonly displayStatus = this._activeItemState.displayStatus;
  readonly activities = this._activeItemState.activities;
  readonly isActivitiesLoading = this._activeItemState.isActivitiesLoading;
  readonly addingCommentStatus = this._activeItemState.addingCommentStatus;
  readonly assignmentUpdateStatus =
    this._activeItemState.assignmentUpdateStatus;
  readonly sendItemUpdateStatus = this._activeItemState.sendItemUpdateStatus;

  patchItemState(
    patch: Partial<TPunchItemState> | PartialStateUpdater<TPunchItemState>
  ) {
    patchState(this._activeItemState, patch);
  }

  readonly fetchActivities = rxMethod<number>(
    switchMap((procorePunchItemId) =>
      this._fetchActivities$(procorePunchItemId)
    )
  );

  readonly fetchStatus = rxMethod<number>(
    switchMap((procorePunchItemId) => this._fetchStatus$(procorePunchItemId))
  );

  private readonly _loadStatus = rxMethod<ProcoreBaseProjectInfo>(
    switchMap(() =>
      this._listStore.getItemSelected$().pipe(
        filterTempPunchItem(),
        distinctUntilChanged((prev, curr) => prev.id === curr.id),
        tap(() => {
          patchState(this._activeItemState, { isDisplayStatusLoading: true });
        }),
        switchMap((punchItem) => {
          return this._fetchStatus$(punchItem.procoreId);
        })
      )
    )
  );

  private readonly _loadActivities = rxMethod<ProcoreBaseProjectInfo>(
    switchMap(() =>
      this._listStore.getItemSelected$().pipe(
        filterTempPunchItem(),
        distinctUntilChanged((prev, curr) => prev.id === curr.id),
        tap(() => {
          patchState(this._activeItemState, { isActivitiesLoading: true });
        }),
        switchMap((punchItem) => {
          return this._fetchActivities$(punchItem.procoreId);
        })
      )
    )
  );

  constructor() {
    const baseInfoProcore = this._baseInfoProcore();
    if (baseInfoProcore === undefined) {
      console.error('baseInfoProcore is undefined');
      return;
    }

    this._loadStatus(baseInfoProcore);
    this._loadActivities(baseInfoProcore);
  }

  deletePunchItem() {
    const baseInfoProcore = this._baseInfoProcore();
    const selectedItem = this._listStore.itemSelected();
    const displayStatus = this.displayStatus();
    if (!selectedItem || !displayStatus || baseInfoProcore === undefined)
      return;

    const { procoreProjectId, procoreCompanyId } = baseInfoProcore;
    this._listStore.deleteItem({
      procoreId: selectedItem.procoreId,
      procoreProjectId,
      procoreCompanyId,
      withActionNumber: displayStatus.showYellowBackground
    });
  }

  private _fetchStatus$(procoreId: number) {
    const baseInfoProcore = this._baseInfoProcore();
    if (baseInfoProcore === undefined) {
      console.error('baseInfoProcore is undefined');
      return EMPTY;
    }
    patchState(this._activeItemState, { isDisplayStatusLoading: true });

    const { procoreCompanyId, procoreProjectId } = baseInfoProcore;
    return this._api
      .getPunchItemDisplayElements({
        procoreCompanyId,
        procoreProjectId,
        punchItemId: procoreId
      })
      .pipe(
        tapResponse({
          next: (displayStatus) =>
            patchState(this._activeItemState, { displayStatus }),
          error: console.error,
          finalize: () =>
            patchState(this._activeItemState, { isDisplayStatusLoading: false })
        })
      );
  }

  private _fetchActivities$(procorePunchItemId: number) {
    const baseInfo = this._baseInfoProcore();
    if (baseInfo === undefined) {
      console.error('baseInfoProcore is undefined');
      return EMPTY;
    }

    const { procoreProjectId, procoreCompanyId } = baseInfo;

    patchState(this._activeItemState, { isActivitiesLoading: true });

    return this._api
      .getPunchItemActivities({
        procoreCompanyId,
        procoreProjectId,
        procorePunchItemId
      })
      .pipe(
        tapResponse({
          next: (activities) => {
            return patchState(this._activeItemState, { activities });
          },
          error: (e) => {
            console.error(e);
          },
          finalize: () =>
            patchState(this._activeItemState, {
              isActivitiesLoading: false
            })
        })
      );
  }

  createUpdateItemAssignmentPayload(
    updatePunchItemAssignment: UpdatePunchItemAssignment,
    uploadIds: string[]
  ): UpdatePunchItemAssignmentPayload {
    const { procoreProjectId, procoreCompanyId } = this._baseInfoProcore() ?? {
      procoreProjectId: 0,
      procoreCompanyId: 0
    };
    const { procoreId } = this.punchItem();
    const {
      comment,
      loginInformationId,
      punchItemAssignmentId,
      approved,
      status
    } = updatePunchItemAssignment;

    return {
      procorePunchItemId: procoreId,
      procoreProjectId,
      procoreCompanyId,
      punchItemAssignmentId,
      comment,
      uploadIds,
      loginInformationId,
      status,
      approved,
      sendEmails: true
    };
  }
}
