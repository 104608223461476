import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  computed,
  EventEmitter,
  input,
  Output
} from '@angular/core';
import { ActivatedRouteSnapshot } from '@angular/router';
import { StageComponent } from '@simlab/data-access';
import {
  ComponentsFacade,
  StagesFacade,
  StageWithCount
} from '@simlab/data-store';
import { DesignRadioAccordionModule } from '@simlab/design/accordion';
import { FormatDatePipe } from '@simlab/design/format-date';

import { UiDividerModule } from '@simlab/ui/divider';
import { UiIconModule } from '@simlab/ui/icon';
import { RouterFacadeService } from '@simlab/util-shared';
import {
  debounceTime,
  defer,
  filter,
  first,
  firstValueFrom,
  map,
  Observable,
  of,
  switchMap,
  tap
} from 'rxjs';
import { OtherComponentItemComponent } from '../other-component-item/other-component-item.component';

@Component({
  selector: 'feature-stages-stage-list-item',
  templateUrl: './stage-list-item.component.html',
  styleUrls: ['./stage-list-item.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    CommonModule,
    DesignRadioAccordionModule,
    UiIconModule,
    OtherComponentItemComponent,
    UiDividerModule,
    FormatDatePipe
  ]
})
export class StageListItemComponent {
  @Output() selected = new EventEmitter<string>();

  readonly item = input.required<StageWithCount>();
  readonly indexNumber = input.required<number>();

  protected readonly sequenceNumber = computed(
    () => this.item().sequenceNumber ?? 0
  );

  protected readonly name = computed(() => this.item().name);

  protected readonly utcDate = computed(() => this.item().stageDate);

  protected readonly annotations = computed(() => {
    const item = this.item();
    const notesCount = item.noteCounts?.totalRootNotesCount ?? 0;
    const rfiCount = item.rfiStatuses?.total ?? 0;
    const punchCount = item.punchItemCount ?? 0;

    return notesCount + rfiCount + punchCount;
  });

  private _openElement = false;
  public get openElement() {
    return this._openElement;
  }
  public set openElement(value) {
    this._openElement = value;
  }

  readonly components$ = defer(() =>
    this.componentsFacade.matterportComponentsForStage$(this.item().id)
  );
  readonly blueprints$ = defer(() =>
    this.componentsFacade.blueprintComponentsForStage$(this.item().id)
  );
  readonly componentsContainsElement$ = defer(() =>
    this.componentsFacade
      .matterportComponentsForStage$(this.item().id)
      .pipe(map((data) => (data !== undefined ? data.length > 0 : false)))
  );

  readonly stageId$: Observable<boolean> = defer(() =>
    this.stagesFacade.selectedId$.pipe(
      map((data) => {
        return data === this.item().id;
      })
    )
  );

  readonly selectedComponentId$ = this.componentsFacade.selectedComponentId$;

  constructor(
    private readonly stagesFacade: StagesFacade,
    private readonly componentsFacade: ComponentsFacade,
    private readonly routerFacade: RouterFacadeService
  ) {}
  trackByComponentId(idx: number, component: StageComponent) {
    return component.id;
  }
  changeVisibility(
    visible: boolean,
    id: string,
    blueprint: OtherComponentItemComponent
  ) {
    visible &&
      firstValueFrom(
        this.routerFacade.getNthRouterParam$(4).pipe(
          switchMap((activatedRoute: ActivatedRouteSnapshot | null) => {
            blueprint.disabled = true;
            if (
              !activatedRoute ||
              activatedRoute.routeConfig?.path !== 'matterport'
            )
              return of(true);
            return this.componentsFacade.blueprintLoaded$.pipe(
              filter((blueprintId: string) => blueprintId === id),
              first(),
              map(() => true)
            );
          }),
          debounceTime(300)
        )
      ).then(() => (blueprint.disabled = false));
    this.componentsFacade.componentVisibilityChange({ visible, id });
  }
  selectedComponent(componentId: string, index: number) {
    firstValueFrom(
      this.stagesFacade.selectedId$.pipe(
        tap((data) => {
          if (data !== this.item().id) {
            this.stagesFacade.setSelectedStageId(this.item().id);
          } else {
            this.componentsFacade.setSelectedComponentById(componentId);
            firstValueFrom(
              this.componentsFacade.getComponentsById$(componentId).pipe(
                tap((selectedComponent) => {
                  if (
                    selectedComponent !== undefined &&
                    !selectedComponent.sweepsUpdating
                  )
                    this.componentsFacade.checkTheSweepOnComponent(
                      selectedComponent
                    );
                })
              )
            );
          }
        })
      )
    );
  }
}
