import { computed, effect, inject } from '@angular/core';
import { takeUntilDestroyed, toObservable } from '@angular/core/rxjs-interop';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { FormatDateSignalPipe } from '@simlab/design/format-date';
import {
  ProcoreImpacts,
  PunchItem,
  PunchItemForm,
  PunchItemFormValue
} from '@simlab/procore/models';
import {
  punchItemDefaultFieldsetKeys,
  PunchItemtFieldsConfiguration
} from '@simlab/procore/shared/models';
import { filter, switchMap, tap } from 'rxjs';
import { PunchItemFormState } from '../../../../services/src/lib/punch-item-form.store';
import { TEMP_PUNCH_ITEM_ID } from '@simlab/procore/annotation-panel/data-access';
import {
  CostImpactForm,
  ScheduleImpactForm
} from '@simlab/procore/shared/models';
import { CommentWithFiles } from '@simlab/design/comment';

export abstract class PunchItemFormSidePanelBase {
  protected readonly state = inject(PunchItemFormState);

  private readonly _formatDateSignalPipe = inject(FormatDateSignalPipe);
  private readonly _config = this.state.defaultFieldsConfig;

  protected readonly formGroup = computed(() => {
    const config = this._config();
    if (!config) return;

    const formGroup = this._createBasicPunchItemFormGroup();
    this._applyFieldsetConfiguration(formGroup, config);
    return formGroup;
  });

  private readonly _formValueChangesObserver = toObservable(
    this.formGroup
  ).pipe(
    filter((formGroup) => !!formGroup),
    switchMap((formGroup) =>
      formGroup.valueChanges.pipe(
        tap((formValue) => this.state.updateFormValues(formValue))
      )
    ),
    takeUntilDestroyed()
  );

  constructor() {
    effect(() => {
      const punchItem = this.state.punchItem();
      const formGroup = this.formGroup();
      if (punchItem.id !== TEMP_PUNCH_ITEM_ID && formGroup) {
        const punchItemFormValues =
          this.convertPunchItemToFormValues(punchItem);
        formGroup.patchValue(punchItemFormValues, { emitEvent: true });
      }
    });

    this._formValueChangesObserver.subscribe();
  }

  protected convertPunchItemToFormValues(
    punchItem: PunchItem
  ): PunchItemFormValue {
    return {
      id: punchItem.id,
      private: punchItem.private,
      name: punchItem.name,
      position: punchItem.position || null,
      due: this._formatDateSignalPipe.transform(punchItem.dueDate),
      punchItemManagerId: punchItem.punchItemManager.id,
      finalApproverId: punchItem.finalApprover?.id,
      loginInformationIds: (punchItem.assignments || []).map(
        (assignment) => assignment.loginInformation.id
      ),
      locationId: punchItem.location,
      distributionMemberIds: (punchItem.distributionMembers || []).map(
        (member) => member.id
      ),
      priority: punchItem.priority || undefined,
      punchItemTypeId: punchItem.punchItemType?.id,
      scheduleImpact: {
        scheduleImpact: punchItem.scheduleImpact
          ? (punchItem.scheduleImpact as ProcoreImpacts)
          : null,
        scheduleImpactDays: punchItem.scheduleImpactDays ?? null
      },
      costImpact: {
        costImpact: punchItem.costImpact
          ? (punchItem.costImpact as ProcoreImpacts)
          : null,
        costImpactAmount: punchItem.costImpactAmount ?? null
      },
      costCodeId: punchItem.costCode?.id || undefined,
      tradeId: punchItem.trade?.id,
      reference: punchItem.reference,
      procoreId: punchItem.procoreId,
      description: {
        comment: punchItem.description,
        files: []
      }
    };
  }

  private _createBasicPunchItemFormGroup(): FormGroup<PunchItemForm> {
    return new FormGroup<PunchItemForm>({
      id: new FormControl(TEMP_PUNCH_ITEM_ID),
      private: new FormControl(false, {
        nonNullable: true
      }),
      name: new FormControl('', {
        nonNullable: true
      }),
      position: new FormControl(null),
      due: new FormControl(),
      punchItemManagerId: new FormControl(null),
      finalApproverId: new FormControl(null),
      loginInformationIds: new FormControl([], { nonNullable: true }),
      procoreId: new FormControl()
    });
  }

  private _applyFieldsetConfiguration(
    formGroup: FormGroup<PunchItemForm>,
    config: PunchItemtFieldsConfiguration
  ) {
    punchItemDefaultFieldsetKeys.forEach((fieldName) => {
      const fieldConfig = config.defaultFields[fieldName];
      if (!fieldConfig) {
        return;
      }

      const { visible, required, defaultValue } = fieldConfig;

      const existingControl = formGroup.get(fieldName);
      if (existingControl) {
        existingControl.addValidators(required ? [Validators.required] : []);
        return;
      }

      if (!visible) return;
      this._addOptionalControl(formGroup, fieldName, required, defaultValue);
    });
  }

  private _addOptionalControl(
    formGroup: FormGroup<PunchItemForm>,
    fieldName: string,
    required: boolean,
    defaultValue?: any
  ) {
    if (fieldName === 'costImpact') {
      this._addOptionalCostImpactForm(formGroup, required);
      return;
    }

    if (fieldName === 'scheduleImpact') {
      this._addOptionalScheduleImpactForm(formGroup, required);
      return;
    }

    if (fieldName === 'description') {
      // TODO (Lukasz O.): Split this control into two FormControls - description and attachments
      this._addOptionalDescriptionControl(formGroup);
      return;
    }

    const newControl = new FormControl();
    newControl.addValidators(required ? [Validators.required] : []);
    newControl.setValue(defaultValue || null);
    formGroup.addControl(fieldName as keyof PunchItemForm, newControl);
  }

  private _addOptionalCostImpactForm(
    formGroup: FormGroup<PunchItemForm>,
    required: boolean
  ) {
    const costImpactForm: FormGroup<CostImpactForm> = new FormGroup({
      costImpact: new FormControl<ProcoreImpacts | null>(null, {
        validators: required ? [Validators.required] : []
      }),
      costImpactAmount: new FormControl()
    });
    formGroup.addControl('costImpact', costImpactForm);
  }

  private _addOptionalScheduleImpactForm(
    formGroup: FormGroup<PunchItemForm>,
    required: boolean
  ) {
    const scheduleImpactFormForm: FormGroup<ScheduleImpactForm> = new FormGroup(
      {
        scheduleImpact: new FormControl<ProcoreImpacts | null>(null, {
          validators: required ? [Validators.required] : []
        }),
        scheduleImpactDays: new FormControl()
      }
    );
    formGroup.addControl('scheduleImpact', scheduleImpactFormForm);
  }

  private _addOptionalDescriptionControl(formGroup: FormGroup<PunchItemForm>) {
    formGroup.addControl(
      'description',
      new FormControl<CommentWithFiles | undefined>(
        {
          comment: '',
          files: []
        },
        { nonNullable: true }
      )
    );
  }
}
