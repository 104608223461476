import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  inject,
  ViewEncapsulation
} from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { Location } from '@simlab/data-access';
import { ProjectsFacade } from '@simlab/data-store';
import { DesignFlatButton, DesignStrokedButton } from '@simlab/design/button';

import { DesignCommonModule, DesignLabel } from '@simlab/design/common';
import { DesignDialogWrapperModule } from '@simlab/design/dialog';
import { DesignFormFieldModule } from '@simlab/design/form-field';
import { DesignInput } from '@simlab/design/input';
import { UiButtonModule } from '@simlab/ui/button';
import { UiDividerModule } from '@simlab/ui/divider';
import { UiFormFieldModule } from '@simlab/ui/form-field';
import { UiHintModule } from '@simlab/ui/hint';
import { UiIconModule } from '@simlab/ui/icon';
import { UiImageInfoModule } from '@simlab/ui/image-info';
import { UiInputModule } from '@simlab/ui/input';
import { API_GOOGLE, UiMapModule } from '@simlab/ui/map';
import { ConfirmationModalRef, MODAL_DATA } from '@simlab/ui/modal';
import { UiProgressSpinnerModule } from '@simlab/ui/progress-spinner';
import { UiTextareaModule } from '@simlab/ui/textarea';
import { UiUploadImageModule } from '@simlab/ui/upload-image';
import { Observable } from 'rxjs';
import { ProjectBase } from '../../models/project-base';
import { ProjectForm } from '../../models/project-form';
import { PipesModule } from '../../pipes/pipes.module';

@Component({
  selector: 'feature-projects-add-project-dialog',
  templateUrl: './add-project-dialog.component.html',
  styleUrls: ['./add-project-dialog.component.scss'],
  providers: [ProjectsFacade],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    CommonModule,
    DesignCommonModule,
    DesignDialogWrapperModule,
    UiButtonModule,
    UiIconModule,
    PipesModule,
    CommonModule,
    UiDividerModule,
    UiButtonModule,
    UiIconModule,
    UiMapModule,
    UiInputModule,
    UiHintModule,
    UiUploadImageModule,
    UiTextareaModule,
    UiFormFieldModule,
    FormsModule,
    ReactiveFormsModule,
    UiProgressSpinnerModule,
    UiImageInfoModule,
    DesignLabel,
    DesignFlatButton,
    DesignStrokedButton,
    DesignInput,
    DesignFormFieldModule
  ],
  encapsulation: ViewEncapsulation.None,
  host: {
    class: 'add-project-dialog'
  }
})
export class AddProjectDialogComponent extends ProjectBase {
  public readonly googleApi$ = inject<Observable<unknown>>(API_GOOGLE);
  private readonly organizationId = inject<string>(MODAL_DATA);
  private readonly modalRef = inject<ConfirmationModalRef<unknown>>(
    ConfirmationModalRef<unknown>
  );
  private readonly projectsFacade = inject(ProjectsFacade);

  cancel(): void {
    this.modalRef.emit({ state: false });
    this.modalRef.close();
  }

  setCoordinates(value: Location): void {
    this.address = value.address;
    this.geolocation = value.coordinates;
  }

  submitForm(): void {
    const newProject: ProjectForm = {
      name: this.nameControl?.value,
      description: this.descriptionControl?.value,
      address: this.addressControl?.value,
      thumbnail:
        !this.formGroup.value.imageData && this._initialThumbnail
          ? this.cleanUrl(this._initialThumbnail)
          : undefined,
      imageData: this.formGroup.value.imageData,
      geolocation: this.geolocationControl?.value,
      organizationId: this.organizationId
    };

    this.projectsFacade.createProject(newProject);
    this.modalRef.emit({
      state: true,
      result: this.projectsFacade.createdProject$
    });
    this.modalRef.close();
  }
}
