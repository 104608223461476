import {
  ChangeDetectionStrategy,
  Component,
  inject,
  OnDestroy
} from '@angular/core';
import { Router, RouterModule, Routes } from '@angular/router';
import { CURRENT_PROJECT_ID } from '@simlab/data-store';
import { canRenderProcoreEntity } from '@simlab/procore/data-access';
import { ProcoreAuthorizationService } from '@simlab/procore/services';
import { filter, map } from 'rxjs';
import {
  ProcoreProjectPermittedElements,
  ProcoreProjectPermittedElementsStates
} from '../../services/procore-permitted-document-elements.service';

export const canActivePermittedProcoreFeatures = () => {
  const permittedElements$ = inject(
    ProcoreProjectPermittedElements
  ).getProcoreProjectPermittedElements$();
  const router = inject(Router);
  const projectId = inject(CURRENT_PROJECT_ID);

  const noPermissionRoutePath = `/project/${projectId()}/documents/procore`;

  return permittedElements$.pipe(
    filter(
      (
        permittedElements
      ): permittedElements is ProcoreProjectPermittedElementsStates =>
        permittedElements !== undefined && permittedElements.state !== 'LOADING'
    ),
    map((permittedElements) => {
      if (permittedElements.state === 'NO_PERMISSIONS') {
        router.navigate([noPermissionRoutePath]);
        return false;
      }

      return true;
    })
  );
};

@Component({
  standalone: true,
  template: '<router-outlet />',
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [RouterModule]
})
export class ProcoreDocumentsComponent implements OnDestroy {
  private readonly _procorePermittedElements = inject(
    ProcoreProjectPermittedElements
  );

  ngOnDestroy(): void {
    this._procorePermittedElements.resetStore();
  }
}

export const documentRoutes: Routes = [
  {
    path: 'stages',
    loadComponent: () =>
      import('./stages-documents/stages-documents.component').then(
        (e) => e.StagesDocumentsComponent
      )
  },
  {
    path: 'procore',
    component: ProcoreDocumentsComponent,
    providers: [ProcoreAuthorizationService, ProcoreProjectPermittedElements],
    canMatch: [() => inject(ProcoreAuthorizationService).userConnected$],
    canActivate: [canRenderProcoreEntity],
    children: [
      {
        path: '',
        loadComponent: () =>
          import('./procore-wrapper/procore-wrapper.component').then(
            (e) => e.ProcoreWrapperComponent
          )
      },
      {
        path: 'documents',
        canActivate: [canActivePermittedProcoreFeatures],
        loadComponent: () =>
          import('./procore-documents/procore-documents.component').then(
            (e) => e.ProcoreDocumentsComponent
          )
      },
      {
        path: 'drawings',
        canActivate: [canActivePermittedProcoreFeatures],
        loadComponent: () =>
          import('./procore-drawings/procore-drawings.component').then(
            (e) => e.ProcoreDrawingsComponent
          )
      },
      {
        path: 'pictures',
        canActivate: [canActivePermittedProcoreFeatures],
        loadComponent: () =>
          import('./procore-pictures/procore-pictures.component').then(
            (e) => e.ProcorePicturesComponent
          )
      }
    ]
  },
  {
    path: 'procore',
    canActivate: [canRenderProcoreEntity],
    loadComponent: () =>
      import(
        './procore-not-connected-account/procore-not-connected-account.component'
      ).then((e) => e.ProcoreNotConnectedAccountComponent)
  },
  {
    path: '',
    redirectTo: 'stages',
    pathMatch: 'full'
  }
];
