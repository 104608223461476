import { ChangeDetectionStrategy, Component, computed } from '@angular/core';
import { ProcorePriorityLabelGetterPipe } from '@simlab/procore/pipes';
import { PunchItemStatusBadgeComponent } from '@simlab/procore/ui';
import { InfoItemContainerComponent } from '../info-item-container/info-item-container.component';
import { PunchItemPanelBaseDirective } from '../punch-item-panel-base/punch-item-panel.base';

@Component({
  selector: 'procore-punch-item-information-basic',
  standalone: true,
  imports: [
    PunchItemStatusBadgeComponent,
    ProcorePriorityLabelGetterPipe,
    InfoItemContainerComponent
  ],
  templateUrl: './punch-item-information-basic.component.html',
  styleUrl: './punch-item-information-basic.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PunchItemInformationBasicComponent extends PunchItemPanelBaseDirective {
  protected readonly showYellowBackground = computed(() => {
    const displayElements = this.displayElements();
    return displayElements?.showYellowBackground;
  });

  protected readonly trade = computed(() => {
    const trade = this.punchItem().trade;
    return trade ? trade.name : this.noValueStr;
  });

  protected readonly type = computed(() => {
    const type = this.punchItem().punchItemType;
    return type ? type.name : this.noValueStr;
  });

  protected readonly location = computed(() => {
    const location = this.punchItem().location;
    return location ? location.name : this.noValueStr;
  });

  protected readonly private = computed(() => {
    return this.punchItem().private
      ? $localize`:@@YES:Yes`
      : $localize`:@@NO:No`;
  });
}
