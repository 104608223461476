import { Pipe, PipeTransform } from '@angular/core';
import { ProcoreImpacts } from '@simlab/procore/models';

export const ProcoreImpactsLabel: Record<ProcoreImpacts, string> = {
  [ProcoreImpacts.Empty]: $localize`:@@PROCORE_IMPACTS_EMPTY:Empty`,
  [ProcoreImpacts.YesKnown]: $localize`:@@PROCORE_IMPACTS_YES_KNOWN:Yes`,
  [ProcoreImpacts.YesUnknown]: $localize`:@@PROCORE_IMPACTS_YES_UNKNOWN:Yes (unknown)`,
  [ProcoreImpacts.NoImpact]: $localize`:@@PROCORE_IMPACTS_NO:No`,
  [ProcoreImpacts.TBD]: $localize`:@@PROCORE_IMPACTS_TBD:To be determined`,
  [ProcoreImpacts.NA]: $localize`:@@PROCORE_IMPACTS_NA:n/a`,
};

@Pipe({ name: 'getProcoreImpactLabel', standalone: true })
export class ProcoreImpactLabelGetterPipe implements PipeTransform {
  transform(impact: ProcoreImpacts): string {
    return ProcoreImpactsLabel[impact];
  }
}
