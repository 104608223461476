import { inject, InjectionToken, Pipe, PipeTransform } from '@angular/core';
import { DesignSymbol } from '@simlab/design/internal';
import {
  ACCEPTED_AUDIO_TAG_EXTENSION_TOKEN,
  ACCEPTED_AUDIO_TAG_EXTENSION_TYPE,
  ACCEPTED_IMG_TAG_EXTENSION_TOKEN,
  ACCEPTED_IMG_TAG_EXTENSION_TYPE,
  ACCEPTED_VIDEO_TAG_EXTENSION_TOKEN,
  ACCEPTED_VIDEO_TAG_EXTENSION_TYPE,
} from '../data-access/tag-accepted-media';
import {
  DocumentTypeEnum,
  DocumentTypeEnumValues,
} from '../models/document-type.models';

type TAcceptableExtensions =
  | ACCEPTED_IMG_TAG_EXTENSION_TYPE[number]
  | ACCEPTED_VIDEO_TAG_EXTENSION_TYPE[number]
  | ACCEPTED_AUDIO_TAG_EXTENSION_TYPE[number];

const iconsStore: Record<DocumentTypeEnumValues, DesignSymbol> = {
  [DocumentTypeEnum.Unknown]: DesignSymbol.File,
  [DocumentTypeEnum.Image]: DesignSymbol.ImageFile,
  [DocumentTypeEnum.Video]: DesignSymbol.VideoFile,
  [DocumentTypeEnum.Audio]: DesignSymbol.AudioFile,
  [DocumentTypeEnum.Doc]: DesignSymbol.DocumentFile,
  [DocumentTypeEnum.Model3D]: DesignSymbol.File,
  [DocumentTypeEnum.Blueprint]: DesignSymbol.File,
};

export const attachmentIcon = new InjectionToken(
  'Attachment icon by filename or type',
  {
    providedIn: 'root',
    factory: () => {
      const acceptedImgTagExtension = inject(ACCEPTED_IMG_TAG_EXTENSION_TOKEN);
      const acceptedVideoTagExtension = inject(
        ACCEPTED_VIDEO_TAG_EXTENSION_TOKEN,
      );
      const acceptedAudioTagExtension = inject(
        ACCEPTED_AUDIO_TAG_EXTENSION_TOKEN,
      );

      const iconsByExtStore: Record<TAcceptableExtensions, DesignSymbol> = {
        ...inject(ACCEPTED_IMG_TAG_EXTENSION_TOKEN).reduce(
          (prev, act) => ({ ...prev, [act]: DesignSymbol.ImageFile }),
          {} as Record<TAcceptableExtensions, DesignSymbol>,
        ),
        ...inject(ACCEPTED_VIDEO_TAG_EXTENSION_TOKEN).reduce(
          (prev, act) => ({ ...prev, [act]: DesignSymbol.VideoFile }),
          {} as Record<TAcceptableExtensions, DesignSymbol>,
        ),
        ...inject(ACCEPTED_AUDIO_TAG_EXTENSION_TOKEN).reduce(
          (prev, act) => ({ ...prev, [act]: DesignSymbol.AudioFile }),
          {} as Record<TAcceptableExtensions, DesignSymbol>,
        ),
      };

      const isAcceptableExtension = (
        arg: string,
      ): arg is TAcceptableExtensions => {
        const values: string[] = [
          ...acceptedImgTagExtension,
          ...acceptedVideoTagExtension,
          ...acceptedAudioTagExtension,
        ];
        return values.includes(arg);
      };

      return {
        getAttachmentIconByFilenameOrType: (
          filename: string,
          type?: DocumentTypeEnumValues,
        ) => {
          let icon = iconsStore['0'];
          const ext: string = filename.split('.').pop() ?? '';
          if (type && ext !== 'pdf') {
            icon = iconsStore[type];
          } else if (ext === 'pdf') {
            return DesignSymbol.PictureAsPdf;
          } else if (ext && isAcceptableExtension(ext)) {
            icon = iconsByExtStore[ext];
          }

          return icon;
        },
      };
    },
  },
);

@Pipe({ name: 'getAttachmentIcon', standalone: true })
export class AttachmentIconGetterPipe implements PipeTransform {
  private readonly _attachmentIcon = inject(attachmentIcon);

  transform(filename: string, type?: any): string {
    return this._attachmentIcon.getAttachmentIconByFilenameOrType(
      filename,
      type,
    );
  }
}
