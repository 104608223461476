import { ChangeDetectionStrategy, Component } from '@angular/core';
import { ImageInfoComponent } from '@simlab/ui/image-info';

const DESCRIPTION_IMAGE_INFO: string[] = [
  $localize`:@@PAGE_NOT_FOUND_INFO_1:The page that you're looking for could not be found`
];

@Component({
    selector: 'simlab-not-found',
    templateUrl: './not-found.component.html',
    styleUrls: ['./not-found.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    imports: [ImageInfoComponent]
})
export class NotFoundComponent {
  readonly descriptionImageInfo = DESCRIPTION_IMAGE_INFO;
}
