import {
  PunchItem,
  PunchItemList,
  PunchListWorkflowStatus
} from '@simlab/procore/models';

export const TEMP_PUNCH_ITEM_ID = 'TEMP_PUNCH_ITEM_ID';

export const EMPTY_SELECTED_PUNCH_ITEM: PunchItemList = {
  id: TEMP_PUNCH_ITEM_ID,
  procoreId: 0,
  procoreCreatorId: 0,
  stageId: '',
  position: 0,
  name: '',
  workflowStatus: PunchListWorkflowStatus.Draft,
  ballInCourts: [],
  updatedAt: '',
  marker: undefined,
  showYellowBackground: false,
  dueDate: ''
};

export const EMPTY_PUNCH_ITEM: PunchItem = {
  id: TEMP_PUNCH_ITEM_ID,
  stageId: '',
  attachments: [],
  assignments: [],
  assignees: [],
  ballInCourt: [],
  showYellowBackground: true,
  link: '',
  costCode: {
    id: 0,
    name: '',
    fullCode: ''
  },
  costImpact: 0,
  costImpactAmount: 0,
  canEmail: false,
  comments: [],
  currentDrawingRevisionIds: [],
  createdAt: '',
  createdBy: {
    id: 0,
    login: '',
    name: '',
    companyName: ''
  },
  customFields: {
    additionalProp1: {
      dataType: '',
      value: ''
    },
    additionalProp2: {
      dataType: '',
      value: ''
    },
    additionalProp3: {
      dataType: '',
      value: ''
    }
  },
  deletedAt: '',
  description: '',
  dueDate: '',
  drawingIds: [],
  drawingRevisions: [],
  hasAttachments: false,
  hasResolvedResponses: false,
  hasUnresolvedResponses: false,
  closedAt: '',
  closedBy: {
    id: 0,
    login: '',
    name: '',
    companyName: ''
  },
  marker: undefined,
  location: {
    id: 0,
    name: '',
    nodeName: '',
    parentId: 0,
    createdAt: '',
    updatedAt: ''
  },
  distributionMembers: [],
  trade: {
    id: 0,
    name: '',
    active: false,
    updatedAt: ''
  },
  reference: '',
  finalApprover: {
    id: 0,
    login: '',
    name: '',
    companyName: ''
  },
  name: '',
  position: 0,
  priority: null,
  private: false,
  procoreId: 0,
  punchItemManager: {
    id: 0,
    login: '',
    name: '',
    companyName: ''
  },
  punchItemType: {
    id: 0,
    name: ''
  },
  scheduleImpact: 0,
  scheduleImpactDays: 0,
  status: 1,
  updatedAt: '',
  workflowStatus: 1,
  richTextDescription: '',
  itemUrl: ''
};
