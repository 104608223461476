
import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { DesignDialogWrapperModule } from '@simlab/design/dialog';
import { UiButtonModule } from '@simlab/ui/button';
import { UiIconModule } from '@simlab/ui/icon';
import { ConfirmationModalRef } from '@simlab/ui/modal';
import { ENVIRONMENT_CONFIG, Environment } from '@simlab/util-shared';

@Component({
    imports: [
    DesignDialogWrapperModule,
    UiButtonModule,
    UiIconModule
],
    templateUrl: './tag-importer-private-information.component.html',
    styleUrls: ['./tag-importer-private-information.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class TagImporterPrivateInformationComponent {
  constructor(
    private readonly modalRef: ConfirmationModalRef<TagImporterPrivateInformationComponent>,
    @Inject(ENVIRONMENT_CONFIG) readonly environment: Environment
  ) {}

  confirm(): void {
    this.modalRef.emit({ state: true });
    this.modalRef.close();
  }
  contactUs() {
    window.open(this.environment.configuration.contactPage, '_blank');
  }
}
