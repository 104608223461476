import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  OnDestroy,
  OnInit,
  Output,
  ViewEncapsulation
} from '@angular/core';
import { ReactiveFormsModule, UntypedFormControl } from '@angular/forms';
import { DesignIconButton } from '@simlab/design/button';
import { DesignIcon } from '@simlab/design/icon';
import { UiMenuPanelModule } from '@simlab/design/menu-panel';
import { UiChipsModule } from '@simlab/ui/chips';
import { UiInputModule } from '@simlab/ui/input';
import { Subject, debounceTime, distinctUntilChanged, takeUntil } from 'rxjs';
export const REFRESH_FILTER_DEBOUNCE = 300;
export const COLORS = [
  '#d44441',
  '#f44336',
  '#e91e63',
  '#f78da7',
  '#9c4b92',
  '#673ab7',
  '#03687d',
  '#03a9f4',
  '#417505',
  '#00bcd4',
  '#51a868',
  '#37d67a',
  '#cddc39',
  '#fbcd00',
  '#ffac17',
  '#ff6900',
  '#abb8c3',
  '#607d8b'
];

export type SelectedColors = { [color: string]: boolean };
@Component({
    selector: 'feature-stages-tag-filters',
    templateUrl: './tag-filters.component.html',
    styleUrls: ['./tag-filters.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    encapsulation: ViewEncapsulation.None,
    imports: [
        CommonModule,
        UiInputModule,
        UiMenuPanelModule,
        UiChipsModule,
        ReactiveFormsModule,
        DesignIconButton,
        DesignIcon
    ]
})
export class TagFiltersComponent implements OnInit, OnDestroy {
  @Output() selectedColorsChange: EventEmitter<SelectedColors> =
    new EventEmitter<SelectedColors>();

  @Output() searchFilterChange: EventEmitter<string> =
    new EventEmitter<string>();
  readonly searchControl: UntypedFormControl = new UntypedFormControl('');
  readonly colors = COLORS;
  readonly selectedColors: SelectedColors = {};
  private _destroySource: Subject<void> = new Subject<void>();

  select(color: string) {
    this.selectedColors[color] = true;
    this.selectedColorsChange.emit(this.selectedColors);
  }

  clearAllSelected() {
    Object.keys(this.selectedColors).forEach((key: string) => {
      delete this.selectedColors[key];
    });
    this.selectedColorsChange.emit(this.selectedColors);
  }
  removeSelected(key: string) {
    delete this.selectedColors[key];
    console.log(this.selectedColors);
    this.selectedColorsChange.emit(this.selectedColors);
  }

  ngOnInit(): void {
    this._searchTextChangeListener();
  }
  private _searchTextChangeListener() {
    this.searchControl.valueChanges
      .pipe(
        takeUntil(this._destroySource),
        debounceTime(REFRESH_FILTER_DEBOUNCE),
        distinctUntilChanged()
      )
      .subscribe((value) => {
        this.searchFilterChange.emit(value);
      });
  }
  ngOnDestroy(): void {
    this._destroySource.next();
    this._destroySource.complete();
  }
}
