import { A11yModule } from '@angular/cdk/a11y';
import { CommonModule } from '@angular/common';
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  OnDestroy,
  TemplateRef,
  ViewChild
} from '@angular/core';
import {
  FormsModule,
  ReactiveFormsModule,
  UntypedFormControl,
  UntypedFormGroup,
  Validators
} from '@angular/forms';
import { ApolloQueryResult } from '@apollo/client';
import { ApiFacadeService } from '@simlab/data-access';
import {
  DesignFlatButtonModule,
  DesignStrokedButton
} from '@simlab/design/button';
import { DesignCommonModule } from '@simlab/design/common';
import { DesignDialogWrapperModule } from '@simlab/design/dialog';
import {
  DesignFormField,
  DesignFormFieldModule
} from '@simlab/design/form-field';
import { DesignInput } from '@simlab/design/input';
import { UiListOption, UiSelectionList } from '@simlab/design/select-list';
import {
  MatterportApiFacadeService,
  MatterportAuthorization,
  MatterportAuthorizationResponse,
  ModelSearchResult,
  ModelsQuery
} from '@simlab/matterport/api';
import { UiButtonModule } from '@simlab/ui/button';
import { UiDividerModule } from '@simlab/ui/divider';
import { UiIconModule } from '@simlab/ui/icon';
import { ConfirmationModalRef } from '@simlab/ui/modal';
import { UiSelectModule } from '@simlab/ui/select';
import {
  BehaviorSubject,
  Observable,
  Subject,
  debounceTime,
  defer,
  distinctUntilChanged,
  iif,
  map,
  mergeMap,
  of,
  startWith,
  switchMap,
  takeUntil,
  tap
} from 'rxjs';
import { AddComponentService } from '../../services/add-component.service';
import { validateShowcaseUrl } from '../../validators/showcase-url-validator';
import {
  ModelListItem,
  ModelListItemModule
} from '../model-list-item/model-list-item.component';

export declare type AuthorizationResult =
  | MatterportAuthorizationResponse<MatterportAuthorization>
  | undefined;

export type SORT_OPTIONS = 'name' | 'date';
export type UPDATE_OPTIONS = 'search' | 'sort';

@Component({
    selector: 'feature-stages-add-component-dialog',
    templateUrl: './add-component-dialog.component.html',
    styleUrls: ['./add-component-dialog.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [AddComponentService],
    imports: [
        FormsModule,
        ReactiveFormsModule,
        A11yModule, //NOTE: focusTrap
        UiSelectionList,
        UiListOption,
        UiButtonModule,
        UiIconModule,
        UiDividerModule,
        UiSelectModule,
        ModelListItemModule,
        CommonModule,
        DesignCommonModule,
        DesignDialogWrapperModule,
        DesignFormFieldModule,
        DesignFormField,
        A11yModule,
        DesignInput,
        UiButtonModule,
        UiIconModule,
        DesignStrokedButton,
        DesignFlatButtonModule
    ]
})
export class AddComponentDialogComponent implements AfterViewInit, OnDestroy {
  private readonly _destroySource: Subject<void> = new Subject<void>();
  private readonly _returnSource: Subject<void> = new Subject<void>();
  private readonly _refresh = new Subject();
  windowView: 'base' | 'models' = 'base';

  modelSearchPhrase: BehaviorSubject<string> = new BehaviorSubject<string>('');
  modelSearchPhrase$: Observable<string> =
    this.modelSearchPhrase.asObservable();

  sortModel: SORT_OPTIONS | undefined = undefined;
  _offset: BehaviorSubject<string | undefined> = new BehaviorSubject<
    string | undefined
  >(undefined);
  _offset$: Observable<string | undefined> = this._offset.asObservable();

  @ViewChild('mainWindowTemplate')
  mainWindowTemplate!: TemplateRef<HTMLElement>;

  modeSelect: 'public' | 'private' = 'public';
  itemTemplate!: TemplateRef<HTMLElement>;

  readonly componentForm: UntypedFormGroup = new UntypedFormGroup({
    name: new UntypedFormControl('', [
      Validators.required,
      Validators.minLength(3)
    ]),
    componentUrl: new UntypedFormControl('', [
      Validators.required,
      validateShowcaseUrl
    ])
  });

  readonly externalApiAuth$ = defer(() =>
    this.matterportApiFacadeService.authorization$()
  ).pipe(
    takeUntil(this._destroySource),
    switchMap((result: AuthorizationResult) =>
      iif(
        () => result?.status === 'SUCCESS' && !!result?.response?.authCode,
        this.apiFacadeService.tokens
          .addMatterportUserToken({
            code: <string>result?.response?.authCode
          })
          .pipe(
            switchMap((token: string) => {
              return this.matterportApiFacadeService
                .clearCache$()
                .pipe(map(() => token));
            }),

            tap((token: string) => {
              this._reset();

              this.matterportApiFacadeService.setMatterportToken(token);
              this.windowView = 'models';
              this.changeDetectorRef.markForCheck();
            })
          ),
        of(false)
      )
    )
  );

  _buffer: Array<ModelListItem> = [];

  readonly filteredModelsWithSteroids$ = defer(() =>
    this.modelSearchPhrase$.pipe(
      debounceTime(300),
      startWith(''),
      distinctUntilChanged(),
      switchMap((searchPhrase) => {
        console.log(searchPhrase);
        return this.modelsWithSteroids$.pipe(
          map((models: ModelListItem[]) => {
            return models.filter((model: ModelListItem) =>
              model.name.toLowerCase().includes(searchPhrase.toLowerCase())
            );
          })
        );
      })
    )
  );

  readonly modelsWithSteroids$: Observable<ModelListItem[]> = defer(() =>
    this._offset$.pipe(takeUntil(this._returnSource)).pipe(
      switchMap((offset) => this.models$(undefined, offset)),
      tap(
        (result: {
          nextOffset: string | undefined;
          items: Array<ModelListItem>;
        }) => {
          console.log('result.items', result.items);
          this._buffer = this._buffer.concat(result.items);

          if (result?.nextOffset) {
            this._offset.next(result?.nextOffset);
          }
        }
      ),
      map(() => this._buffer)
    )
  );

  readonly models$ = (
    query?: string | undefined,
    offset?: string | undefined
  ): Observable<{
    nextOffset: string | undefined;
    items: Array<ModelListItem>;
  }> =>
    this.matterportApiFacadeService.getModels$(query, offset).pipe(
      mergeMap((queryResult: ApolloQueryResult<ModelsQuery>) =>
        iif(
          () => !!queryResult.data,
          of(
            (queryResult.data.models?.results ?? []) as Array<ModelSearchResult>
          ),
          of([] as Array<ModelSearchResult>)
        ).pipe(
          map((elements: Array<ModelSearchResult>) => ({
            nextOffset: queryResult.data.models?.nextOffset,
            items: elements.map(
              (element: ModelSearchResult) =>
                <ModelListItem>{
                  id: element.id,
                  name: element.name,
                  created: element.created,
                  image: element.image?.resizeUrl,
                  address: element.address?.address
                }
            )
          }))
        )
      )
    );

  constructor(
    private readonly changeDetectorRef: ChangeDetectorRef,
    private readonly apiFacadeService: ApiFacadeService,
    private readonly matterportApiFacadeService: MatterportApiFacadeService,
    private readonly modelRef: ConfirmationModalRef<{
      name: string;
      componentUrl: string;
      modeSelect: string;
    }>
  ) {}

  ngOnDestroy(): void {
    this._destroySource.next();
    this._destroySource.complete();

    this._returnSource.next();
    this._returnSource.complete();

    this._offset.complete();
    this.modelSearchPhrase.complete();
  }

  confirm() {
    if (!this.componentForm.valid) {
      return;
    }

    this.modelRef.emit({
      state: true,
      result: { ...this.componentForm.value, modeSelect: this.modeSelect }
    });
    this.modelRef.close();
  }

  close() {
    this.modelRef.emit({ state: false });
    this.modelRef.close();
  }

  private _reset() {
    this._buffer = [];
    this._offset.next(undefined);
    this.modelSearchPhrase.next('');

    this._returnSource.next();
  }

  return() {
    // this._buffer = [];
    // this._offset.next(undefined);
    // this.modelSearchPhrase.next('');

    // this._returnSource.next();
    this._reset();
    this.windowView = 'base';
    this.changeDetectorRef.markForCheck();
  }

  ngAfterViewInit(): void {
    this.itemTemplate = this.mainWindowTemplate;
    this.changeDetectorRef.detectChanges();
    this.componentForm
      .get('componentUrl')
      ?.valueChanges.pipe(
        tap((value: string) => {
          if (value.includes(' ')) {
            this.componentForm.get('componentUrl')?.patchValue(value.trim());
          }
        }),
        takeUntil(this._destroySource)
      )
      .subscribe();
  }

  trackByModelId(index: number, model: ModelListItem): string {
    return model.id;
  }

  updateFormGroup(listItems: ModelListItem[]) {
    this.componentForm.patchValue({
      name: listItems[0].name,
      componentUrl: `https://my.matterport.com/show/?m=${listItems[0].id}`
    });
  }
}
