/* eslint-disable @angular-eslint/no-inputs-metadata-property */
import { Directive } from '@angular/core';
import { mixinDisabled } from '@simlab/design/internal';

// Boilerplate for applying mixins to UiListBase.
const _UiListBase = mixinDisabled(class {});

@Directive({
    host: {
        '[class.ui-list--disabled]': 'disabled',
        '[attr.aria-disabled]': 'disabled',
    },
    inputs: ['disabled'],
    standalone: false
})
export abstract class UiListBase extends _UiListBase {}
