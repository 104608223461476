export const ProcoreImpacts = {
  Empty: 0,
  YesKnown: 1,
  YesUnknown: 2,
  NoImpact: 3,
  TBD: 4,
  NA: 5
} as const;
export type ProcoreImpacts =
  (typeof ProcoreImpacts)[keyof typeof ProcoreImpacts];

export const ProcorePriorities = {
  Low: 1,
  Medium: 2,
  High: 3
} as const;
export type ProcorePriorities =
  (typeof ProcorePriorities)[keyof typeof ProcorePriorities];

export const PunchListWorkflowStatus = {
  Draft: 1,
  Initiated: 2,
  InDispute: 3,
  WorkRequired: 4,
  ReadyForReview: 5,
  WorkNotAccepted: 6,
  ReadyToClose: 7,
  NotAcceptedByCreator: 8,
  Closed: 9
} as const;
export type PunchListWorkflowStatus =
  (typeof PunchListWorkflowStatus)[keyof typeof PunchListWorkflowStatus];

export const PunchListStatus = {
  Open: 1,
  Closed: 2,
  Overdue: 3,
  Pending: 4
} as const;
export type PunchListStatus =
  (typeof PunchListStatus)[keyof typeof PunchListStatus];

export const ProcorePunchItemStatuses = {
  Draft: 'draft',
  Initiated: 'initiated',
  InDispute: 'in_dispute',
  WorkRequired: 'work_required',
  ReadyForReview: 'ready_for_review',
  WorkNotAccepted: 'work_not_accepted',
  ReadyToClose: 'ready_to_close',
  NotAcceptedByCreator: 'not_accepted_by_creator',
  Closed: 'closed'
} as const;
export type ProcorePunchItemStatusesKeys =
  keyof typeof ProcorePunchItemStatuses;
export type ProcorePunchItemStatusesValues =
  (typeof ProcorePunchItemStatuses)[ProcorePunchItemStatusesKeys];

export const ProcorePunchItemStatusesRecord: Record<
  ProcorePunchItemStatusesKeys,
  string
> = {
  Draft: $localize`:@@DRAFT:Draft`,
  Initiated: $localize`:@@INITIATED:Initiated`,
  InDispute: $localize`:@@IN_DISPUTE:In Dispute`,
  WorkRequired: $localize`:@@WORK_REQUIRED:Work Required`,
  ReadyForReview: $localize`:@@READY_FOR_REVIEW:Ready For Review`,
  WorkNotAccepted: $localize`:@@WORK_NOT_ACCEPTED:Work Not Accepted`,
  NotAcceptedByCreator: $localize`:@@NOT_ACCEPTED:Not Accepted`,
  ReadyToClose: $localize`:@@READY_TO_CLOSE:Ready To Close`,
  Closed: $localize`:@@CLOSED:Closed`
};

export const PunchItemAssignmentStatus = {
  Unresolved: 1,
  ReadyForReview: 2,
  WorkNotAccepted: 3,
  Resolved: 4
} as const;

export type PunchItemAssignmentStatus =
  (typeof PunchItemAssignmentStatus)[keyof typeof PunchItemAssignmentStatus];
