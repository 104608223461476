import { computed } from '@angular/core';
import {
  patchState,
  signalStore,
  withComputed,
  withMethods,
  withState
} from '@ngrx/signals';
import { UserElementFilterExtended } from '@simlab/annotations-filters/models';

export type Filters = {
  authors: string[];
  stakeholders: string[];
  dateCreation: {
    from: string | Date | undefined;
    to: string | Date | undefined;
  };
  dateModification: {
    from: string | Date | undefined;
    to: string | Date | undefined;
  };
  stagesRange: string[];
};

export type GlobalFiltersState = {
  filter: {
    authors: UserElementFilterExtended[];
    stakeholders: UserElementFilterExtended[];
    dateCreation: Partial<{
      from: string | Date | undefined;
      to: string | Date | undefined;
    }>;
    dateModification: Partial<{
      from: string | Date | undefined;
      to: string | Date | undefined;
    }>;
    stagesRange: string[];
  };
};

const filtersLength = (data: Partial<GlobalFiltersState['filter']>) => {
  return (
    (data.authors?.length ?? 0) +
    (data.stakeholders?.length ?? 0) +
    (data.stagesRange?.length ?? 0) +
    Number(!!(data.dateCreation?.from && data.dateCreation?.to)) +
    Number(!!(data.dateModification?.from && data.dateModification?.to))
  );
};

const areActiveGlobalFilters = (
  data: Partial<GlobalFiltersState['filter']>
) => {
  const { authors, stakeholders, dateCreation, dateModification, stagesRange } =
    data;

  return (
    !!authors?.length ||
    !!stakeholders?.length ||
    !!dateCreation?.from ||
    !!dateCreation?.to ||
    !!dateModification?.from ||
    !!dateModification?.to ||
    !!stagesRange?.length
  );
};

const initialState: GlobalFiltersState = {
  filter: {
    authors: [],
    stakeholders: [],
    dateCreation: {
      from: undefined,
      to: undefined
    },
    dateModification: {
      from: undefined,
      to: undefined
    },
    stagesRange: []
  }
};

export const GlobalFiltersStore = signalStore(
  withState(initialState),
  withMethods((store) => ({
    resetFilters(): void {
      patchState(store, () => ({
        ...initialState
      }));
    },
    setFilters(newFilter: Partial<GlobalFiltersState['filter']>): void {
      patchState(store, ({ filter }) => ({
        filter: { ...filter, ...newFilter }
      }));
    }
  })),
  withComputed(({ filter }) => ({
    filtersLength: computed(() => filtersLength(filter())),
    hasActiveStakeholders: computed(() => !!filter().stakeholders.length),
    hasActiveAuthors: computed(() => !!filter().authors.length),
    areFiltersActive: computed(() => areActiveGlobalFilters(filter())),

    hasOnlySelectedAuthorFromStages: computed(() => {
      const { authors } = filter();

      return (
        !!authors.length &&
        authors.every(({ procoreUserId }) => procoreUserId === undefined)
      );
    }),
    hasOnlySelectedStakeHoldersFromStages: computed(() => {
      const { stakeholders } = filter();

      return (
        !!stakeholders.length &&
        stakeholders.every(({ procoreUserId }) => procoreUserId === undefined)
      );
    }),
    hasOnlySelectedAuthorFromProcore: computed(() => {
      const { authors } = filter();

      return !!authors.length && authors.every(({ id }) => id === undefined);
    }),
    hasOnlySelectedStakeHoldersFromProcore: computed(() => {
      const { stakeholders } = filter();

      return (
        !!stakeholders.length &&
        stakeholders.every(({ id }) => id === undefined)
      );
    })
  }))
);
