import { PortalModule } from '@angular/cdk/portal';
import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  ViewEncapsulation,
  inject,
} from '@angular/core';

import { DesignIcon } from '@simlab/design/icon';
import { ToastAnimation } from '../animation/toast-animation';
import { ToastConfig, ToastType } from '../models/toast-config';
import { ToastContainerBase } from './toast-container-base.directive';

type ToastText = { [key in ToastType]: string };

const TYPE_TEXT: ToastText = {
  Error: $localize`:@@ERROR_TYPE_TOAST: Error `,
  Info: $localize`:@@INFO_TYPE_TOAST: Info `,
  Warning: $localize`:@@WARNING_TYPE_TOAST: Warning `,
  Success: $localize`:@@SUCCESS:Success`,
  Pending: $localize`:@@PENDING_TYPE_TOAST: Pending `,
};

@Component({
    selector: 'design-toast',
    template: `
    <div class="design-toast__wrapper">
      <i [designIcon]="icon"></i>
      <div class="overflow-text" data-cy="toast-message">
        <strong *ngIf="showTypeToast">{{ typeToast }}</strong>
        {{ message }}
      </div>
      <ng-container *ngIf="includeCloseButton">
        <i class="close" designIcon="cross" (click)="close()"></i>
      </ng-container>
    </div>
  `,
    styleUrls: ['./toast.component.scss'],
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
    imports: [PortalModule, CommonModule, DesignIcon],
    animations: [ToastAnimation],
    // eslint-disable-next-line @angular-eslint/no-host-metadata-property
    host: {
        '(@toastAnimation.done)': 'captureDoneEvent($event)',
        '(mouseover)': 'mouseOver()',
        '(mouseout)': 'mouseOut()',
    }
})
export class ToastComponent extends ToastContainerBase {
  public toastConfig = inject(ToastConfig);
  readonly typeText: ToastText = TYPE_TEXT;

  get typeToast(): string {
    return this.typeText[this.toastConfig.toastType];
  }

  get showTypeToast(): boolean {
    return this.toastConfig.showType;
  }

  get includeCloseButton(): boolean {
    return !!this.toastConfig.closeButton;
  }

  get message(): string {
    return this.toastConfig.message;
  }

  get icon(): string {
    return this.toastConfig.toastType === 'Pending'
      ? 'rotate'
      : this.toastConfig.toastType === 'Info'
        ? 'info_first'
        : this.toastConfig.toastType;
  }
}
