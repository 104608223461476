/* eslint-disable @angular-eslint/no-inputs-metadata-property */
import { NgClass, NgTemplateOutlet } from '@angular/common';
import {
  booleanAttribute,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  input,
  Input,
  TemplateRef,
  viewChild,
  ViewEncapsulation,
} from '@angular/core';
import { CanColor, mixinColor } from '@simlab/design/internal';

// Boilerplate for applying mixins to UiButton.
const _UiTooltipBase = mixinColor(
  class {
    constructor(public _elementRef: ElementRef) {}
  },
);

export type UiTooltipPosition = 'top' | 'right' | 'bottom' | 'left';

@Component({
    selector: 'design-tooltip',
    imports: [NgClass, NgTemplateOutlet],
    templateUrl: './ui-tooltip.component.html',
    styleUrls: ['./ui-tooltip.component.scss'],
    host: {
        class: 'ui-tooltip ui-tooltip-position-bottom',
        '[attr.tabindex]': '0 ',
    },
    inputs: ['color'],
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush
})
// eslint-disable-next-line @angular-eslint/component-class-suffix
export class UiTooltip extends _UiTooltipBase implements CanColor {
  @Input()
  set tooltip(value: string | TemplateRef<any>) {
    this._hasEmbeddedView = value instanceof TemplateRef;
    this._uiTooltip = value;
  }

  get text(): string {
    return <string>this._uiTooltip;
  }

  get template(): TemplateRef<any> {
    return <TemplateRef<any>>this._uiTooltip;
  }

  private _uiTooltip!: string | TemplateRef<any>;

  get hasEmbeddedView(): boolean {
    return this._hasEmbeddedView;
  }
  private _hasEmbeddedView = false;

  @Input()
  set position(value: UiTooltipPosition) {
    const cachedPosition = value;

    if (cachedPosition !== this._position) {
      if (this._position) {
        this._elementRef.nativeElement.classList.remove(
          `ui-tooltip-position-${this._position}`,
        );
      }

      if (cachedPosition) {
        this._elementRef.nativeElement.classList.add(
          `ui-tooltip-position-${cachedPosition}`,
        );
      }

      this._position = cachedPosition;
    }
  }
  _position: UiTooltipPosition = 'bottom';

  readonly customClass = input<string | undefined>(undefined);
  readonly disabled = input(false, { transform: booleanAttribute });

  set focused(value: boolean) {
    if (value !== this._focused) {
      this._focused = value;
      this._changeDetectorRef.markForCheck();
    }
  }

  _focused = false;

  readonly tooltipWrapper =
    viewChild.required<ElementRef<HTMLDivElement>>('tooltipWrapper');

  constructor(
    readonly elementRef: ElementRef,
    private readonly _changeDetectorRef: ChangeDetectorRef,
  ) {
    super(elementRef);
  }
}
