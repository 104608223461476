Object.defineProperty(Object.prototype, 'omitProperty', {
  value: function <T extends Record<string, any>, K extends keyof T>(
    this: T,
    ...keys: K[]
  ): Omit<T, K> {
    return Object.keys(this).reduce(
      (acc, key) => {
        if (!keys.includes(key as K)) {
          acc[key as keyof Omit<T, K>] = this[key];
        }
        return acc;
      },
      {} as Omit<T, K>
    );
  },
  writable: false,
  enumerable: false,
  configurable: false
});

export {};

declare global {
  interface Object {
    omitProperty<T extends Record<string, any>, K extends keyof T>(
      this: T,
      ...keys: K[]
    ): Omit<T, K>;
  }
}
