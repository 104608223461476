<design-dialog-wrapper cdkTrapFocus>
  <ng-container designDialogHeader>
    <div class="header">
      <h2 designLabel>
        {{ name }}
      </h2>
    </div>
  </ng-container>

  <div
    class="assignee-response__content"
    designDialogContent
    [formGroup]="formGroup"
  >
    <design-radio-group formControlName="status">
      @for (radioButton of radioButtons; track radioButton) {
        <design-radio-button [value]="radioButton">
          <label designLabel>{{
            radioButton | getPunchItemAssignmentStatusLabel
          }}</label>
        </design-radio-button>
      }
    </design-radio-group>

    <design-comment formControlName="comment" [isEditable]="true" />
  </div>

  <div designDialogFooter class="assignee-response__footer">
    <button
      design-stroked-button
      designButtonColor="primary"
      (click)="cancelDialog()"
      [disabled]="isPending()"
    >
      <div i18n="@@CANCEL">Cancel</div>
    </button>
    <button
      design-flat-button
      designButtonColor="accent"
      [disabled]="isPending()"
      [designFireOnClick]="save$"
    >
      <div i18n="@@SAVE">Save</div>
      <design-button-loader
        designPrefix
        designColor="accent"
      ></design-button-loader>
    </button>
  </div>
</design-dialog-wrapper>
