import { NgClass, NgTemplateOutlet } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  computed,
  inject,
  input,
  output,
  ViewEncapsulation,
} from '@angular/core';
import { Router, RouterModule } from '@angular/router';
import { DesignIconButton } from '@simlab/design/button';
import { DesignIcon } from '@simlab/design/icon';
import { DesignSymbol } from '@simlab/design/internal';
import { UiMenuPanelModule } from '@simlab/design/menu-panel';

export interface DtoNameId {
  id: string;
  name: string;
}

export type BasicPathItem = {
  id: string | number | null;
  name: string;
  icon?: DesignSymbol;
};

@Component({
    selector: 'design-breadcrumb',
    imports: [
        NgClass,
        RouterModule,
        DesignIcon,
        DesignIconButton,
        UiMenuPanelModule,
        NgTemplateOutlet,
    ],
    templateUrl: './breadcrumb.component.html',
    styleUrls: ['./breadcrumb.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    host: {
        class: 'design-breadcrumb',
        role: 'list',
    },
    encapsulation: ViewEncapsulation.None
})
export class BreadcrumbComponent<TPathItem extends BasicPathItem = DtoNameId> {
  private readonly _router = inject(Router);
  protected readonly slicedPath = computed(() => {
    const path = this.path();
    const threshold = path.length - this.numberOfRenderedElementsOnScreen();

    const sliceForMenu = path.slice(0, threshold);
    const sliceForFirstScreen = path.slice(threshold, path.length);

    return { sliceForMenu, sliceForFirstScreen };
  });

  readonly numberOfRenderedElementsOnScreen = input<number>(2);
  readonly path = input.required<TPathItem[]>();
  readonly showIcon = input<boolean>(false);
  readonly customNavigate = output<TPathItem>();

  protected onNavigate(item: TPathItem) {
    if (this.customNavigate['listeners'] === null) {
      this._router.navigate([], {
        queryParams: { folderId: item.id },
        queryParamsHandling: 'merge',
      });
      return;
    }

    this.customNavigate.emit(item);
  }
}
