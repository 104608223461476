import {
  ChangeDetectionStrategy,
  Component,
  computed,
  inject,
  input,
  Pipe,
  PipeTransform,
  signal
} from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { FormControl, ReactiveFormsModule } from '@angular/forms';
import { BasicPathItem, BreadcrumbComponent } from '@simlab/design/breadcrumb';
import { DesignLabel } from '@simlab/design/common';
import { DesignFormField } from '@simlab/design/form-field';
import { DesignIcon } from '@simlab/design/icon';
import { DesignInput } from '@simlab/design/input';
import { DesignSymbol } from '@simlab/design/internal';
import { DesignProgressSpinnerComponent } from '@simlab/design/progress-spinner';
import { ToastService } from '@simlab/design/toast';
import { ApiProcoreService } from '@simlab/procore/data-access';
import {
  procoreBaseInfoPayload,
  ProcoreLocation,
  ProcoreProjectInfo,
  ProjectLocationsPayload
} from '@simlab/procore/models';
import { UiBreadcrumbModule } from '@simlab/ui/breadcrumb';
import { UiFormFieldModule } from '@simlab/ui/form-field';
import { UiSelectModule } from '@simlab/ui/select';
import { derivedAsync } from 'ngxtension/derived-async';
import {
  catchError,
  debounceTime,
  EMPTY,
  map,
  Observable,
  of,
  startWith,
  tap
} from 'rxjs';

type SearchQuery = Pick<ProjectLocationsPayload, 'search'>;
type ParentQuery = Pick<ProjectLocationsPayload, 'parentId'>;
type BreadcrumbsQuery = Pick<ProjectLocationsPayload, 'superLocationFor'>;
type DepthRangeQuery = Pick<ProjectLocationsPayload, 'depthRange'>;
type QueryUnion =
  | SearchQuery
  | ParentQuery
  | BreadcrumbsQuery
  | DepthRangeQuery;

type LocationPathInfo = {
  location: ProcoreLocation | null;
  icon?: DesignSymbol;
} & BasicPathItem;

@Pipe({ standalone: true, name: 'locationName' })
export class LocationNamePipe implements PipeTransform {
  transform(locationName: string | undefined): string {
    return !locationName ? '' : locationName.replaceAll('>', ' > ');
  }
}

const ERR_FETCHING_PROCORE_LOCATIONS = $localize`:@@ERR_FETCHING_PROCORE_LOCATIONS:An error occured while fetching Procore locations.`;
const SEARCH_CONTROL_DEBOUNCE = 300;

@Component({
  selector: 'procore-location-select',
  standalone: true,
  imports: [
    ReactiveFormsModule,
    UiSelectModule,
    UiFormFieldModule,
    DesignFormField,
    DesignInput,
    DesignLabel,
    DesignIcon,
    UiBreadcrumbModule,
    LocationNamePipe,
    BreadcrumbComponent,
    DesignProgressSpinnerComponent
  ],
  providers: [LocationNamePipe],
  templateUrl: './location-select.component.html',
  styleUrl: './location-select.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LocationSelectComponent {
  private readonly _apiProcoreService = inject(ApiProcoreService);
  private readonly _toastService = inject(ToastService);
  private readonly _projectInfo = inject(procoreBaseInfoPayload);
  private readonly _locationNamePipe = inject(LocationNamePipe);
  private readonly _panelOpenedAtLeastOnce = signal(false);
  private readonly _selectedNode = derivedAsync(() =>
    this.control().valueChanges.pipe(tap(() => this.optionsLoading.set(true)))
  );
  private readonly _childNodesCache: Map<number, ProcoreLocation[]> = new Map();
  private _breadcrumbCache: ProcoreLocation[] = [];

  protected readonly optionsLoading = signal(true);
  protected readonly beforeInitialFetch = signal(true);

  protected readonly searchOptions = derivedAsync(
    () => {
      const projectInfo = this._projectInfo();
      const search = this.searchValue();
      const panelOpenedAtLeastOnce = this._panelOpenedAtLeastOnce();

      if (!panelOpenedAtLeastOnce || projectInfo === undefined) {
        return of([]);
      }

      if (!search) {
        return of([]).pipe(this._setLoadingToFalse());
      }

      return this._fetchLocations({ search }, projectInfo);
    },
    { initialValue: [] }
  );

  protected readonly childNodeOptions = derivedAsync(
    () => {
      const projectInfo = this._projectInfo();
      const selectedNode = this._selectedNode();
      const panelOpenedAtLeastOnce = this._panelOpenedAtLeastOnce();

      if (!panelOpenedAtLeastOnce || projectInfo === undefined) {
        return of([]);
      }

      if (!selectedNode) {
        return this._fetchLocations({ depthRange: 0 }, projectInfo);
      }

      const id = selectedNode.id;
      if (this._childNodesCache.has(id)) {
        return of(this._childNodesCache.get(id)).pipe(
          this._setLoadingToFalse()
        );
      }

      return this._fetchLocations(
        { parentId: selectedNode.id },
        projectInfo
      ).pipe(
        tap((options) => {
          this._childNodesCache.set(selectedNode.id, options);
        })
      );
    },
    { initialValue: [] }
  );

  protected readonly ancestorNodes = derivedAsync(
    () => {
      const projectInfo = this._projectInfo();
      const selectedNode = this._selectedNode();

      if (!selectedNode || projectInfo === undefined) return of([]);

      if (!selectedNode.parentId) return of([selectedNode]);

      if (this._isParentNodeLastInCache(selectedNode)) {
        const breadcrumbs = [...this._breadcrumbCache, selectedNode];
        this._breadcrumbCache = breadcrumbs;
        return of(breadcrumbs);
      }

      if (this._isSelectedNodeExistInCache(selectedNode)) {
        const breadcrumbs = this._filterAncestorNodesFromCache(selectedNode);
        this._breadcrumbCache = breadcrumbs;
        return of(breadcrumbs);
      }

      return this._fetchAncestorLocations(selectedNode, projectInfo).pipe(
        tap((breadcrumbs) => {
          this._breadcrumbCache = breadcrumbs;
        })
      );
    },
    { initialValue: [] }
  );

  protected readonly breadcrumbs = computed(() => {
    const ancestorNodes = this.ancestorNodes();
    const mappedNodes = ancestorNodes.map((ancestorNode) => {
      return {
        id: ancestorNode.id,
        name: ancestorNode.nodeName,
        location: ancestorNode
      } as LocationPathInfo;
    });

    return [
      {
        id: 0,
        name: '',
        icon: DesignSymbol.Home,
        location: null
      },
      ...mappedNodes
    ];
  });

  protected readonly compareLocations: (
    location: ProcoreLocation,
    selectedLocation: ProcoreLocation
  ) => boolean = (location, selectedLocation) =>
    location.id === selectedLocation?.id;

  protected readonly transformTrigger: (value: ProcoreLocation) => string = (
    value
  ) => {
    return this._locationNamePipe.transform(value.name);
  };

  readonly searchControl = new FormControl('');
  readonly searchValue = toSignal(
    this.searchControl.valueChanges.pipe(
      startWith(null),
      debounceTime(SEARCH_CONTROL_DEBOUNCE),
      tap(() => this.optionsLoading.set(true))
    )
  );

  readonly control =
    input.required<FormControl<ProcoreLocation | null | undefined>>();

  onPanelClose() {
    if (this.searchControl.value) {
      this.searchControl.setValue(null);
    }
  }

  onPanelOpen() {
    if (!this._panelOpenedAtLeastOnce()) {
      this.optionsLoading.set(true);
      this.control().updateValueAndValidity({ emitEvent: true });
      this._panelOpenedAtLeastOnce.set(true);
    }
  }

  selectNode(node: ProcoreLocation | null) {
    this.control().setValue(node);
  }

  selectAndShowNested(event: Event, node: ProcoreLocation | null) {
    event.stopPropagation();
    this.selectNode(node);
  }

  private _setLoadingToFalse() {
    return <T>(source: Observable<T>) => {
      return source.pipe(
        tap(() => {
          this.optionsLoading.set(false);
        })
      );
    };
  }

  private _isParentNodeLastInCache(selectedNode: ProcoreLocation) {
    return (
      this._breadcrumbCache[this._breadcrumbCache.length - 1]?.id ===
      selectedNode.parentId
    );
  }

  private _filterAncestorNodesFromCache(
    selectedNode: ProcoreLocation
  ): ProcoreLocation[] {
    return this._breadcrumbCache.filter(
      (location) => location.id <= selectedNode.id
    );
  }

  private _isSelectedNodeExistInCache(selectedNode: ProcoreLocation): boolean {
    return this._breadcrumbCache.some(
      (location) => location.id === selectedNode.id
    );
  }

  private _fetchAncestorLocations(
    selectedNode: ProcoreLocation,
    projectInfo: ProcoreProjectInfo
  ): Observable<ProcoreLocation[]> {
    return this._fetchLocations(
      {
        superLocationFor: selectedNode.id
      },
      projectInfo
    ).pipe(
      map((breadcrumbs) => {
        return [...breadcrumbs.reverse(), selectedNode];
      })
    );
  }

  private _createGetLocationPayload(
    params: QueryUnion,
    projectInfo: ProcoreProjectInfo
  ): ProjectLocationsPayload {
    return {
      ...projectInfo,
      search: null,
      superLocationFor: null,
      parentId: null,
      depthRange: null,
      ...params
    };
  }

  private _fetchLocations(
    query: QueryUnion,
    projectInfo: ProcoreProjectInfo
  ): Observable<ProcoreLocation[]> {
    return this._apiProcoreService
      .getProjectLocations(this._createGetLocationPayload(query, projectInfo))
      .pipe(
        catchError(() => {
          this._toastService.open(ERR_FETCHING_PROCORE_LOCATIONS, 'Error');
          this.optionsLoading.set(false);
          return EMPTY;
        }),
        map((locations) => locations.items),
        tap(() => {
          this.beforeInitialFetch.set(false);
          this.optionsLoading.set(false);
        })
      );
  }
}
