<div class="annotation-panel-menu">
  <div class="annotation-panel-menu__wrapper">
    @for (menuItem of panelMenuItems(); track menuItem.key) {
    <button
      class="annotation-panel-menu__item"
      [ngClass]="{ 'annotation-panel-menu__item--active': selectedItemKey() === menuItem.key }"
      (click)="onItemSelect.emit(menuItem)">
      <span class="annotation-panel-menu__item__icon-wrapper" style="width: 24px; height: 24px; display: flex; justify-content: center; align-items: center">
        <i class="annotation-panel-menu__item__icon" [designIcon]="menuItem.icon" designSize="1.25rem"></i>
      </span>
      <span class="annotation-panel-menu__item__label">
        {{ menuItem.label }}
      </span>
    </button>
    }
  </div>
</div>
