import { DocumentTypeEnumValues } from '@simlab/design/attachment-icon';

export type TDocumentChangeName = {
  id: string;
  newName: string;
};

export type DirectoryAndDocumentIds = {
  directoryIds: string[];
  documentIds: string[];
};

export type MoveResourcesRequest = {
  targetDirectoryId: string;
} & DirectoryAndDocumentIds;

export type TElementsToZip = {
  projectId: string;
  parentDocumentId?: string | number;
} & DirectoryAndDocumentIds;

export type TTaskIdElementsToZip = {
  taskId: string;
};

export type TDocumentsToZipStatus = {
  status: TBlobsPackingStatus;
  progress: number;
  queueOrder: number;
  notFoundFilePaths?: string[];
  resultUrl?: string;
};

export const BlobsPackingStatus = {
  Unknown: 0,
  Processing: 1,
  Finished: 2,
  Waiting: 3,
  Failure: 4
} as const;

export type TBlobsPackingStatus =
  (typeof BlobsPackingStatus)[keyof typeof BlobsPackingStatus];

export type UploadFile = {
  projectId: string;
  directoryId: string;
  name: string;
  blobUrl: string;
  documentType: DocumentTypeEnumValues;
};

export type UploadDirectory = {
  projectId: string;
  directoryId?: string;
  uploads: string[];
};
