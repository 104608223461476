import { DIALOG_DATA, DialogRef } from '@angular/cdk/dialog';
import { AsyncPipe } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  ViewEncapsulation,
  inject
} from '@angular/core';
import { MediaSlideShow } from '@simlab/data-access';
import {
  ACCEPTED_AUDIO_TAG_EXTENSION_TOKEN,
  ACCEPTED_AUDIO_TAG_EXTENSION_TYPE,
  ACCEPTED_IMG_TAG_EXTENSION_TOKEN,
  ACCEPTED_IMG_TAG_EXTENSION_TYPE,
  ACCEPTED_VIDEO_TAG_EXTENSION_TOKEN,
  ACCEPTED_VIDEO_TAG_EXTENSION_TYPE,
  DocumentTypeEnumValues
} from '@simlab/design/attachment-icon';
import { DesignIconButton } from '@simlab/design/button';
import { DesignDialogWrapperModule } from '@simlab/design/dialog';
import { DesignIcon } from '@simlab/design/icon';
import { documentsApiToken } from '@simlab/documents/data-access';
import { UiAudioModule } from '@simlab/ui/audio';
import { UiIconModule } from '@simlab/ui/icon';
import { UiSlideshowModule } from '@simlab/ui/slideshow';

import { defer, map, of } from 'rxjs';
import { DocumentsThumbnailIconGetterPipe } from '../documents-thumbnail-icon-getter/documents-thumbnail-icon-getter.pipe';

export type DocumentsPreviewModalData = {
  name: string;
  fileId: string;
  type: DocumentTypeEnumValues;
};

@Component({
  imports: [
    DesignDialogWrapperModule,
    UiSlideshowModule,
    AsyncPipe,
    DesignIconButton,
    DesignIcon,
    DocumentsThumbnailIconGetterPipe,
    UiIconModule,
    UiAudioModule
  ],
  templateUrl: './documents-preview-modal.component.html',
  styleUrl: './documents-preview-modal.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  host: {
    class: 'documents-documents-preview-modal'
  }
})
export class DocumentsPreviewModalComponent {
  private readonly _api = inject(documentsApiToken);
  private readonly _dialogRef = inject(DialogRef);

  protected readonly data = inject<DocumentsPreviewModalData>(DIALOG_DATA);

  protected readonly slideshowSource$ = defer(() =>
    this._api
      .getResourcesDownloadUrls$([this.data.fileId])
      .pipe(
        map((url) => [
          <MediaSlideShow>(<unknown>{ privateUrl: url, url$: of(url) })
        ])
      )
  );

  protected readonly audioSource$ = defer(() =>
    this._api
      .getResourcesDownloadUrls$([this.data.fileId])
      .pipe(map((urls) => urls[0]))
  );

  protected readonly extension = this.data.name.split('.').pop()?.toLowerCase();
  protected readonly isAudioDocument =
    this.extension === undefined
      ? false
      : inject(ACCEPTED_AUDIO_TAG_EXTENSION_TOKEN).includes(
          this.extension as ACCEPTED_AUDIO_TAG_EXTENSION_TYPE[number]
        );
  protected readonly isVideoDocument =
    this.extension === undefined
      ? false
      : inject(ACCEPTED_VIDEO_TAG_EXTENSION_TOKEN).includes(
          this.extension as ACCEPTED_VIDEO_TAG_EXTENSION_TYPE[number]
        );
  protected readonly isPhotoDocument =
    this.extension === undefined
      ? false
      : inject(ACCEPTED_IMG_TAG_EXTENSION_TOKEN).includes(
          this.extension as ACCEPTED_IMG_TAG_EXTENSION_TYPE[number]
        );

  protected close() {
    this._dialogRef.close();
  }
}
