import { DIALOG_DATA, DialogRef } from '@angular/cdk/dialog';
import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { DomSanitizer } from '@angular/platform-browser';
import { EulaUrls } from '@simlab/data-access';
import { DesignFlatButton } from '@simlab/design/button';
import { DesignCheckbox } from '@simlab/design/checkbox';
import { UiImageInfoModule } from '@simlab/ui/image-info';
import { map } from 'rxjs';
import { EulaService } from '../../services/eula.service';

export type TUserConsents = {
  eulaAccepted: boolean;
  marketingEmailsPermission: boolean;
  processingPersonalDataPermission: boolean;
};

@Component({
    selector: 'util-core-eula',
    templateUrl: './eula-dialog.component.html',
    styleUrls: ['./eula-dialog.component.scss'],
    // encapsulation: ViewEncapsulation.None,
    providers: [EulaService],
    imports: [DesignCheckbox, DesignFlatButton, UiImageInfoModule],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class EulaDialogComponent {
  public readonly eulaUrl = inject<EulaUrls>(DIALOG_DATA);
  private readonly _eulaService = inject(EulaService);
  private readonly _modelRef = inject(DialogRef<TUserConsents>);
  private readonly _sanitized = inject(DomSanitizer);
  eulaAccepted = false;
  processingData = false;
  communicationByEmail = false;

  readonly eulaText = toSignal(
    this._eulaService
      .getEulaText$(this.eulaUrl.eulaUrl)
      .pipe(
        map((value: string) => this._sanitized.bypassSecurityTrustHtml(value))
      )
  );

  handleConfirm(): void {
    this._modelRef.close({
      eulaAccepted: this.eulaAccepted,
      marketingEmailsPermission: this.communicationByEmail,
      processingPersonalDataPermission: this.processingData
    });
  }
}
