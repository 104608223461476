import { coerceBooleanProperty } from '@angular/cdk/coercion';
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  HostBinding,
  Input,
  Output,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

let nextUniqueId = 0;

@Component({
    selector: 'ui-checkbox',
    templateUrl: './checkbox.component.html',
    styleUrls: ['./checkbox.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    encapsulation: ViewEncapsulation.None,
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            multi: true,
            useExisting: CheckboxComponent,
        },
    ],
    standalone: false
})
export class CheckboxComponent implements ControlValueAccessor, AfterViewInit {
  @HostBinding('class.ui-checkbox') hostClass = true;

  @HostBinding('class.checked') get checkedClass(): boolean {
    return this._checked;
  }
  @HostBinding('class.disabled') get disabledClass(): boolean {
    return this._disabled;
  }
  @HostBinding('class.label-before') get labelPositionBeforeClass(): boolean {
    return this.labelPosition === 'before';
  }
  private _uniqueId = `ui-checkbox-${++nextUniqueId}`;

  @Input() id: string = this._uniqueId;

  get inputId(): string {
    return `${this.id || this._uniqueId}-input`;
  }

  /** Whether the checkbox is required. */
  @Input()
  get required(): boolean {
    return this._required;
  }
  set required(value: boolean) {
    this._required = coerceBooleanProperty(value);
  }
  private _required = false;

  @Output() readonly valueChange: EventEmitter<boolean> =
    new EventEmitter<boolean>();

  @Input()
  get type(): 'normal' | 'customize' {
    return this._type;
  }
  set type(value: 'normal' | 'customize') {
    // Make sure we have a valid value.

    this._type = value;
  }
  private _type: 'normal' | 'customize' = 'normal';

  /** Whether the label should appear after or before the checkbox. Defaults to 'after' */
  @Input() labelPosition: 'before' | 'after' = 'after';

  /** The native `<input type="checkbox">` element */
  @ViewChild('input') private _inputElement!: ElementRef<HTMLInputElement>;

  /**
   * Whether the checkbox is checked.
   */
  @Input()
  get checked(): boolean {
    return this._checked;
  }
  set checked(value: boolean) {
    if (value != this.checked) {
      this._checked = value;
      this._changeDetectorRef.markForCheck();
    }
    if (this._inputElement?.nativeElement) {
      this._inputElement.nativeElement.checked = this.checked;
    }
  }
  private _checked = false;

  @Input()
  get disabled(): boolean {
    return this._disabled;
  }
  set disabled(value: boolean) {
    const newValue = coerceBooleanProperty(value);

    if (newValue !== this.disabled) {
      this._disabled = newValue;
      this._changeDetectorRef.markForCheck();
    }
  }
  private _disabled = false;
  /** Readonly section start */

  get readonly(): boolean {
    return this._readonly;
  }

  @Input()
  set readonly(value: boolean) {
    this._readonly = coerceBooleanProperty(value);
  }
  private _readonly = false;
  /** Readonly section end */
  @Input()
  get touched(): boolean {
    return this._touched;
  }
  set touched(value: boolean) {
    this._touched = value;
  }
  private _touched = false;
  constructor(
    private readonly _elementRef: ElementRef,
    private readonly _changeDetectorRef: ChangeDetectorRef
  ) {}
  ngAfterViewInit(): void {
    this._inputElement.nativeElement.checked = this.checked;
  }
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  onTouched: () => any = () => {};

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  onChange: (value: any) => void = () => {};

  writeValue(value: any) {
    this.checked = !!value;
  }

  // Implemented as part of ControlValueAccessor.
  registerOnTouched(fn: any) {
    this.onTouched = fn;
  }

  // Implemented as part of ControlValueAccessor.
  setDisabledState(isDisabled: boolean) {
    this.disabled = isDisabled;
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  emitChangeEvent(event: Event) {
    event.stopPropagation();
    this._toggle();
    this.onChange(this.checked);
    this._markAsTouched();
    this.valueChange.emit((event.target as HTMLInputElement).checked);
  }

  private _markAsTouched() {
    if (!this._touched) {
      this.onTouched();
      this.touched = true;
    }
  }

  private _toggle(): void {
    this.checked = !this.checked;
  }
}
