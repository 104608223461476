import { ChangeDetectionStrategy, Component } from '@angular/core';
import { ImageInfoComponent } from '@simlab/ui/image-info';

const DESCRIPTION_IMAGE_INFO: string[] = [
  $localize`:@@ACCESS_DENIED_INFO_1:The page you were trying to reach has restricted access.`
];

@Component({
    selector: 'simlab-forbidden',
    templateUrl: './forbidden.component.html',
    styleUrls: ['./forbidden.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    imports: [ImageInfoComponent]
})
export class ForbiddenComponent {
  readonly descriptionImageInfo = DESCRIPTION_IMAGE_INFO;
}
