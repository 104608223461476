import {
  ChangeDetectionStrategy,
  Component,
  computed,
  inject,
  input,
  output,
  signal
} from '@angular/core';
import { Params } from '@angular/router';
import { DesignSymbol } from '@simlab/design/internal';
import { StagesRootService } from '@simlab/feature/stages';
import { PUNCH_ITEM_STATE_PROVIDERS } from '@simlab/procore/annotation-panel/services';
import { ApiProcoreService } from '@simlab/procore/data-access';
import {
  procoreBaseInfoPayload,
  PunchItem,
  PunchItemAssignmentStatus
} from '@simlab/procore/models';
import { OpenNewTabService, RouterFacadeService } from '@simlab/util-shared';
import {
  AnnotationPanelMenuItem,
  annotationsActionsToken
} from 'common/annotation-panel/models';
import { AnnotationPanelRootComponent } from 'common/annotation-panel/ui';
import { derivedAsync } from 'ngxtension/derived-async';
import { map, of } from 'rxjs';
import { PunchItemSidePanelAssigneesComponent } from '../punch-item-side-panel-assignees/punch-item-side-panel-assignees.component';
import { PunchItemSidePanelInformationComponent } from '../punch-item-side-panel-information/punch-item-side-panel-information.component';
import { PunchItemSidePanelMainComponent } from '../punch-item-side-panel-main/punch-item-side-panel-main.component';
import { PunchItemSidePanelWorkflowComponent } from '../punch-item-side-panel-workflow/punch-item-side-panel-workflow.component';
import { BasePunchItemSidePanel } from './base-punch-item-side-panel';
import { PunchItemSidePanelAction } from './punch-item-side-panel-actions';

type PunchItemAnnotationMenu = 'main' | 'item-info' | 'workflow' | 'assignees';

export type OnTransferPunchItemElementModel = {
  id: PunchItem['id'];
  withActionNumber: PunchItem['showYellowBackground'];
};

@Component({
  selector: 'procore-punch-item-side-panel',
  imports: [
    AnnotationPanelRootComponent,
    PunchItemSidePanelMainComponent,
    PunchItemSidePanelWorkflowComponent,
    PunchItemSidePanelInformationComponent,
    PunchItemSidePanelAssigneesComponent
  ],
  templateUrl: './punch-item-side-panel.component.html',
  styleUrl: './punch-item-side-panel.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    OpenNewTabService,
    {
      provide: annotationsActionsToken,
      useClass: PunchItemSidePanelAction
    },
    ...PUNCH_ITEM_STATE_PROVIDERS
  ]
})
export class PunchItemSidePanelComponent extends BasePunchItemSidePanel {
  private readonly _routerFacade = inject(RouterFacadeService);
  private readonly _stagesRootService = inject(StagesRootService);
  private readonly _procoreApiService = inject(ApiProcoreService);
  private readonly _procoreBaseInfo = inject(procoreBaseInfoPayload);

  readonly menu: AnnotationPanelMenuItem<PunchItemAnnotationMenu>[] = [
    {
      key: 'main',
      icon: DesignSymbol.Home,
      label: $localize`:@@PUNCH_ITEM_PANEL_MENU_MAIN:Main`
    },
    {
      key: 'item-info',
      icon: DesignSymbol.InfoCircle,
      label: $localize`:@@PUNCH_ITEM_PANEL_MENU_INFO:Item Info`
    },
    {
      key: 'workflow',
      icon: DesignSymbol.People,
      label: $localize`:@@PUNCH_ITEM_PANEL_MENU_WORKFLOW:Workflow`
    },
    {
      key: 'assignees',
      icon: DesignSymbol.ManageAccounts,
      label: $localize`:@@PUNCH_ITEM_PANEL_MENU_ASSIGNEES:Assignees`
    }
  ] as const;

  readonly stageName = input.required<string>();
  readonly onClosePanel = output<void>();
  readonly onTransferElement = output<OnTransferPunchItemElementModel>();

  protected readonly selectedMenuItemKey =
    signal<PunchItemAnnotationMenu>('main');

  protected readonly currencySymbol = derivedAsync(
    () => {
      const procoreCompanyId = this._procoreBaseInfo()?.procoreCompanyId;
      if (procoreCompanyId === undefined) return of('');

      return this._procoreApiService
        .getProcoreCompanyConfiguration(procoreCompanyId)
        .pipe(map((configuration) => configuration.currencySymbol));
    },
    { initialValue: '' }
  );

  readonly name = computed(() => {
    const { name, position } = this.punchItem();
    return `#${position ?? ''}: ${name}`;
  });

  protected readonly activities = this.punchItemState.activities;
  protected readonly addingCommentStatus =
    this.punchItemState.addingCommentStatus;
  protected readonly isActivitiesLoading =
    this.punchItemState.isActivitiesLoading;

  protected readonly assigneeOptions = derivedAsync(
    () => {
      const baseInfo = this._procoreBaseInfo();
      if (baseInfo === undefined) return of([]);

      const { procoreCompanyId, procoreProjectId } = baseInfo;
      return this._procoreApiService.getPunchListAssigneeOptions({
        procoreCompanyId,
        procoreProjectId
      });
    },
    { initialValue: [] }
  );

  protected itemSelected(itemKey: string) {
    this.selectedMenuItemKey.set(itemKey as PunchItemAnnotationMenu);
  }

  protected allWorkComplete() {
    this.punchItemState.updatePunchItemAssignmentsStatus({
      status: PunchItemAssignmentStatus.Resolved,
      punchItemAssignmentIds: this.punchItem().assignments.map(
        (assignment) => assignment.id
      )
    });
  }

  editPunchItem() {
    this.onClosePanel.emit();

    const queryParams: Params = {
      sidenavContent: 'form-punch-item'
    };

    this._routerFacade.setQueryParams(undefined, queryParams, 'merge');
    this._stagesRootService.rightPanelState = 'open';
  }
}
