import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import {
  API_URL,
  Marker,
  PunchItemsWithMarkers,
  RFIWithMarkers
} from '@simlab/data-access';
import {
  AddUserToken,
  Assignment,
  AssignProject,
  BallInCourtUser,
  Company,
  CostCode,
  CreatePunchItemPayload,
  CreatePunchItemResponse,
  DeleteProcoreItemPayload,
  GetPunchItemAssignmentPayload,
  ProcoreCompanyConfiguration,
  ProcoreDataProgressResponse,
  ProcoreItemGetPayload,
  ProcorePermission,
  ProcorePunchItemPayload,
  ProcoreTrade,
  Project,
  ProjectLocations,
  ProjectLocationsPayload,
  PunchItem,
  PunchItemActivity,
  PunchItemAssignee,
  PunchItemCommentPayload,
  PunchItemDisplayElements,
  PunchItemDistributionalMembers,
  PunchItemPayload,
  PunchItemPotentialDistributionMemberPayload,
  PunchItemsGetPayload,
  PunchItemsResponse,
  PunchItemType,
  RFI,
  RFIGetPayload,
  RFIListResponse,
  RfiUserDisplayElements,
  RFIUserDisplayElementsRequest,
  TransferElementByStage,
  UnAssignProject,
  UpdatePunchItemAssignees,
  UpdatePunchItemAssignmentPayload,
  UpdatePunchItemAssignmentsPayload,
  UpdatePunchItemPayload,
  UpdatePunchItemWorkflowStatusPayload,
  UserInfo,
  UserToken
} from '@simlab/procore/models';
import {
  ProcoreBaseProjectInfo,
  ProcoreConfigurableFieldset,
  ProjectConfigurableFieldsetPayload
} from '@simlab/procore/shared/models';
import { requestToHttpParamsMapper } from '@simlab/util/core';
import { catchError, mergeMap, Observable } from 'rxjs';
import {
  CreateUploadPayload,
  CreateUploadResponse
} from '../../../models/src/lib/create-upload.models';

@Injectable({
  providedIn: 'root'
})
export class ApiProcoreService {
  public readonly url = inject<string>(API_URL);
  public readonly httpClient = inject(HttpClient);

  hasUserProcoreToken(): Observable<boolean> {
    return this.httpClient.get<boolean>(
      `${this.url}/users-module/has-user-procore-token`
    );
  }

  getUserCompanyPermissions(
    procoreCompanyId: number
  ): Observable<ProcorePermission[]> {
    return this.httpClient.get<ProcorePermission[]>(
      `${this.url}/procore-api/get-user-company-permissions?procoreCompanyId=${procoreCompanyId}`
    );
  }

  getCompanies$(): Observable<Company[]> {
    return this.httpClient.get<Company[]>(
      `${this.url}/procore-api/get-companies`
    );
  }

  getCompanyProjects$(procoreCompanyId: number): Observable<Project[]> {
    return this.httpClient.get<Project[]>(
      `${this.url}/procore-api/get-company-projects?procoreCompanyId=${procoreCompanyId}`
    );
  }

  getUserProjectPermissions({
    procoreCompanyId,
    procoreProjectId
  }: {
    procoreCompanyId: number;
    procoreProjectId: number;
  }): Observable<ProcorePermission[]> {
    return this.httpClient.get<ProcorePermission[]>(
      `${this.url}/procore-api/get-user-project-permissions?procoreCompanyId=${procoreCompanyId}&procoreProjectId=${procoreProjectId}`
    );
  }

  assignProcoreProject$(body: AssignProject): Observable<void> {
    return this.httpClient.post<void>(
      `${this.url}/organizations-module/assign-procore-project`,
      body
    );
  }

  unAssignProcoreProject$(body: UnAssignProject): Observable<void> {
    return this.httpClient.post<void>(
      `${this.url}/organizations-module/unassign-procore-project`,
      body
    );
  }

  getProcoreUserToken(): Observable<UserToken> {
    return this.httpClient.get<UserToken>(
      `${this.url}/users-module/get-procore-user-token`
    );
  }

  getProcoreUserInfo(): Observable<UserInfo> {
    return this.httpClient.get<UserInfo>(
      `${this.url}/procore-api/get-procore-user-info`
    );
  }

  getProcoreAuthorizationCodeUrl(returnUrl: string): Observable<string> {
    return this.httpClient.get(
      `${this.url}/users-module/get-procore-authorization-code-url?returnUrl=${returnUrl}`,
      { responseType: 'text' }
    );
  }

  addProcoreUserToken(body: AddUserToken): Observable<void> {
    return this.httpClient.post<void>(
      `${this.url}/users-module/add-procore-user-token`,
      body
    );
  }

  removeProcoreUserToken(): Observable<void> {
    return this.httpClient.delete<void>(
      `${this.url}/users-module/remove-procore-user-token`
    );
  }

  reassignProcoreItems(projectId: string, includeManuallyAssigned: boolean) {
    return this.httpClient
      .post(`${this.url}/procore-module/reassign-procore-items-to-stages`, {
        projectId,
        includeManuallyAssigned
      })
      .pipe(mergeMap(() => this.importProcoreData(projectId)));
  }

  importProcoreData(projectId: string) {
    return this.httpClient.post(
      `${this.url}/procore-module/import-procore-data`,
      { projectId }
    );
  }

  getRfiList(payload: RFIGetPayload) {
    const params = requestToHttpParamsMapper(payload);

    return this.httpClient.get<RFIListResponse>(
      `${this.url}/procore-module/get-rfis`,
      {
        params
      }
    );
  }

  getPunchItemsList(payload: PunchItemsGetPayload) {
    const params = requestToHttpParamsMapper(payload);

    return this.httpClient.get<PunchItemsResponse>(
      `${this.url}/procore-module/get-punch-items`,
      {
        params
      }
    );
  }

  deletePunchItem(payload: DeleteProcoreItemPayload) {
    const params = requestToHttpParamsMapper(payload);
    return this.httpClient.delete<void>(
      `${this.url}/procore-module/delete-punch-item`,
      {
        params
      }
    );
  }

  deleteRfi(payload: DeleteProcoreItemPayload) {
    const params = requestToHttpParamsMapper(payload);
    return this.httpClient.delete<void>(
      `${this.url}/procore-module/delete-rfi`,
      { params }
    );
  }

  transferPunchItem(body: TransferElementByStage) {
    return this.httpClient.put<void>(
      `${this.url}/procore-module/move-punch-item-to-stage`,
      body
    );
  }

  transferRfi(body: TransferElementByStage) {
    return this.httpClient.put<void>(
      `${this.url}/procore-module/move-rfi-to-stage`,
      body
    );
  }

  getRfiItemBallInCourtUsers(projectId: string): Observable<BallInCourtUser[]> {
    return this.httpClient
      .get<
        BallInCourtUser[]
      >(`${this.url}/procore-module/get-rfi-ball-in-court-users?projectId=${projectId}`)
      .pipe(
        catchError((e) => {
          console.error(e);
          return [];
        })
      );
  }

  getPunchItemBallInCourtUsers(
    projectId: string
  ): Observable<BallInCourtUser[]> {
    return this.httpClient.get<BallInCourtUser[]>(
      `${this.url}/procore-module/get-punch-item-ball-in-court-users?projectId=${projectId}`
    );
  }

  placeMarkerToPunchItem(payload: { id: string } & Marker): Observable<void> {
    return this.httpClient.put<void>(
      `${this.url}/procore-module/place-marker-to-punch-item`,
      payload
    );
  }

  placeMarkerToRFI(payload: { id: string } & Marker): Observable<void> {
    return this.httpClient.put<void>(
      `${this.url}/procore-module/place-marker-to-rfi`,
      payload
    );
  }

  getRfi(payload: ProcoreItemGetPayload): Observable<RFI> {
    const params = requestToHttpParamsMapper(payload);

    return this.httpClient.get<RFI>(`${this.url}/procore-module/get-rfi`, {
      params
    });
  }

  getPunchItem(payload: ProcoreItemGetPayload): Observable<PunchItem> {
    const params = requestToHttpParamsMapper(payload);

    return this.httpClient.get<PunchItem>(
      `${this.url}/procore-module/get-punch-item`,
      {
        params
      }
    );
  }

  getPunchItemDisplayElements(
    payload: PunchItemPayload
  ): Observable<PunchItemDisplayElements> {
    const params = requestToHttpParamsMapper(payload);

    return this.httpClient.get<PunchItemDisplayElements>(
      `${this.url}/procore-module/get-user-punch-item-display-elements`,
      {
        params
      }
    );
  }

  getPunchItemTypes(
    payload: ProcoreBaseProjectInfo
  ): Observable<PunchItemType[]> {
    const params = requestToHttpParamsMapper(payload);

    return this.httpClient.get<PunchItemType[]>(
      `${this.url}/procore-module/get-punch-item-types`,
      {
        params
      }
    );
  }

  getPunchItemPotentialDistributionMembers(
    payload: PunchItemPotentialDistributionMemberPayload
  ): Observable<PunchItemDistributionalMembers> {
    const params = requestToHttpParamsMapper(payload);

    return this.httpClient.get<PunchItemDistributionalMembers>(
      `${this.url}/procore-module/get-punch-item-potential-distribution-members`,
      {
        params
      }
    );
  }

  getAvailablePunchItemManagers(
    payload: ProcoreBaseProjectInfo
  ): Observable<PunchItemAssignee[]> {
    const params = requestToHttpParamsMapper(payload);

    return this.httpClient.get<PunchItemAssignee[]>(
      `${this.url}/procore-module/get-available-punch-item-managers`,
      {
        params
      }
    );
  }

  getPunchListAssigneeOptions(
    payload: ProcoreBaseProjectInfo
  ): Observable<PunchItemAssignee[]> {
    const params = requestToHttpParamsMapper(payload);

    return this.httpClient.get<PunchItemAssignee[]>(
      `${this.url}/procore-module/get-punch-list-assignee-options`,
      {
        params
      }
    );
  }

  getProcoreCostCodes(payload: ProcoreBaseProjectInfo): Observable<CostCode[]> {
    const params = requestToHttpParamsMapper(payload);

    return this.httpClient.get<CostCode[]>(
      `${this.url}/procore-module/get-project-cost-codes`,
      {
        params
      }
    );
  }

  getTrades(procoreCompanyId: number): Observable<ProcoreTrade[]> {
    return this.httpClient.get<ProcoreTrade[]>(
      `${this.url}/procore-module/get-trades?procoreCompanyId=${procoreCompanyId}`
    );
  }

  getProjectLocations(
    payload: ProjectLocationsPayload
  ): Observable<ProjectLocations> {
    const params = requestToHttpParamsMapper(payload);

    return this.httpClient.get<ProjectLocations>(
      `${this.url}/procore-module/get-project-locations`,
      {
        params
      }
    );
  }

  isAnythingToImportFromProcore(projectId: string): Observable<boolean> {
    return this.httpClient.get<boolean>(
      `${this.url}/procore-module/is-anything-to-import?projectId=${projectId}`
    );
  }

  removeMarkerFromPunchItem(punchItemId: string): Observable<void> {
    return this.httpClient.put<void>(
      `${this.url}/procore-module/remove-marker-from-punch-item`,
      { id: punchItemId }
    );
  }

  removeMarkerFromRFI(punchItemId: string): Observable<void> {
    return this.httpClient.put<void>(
      `${this.url}/procore-module/remove-marker-from-rfi`,
      { id: punchItemId }
    );
  }

  getPunchItemsWithMarkersForStages(
    payload: Omit<PunchItemsGetPayload, 'page'>
  ): Observable<PunchItemsWithMarkers> {
    const params = requestToHttpParamsMapper(payload);

    return this.httpClient.get<PunchItemsWithMarkers>(
      `${this.url}/procore-module/get-punch-items-with-markers-for-stages`,
      { params }
    );
  }

  getRfisWithMarkersForStages(
    payload: Omit<RFIGetPayload, 'page'>
  ): Observable<RFIWithMarkers> {
    const params = requestToHttpParamsMapper(payload);

    return this.httpClient.get<RFIWithMarkers>(
      `${this.url}/procore-module/get-rfis-with-markers-for-stages`,
      { params }
    );
  }

  getProcoreCompanyConfiguration(
    procoreCompanyId: number
  ): Observable<ProcoreCompanyConfiguration> {
    return this.httpClient.get<ProcoreCompanyConfiguration>(
      `${this.url}/procore-module/get-company-configuration?procoreCompanyId=${procoreCompanyId}`
    );
  }

  updatePunchItemWorkflowStatus(payload: UpdatePunchItemWorkflowStatusPayload) {
    return this.httpClient.put<PunchItem>(
      `${this.url}/procore-module/update-punch-item-workflow-status`,
      payload
    );
  }

  getPunchItemActivities(
    payload: ProcorePunchItemPayload
  ): Observable<PunchItemActivity[]> {
    const params = requestToHttpParamsMapper(payload);

    return this.httpClient.get<PunchItemActivity[]>(
      `${this.url}/procore-module/get-punch-item-activities`,
      { params }
    );
  }

  createPunchItemComment(payload: PunchItemCommentPayload): Observable<void> {
    const formData = new FormData();
    formData.append('comment', payload.comment);
    formData.append('procoreCompanyId', payload.procoreCompanyId.toString());
    formData.append(
      'procorePunchItemId',
      payload.procorePunchItemId.toString()
    );
    formData.append('procoreProjectId', payload.procoreProjectId.toString());
    formData.append('procoreCompanyId', payload.procoreCompanyId.toString());
    payload.formFiles.forEach((file) => {
      formData.append('FormFiles', file, encodeURIComponent(file.name));
    });

    return this.httpClient.post<void>(
      `${this.url}/procore-module/create-punch-item-comment`,
      formData
    );
  }

  getRfiAllowedActions(
    payload: RFIUserDisplayElementsRequest
  ): Observable<RfiUserDisplayElements> {
    const params = requestToHttpParamsMapper(payload);

    return this.httpClient.get<RfiUserDisplayElements>(
      `${this.url}/procore-module/get-user-rfi-display-elements`,
      { params }
    );
  }

  getPunchItemAssignment(
    payload: GetPunchItemAssignmentPayload
  ): Observable<Assignment> {
    const params = requestToHttpParamsMapper(payload);

    return this.httpClient.get<Assignment>(
      `${this.url}/procore-module/get-punch-item-assignment`,
      { params }
    );
  }

  updatePunchItemAssignment(
    payload: UpdatePunchItemAssignmentPayload
  ): Observable<void> {
    return this.httpClient.put<void>(
      `${this.url}/procore-module/update-punch-item-assignment`,
      payload
    );
  }

  updatePunchItemAssignments(
    payload: UpdatePunchItemAssignmentsPayload
  ): Observable<void> {
    return this.httpClient.put<void>(
      `${this.url}/procore-module/update-punch-item-assignments`,
      payload
    );
  }

  updatePunchItemAssignees(
    payload: UpdatePunchItemAssignees
  ): Observable<PunchItem> {
    return this.httpClient.put<PunchItem>(
      `${this.url}/procore-module/update-punch-item-assignees`,
      payload
    );
  }

  createUpload(payload: CreateUploadPayload): Observable<CreateUploadResponse> {
    return this.httpClient.post<CreateUploadResponse>(
      `${this.url}/procore-module/create-upload`,
      payload
    );
  }

  uploadFileToUrl(
    file: File,
    createUploadResponse: CreateUploadResponse
  ): Observable<void> {
    const formData = new FormData();
    Object.entries(createUploadResponse.fields).forEach(([key, value]) => {
      formData.append(key, value);
    });
    formData.append('file', file);

    return this.httpClient.post<void>(createUploadResponse.url, formData);
  }

  createPunchItem(payload: CreatePunchItemPayload) {
    return this.httpClient.post<CreatePunchItemResponse>(
      `${this.url}/procore-module/create-punch-item`,
      payload
    );
  }

  updatePunchItem(payload: UpdatePunchItemPayload) {
    return this.httpClient.put<PunchItem>(
      `${this.url}/procore-module/update-punch-item`,
      payload
    );
  }

  getImportProcoreDataProgress(
    projectId: string
  ): Observable<ProcoreDataProgressResponse> {
    return this.httpClient.get<ProcoreDataProgressResponse>(
      `${this.url}/procore-module/get-import-procore-data-progress?projectId=${projectId}`
    );
  }

  getProjectConfigurableFieldset<T extends ProjectConfigurableFieldsetPayload>(
    payload: T
  ): Observable<ProcoreConfigurableFieldset<T['type']>> {
    const params = requestToHttpParamsMapper(payload);

    return this.httpClient.get<ProcoreConfigurableFieldset<T['type']>>(
      `${this.url}/procore-module/get-project-configurable-field-set`,
      { params }
    );
  }
}
