import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { DesignFlatButton, DesignStrokedButton } from '@simlab/design/button';
import { DesignDialogWrapperModule } from '@simlab/design/dialog';
import { ConfirmationModalRef, MODAL_DATA } from '@simlab/ui/modal';

export interface ButtonConfig {
  visible: true;
  label: string;
}
export interface InformationModalConfig {
  text: string;
  cancel: ButtonConfig;
  confirm: ButtonConfig;
}
@Component({
    selector: 'feature-stages-tag-importer-information-modal',
    templateUrl: './tag-importer-information-modal.component.html',
    styleUrls: ['./tag-importer-information-modal.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    imports: [
        DesignDialogWrapperModule,
        DesignFlatButton,
        CommonModule,
        DesignStrokedButton
    ]
})
export class TagImporterInformationModalComponent {
  // readonly displayText = this.data.text;

  constructor(
    private readonly modalRef: ConfirmationModalRef<unknown>,
    @Inject(MODAL_DATA)
    readonly data: InformationModalConfig
  ) {}

  confirm(): void {
    this.modalRef.emit({ state: true });
    this.modalRef.close();
  }

  cancel(): void {
    this.modalRef.emit({ state: false });
    this.modalRef.close();
  }
}
