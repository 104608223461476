import { NoteStatus, NoteType } from 'common/annotation-note/models';
import { Marker } from './marker';

export interface NoteBase {
  id: string;
  name: string;
  status: NoteStatus;
  type: NoteType;
  marker: Marker | undefined;
}
