import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  Inject,
  OnInit,
} from '@angular/core';
import {
  AbstractControl,
  ReactiveFormsModule,
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { DesignFlatButton, DesignStrokedButton } from '@simlab/design/button';
import { DesignCommonModule } from '@simlab/design/common';
import { DesignDialogWrapperModule } from '@simlab/design/dialog';
import { DesignFormFieldModule } from '@simlab/design/form-field';
import { DesignInput } from '@simlab/design/input';
import { UiButtonModule } from '../../../ui-button/ui-button.module';
import { UiFormFieldModule } from '../../../ui-form-field/ui-form-field.module';
import { UiIconModule } from '../../../ui-icon/ui-icon.module';
import { UiInputModule } from '../../../ui-input/ui-input.module';
import { ConfirmationModalRef } from '../../models/confirmation-modal-ref';
import { MODAL_DATA } from '../../tokens/modal-data.token';

@Component({
    templateUrl: './delete-with-confirm-name-dialog.component.html',
    styleUrls: ['./delete-with-confirm-name-dialog.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    imports: [
        CommonModule,
        DesignCommonModule,
        DesignDialogWrapperModule,
        UiButtonModule,
        UiInputModule,
        UiIconModule,
        UiFormFieldModule,
        ReactiveFormsModule,
        DesignFormFieldModule,
        DesignInput,
        DesignFlatButton,
        DesignStrokedButton
    ]
})
export class DeleteWithConfirmNameDialogComponent implements OnInit {
  formGroup = new UntypedFormGroup({
    organizationName: new UntypedFormControl('', Validators.required),
  });
  constructor(
    @Inject(MODAL_DATA)
    public readonly modalData: {
      name: string;
      typeToDelete: string;
      additionalText?: string;
    },
    private readonly modalRef: ConfirmationModalRef<unknown>
  ) {}

  ngOnInit(): void {
    if (this.modalData.name) {
      this.formGroup
        .get('organizationName')
        ?.addValidators(this.equals(this.modalData.name));
    }
  }

  confirm(): void {
    this.modalRef.emit({ state: true });
    this.modalRef.close();
  }

  cancel(): void {
    this.modalRef.emit({ state: false });
    this.modalRef.close();
  }

  private equals(nameOfOrganization: string) {
    return (control: AbstractControl): { [key: string]: boolean } | null => {
      if (control.value === nameOfOrganization) {
        return null;
      }
      return { equals: false };
    };
  }
}
