import {
  Component,
  HostBinding,
  Input,
  ViewEncapsulation,
} from '@angular/core';
import { ValidationErrors } from '@angular/forms';
import { BehaviorSubject, Observable } from 'rxjs';

const FIELD_IS_REQUIRED = $localize`:@@FIELD_IS_REQUIRED:Field is required`;
const DATE_FROM_MUST_BE_PREVIOUS_THAN_TO = $localize`:@@DATE_FROM_MUST_BE_PREVIOUS_THAN_TO: Date 'From' must be previous than 'To'`;
const ERROR_PROVIDED_MATTERPORT_LINK_IS_INVALID = $localize`:@@ERROR_PROVIDED_MATTERPORT_LINK_IS_INVALID:Provided matterport link is invalid!`;

export type ValidatorsType =
  | 'required'
  | 'minlength'
  | 'maxlength'
  | 'custom'
  | 'createdDateMiss'
  | 'rangeDateMiss'
  | 'modifiedDateMiss'
  | 'unknownShowcaseId';
@Component({
    selector: 'ui-hint',
    templateUrl: './hint.component.html',
    styleUrls: ['./hint.component.scss'],
    encapsulation: ViewEncapsulation.None,
    standalone: false
})
export class HintComponent {
  @HostBinding('class.hint-container') class = true;
  private _message?: string;
  private _hidden = false;
  @Input() set hidden(value: boolean) {
    this._hasError.next(!!this._validationErrors && !value);
    this._hidden = value;
  }
  get hidden(): boolean {
    return this._hidden;
  }
  @Input() set message(value: string | undefined) {
    this._message = value;
  }
  get message(): string | undefined {
    return this._message;
  }
  private readonly _hasError: BehaviorSubject<boolean> =
    new BehaviorSubject<boolean>(false);

  get hasError$(): Observable<boolean> {
    return this._hasError.asObservable();
  }

  private _validationErrors?: ValidationErrors | null | undefined;
  @Input() set validationErrors(value: ValidationErrors | null | undefined) {
    this._hasError.next(!!value && !this.hidden);
    this._validationErrors = value;
  }
  get validationErrors(): ValidationErrors | null | undefined {
    return this._validationErrors;
  }

  get hintMessage(): string | undefined {
    return this._message ?? this.validationMessage;
  }

  get validationMessage(): string {
    let msg = '';
    if (this._validationErrors) {
      msg = this.getMessage(this._validationErrors);
    }
    return msg;
  }

  getMessage(error: ValidationErrors): string {
    const key = Object.keys(error)[0] as ValidatorsType;
    switch (key) {
      case 'required': {
        return FIELD_IS_REQUIRED;
      }
      case 'minlength': {
        return $localize`:@@MINIMUM_STAGE_NAME_LENGTH:Minimum length of field is ${error['minlength'].requiredLength}`;
      }
      case 'maxlength': {
        return $localize`:@@MAXIMUM_STAGE_NAME_LENGTH:Maximum length of field is ${error['maxlength'].requiredLength}`;
      }
      case 'modifiedDateMiss':
      case 'rangeDateMiss':
      case 'createdDateMiss': {
        return DATE_FROM_MUST_BE_PREVIOUS_THAN_TO;
      }
      case 'unknownShowcaseId': {
        return ERROR_PROVIDED_MATTERPORT_LINK_IS_INVALID;
      }
      case 'custom': {
        return error?.['custom'].message;
      }

      default: {
        return '';
      }
    }
  }
}
