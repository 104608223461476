import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  TemplateRef,
  ViewChild,
  ViewContainerRef,
  contentChild,
  inject
} from '@angular/core';
import {
  AnnotationModules,
  ApiFacadeService,
  TimelineMode,
  TimelineType,
  projectIdPublicToken,
  timelinePublicToken
} from '@simlab/data-access';
import {
  ComponentsFacade,
  StagesFacade,
  UserPreferenceFacade
} from '@simlab/data-store';
import {
  MatterportManagerService,
  MatterportSdkBundleModule,
  MatterportService
} from '@simlab/matterport';
import { MatterportApiFacadeService } from '@simlab/matterport/api';
import { Camera } from '@simlab/matterport/assets/mpSdk/sdk';

import {
  Observable,
  distinctUntilChanged,
  firstValueFrom,
  map,
  of,
  switchMap,
  tap
} from 'rxjs';
import { Matterport3DWalkInterface } from '../../models/matterport.interface';

import {
  DesignFabButtonMenu,
  DesignFabButtonModule,
  DesignFlatButtonModule
} from '@simlab/design/button';
import { UiMenuPanelModule } from '@simlab/design/menu-panel';
import { UiTooltip } from '@simlab/design/tooltip';
import { UiButtonModule } from '@simlab/ui/button';
import { UiIconModule } from '@simlab/ui/icon';
import { UiImageInfoModule } from '@simlab/ui/image-info';
import { UiMatterportLoadingModule } from '@simlab/ui/matterport-loading';
import { UiSelectModule } from '@simlab/ui/select';
import { ThemeService } from '@simlab/ui/theme';
import {
  MATTERPORT_TOKEN,
  MatterportBaseService
} from '../../services/matterport-base.service';
import { MatterportBlueprintService } from '../../services/matterport-blueprint.service';
import {
  MatterportOauthService,
  OauthState
} from '../../services/matterport-oauth.service';
import { matterport3DWalkControlToken } from '../../tokens/matterport-control.token';
import { TimelineCondensedComponent } from '../timeline-condensed/timeline-condensed.component';
import { TimelineComponent } from '../timeline/timeline.component';
const FIRST_HINT_INVERT = $localize`:@@MOVE_TO_SPOT_PRESS:
${'<p>'}
Move to the desired spot and press ${'<img style="width: 10px; filter: invert(1);" src="data:image/svg+xml;base64,PHN2ZyBpZD0iV2Fyc3R3YV8xIiBkYXRhLW5hbWU9IldhcnN0d2EgMSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB2aWV3Qm94PSIwIDAgNjAgNjAiPjxkZWZzPjxzdHlsZT4uY2xzLTF7aXNvbGF0aW9uOmlzb2xhdGU7fTwvc3R5bGU+PC9kZWZzPjxnIGNsYXNzPSJjbHMtMSI+PHBhdGggZD0iTTM1LjI3LDI0LjA2aDI1VjM0aC0yNVY2MEgyNC43NFYzNGgtMjV2LTkuOWgyNVYwSDM1LjI3WiIvPjwvZz48L3N2Zz4="></img>/<img style="width: 10px; filter: invert(1);" src="data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA2MCA2MCI+PGRlZnM+PHN0eWxlPi5jbHMtMXtmaWxsOiMwMTAxMDE7fTwvc3R5bGU+PC9kZWZzPjxnIGlkPSJUcnliX2l6b2xhY2ppIiBkYXRhLW5hbWU9IlRyeWIgaXpvbGFjamkiPjxwYXRoIGNsYXNzPSJjbHMtMSIgZD0iTTQyLjcyLDEwLjE3aC0xN2wtOS4zNiwwLDctN0ExLjg3LDEuODcsMCwwLDAsMjMuMjEuNTksMS44OCwxLjg4LDAsMCwwLDIwLjU2LjUxbC05Ljg0LDkuODRhMi41NCwyLjU0LDAsMCwwLS41MS4zNywxLjgxLDEuODEsMCwwLDAtLjMsMi40MSwyLjI0LDIuMjQsMCwwLDAsLjMzLjQzLDIuNzIsMi43MiwwLDAsMCwuMzguMzFsOS45MSw5LjkxQTEuOTQsMS45NCwwLDAsMCwyMy4yNywyMWwtNy03LDkuMzksMGgxN2E1LDUsMCwwLDEsNSw1VjM3LjIyaDRWMTkuMTRBOSw5LDAsMCwwLDQyLjcyLDEwLjE3WiIvPjxwYXRoIGNsYXNzPSJjbHMtMSIgZD0iTTUwLjA5LDQ2Ljg3YTIuMjQsMi4yNCwwLDAsMC0uMzMtLjQzLDIuNzIsMi43MiwwLDAsMC0uMzgtLjMxbC05LjkxLTkuOTFBMS45NCwxLjk0LDAsMCwwLDM2LjczLDM5bDcsN0g0MC45NHYwbC0yMiwwYTUsNSwwLDAsMS01LTVWMjMuNzhoLTRWNDAuODZhOSw5LDAsMCwwLDksOWwyMiwwdjBoMi43M2wtNyw3YTEuOTQsMS45NCwwLDAsMCwyLjc0LDIuNzNsOS44NC05Ljg0YTIuNTQsMi41NCwwLDAsMCwuNTEtLjM3QTEuODEsMS44MSwwLDAsMCw1MC4wOSw0Ni44N1oiLz48L2c+PC9zdmc+"></img>'} to place the marker.
Press ${'<img style="width: 10px; filter: invert(1);" src="data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA2MCA2MCI+PGRlZnM+PHN0eWxlPi5jbHMtMXtmaWxsOiMwMTAxMDE7fTwvc3R5bGU+PC9kZWZzPjxnIGlkPSJXYXJzdHdhXzYiIGRhdGEtbmFtZT0iV2Fyc3R3YSA2Ij48cGF0aCBjbGFzcz0iY2xzLTEiIGQ9Ik00OC41OCwxMS45NFY1Ni4yMkgxMS40MlYxMS45NEg0OC41OG0zLjc4LTMuNzhINy42NFY1Ni4yMkEzLjc4LDMuNzgsMCwwLDAsMTEuNDIsNjBINDguNThhMy43OCwzLjc4LDAsMCwwLDMuNzgtMy43OFY4LjE2WiIvPjxyZWN0IGNsYXNzPSJjbHMtMSIgeD0iMy44NCIgeT0iNy45OCIgd2lkdGg9IjUyLjMxIiBoZWlnaHQ9IjMuNzgiIHJ4PSIxLjg5Ii8+PHJlY3QgY2xhc3M9ImNscy0xIiB4PSIxOCIgeT0iMTguMzkiIHdpZHRoPSIzLjc4IiBoZWlnaHQ9IjMxLjgiLz48cmVjdCBjbGFzcz0iY2xzLTEiIHg9IjI4LjExIiB5PSIxOC4zOSIgd2lkdGg9IjMuNzgiIGhlaWdodD0iMzEuOCIvPjxyZWN0IGNsYXNzPSJjbHMtMSIgeD0iMzguNTIiIHk9IjE4LjM5IiB3aWR0aD0iMy43OCIgaGVpZ2h0PSIzMS44Ii8+PHJlY3QgY2xhc3M9ImNscy0xIiB4PSIxNS43MiIgd2lkdGg9IjI5LjEiIGhlaWdodD0iMy43OCIgcng9IjEuODkiLz48L2c+PC9zdmc+"></img>'} to delete existing position
${'</p>'}`;

const FIRST_HINT = $localize`:@@MOVE_TO_SPOT_PRESS:
${'<p>'}
Move to the desired spot and press ${'<img style="width: 10px;" src="data:image/svg+xml;base64,PHN2ZyBpZD0iV2Fyc3R3YV8xIiBkYXRhLW5hbWU9IldhcnN0d2EgMSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB2aWV3Qm94PSIwIDAgNjAgNjAiPjxkZWZzPjxzdHlsZT4uY2xzLTF7aXNvbGF0aW9uOmlzb2xhdGU7fTwvc3R5bGU+PC9kZWZzPjxnIGNsYXNzPSJjbHMtMSI+PHBhdGggZD0iTTM1LjI3LDI0LjA2aDI1VjM0aC0yNVY2MEgyNC43NFYzNGgtMjV2LTkuOWgyNVYwSDM1LjI3WiIvPjwvZz48L3N2Zz4="></img>/<img style="width: 10px;" src="data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA2MCA2MCI+PGRlZnM+PHN0eWxlPi5jbHMtMXtmaWxsOiMwMTAxMDE7fTwvc3R5bGU+PC9kZWZzPjxnIGlkPSJUcnliX2l6b2xhY2ppIiBkYXRhLW5hbWU9IlRyeWIgaXpvbGFjamkiPjxwYXRoIGNsYXNzPSJjbHMtMSIgZD0iTTQyLjcyLDEwLjE3aC0xN2wtOS4zNiwwLDctN0ExLjg3LDEuODcsMCwwLDAsMjMuMjEuNTksMS44OCwxLjg4LDAsMCwwLDIwLjU2LjUxbC05Ljg0LDkuODRhMi41NCwyLjU0LDAsMCwwLS41MS4zNywxLjgxLDEuODEsMCwwLDAtLjMsMi40MSwyLjI0LDIuMjQsMCwwLDAsLjMzLjQzLDIuNzIsMi43MiwwLDAsMCwuMzguMzFsOS45MSw5LjkxQTEuOTQsMS45NCwwLDAsMCwyMy4yNywyMWwtNy03LDkuMzksMGgxN2E1LDUsMCwwLDEsNSw1VjM3LjIyaDRWMTkuMTRBOSw5LDAsMCwwLDQyLjcyLDEwLjE3WiIvPjxwYXRoIGNsYXNzPSJjbHMtMSIgZD0iTTUwLjA5LDQ2Ljg3YTIuMjQsMi4yNCwwLDAsMC0uMzMtLjQzLDIuNzIsMi43MiwwLDAsMC0uMzgtLjMxbC05LjkxLTkuOTFBMS45NCwxLjk0LDAsMCwwLDM2LjczLDM5bDcsN0g0MC45NHYwbC0yMiwwYTUsNSwwLDAsMS01LTVWMjMuNzhoLTRWNDAuODZhOSw5LDAsMCwwLDksOWwyMiwwdjBoMi43M2wtNyw3YTEuOTQsMS45NCwwLDAsMCwyLjc0LDIuNzNsOS44NC05Ljg0YTIuNTQsMi41NCwwLDAsMCwuNTEtLjM3QTEuODEsMS44MSwwLDAsMCw1MC4wOSw0Ni44N1oiLz48L2c+PC9zdmc+"></img>'} to place the marker.
Press ${'<img style="width: 10px;" src="data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA2MCA2MCI+PGRlZnM+PHN0eWxlPi5jbHMtMXtmaWxsOiMwMTAxMDE7fTwvc3R5bGU+PC9kZWZzPjxnIGlkPSJXYXJzdHdhXzYiIGRhdGEtbmFtZT0iV2Fyc3R3YSA2Ij48cGF0aCBjbGFzcz0iY2xzLTEiIGQ9Ik00OC41OCwxMS45NFY1Ni4yMkgxMS40MlYxMS45NEg0OC41OG0zLjc4LTMuNzhINy42NFY1Ni4yMkEzLjc4LDMuNzgsMCwwLDAsMTEuNDIsNjBINDguNThhMy43OCwzLjc4LDAsMCwwLDMuNzgtMy43OFY4LjE2WiIvPjxyZWN0IGNsYXNzPSJjbHMtMSIgeD0iMy44NCIgeT0iNy45OCIgd2lkdGg9IjUyLjMxIiBoZWlnaHQ9IjMuNzgiIHJ4PSIxLjg5Ii8+PHJlY3QgY2xhc3M9ImNscy0xIiB4PSIxOCIgeT0iMTguMzkiIHdpZHRoPSIzLjc4IiBoZWlnaHQ9IjMxLjgiLz48cmVjdCBjbGFzcz0iY2xzLTEiIHg9IjI4LjExIiB5PSIxOC4zOSIgd2lkdGg9IjMuNzgiIGhlaWdodD0iMzEuOCIvPjxyZWN0IGNsYXNzPSJjbHMtMSIgeD0iMzguNTIiIHk9IjE4LjM5IiB3aWR0aD0iMy43OCIgaGVpZ2h0PSIzMS44Ii8+PHJlY3QgY2xhc3M9ImNscy0xIiB4PSIxNS43MiIgd2lkdGg9IjI5LjEiIGhlaWdodD0iMy43OCIgcng9IjEuODkiLz48L2c+PC9zdmc+"></img>'} to delete existing position
${'</p>'}`;

const DESCRIPTION_IMAGE_INFO_MATTERPORT_NOT_FOUND = [
  $localize`:@@NO_COMPONENT_IN_STAGE:The current stage does not contain a Matterport component.`,
  $localize`:@@ADD_COMPONENT_OR_USE_DESKTOP:Add the appropriate component or use the desktop version to display components not supported by the web version of the application.`
];

const DESCRIPTION_IMAGE_INFO_PUBLIC_LINK = [
  $localize`:@@NO_ACCESS_PUBLIC_LINK_1:The link leads to a scan that is private or password protected and cannot be displayed.`,
  $localize`:@@NO_ACCESS_PUBLIC_LINK_2:Contact the sender of the link.`
];

const DESCRIPTION_IMAGE_INFO_MATTERPORT_NOT_SYNCHRO = [
  $localize`:@@PROJ_DASH_MATTER_NOT_SYNCHRO_1:The component of the selected stage is not synchronized.`,
  $localize`:@@PROJ_DASH_MATTER_NOT_SYNCHRO_2:To display the component you must first synchronize it.`
];

const DESCRIPTION_IMAGE_INFO_PRIVATE_SCAN = [
  $localize`:@@NO_ACCESS_PRIV_SCAN_1:This scan is private.`,
  $localize`:@@NO_ACCESS_PRIV_SCAN_2:Log in to another matterport account to access it.`
];

const DESCRIPTION_IMAGE_INFO_MATTERPORT_NOT_EXIST_COMPONENT = [
  $localize`:@@NO_ACCESS_NOT_EXIST_COMP_1:Component does not exist on selected Matterport account.`,
  $localize`:@@NO_ACCESS_NOT_EXIST_COMP_2:(Try different account or make sure you have access).`
];

const ADD_NOTE = $localize`:@@ADD_NOTE:Add note`;

const PERMISSION_DENIED = $localize`:@@PERMISSION_DENIED:Permission denied`;

@Component({
    selector: 'simlab-matterport',
    imports: [
        CommonModule,
        UiImageInfoModule,
        UiButtonModule,
        UiIconModule,
        UiMatterportLoadingModule,
        TimelineComponent,
        TimelineCondensedComponent,
        UiMenuPanelModule,
        UiSelectModule,
        UiTooltip,
        DesignFabButtonModule,
        DesignFlatButtonModule,
        MatterportSdkBundleModule,
        DesignFabButtonMenu
    ],
    templateUrl: './matterport.component.html',
    styleUrls: ['./matterport.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [
        MatterportService,
        MatterportOauthService,
        MatterportBlueprintService
    ]
})
export default class MatterportComponent
  implements OnInit, Matterport3DWalkInterface, OnDestroy
{
  private readonly _apiFacadeService = inject(ApiFacadeService);
  private readonly _stagesFacade = inject(StagesFacade);
  private readonly _componentsFacade = inject(ComponentsFacade);
  public readonly matterportService =
    inject<MatterportService>(MATTERPORT_TOKEN);
  public readonly matterportManagerService = inject(MatterportManagerService);
  public readonly matterportBaseService = inject(MatterportBaseService);
  public readonly matterportApiService = inject(MatterportApiFacadeService);
  public readonly publicProjectId = inject<string>(projectIdPublicToken, {
    optional: true
  });
  public readonly timelinePublic = inject<TimelineType>(timelinePublicToken, {
    optional: true
  });
  public readonly matterport3DWalkControlToken =
    inject<Matterport3DWalkInterface>(matterport3DWalkControlToken, {
      optional: true
    });
  private readonly userPreferenceFacade = inject(UserPreferenceFacade);
  private readonly themeService = inject(ThemeService);
  private readonly blueprints = inject(MatterportBlueprintService);
  @ViewChild('showcaseSocket', { static: true, read: ViewContainerRef })
  private _showcaseSocket!: ViewContainerRef;

  protected readonly tagPlacementContent =
    contentChild<TemplateRef<unknown>>('tagPlacement');

  private readonly _cdr = inject(ChangeDetectorRef);

  readonly descriptionImageInfoMatterportNotFound =
    DESCRIPTION_IMAGE_INFO_MATTERPORT_NOT_FOUND;

  readonly descriptionImageInfoPublicLink = DESCRIPTION_IMAGE_INFO_PUBLIC_LINK;

  readonly descriptionImageInfoMatterportNotSynchro =
    DESCRIPTION_IMAGE_INFO_MATTERPORT_NOT_SYNCHRO;

  readonly descriptionImageInfoPrivateScan =
    DESCRIPTION_IMAGE_INFO_PRIVATE_SCAN;

  readonly descriptionImageInfoNotExistComponent =
    DESCRIPTION_IMAGE_INFO_MATTERPORT_NOT_EXIST_COMPONENT;

  readonly addNoteText = ADD_NOTE;
  readonly permissionDeniedText = PERMISSION_DENIED;

  private _blockPrivateLink = false;
  @Input() set blockPrivateLink(value: boolean) {
    this._blockPrivateLink = value;
  }
  get blockPrivateLink() {
    return this._blockPrivateLink;
  }

  private _screenshotMode = false;
  @Input() set screenshotMode(value: boolean) {
    this._screenshotMode = value;
    this._cdr.markForCheck();
  }
  get screenshotMode() {
    return this._screenshotMode;
  }

  @Input() initialPosition?: any; // Camera.Pose not work.........
  @Input() initialFloor?: number;
  private _localizationPanelOpen$: Observable<boolean> | undefined;
  @Input() set localizationPanelOpen$(value: Observable<boolean> | undefined) {
    this._localizationPanelOpen$ = value?.pipe(
      distinctUntilChanged(),
      switchMap((state: boolean) => {
        if (state) {
          return this.themeService.update$.pipe(
            map((theme) => {
              this.matterportService.setHint(
                theme === 'light' ? FIRST_HINT : FIRST_HINT_INVERT,
                {},
                'var(--ui-theme-surface-primary)',
                'var(--ui-theme-text-primary)'
              );
              return true;
            })
          );
        } else {
          return of(false).pipe(
            tap(() => {
              this.matterportService.setHint(undefined);
            })
          );
        }
      })
    );
  }
  get localizationPanelOpen$(): Observable<boolean> | undefined {
    return this._localizationPanelOpen$;
  }

  @Output() moveToSplitView: EventEmitter<Camera.Pose | undefined> =
    new EventEmitter<Camera.Pose | undefined>();

  readonly hasSpinner$: Observable<boolean> =
    this.matterportBaseService.hasSpinner$;

  readonly isCompletelyLoaded$: Observable<boolean> =
    this.matterportBaseService.isCompletelyLoaded$;

  readonly hasMatterport$: Observable<boolean> =
    this.matterportBaseService.hasMatterport$;

  get hideOverlayElements() {
    return this.matterportBaseService.hideOverlayElements;
  }

  readonly timelineModeExpanded$: Observable<boolean> =
    this.userPreferenceFacade.getTimelineMode$.pipe(
      map((timelineMode: TimelineMode) => {
        if (this.timelinePublic) {
          return this.timelinePublic === 'Expanded';
        }

        return timelineMode === TimelineMode.Expanded;
      })
    );

  readonly containStages$: Observable<boolean> =
    this._stagesFacade.containsAnyStage$;

  @Output() oauthStateEventEmitter: EventEmitter<Observable<string>> =
    new EventEmitter<Observable<string>>();

  readonly oauthState$: Observable<string> =
    this.matterportBaseService.oauthState$.pipe(
      map((state: OauthState) => {
        switch (state) {
          case OauthState.ERROR_CLIENT:
          case OauthState.ERROR_PRIVATE:
            return 'login';

          case OauthState.ERROR_PROTECTED:
            return 'outside';

          case OauthState.NOT_SYNCHRONIZED_COMPONENT:
            return 'notSynchronized';

          case OauthState.NOT_ENABLE_PUBLIC_LINK:
            return 'not_enable_public_link';

          default:
            return 'other';
        }
      })
    );
  readonly measurementToolsEnabled =
    this.matterport3DWalkControlToken?.openMeasurementTool !== undefined;

  readonly canAddNote$: Observable<boolean> =
    this.matterport3DWalkControlToken?.canAddNote$ ?? of(true);
  readonly refreshOpenScan$ = () =>
    this._apiFacadeService.tokens.removeMatterportUserToken().pipe(
      tap(() => {
        this.matterportApiService.deleteToken();
        this.matterportBaseService.refreshOpenScan.next();
      })
    );

  readonly matterportIsOpen$ = this.matterportBaseService.matterportIsOpen$;

  ngOnDestroy(): void {
    this.matterportService.destroy();
  }

  ngOnInit(): void {
    if (this.initialPosition)
      this.matterportBaseService.lastKnownCameraPose = this.initialPosition;

    this.matterportBaseService.lastKnownFloor = this.initialFloor;
    this.matterportBaseService.start(
      this._showcaseSocket,
      this.blockPrivateLink
    );
  }

  deleteNote(noteId: string): void {
    this.matterportService.deleteNote(noteId);
  }

  goToSplitView() {
    this.matterport3DWalkControlToken?.goToSplitView(
      this.matterportBaseService.lastKnownCameraPose,
      this.matterportBaseService.lastKnownFloor
    );
  }

  openProjectInfo() {
    if (this.matterport3DWalkControlToken?.openProjectInfo) {
      this.matterport3DWalkControlToken.openProjectInfo();
    }
  }
  openMeasurementTool(type: 'area' | 'distance') {
    if (this.matterport3DWalkControlToken?.openMeasurementTool) {
      this.matterport3DWalkControlToken.openMeasurementTool(type);
    }
  }

  addNote() {
    if (this.matterport3DWalkControlToken?.addNote) {
      this.matterport3DWalkControlToken.addNote();
    }
  }

  moveTo(response: { annotationType: AnnotationModules; flag: boolean }): void {
    this.matterportBaseService.goToSelectedMarker(response.annotationType);

    if (!response.flag) firstValueFrom(this.matterportBaseService.goToMarker$);
  }
}
