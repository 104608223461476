import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output, inject } from '@angular/core';
import { DesignIconButton } from '@simlab/design/button';
import { DesignIcon } from '@simlab/design/icon';
import { ICON_FONT_TYPE } from '@simlab/ui/icon';
import { HamburgerService } from '@simlab/util/core';

@Component({
    selector: 'simlab-hamburger',
    template: `<button design-icon-button (click)="toggle()">
    <i
      class="hamburger-icon"
      [designIcon]="name"
      designButtonColor="primary"
      [ngClass]="{ open: open === true, close: open === false }"
    ></i>
  </button>`,
    styleUrls: ['./hamburger.component.scss'],
    imports: [CommonModule, DesignIconButton, DesignIcon],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class HamburgerComponent {
      private readonly hamburgerService = inject(HamburgerService);
  @Output() clicked: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Input() size!: string;
  @Input() name!: ICON_FONT_TYPE;
  @Input() color!: string;

  get open(): boolean {
    return this.hamburgerService.state;
  }

  toggle(): void {
    this.hamburgerService.toggle();
    this.clicked.next(this.hamburgerService.state);
  }
}
