import { BooleanInput, coerceBooleanProperty } from '@angular/cdk/coercion';
import {
  AfterViewInit,
  Component,
  ElementRef,
  HostBinding,
  Input,
  Optional,
  Renderer2,
  Self,
  ViewChild,
  ViewEncapsulation
} from '@angular/core';
import { ControlValueAccessor, NgControl, Validators } from '@angular/forms';
import { DatePickerComponent, IDatePickerConfig } from 'ng-datepicker';
import { FormFieldControl } from '../../../ui-form-field/directives/form-field-control.directive';

@Component({
  selector: 'ui-datepicker',
  templateUrl: './datepicker.component.html',
  styleUrls: ['./datepicker.component.scss'],
  encapsulation: ViewEncapsulation.None,
  standalone: false
})
export class DatepickerComponent
  implements AfterViewInit, ControlValueAccessor, FormFieldControl<any>
{
  @HostBinding('class.ui-calendar') class = true;
  @ViewChild(DatePickerComponent, { static: true, read: ElementRef })
  dp!: ElementRef;
  readonly config: IDatePickerConfig = {
    format: 'YYYY-MM-DD'
  };
  private _value!: string;
  private _placeholder = '';

  @Input()
  set placeholder(val: string) {
    this._placeholder = val;
  }

  get placeholder(): string {
    return this._placeholder;
  }

  @Input()
  set value(val: string) {
    this._value = val;
  }

  get value(): string {
    return this._value;
  }
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  onTouched = () => {};

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  onChange: (value: any) => void = () => {};
  // constructor(private readonly renderer: Renderer2) {}

  ngAfterViewInit(): void {
    const icon = this.renderer.createElement('img');
    this.renderer.addClass(icon, 'dp-icon');
    this.renderer.setAttribute(
      icon,
      'src',
      'data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA2MCA2MCI+PGRlZnM+PHN0eWxlPi5jbHMtMXtmaWxsOiMwMTAxMDE7fTwvc3R5bGU+PC9kZWZzPjxnIGlkPSJXYXJzdHdhXzYiIGRhdGEtbmFtZT0iV2Fyc3R3YSA2Ij48cGF0aCBjbGFzcz0iY2xzLTEiIGQ9Ik01MC44MSw5LjczSDQ3LjN2LTZBMy43OSwzLjc5LDAsMCwwLDQzLjUxLDBoMGEzLjc4LDMuNzgsMCwwLDAtMy43OCwzLjc4djZIMjAuMjd2LTZBMy43OCwzLjc4LDAsMCwwLDE2LjQ5LDBoMEEzLjc5LDMuNzksMCwwLDAsMTIuNywzLjc4djZIOS4xOWE0LjMzLDQuMzMsMCwwLDAtNC4zMyw0LjMyVjU1LjY4QTQuMzMsNC4zMywwLDAsMCw5LjE5LDYwSDUwLjgxYTQuMzMsNC4zMywwLDAsMCw0LjMzLTQuMzJWMTQuMDVBNC4zMyw0LjMzLDAsMCwwLDUwLjgxLDkuNzNabS41NCw0My43OHYyLjcxSDguNjV2LTMzaDQyLjdaIi8+PHJlY3QgY2xhc3M9ImNscy0xIiB4PSIxNC42NSIgeT0iNDMuMDMiIHdpZHRoPSI1Ljg0IiBoZWlnaHQ9IjUuODQiIHRyYW5zZm9ybT0idHJhbnNsYXRlKDM1LjE0IDkxLjg5KSByb3RhdGUoMTgwKSIvPjxyZWN0IGNsYXNzPSJjbHMtMSIgeD0iMjcuMDgiIHk9IjMwLjU5IiB3aWR0aD0iNS44NCIgaGVpZ2h0PSI1Ljg0IiB0cmFuc2Zvcm09InRyYW5zbGF0ZSg2MCA2Ny4wMykgcm90YXRlKDE4MCkiLz48cmVjdCBjbGFzcz0iY2xzLTEiIHg9IjI3LjA4IiB5PSI0My4wMyIgd2lkdGg9IjUuODQiIGhlaWdodD0iNS44NCIgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoNjAgOTEuODkpIHJvdGF0ZSgxODApIi8+PHJlY3QgY2xhc3M9ImNscy0xIiB4PSIzOS41MSIgeT0iMzAuNTkiIHdpZHRoPSI1Ljg0IiBoZWlnaHQ9IjUuODQiIHRyYW5zZm9ybT0idHJhbnNsYXRlKDg0Ljg2IDY3LjAzKSByb3RhdGUoMTgwKSIvPjxyZWN0IGNsYXNzPSJjbHMtMSIgeD0iMzkuNTEiIHk9IjQzLjAzIiB3aWR0aD0iNS44NCIgaGVpZ2h0PSI1Ljg0IiB0cmFuc2Zvcm09InRyYW5zbGF0ZSg4NC44NiA5MS44OSkgcm90YXRlKDE4MCkiLz48cmVjdCBjbGFzcz0iY2xzLTEiIHg9IjE0LjY1IiB5PSIzMC41OSIgd2lkdGg9IjUuODQiIGhlaWdodD0iNS44NCIgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoMzUuMTQgNjcuMDMpIHJvdGF0ZSgxODApIi8+PC9nPjwvc3ZnPg=='
    );
    this.renderer.appendChild(this.dp.nativeElement, icon);
  }

  writeValue(obj: any): void {
    this._value = obj;
  }
  registerOnChange(fn: any): void {
    this.onChange = fn;
  }
  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  onDateChange(event: any) {
    if (event?.date?.$d) {
      const date = new Date(event?.date?.$d);
      const month =
        date.getMonth() < 9 // In the Date, the months begin from 0
          ? 0 + (date.getMonth() + 1).toString()
          : date.getMonth() + 1;
      const day =
        date.getDate() < 10 ? 0 + date.getDate().toString() : date.getDate();
      const year = date.getFullYear();
      const dateString = `${year}-${month}-${day}`;
      this._value = dateString;
      this.onChange(this._value);
    } else {
      this.onChange('');
    }
  }

  /*
    //TODO: changes will be make from here!
  */
  @Input()
  get required(): boolean {
    return (
      this._required ??
      this.ngControl?.control?.hasValidator(Validators.required) ??
      false
    );
  }
  set required(value: BooleanInput) {
    this._required = coerceBooleanProperty(value);
  }
  // eslint-disable-next-line @typescript-eslint/member-ordering
  _required: boolean | undefined;

  constructor(
    @Optional()
    @Self()
    private ngControl: NgControl,
    private readonly renderer: Renderer2
  ) {
    if (this.ngControl != null) {
      ngControl.valueAccessor = this;
    }
  }
}
