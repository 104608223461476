import {
  Inject,
  Injectable,
  LOCALE_ID,
  OnDestroy,
  inject
} from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Params, Router } from '@angular/router';
import {
  ApiFacadeService,
  Collaborator,
  FormatDate,
  Note,
  OrganizationLimitsFacade,
  Stage,
  TForm
} from '@simlab/data-access';

import { MatterportAnnotationControlService } from '@simlab/annotation/data-access';
import {
  FULL_EMPTY_NOTE,
  NEW_NOTE_LOCALLY_ID,
  NotesFacade,
  ProjectPrivilegesFacade,
  StagesFacade,
  UserPreferenceFacade
} from '@simlab/data-store';
import { ToastService } from '@simlab/design/toast';
import {
  ConfirmationModalRef,
  MODAL_DATA,
  ModalService
} from '@simlab/ui/modal';
import { RouterFacadeService, RouterStoreParams } from '@simlab/util-shared';
import { OidcSecurityService } from 'angular-auth-oidc-client';
import { NoteExtendStatus, NoteType } from 'common/annotation-note/models';
import {
  BehaviorSubject,
  Observable,
  Subject,
  catchError,
  combineLatest,
  debounceTime,
  distinctUntilChanged,
  filter,
  forkJoin,
  from,
  iif,
  map,
  mergeMap,
  of,
  pairwise,
  startWith,
  switchMap,
  take,
  takeUntil,
  tap
} from 'rxjs';
import { ProjectLimitsService } from './project-limits.service';

interface AddNote {
  noteId: string;
  stageId: string;
}

export interface INote {
  name: string;
  noteType: NoteType;
  noteStatus: NoteExtendStatus<INote['noteType']>;
  stakeholderId: string;
  description: string;
  stageId: string;
  id: string;
}

export type NoteForm = TForm<INote>;

const FORM_CHANGE_DEBOUNCE = 500;

const INFO_STATUS_UPDATED = $localize`:@@INFO_STATUS_UPDATED:status updated`;
const NOTE_INFO_LIMITS = $localize`:@@LIMIT_OF_NOTES_OVER:Limit of notes is over. \nGo to a higher plan to solve the problem.`;
const INFO_TYPE_UPDATED = $localize`:@@INFO_TYPE_UPDATED:type updated`;
const INFO_TYPE_STATUS_UPDATED = $localize`:@@INFO_TYPE_STATUS_UPDATED:type and status updated`;
const INFO_DESCRIPTION_UPDATED = $localize`:@@INFO_DESCRIPTION_UPDATED:description updated`;
const INFO_STAKEHOLDER_UPDATED = $localize`:@@INFO_STAKEHOLDER_UPDATED:stakeholder updated`;
const INFO_NOTE_MUST_CONTAINT = $localize`:@@INFO_NOTE_MUST_CONTAINT:note name must contain 3-75 characters.`;
const INFO_NAME_UPDATED = $localize`:@@INFO_NAME_UPDATED:name updated`;

@Injectable()
export class PanelRightService implements OnDestroy {
  private readonly _matterportAnnotationControl = inject(
    MatterportAnnotationControlService
  );
  private readonly _userPreferenceFacade = inject(UserPreferenceFacade);
  private readonly _destroySource: Subject<void> = new Subject<void>();
  private readonly _formGroup: FormGroup<NoteForm> = new FormGroup<NoteForm>({
    name: new FormControl('', {
      validators: [
        Validators.required,
        Validators.minLength(3),
        Validators.maxLength(75)
      ],
      nonNullable: true
    }),
    noteType: new FormControl('Information', { nonNullable: true }),
    noteStatus: new FormControl('None', { nonNullable: true }),
    stakeholderId: new FormControl('', { nonNullable: true }),
    description: new FormControl('', {
      validators: [Validators.maxLength(2000)],
      nonNullable: true
    }),
    stageId: new FormControl('', {
      validators: [Validators.required],
      nonNullable: true
    }),
    id: new FormControl('', { nonNullable: true })
  });

  private readonly _routerFacade = inject(RouterFacadeService);
  private readonly _noteOwnerSource = new BehaviorSubject<boolean>(false);
  private readonly _noteOwner$: Observable<boolean> =
    this._noteOwnerSource.asObservable();

  readonly renameAndChangeDescriptionNote$: Observable<boolean> =
    this._noteOwner$.pipe(
      switchMap((state: boolean) =>
        iif(
          () => state,
          this.privilegesFacade.addEditDeleteOwnNotes$,
          this.privilegesFacade.renameNote$
        )
      )
    );

  readonly hasAdminRoleInProject$: Observable<boolean> =
    this.privilegesFacade.hasAdminRoleInProject$;

  readonly deleteNote$: Observable<boolean> = this._noteOwner$.pipe(
    switchMap((state: boolean) =>
      iif(
        () => state,
        this.privilegesFacade.addEditDeleteOwnNotes$,
        this.privilegesFacade.deleteNote$
      )
    )
  );

  readonly addReplaceMarker$: Observable<boolean> = this._noteOwner$.pipe(
    startWith(false),
    switchMap((state: boolean) =>
      iif(
        () => state,
        this.privilegesFacade.addEditDeleteOwnNotes$,
        this.privilegesFacade.addReplaceMarker$
      )
    ),
    mergeMap((state: boolean) =>
      forkJoin([
        of(state),
        this.stagesFacade.hasSynchronizedShowcase$.pipe(take(1))
      ])
    ),
    map(([hasPrivileges, hasShowcase]) => hasPrivileges && hasShowcase)
  );

  readonly addEditDeleteOwnElements$: Observable<boolean> =
    this._noteOwner$.pipe(
      switchMap((state: boolean) =>
        iif(
          () => state,
          this.privilegesFacade.addEditDeleteOwnNotes$,
          this.privilegesFacade.addEditDeleteOwnElements$
        )
      )
    );

  readonly changeNoteStatus$: Observable<boolean> = this._noteOwner$.pipe(
    switchMap((state: boolean) =>
      iif(
        () => state,
        this.privilegesFacade.addEditDeleteOwnNotes$,
        this.privilegesFacade.changeNoteStatus$
      )
    )
  );

  readonly changeStakeholder$: Observable<boolean> = this._noteOwner$.pipe(
    switchMap((state: boolean) =>
      iif(
        () => state,
        this.privilegesFacade.addEditDeleteOwnNotes$,
        this.privilegesFacade.changeStakeholder$
      )
    )
  );

  readonly addEditDeleteComments$: Observable<boolean> = this._noteOwner$.pipe(
    switchMap((state: boolean) =>
      iif(
        () => state,
        this.privilegesFacade.addEditDeleteOwnNotes$,
        this.privilegesFacade.addEditDeleteOwnComments$
      )
    )
  );

  readonly collaborators$: Observable<Collaborator[]> =
    this.stagesFacade.getRouteNestedParams$.pipe(
      take(1),
      switchMap((routerParams: RouterStoreParams) => {
        return iif(
          () => routerParams.params['projectId'],
          this.apiFacadeService.projects.getProjectCollaborators({
            projectId: routerParams.params['projectId'],
            excludeViewers: true
          }),
          of([] as Collaborator[])
        );
      })
    );

  readonly stageName$: Observable<string> =
    this.stagesFacade.selectedStages$.pipe(
      map((stage: Stage | undefined) => {
        return stage?.name ?? '';
      })
    );

  readonly selectedNote$: Observable<Note> = this.notesFacade.selectedNote$;

  readonly selectedNoteId$: Observable<string> =
    this.notesFacade.selectedNoteId$;
  private _emitEvents = true;

  public get formGroup(): FormGroup<NoteForm> {
    return this._formGroup;
  }

  get nameControl(): FormControl<string> {
    return this._formGroup.controls.name;
  }

  get stakeholderControl(): FormControl<string> {
    return this._formGroup.controls.stakeholderId;
  }

  get noteTypeControl() {
    return this._formGroup.controls.noteType;
  }

  get noteStatusControl(): FormControl<string> {
    return this._formGroup.controls.noteStatus;
  }

  get descriptionControl(): FormControl<string> {
    return this._formGroup.controls.description;
  }

  readonly projectIsActive$: Observable<boolean> =
    this.projectLimitsService.projectIsActive$;

  constructor(
    @Inject(LOCALE_ID) private readonly locale: string,
    private readonly notesFacade: NotesFacade,
    private readonly stagesFacade: StagesFacade,
    private readonly route: ActivatedRoute,
    private readonly apiFacadeService: ApiFacadeService,
    private readonly privilegesFacade: ProjectPrivilegesFacade,
    private readonly router: Router,
    private readonly projectLimitsService: ProjectLimitsService,
    private readonly oidcSecurityService: OidcSecurityService,
    private readonly toastService: ToastService,
    private readonly modalService: ModalService,
    private readonly organizationLimitsFacade: OrganizationLimitsFacade
  ) {
    this._formGroup.controls.name.disable({ emitEvent: false });
    this._formGroup.controls.noteType.disable({ emitEvent: false });
    this._formGroup.controls.noteStatus.disable({ emitEvent: false });
    this._formGroup.controls.stakeholderId.disable({ emitEvent: false });
    this._formGroup.controls.description.disable({ emitEvent: false });
    this._noteSelectionObserver();
    this._privilegesObserver();
    this._valueChangeObserver();
  }

  private _timer!: NodeJS.Timeout;

  private setTimeoutForToastWithInfoUpdatedName() {
    this._timer = setTimeout(() => {
      this.toastService.open(INFO_NAME_UPDATED, 'Success');
    }, 2000);
  }

  private _valueChangeObserver(): void {
    this._formGroup.valueChanges
      .pipe(
        pairwise(),
        tap(([prev, next]) => {
          if (prev.name && prev.name !== next.name) {
            clearTimeout(this._timer);
          }
        }),
        debounceTime(FORM_CHANGE_DEBOUNCE),
        filter(([prev, next]) => prev.id === next.id),
        switchMap(([prev, next]) => {
          if (
            prev.id === NEW_NOTE_LOCALLY_ID &&
            next.id === NEW_NOTE_LOCALLY_ID
          ) {
            this._emitEvents = false;
            this.notesFacade.saveLocalNote();
            return this.notesFacade.noteAdded$.pipe(
              map(({ id }: Note) => {
                this._formGroup.patchValue({
                  id
                });

                next = { ...next, id };
                prev = { ...prev };
                this._emitEvents = true;
                return [prev, next];
              })
            );
          }

          return of([prev, next]);
        }),
        mergeMap(([prev, next]) => {
          const changes: { [name: string]: Observable<any> } = {};
          if (prev.name && prev.name !== next.name && this.nameControl.valid) {
            changes['name'] = this.apiFacadeService.rootNotes
              .editRootNoteName({
                rootNoteId: next.id as string,
                newName: next.name as string
              })
              .pipe(
                tap(() => {
                  this.notesFacade.changeNoteName({
                    name: next.name as string
                  });
                  this.setTimeoutForToastWithInfoUpdatedName();
                }),

                catchError((e) => {
                  this.toastService.open(e.error.errorMessage, 'Error');
                  return of();
                })
              );
          }
          if (
            !this.nameControl.valid &&
            this.nameControl.errors &&
            (this.nameControl.errors['maxlength'] ||
              this.nameControl.errors['minlength'])
          ) {
            this.toastService.open(INFO_NOTE_MUST_CONTAINT, 'Error');
          }

          if (prev.stakeholderId !== next.stakeholderId) {
            changes['stakeholderId'] = this.apiFacadeService.rootNotes
              .changeRootNoteStakeholder({
                rootNoteId: next.id as string,
                stakeholderId: next.stakeholderId as string
              })
              .pipe(
                tap(() => {
                  this.notesFacade.changeNoteStakeholder({
                    stakeholderId: next.stakeholderId as string
                  });

                  this.toastService.open(INFO_STAKEHOLDER_UPDATED, 'Success');
                })
              );
          }

          if (
            prev.description !== next.description &&
            this.descriptionControl.valid
          ) {
            changes['description'] = this.apiFacadeService.rootNotes
              .editRootNoteDescription({
                rootNoteId: next.id as string,
                newDescription: next.description as string
              })
              .pipe(
                tap(() => {
                  this.notesFacade.changeNoteDescription({
                    nodeDescription: next.description as string
                  });

                  this.toastService.open(INFO_DESCRIPTION_UPDATED, 'Success');
                })
              );
          }

          if (
            prev.noteStatus !== next.noteStatus &&
            prev.noteType !== next.noteType
          ) {
            this.toastService.open(INFO_TYPE_STATUS_UPDATED, 'Success');
          }

          if (prev.noteType !== next.noteType) {
            changes['noteType'] = this.apiFacadeService.rootNotes
              .changeRootNoteType({
                rootNoteId: next.id as string,
                newRootNoteType: next.noteType as string
              })
              .pipe(
                tap(() => {
                  if (next.noteType === undefined) {
                    console.error('NoteType is undefined');
                    return;
                  }
                  this.notesFacade.changeNoteType({
                    nodeType: next.noteType
                  });
                  if (prev.noteStatus === next.noteStatus)
                    this.toastService.open(INFO_TYPE_UPDATED, 'Success');
                })
              );

            this.noteStatusControl.patchValue(
              next.noteType === 'Information' ? 'None' : 'Pending'
            );
          } else {
            if (prev.noteStatus !== next.noteStatus) {
              changes['noteStatus'] = this.apiFacadeService.rootNotes
                .changeRootNoteStatus({
                  rootNoteId: next.id as string,
                  newRootNoteStatus:
                    (next.noteType as string) === 'Information'
                      ? ''
                      : (next.noteStatus as string)
                })
                .pipe(
                  tap(() => {
                    if (next.noteStatus === undefined) {
                      console.error('NoteStatus is undefined');
                      return;
                    }
                    this.notesFacade.changeNoteStatus({
                      status: next.noteStatus
                    });

                    if (prev.noteStatus !== 'None')
                      this.toastService.open(INFO_STATUS_UPDATED, 'Success');
                  })
                );
            }
          }

          return forkJoin(changes);
        }),
        takeUntil(this._destroySource)
      )
      .subscribe();
  }

  private _noteSelectionObserver(): void {
    this.selectedNote$
      .pipe(
        distinctUntilChanged((previous, current) => previous.id === current.id),
        filter(() => this._emitEvents),
        tap((note: Note) => {
          this._noteOwnerSource.next(note.isOwner);
          if (note.id === NEW_NOTE_LOCALLY_ID) this.nameControl.enable();
          else this.nameControl.disable();
          this._formGroup.patchValue({
            description: note.description,
            name: note.name,
            stakeholderId: note.stakeholderId,
            noteStatus: note.status,
            noteType: note.type,
            id: note.id,
            stageId: note.stageId
          });
        }),
        takeUntil(this._destroySource)
      )
      .subscribe();
  }

  private _privilegesObserver(): void {
    this.changeStakeholder$
      .pipe(
        switchMap((state: boolean) =>
          this.projectIsActive$.pipe(
            tap((projectIsActive: boolean) => {
              if (projectIsActive === false || !state) {
                this.stakeholderControl.disable({ emitEvent: false });
                return;
              }
              this.stakeholderControl.enable({ emitEvent: false });
            })
          )
        ),
        takeUntil(this._destroySource)
      )
      .subscribe();

    combineLatest([
      this.noteTypeControl.valueChanges.pipe(
        startWith(this.noteTypeControl.value),
        distinctUntilChanged()
      ),
      this.changeNoteStatus$
    ])
      .pipe(
        switchMap(([noteTypeValue, permissionState]) =>
          this.projectIsActive$.pipe(
            tap((projectIsActive: boolean) => {
              if (projectIsActive === false) {
                this.noteStatusControl.disable({ emitEvent: false });
                this.noteTypeControl.disable({ emitEvent: false });
                return;
              }

              if (permissionState) {
                this.noteTypeControl.enable({ emitEvent: false });
                if (noteTypeValue === 'Information') {
                  this.noteStatusControl.disable({ emitEvent: false });
                } else {
                  this.noteStatusControl.enable({ emitEvent: false });
                }
              } else {
                this.noteTypeControl.disable({ emitEvent: false });
                this.noteStatusControl.disable({ emitEvent: false });
              }
            })
          )
        ),
        takeUntil(this._destroySource)
      )
      .subscribe();

    this.renameAndChangeDescriptionNote$
      .pipe(
        switchMap((state: boolean) =>
          this.projectIsActive$.pipe(
            tap((projectIsActive: boolean) => {
              if (projectIsActive === false || !state) {
                this.descriptionControl.disable({ emitEvent: false });
                return;
              }
              this.descriptionControl.enable({ emitEvent: false });
            })
          )
        ),
        takeUntil(this._destroySource)
      )
      .subscribe();
  }

  addNote$(): Observable<boolean> {
    return this.stagesFacade.getRouteNestedParams$.pipe(
      take(1),
      switchMap((routerParams: RouterStoreParams) =>
        this.projectLimitsService.projectIsActive$.pipe(
          take(1),
          switchMap((isActivate: boolean) => {
            if (isActivate) {
              return this.stagesFacade.selectedId$.pipe(
                switchMap((stageId: string) =>
                  this.organizationLimitsFacade
                    .canAddNoteToStage(
                      routerParams.params['projectId'] as string
                    )
                    .pipe(
                      switchMap((possibleToAdd: boolean) =>
                        iif(
                          () => possibleToAdd,
                          this._userPreferenceFacade.getDateFormat$.pipe(
                            map((userFormatDate: FormatDate) => {
                              this.setLocallyNoteAsSelected(
                                FULL_EMPTY_NOTE(
                                  stageId,
                                  userFormatDate + ' HH:mm:ss'
                                )
                              );
                              return true;
                            })
                          ),
                          of(false).pipe(
                            mergeMap(() =>
                              this.stagesFacade.getRouteNestedParams$.pipe(
                                switchMap(
                                  (routerStoreParams: RouterStoreParams) =>
                                    this.organizationLimitsFacade.getOrganizationId(
                                      routerStoreParams.params['projectId']
                                    )
                                ),
                                switchMap((organizationId: string) =>
                                  from(
                                    this._noAccessLimitDialog(organizationId)
                                  ).pipe(
                                    switchMap((component) => component.events$)
                                  )
                                )
                              )
                            ),
                            map(() => false)
                          )
                        )
                      )
                    )
                )
              );
            }
            return from(this._openProjectViewOnlyInfoDialog()).pipe(
              switchMap((component) => component.events$.pipe(map(() => false)))
            );
          })
        )
      ),
      takeUntil(this._destroySource),
      take(1)
    );
  }

  private _setBaseParams(noteId: string): void {
    const queryParams: Params = { noteId, sidenavContent: 'note' };
    this._routerFacade.setQueryParams(undefined, queryParams, 'merge');
  }

  changeSelectionNote(noteId: string): void {
    this._setBaseParams(noteId);
    this.notesFacade.selectedNoteId(noteId);
  }

  setLocallyNoteAsSelected(note: Note): void {
    this._setBaseParams(note.id);
    this.notesFacade.setLocallyNoteAsSelected(note);
  }

  moveTo(fn: () => void): void {
    this._matterportAnnotationControl.moveToAnnotation('notes', fn);
  }

  addLocalization() {
    this._matterportAnnotationControl.triggerAnnotationMarkerAddMode(
      'notes',
      true
    );
  }

  changeLocalization() {
    this._matterportAnnotationControl.triggerAnnotationMarkerChangeMode(
      'notes'
    );
  }

  navigateToMatterport(): Promise<boolean> {
    return new Promise<boolean>((resolve) => {
      if (this.route.snapshot.firstChild?.routeConfig?.path !== 'matterport') {
        this.router
          .navigate(['matterport'], {
            queryParamsHandling: 'preserve',
            relativeTo: this.route
          })
          .then(() => {
            resolve(true);
          });
      } else {
        resolve(false);
      }
    });
  }

  ngOnDestroy(): void {
    this._destroySource.next();
    this._destroySource.complete();
  }

  private async _openProjectViewOnlyInfoDialog(): Promise<
    ConfirmationModalRef<
      import('@simlab/feature/projects').ViewOnlyDialogComponent
    >
  > {
    const component = (await import('@simlab/feature/projects'))
      .ViewOnlyDialogComponent;
    return this.modalService.createModalWithProviders(component, {
      centered: true,
      offset: {
        x: 0,
        y: 0
      },
      maxWidth: 'min(90%, 380px)',
      minWidth: 'min(90%, 380px)',
      maxHeight: '90%'
    });
  }

  private async _noAccessLimitDialog(
    organizationId: string
  ): Promise<
    ConfirmationModalRef<
      import('@simlab/feature/projects').NoAccessDialogComponent
    >
  > {
    const component = (await import('@simlab/feature/projects'))
      .NoAccessDialogComponent;
    return this.modalService.createModalWithProviders(
      component,
      {
        width: '100%',
        maxWidth: 'min(90%, 380px)',
        maxHeight: 'min(90%, 1000px)'
      },
      [
        {
          provide: MODAL_DATA,
          useValue: {
            organizationId,
            text: NOTE_INFO_LIMITS
          }
        }
      ]
    );
  }
}
