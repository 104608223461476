import {
  ChangeDetectionStrategy,
  Component,
  inject,
  input,
  signal
} from '@angular/core';
import { DesignIconButton } from '@simlab/design/button';
import { DesignIcon } from '@simlab/design/icon';
import { UiMenuPanelModule } from '@simlab/design/menu-panel';
import { annotationsActionsToken } from '../../../../models/src/lib/annotation-actions';
import { AnnotationPanelHeaderActionComponent } from '../annotation-panel-header-action/annotation-panel-header-action.component';

@Component({
    selector: 'lib-annotation-panel-header-btn',
    imports: [
        UiMenuPanelModule,
        AnnotationPanelHeaderActionComponent,
        DesignIcon,
        DesignIconButton
    ],
    templateUrl: './annotation-panel-header-btn.component.html',
    styleUrl: './annotation-panel-header-btn.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class AnnotationPanelHeaderBtnComponent {
  private readonly _actions = inject(annotationsActionsToken);
  readonly isMenuTriggerVisible = input<boolean>(true);
  readonly actionsList = signal(this._actions.actionList);
  readonly closePanel = () => this._actions.closePanel();
}
