<ng-container [formGroup]="form()">
  <ui-form-field class="cost-impact__select">
    <label designLabel i18n="@@COST_IMPACT">Cost Impact</label>
    <ui-select
      i18n-placeholder="@@SELECT"
      placeholder="Select"
      formControlName="costImpact"
    >
      @for (impact of impactOptions; track impact) {
        <ui-option [value]="impact">
          {{ impact !== null ? (impact | getProcoreImpactLabel) : '&nbsp;' }}
        </ui-option>
      }
    </ui-select>
  </ui-form-field>

  @if (isImpactAmountControlVisible()) {
    <design-form-field class="cost-impact__amount">
      <label designLabel i18n="@@COST_IMPACT_AMOUNT"> Amount </label>
      <input designInput formControlName="costImpactAmount" />
      <design-error></design-error>
    </design-form-field>
  }
</ng-container>
