import { NgFor, NgIf } from '@angular/common';
import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { DesignFlatButton } from '@simlab/design/button';
import { DesignDialogWrapperModule } from '@simlab/design/dialog';
import { UiButtonModule } from '@simlab/ui/button';
import { ICON_TYPE, UiIconModule } from '@simlab/ui/icon';
import { ConfirmationModalRef, MODAL_DATA } from '@simlab/ui/modal';
import { ReportErrors } from '../mattertag-to-root-note';

export type ImportSummary = {
  type: 'success' | 'warning' | 'error';
  reports: ReportErrors[];
};

export const IMPORT_SUMMARY_MESSAGE: Record<
  ImportSummary['type'],
  { title: string; message: string; icon: ICON_TYPE }
> = {
  success: {
    title: $localize`:@@IMPORT_SUCCESSFUL:Import Successful`,
    message: $localize`:@@PU_IMPORTED_ALL_MATTERTAGS_SUCCESSFULLY:Imported all Mattertags into the project successfully.`,
    icon: 'icon_success_color'
  },
  warning: {
    title: $localize`:@@IMPORT_COMPLETED_WITH_WARNINGS:Import Completed with Warnings`,
    message: $localize`:@@PU_IMPORT_COMPLETED_SOME_ISSUES_OCCURRED:The import was completed, but some issues occurred during the process. Please check the report.`,
    icon: 'icon_warning_color'
  },
  error: {
    title: $localize`:@@IMPORT_ERROR:Import Error`,
    message: $localize`:@@SOMETHING_WENT_WRONG_PLEASE_TRY_AGAIN:Something went wrong. Please try again later.`,
    icon: 'icon_error_color'
  }
};
@Component({
    imports: [
    DesignDialogWrapperModule,
    UiButtonModule,
    UiIconModule,
    NgIf,
    NgFor,
    DesignFlatButton
],
    templateUrl: './tag-import-finish-modal.component.html',
    styleUrls: ['./tag-import-finish-modal.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class TagImportFinishModalComponent {
  readonly title = IMPORT_SUMMARY_MESSAGE[this.summary.type].title;
  readonly message = IMPORT_SUMMARY_MESSAGE[this.summary.type].message;
  readonly icon = IMPORT_SUMMARY_MESSAGE[this.summary.type].icon;
  public detailsOpened = false;
  constructor(
    @Inject(MODAL_DATA)
    readonly summary: ImportSummary,
    private readonly modalRef: ConfirmationModalRef<unknown>
  ) {}

  confirm(): void {
    this.modalRef.emit({ state: true });
    this.modalRef.close();
  }
}
