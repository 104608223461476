import { NgIf } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  Output,
  ViewChild
} from '@angular/core';
import { MentionComponent, MentionData } from '@simlab/design/mention';
import { UiButtonModule } from '@simlab/ui/button';
import { UiIconModule } from '@simlab/ui/icon';
import { CommentLengthBase } from '../comment-list/comment-length.base';

@Component({
    selector: 'feature-stages-add-comment',
    templateUrl: './add-comment.component.html',
    styleUrls: ['./add-comment.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    imports: [NgIf, MentionComponent, UiIconModule, UiButtonModule]
})
export class AddCommentComponent extends CommentLengthBase {
  @ViewChild('sendCommentButton', { read: ElementRef })
  sendCommentButton!: ElementRef;
  @ViewChild(MentionComponent) mention!: MentionComponent;
  @Input() enableAddComment = false;
  @Input() mentionData: MentionData[] = [];
  @Input() set reset(reset: string | null) {
    this.mention?.resetValue();
  }
  @Input() privilegesToAdd = false;
  @Output() commented: EventEmitter<string> = new EventEmitter<string>();

  async emitComment(mention: MentionComponent): Promise<void> {
    const text = await mention.contentTextValue;
    if (!text) {
      return;
    }
    this.commented.emit(text);
    mention.resetValue();
    mention.loseFocus();
  }
}
