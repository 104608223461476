<common-annotation-panel-root
  [name]="isPunchItemSelected() ? panelHeaderTextEdit : panelHeaderTextNew"
  iconType="icon_punch_draft_status"
  [isMenuTriggerVisible]="false"
  [loading]="isLoading()"
>
  @if (!isLoading() && formGroup()) {
    <procore-punch-item-panel-location-3d [showEdit]="true" />
    <procore-general-information-form [form]="formGroup()!" />
    <procore-more-details-form [form]="formGroup()!" />
  }
</common-annotation-panel-root>

<div class="add-edit-punch-item__buttons">
  <button
    design-flat-button
    designButtonColor="secondary"
    (click)="cancel()"
    [designButtonDisabled]="isSavingInProgress()"
  >
    <div i18n="@@CANCEL">Cancel</div>
  </button>

  <button
    design-flat-button
    designButtonColor="accent"
    (click)="save()"
    [designButtonDisabled]="!formGroup()?.valid || isSavingInProgress()"
  >
    <div i18n="@@SAVE">Save</div>
  </button>
</div>
