import {CommonModule} from '@angular/common';
import {ChangeDetectionStrategy, Component, Inject} from '@angular/core';
import {DesignCommonModule} from '@simlab/design/common';
import {DesignDialogWrapperModule} from '@simlab/design/dialog';
import {UiButtonModule} from '../../../ui-button/ui-button.module';
import {UiIconModule} from '../../../ui-icon/public-api';
import {ConfirmationModalRef, MODAL_DATA} from '../../public-api';
import { DesignFlatButton, DesignStrokedButton } from '@simlab/design/button';

@Component({
    selector: 'ui-warning-confirmation-modal',
    templateUrl: './warning-confirmation-modal.component.html',
    styleUrls: ['./warning-confirmation-modal.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    imports: [
        CommonModule,
        DesignCommonModule,
        DesignDialogWrapperModule,
        UiButtonModule,
        UiIconModule,
        DesignFlatButton,
        DesignStrokedButton
    ]
})
export class WarningConfirmationModalComponent {
  constructor(
    @Inject(MODAL_DATA)
    readonly data: {text: string, buttonText: string},
    private readonly modalRef: ConfirmationModalRef<unknown>
  ) {
  }

  confirm(): void {
    this.modalRef.emit({ state: true });
    this.modalRef.close();
  }

  cancel(): void {
    this.modalRef.emit({ state: false });
    this.modalRef.close();
  }
}
