<div class="punch-item-activity__icon-wrapper">
  <i designIcon="info_circle" designSize="1.5rem"></i>
</div>

<div class="punch-item-activity__content">
  <div class="punch-item-activity__content__heading">
    <h3>
      {{ heading() }}
    </h3>

    <div class="punch-item-activity__content__heading__creation-date">
      {{ activity().createdAt | formatDateSignal }}
      {{ activity().createdAt | date: 'H:mm a' }}
    </div>
  </div>

  @if (comment(); as comment) {
    <div
      class="punch-item-activity__content__comment"
      [innerHTML]="comment"
    ></div>
  }

  @if (areAnyAttachments()) {
    <div class="punch-item-activity__content__attachments">
      @for (attachment of activity().attachments; track attachment.id) {
        <ui-chip>
          <div
            class="punch-item-activity__content__attachments__chip-content"
            (click)="downloadFile(attachment)"
          >
            <i
              class="color"
              [designIcon]="
                attachment.filename | getAttachmentIcon: attachment.documentType
              "
              designSize="1rem"
            ></i>
            <span class="filename">{{ attachment.filename }}</span>
          </div>
        </ui-chip>
      }
    </div>
  }
</div>
