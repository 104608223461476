import { Direction } from '@angular/cdk/bidi';
import { NgClass } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  ContentChildren,
  EventEmitter,
  input,
  OnDestroy,
  Output,
  QueryList,
  TemplateRef,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { MenuItemComponent } from '../menu-item/menu-item.component';

export type MenuCloseReason = void | 'click' | 'keydown' | 'tab';

@Component({
    selector: 'design-menu-panel',
    templateUrl: './menu-panel.component.html',
    styleUrls: ['./menu-panel.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    encapsulation: ViewEncapsulation.None,
    imports: [NgClass],
    host: {
        class: 'design-menu-panel',
    }
})
export class MenuPanelComponent implements OnDestroy {
  readonly customPanelClass = input<string>();
  @Output() readonly closed: EventEmitter<MenuCloseReason> =
    new EventEmitter<MenuCloseReason>();
  @ViewChild(TemplateRef) templateRef!: TemplateRef<any>;
  @ContentChildren(MenuItemComponent, { descendants: false })
  items!: QueryList<MenuItemComponent>;

  uiLoginMenu?: boolean;
  xPosition = 'after';
  yPosition = 'below';
  direction!: Direction;

  ngOnDestroy(): void {
    this.closed.complete();
  }
}
