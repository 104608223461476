import { concat, Observable, tap } from 'rxjs';
import { take } from 'rxjs/operators';

export function tapOnce<T>(fn: (v: T) => any) {
  return (source$: Observable<T>) => {
    const tapped$ = source$.pipe(
      tap(fn),
      take(1)
    );

    return concat(tapped$, source$);
  };
}
