import { Component, OnInit, inject } from '@angular/core';
import { TitleService } from '@simlab/ui/title';

const PROJECT_LIST = $localize`:@@PROJECT_LIST:Project List`;

@Component({
    selector: 'feature-projects-projects-root',
    templateUrl: './projects-root.component.html',
    styleUrls: ['./projects-root.component.scss'],
    standalone: false
})
export class ProjectsRootComponent implements OnInit {
      private readonly titleService = inject(TitleService);

  ngOnInit(): void {
    this.titleService.setTitle(PROJECT_LIST);
  }
}
