import { RootNoteStatus } from '@simlab/data-access';
import { TAreaMeasurement } from '@simlab/feature-measurement/models';
import { NoteType } from 'common/annotation-note/models';
import { BlobBody } from './blob-body';
import { Brush } from './brush';
import { Measurement } from './measurement';
import { Media } from './media';
import { NoteBase } from './note-base';
import { Quaternion } from './quaternion';
import { ICreationInfo } from './stages-base.interace';
import { IVector3 } from './vector3';

export interface Note extends NoteBase, ICreationInfo {
  stageId: string;
  creatorId: string;
  lastEditorId: string;
  stakeholderId: string;
  stakeholderName: string;
  audioNotes: Media[];
  documentNotes: Media[];
  photoNotes: Media[];
  procoreDocuments: Media[];
  procoreDrawings: Media[];
  procoreImages: Media[];
  measurementNotes: Measurement[];
  distanceMeasurements: any[];
  areaMeasurements: TAreaMeasurement[];
  brushNotes: Brush[];
  videoNotes: Media[];
  hasStageMatterport?: boolean;
  description: string;
  hasComments: boolean;
  matterTagId?: string;
  scanId?: string;
  isOwner: boolean;
  notificationsEnabled: boolean;
  isFrozen: boolean;
}

export interface CreateRootNote {
  stageId?: string | undefined;
  id?: string | undefined;
  name: string;
  status: RootNoteStatus;
  type: NoteType;
  stakeholderId?: string | undefined;
  digitalNotes?: BlobBody[] | undefined;
  description?: string | undefined;
  scanId?: string | undefined;
  scanTagId?: string | undefined;
  scanProvider: (typeof ScanProviderEnum)[keyof typeof ScanProviderEnum];
}

export const ScanProviderEnum = {
  Unknown: 0,
  Matterport: 1,
  Navvis: 2
} as const;

export interface RootNotePosition {
  positionDto: IVector3;
  rotationDto: Quaternion;
  anchorPointNormalDto: IVector3;
}
