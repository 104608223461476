import { DatePipe } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  inject,
  input
} from '@angular/core';

import { FileCardStates } from '@simlab/documents/models';
import { UiIconModule } from '@simlab/ui/icon';
import { CanOpenDirective } from '../can-open/can-open.directive';
import {
  CanSelectDirective,
  SelectableOption
} from '../can-select/can-select.directive';
import { CanUploadingDirective } from '../can-uploading/can-uploading.directive';
import { DocumentBadgeStateComponent } from '../document-badge-state/document-badge-state.component';
import { DocumentsActionBtnComponent } from '../documents-action-btn/documents-action-btn.component';
import { ItemCardImageComponent } from './item-card-image/item-card-image.component';

@Component({
    selector: 'documents-file-card',
    imports: [
    DatePipe,
    DocumentsActionBtnComponent,
    DocumentBadgeStateComponent,
    UiIconModule,
    ItemCardImageComponent
],
    templateUrl: './file-card.component.html',
    styleUrl: './file-card.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush,
    host: {
        tabindex: '0'
    },
    hostDirectives: [
        {
            directive: CanSelectDirective,
            inputs: ['data']
        },
        {
            directive: CanOpenDirective,
            inputs: ['data'],
            outputs: ['openSource']
        },
        {
            directive: CanUploadingDirective,
            inputs: ['data']
        }
    ]
})
export class FileCardComponent {
  private readonly _selection = inject(SelectableOption);

  protected readonly isSelected = this._selection.selected;

  readonly data = input.required<FileCardStates>();
}
