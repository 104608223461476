import {
  ChangeDetectionStrategy,
  Component,
  computed,
  inject,
  viewChild
} from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { DesignProgressSpinnerComponent } from '@simlab/design/progress-spinner';
import { documentsActionsToken } from '@simlab/documents/data-access';
import { FolderComponent } from '@simlab/documents/ui';
import { ProcoreNoPermissionsComponent } from '../../../pages/documents-page/procore-no-permissions/procore-no-permissions.component';
import { ProcoreProjectPermittedElements } from '../../../services/procore-permitted-document-elements.service';
import { ProcoreInModalSelectStateService } from './services/procore-in-modal-select-state.service';
import { ProcoreDocumentsInModalComponent } from './ui/procore-documents-in-modal/procore-documents-in-modal.component';
import { ProcoreDrawingsInModalComponent } from './ui/procore-drawings-in-modal/procore-drawings-in-modal.component';
import { ProcorePicturesInModalComponent } from './ui/procore-pictures-in-modal/procore-pictures-in-modal.component';

@Component({
  selector: 'feature-projects-procore-documents-wrapper-in-modal',
  standalone: true,
  imports: [
    FolderComponent,
    ProcoreDrawingsInModalComponent,
    ProcoreDocumentsInModalComponent,
    ProcorePicturesInModalComponent,
    ProcoreNoPermissionsComponent,
    DesignProgressSpinnerComponent
  ],
  templateUrl: './procore-documents-wrapper-in-modal.component.html',
  styleUrl: './procore-documents-wrapper-in-modal.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: documentsActionsToken,
      useValue: { actionList: [] }
    },
    ProcoreInModalSelectStateService,
    ProcoreProjectPermittedElements
  ]
})
export class ProcoreDocumentsWrapperInModalComponent {
  private readonly _documents = viewChild(ProcoreDocumentsInModalComponent);
  private readonly _drawings = viewChild(ProcoreDrawingsInModalComponent);
  private readonly _pictures = viewChild(ProcorePicturesInModalComponent);
  private readonly _projectPermittedElements = inject(
    ProcoreProjectPermittedElements
  );

  protected readonly state = inject(ProcoreInModalSelectStateService);
  protected readonly states = toSignal(
    this._projectPermittedElements.getProcoreProjectPermittedElements$()
  );

  readonly selectedDocuments = computed(() => {
    const selectedDocuments = this._documents()?.selectedItems() ?? [];
    const selectedDrawings = this._drawings()?.selectedItems() ?? [];
    const selectedPictures = this._pictures()?.selectedItems() ?? [];

    if (selectedDocuments.length)
      return {
        documents: selectedDocuments,
        from: 'procoreDocuments' as const
      };

    if (selectedDrawings.length)
      return { documents: selectedDrawings, from: 'procoreDrawings' as const };

    if (selectedPictures.length)
      return { documents: selectedPictures, from: 'procorePictures' as const };

    return { documents: [], from: undefined };
  });

  resetSelectedItems(): void {
    this._documents()?.resetSelectedItems();
    this._drawings()?.resetSelectedItems();
    this._pictures()?.resetSelectedItems();
  }
}
