import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  inject,
  input,
  OnInit,
  Renderer2
} from '@angular/core';
import { DesignIcon } from '@simlab/design/icon';
import { DesignSymbolInput } from '@simlab/design/internal';
import { NotificationType } from '@simlab/procore/models';

type NotyficationColors = { baseColor: string; secondColor: string };
const Colors: Record<NotificationType, NotyficationColors> = {
  Blue: {
    baseColor: 'var(--ui-theme-info-bg)',
    secondColor: 'var(--ui-theme-info-primary)'
  },
  Green: {
    baseColor: 'var(--ui-theme-success-bg)',
    secondColor: 'var(--ui-theme-success-primary)'
  },
  Yellow: {
    baseColor: 'var(--ui-theme-warning-bg)',
    secondColor: 'var(--ui-theme-warning-primary)'
  }
};

@Component({
    selector: 'procore-annotation-panel-notifications',
    imports: [DesignIcon],
    templateUrl: './notifications.component.html',
    styleUrl: './notifications.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class NotificationsComponent implements OnInit {
  private readonly _elementRef: ElementRef = inject(ElementRef);
  private readonly _renderer: Renderer2 = inject(Renderer2);

  readonly title = input.required<string>();
  readonly text = input.required<string>();
  readonly icon = input.required<DesignSymbolInput>();
  readonly type = input.required<NotificationType>();

  ngOnInit(): void {
    const colors = Colors[this.type()];
    this._renderer.setStyle(
      this._elementRef.nativeElement,
      '--baseColor',
      colors.baseColor,
      2
    );
    this._renderer.setStyle(
      this._elementRef.nativeElement,
      '--secondColor',
      colors.secondColor,
      2
    );
  }
}
