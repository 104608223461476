import { ChangeDetectionStrategy, Component, input } from '@angular/core';
import { DesignIconButton } from '@simlab/design/button';
import { DesignIcon } from '@simlab/design/icon';
import { UiMenuPanelModule } from '@simlab/design/menu-panel';
import { DocumentComponentsLoadedState } from '@simlab/documents/models';
import { DocumentsActionMenuComponent } from '../documents-action-menu/documents-action-menu.component';

@Component({
    selector: 'documents-documents-action-btn',
    imports: [
        DocumentsActionMenuComponent,
        UiMenuPanelModule,
        DesignIcon,
        DesignIconButton
    ],
    templateUrl: './documents-action-btn.component.html',
    styleUrls: ['./documents-action-btn.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class DocumentsActionBtnComponent {
  readonly data = input.required<DocumentComponentsLoadedState>();
}
