/* eslint-disable @angular-eslint/no-host-metadata-property */
import {
  ChangeDetectionStrategy,
  Component,
  ContentChildren,
  QueryList,
  ViewEncapsulation
} from '@angular/core';
import { DRAWER_CONTAINER } from '../../tokens/drawer-container.token';
import { DrawerContainerComponent } from '../drawer-container/drawer-container.component';
import { SidenavComponent } from '../sidenav/sidenav.component';

@Component({
    selector: 'ui-sidenav-container',
    templateUrl: './sidenav-container.component.html',
    styleUrls: ['./sidenav-container.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    encapsulation: ViewEncapsulation.None,
    providers: [
        {
            provide: DRAWER_CONTAINER,
            useExisting: SidenavContainerComponent
        }
    ],
    host: {
        class: 'ui-drawer-container ui-sidenav-container'
    },
    standalone: false
})
export class SidenavContainerComponent extends DrawerContainerComponent {
  //Dzięki temu działa ustawienie marginesu w SidenavContentComponent
  @ContentChildren(SidenavComponent, {
    descendants: true
  })
  override _allDrawers!: QueryList<SidenavComponent>;
}
