import {
  DocumentTypeEnum,
  DocumentTypeEnumValues
} from '@simlab/design/attachment-icon';
import {
  DocumentSourceEnum,
  DocumentSourceEnumValues
} from '@simlab/documents/models';

export interface DocumentTypeItem {
  id: DocumentTypeEnumValues;
  name: string;
}

export const documentTypeOptions: DocumentTypeItem[] = [
  { id: DocumentTypeEnum.Audio, name: 'Audio' },
  { id: DocumentTypeEnum.Blueprint, name: 'Blueprint' },
  { id: DocumentTypeEnum.Doc, name: 'Doc' },
  { id: DocumentTypeEnum.Image, name: 'Image' },
  { id: DocumentTypeEnum.Model3D, name: 'Model3D' },
  { id: DocumentTypeEnum.Unknown, name: 'Unknown' },
  { id: DocumentTypeEnum.Video, name: 'Video' }
];

export interface DocumentSource {
  id: DocumentSourceEnumValues;
  name: string;
}

export const documentSourcesList: DocumentSource[] = [
  // { id: DocumentSourceEnum.Unknown, name: 'unknown' },
  { id: DocumentSourceEnum.Local, name: 'local' },
  { id: DocumentSourceEnum.Procore, name: 'procore' }
];

export const BlobErrorCodesEnum = {
  InvalidExpirationDate: 'blob.url.has.invalid.expiration.date',
  InvalidPath: 'blob.url.has.invalid.storage.base.path',
  NotAcceptedExtension: 'blob.url.has.not.accepted.extension'
} as const;

export type BlobErrorCodesKeys = keyof typeof BlobErrorCodesEnum;
export type BlobErrorCodes = {
  [ErrorKey in BlobErrorCodesKeys]: (typeof BlobErrorCodesEnum)[ErrorKey];
}[BlobErrorCodesKeys];

export const getTranslationForBlobErrorCode = (
  errorCode: BlobErrorCodes,
  name: string
): string => {
  const extension = name.split('.').pop();
  switch (errorCode) {
    case BlobErrorCodesEnum.NotAcceptedExtension:
      return $localize`:@@NOT_ACCEPTED_EXTENSION:Extension: .${extension} is not acceptable.`;

    case BlobErrorCodesEnum.InvalidPath:
      return $localize`:@@BLOB_URL_INVALID_PATH:Blob url has invalid path.`;

    case BlobErrorCodesEnum.InvalidExpirationDate:
      return $localize`:@@BLOB_URL_INVALID_EXPIRATION_DATE:Blob url has invalid expiration date.`;

    default:
      return '';
  }
};
