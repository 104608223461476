import {
  CustomProcoreField,
  FieldsConfigurationByType,
  ProcoreAnnotationFieldsetType,
  PunchItemConfigurableFieldset,
  punchItemDefaultFieldsetKeys,
  rfiDefaultFieldsetKeys
} from '@simlab/procore/shared/models';

export const prepareFieldsetConfig = <T extends PunchItemConfigurableFieldset>(
  configurableFieldset: T
): FieldsConfigurationByType<T['type']> => {
  const initialReduceValue = {
    defaultFields: {},
    customFields: [] as CustomProcoreField[]
  } as FieldsConfigurationByType<T['type']>;

  const fieldsetKeys =
    configurableFieldset.type === ProcoreAnnotationFieldsetType.PunchItem
      ? punchItemDefaultFieldsetKeys
      : rfiDefaultFieldsetKeys;

  return Object.keys(configurableFieldset.fields).reduce(
    ({ defaultFields, customFields }, key) => {
      const field = configurableFieldset.fields[key];

      if (fieldsetKeys.find((v) => v === key)) {
        defaultFields = { ...defaultFields, [key]: field };
      } else {
        customFields.push(field as CustomProcoreField);
      }

      return { defaultFields, customFields };
    },
    initialReduceValue
  );
};
